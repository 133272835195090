import React from "react";
import {
  Form,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
const ImportModal = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.onToggleModal}>
      <ModalHeader toggle={props.onToggleModal}>
        Import {props.title}
      </ModalHeader>
      <ModalBody>
        <Form>
          <Label for="import">Import File (.xlsx only)</Label>
          <Input
            type="file"
            name="import"
            onChange={props.handleSelectFile}
            accept={props.accept}
          />
          {props.title === "Cars" ? (
            <Label check className="mt-3">
              <Input
                type="checkbox"
                name="overwrite"
                onChange={props.handleOverWrite}
              />{" "}
              Hide cars from search results that don't exist in this sheet?
            </Label>
          ) : null}
        </Form>
      </ModalBody>
      <ModalFooter className="d-flex align-items-center justify-content-between">
        <div>
          <a
            className="btn btn-primary btn-outline"
            download
            href={props.sampleUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download Sample
          </a>
        </div>
        <div>
          <Button className="mr-1" color="info" onClick={props.onImport}>
            Import
          </Button>
          <Button color="secondary" onClick={props.onToggleModal}>
            Cancel
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ImportModal;
