import { getAuthenticatedAxios } from "../../helpers";
import api from "../../apiEndPoint";
// import { encrypt } from ".";
// import { getAndDecryptDataFromResponse } from "../../../Api/@driveavva/core";

export const UserApiEndpoints = {
  UserList: () =>
    getAuthenticatedAxios(true, true)
      .get(`${api["@driveavva"].new.Users}`)
      .then((r) => r.data),
  UserDetails: (UserId) =>
    getAuthenticatedAxios(true, true)
      .get(`${api["@driveavva"].new.Users}/${UserId}`)
      .then(async (r) => {
        return r.data;
      }),
  Notifications: (userId) =>
    getAuthenticatedAxios(true, true)
      .get(
        `${api["@driveavva"].new.Notifications}${
          userId ? `?user=${userId}` : ""
        }`
      ) // query="id"
      .then(async (r) => {
        return r.data;
      }),
  Banners: (userId) =>
    getAuthenticatedAxios(true, true)
      .get(`${api["@driveavva"].new.Banners}${userId ? `?user=${userId}` : ""}`) // query="id"
      .then(async (r) => {
        return r.data;
      }),
  ModifyBanner: (id, payload) =>
    getAuthenticatedAxios(true, true)
      .post(`${api["@driveavva"].new.Banners}/${id}`, payload) // query="id"
      .then(async (r) => {
        return r.data;
      }),
  DeleteBanner: (id) =>
    getAuthenticatedAxios(true, true)
      .delete(`${api["@driveavva"].new.Banners}/${id}`) // query="id"
      .then(async (r) => {
        return r.data;
      }),
  Send: (type, data, userId) =>
    getAuthenticatedAxios(true, true)
      .post(
        `${api["@driveavva"].new.Users}/send${userId ? `?user=${userId}` : ""}`,
        { type, data }
      )
      .then(async (r) => {
        return r.data;
      }),
  ImportFromDeal: (dealId) =>
    getAuthenticatedAxios(true, true)
      .post(`${process.env.REACT_APP_NEW_API_ENDPOINT}/members/hubspot`, {
        ids: [dealId],
      })
      .then(async (r) => {
        return r.data;
      }),
  UpdatePhone: (userId, phone) =>
    getAuthenticatedAxios(true, true)
      .post(`${api["@driveavva"].new.Users}/${userId}/update-phone`, { phone })
      .then(async (r) => {
        return r.data;
      }),
  UpdateStripe: (userId, payload) =>
    getAuthenticatedAxios(true, true)
      .post(`${api["@driveavva"].new.Users}/${userId}/update-stripe`, payload)
      .then(async (r) => {
        return r.data;
      }),
  ImportFromStripeAndDeal: (dealId, stripeId, stripePaymentIntentId) =>
    getAuthenticatedAxios(true, true)
      .post(`${process.env.REACT_APP_NEW_API_ENDPOINT}/members/stripe`, {
        dealId,
        stripeId,
        stripePaymentIntentId,
      })
      .then(async (r) => {
        return r.data;
      }),
  ChangeRole: (userId, role) =>
    getAuthenticatedAxios(true, true)
      .post(`${api["@driveavva"].new.Users}/${userId}/change-role`, {
        role,
      })
      .then(async (r) => {
        return r.data;
      }),
};
