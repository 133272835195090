import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import SharedComponent from "../../Shared";
import { Api } from "../../../../Api/index";
import DateTimeSelector from "../DateTimePicker/DateTimeSelector";

export class PaymentAlertModal extends SharedComponent {
  state = {
    type: false,
    errors: false,
    submitting: false,
  };

  validate = () => {
    if (!this.state.type) {
      this.setState({
        errors: {
          type: "Required",
        },
      });
      return false;
    }

    return {
      type: this.state.type,
    };
  };

  submit = () => {
    const payload = this.validate();
    if (payload) {
      console.log(payload);
      this.setState({ submitting: true });
      Api["@driveavva"].new.Payments.Send(payload.type, this.props.user.id)
        .then(() => {
          this.renderSuccessAlert("Success!", "");
          this.setState({
            submitting: false,
            errors: false,
          });
          this.props.onClose();
        })
        .catch((e) => {
          console.warn(e);
          this.renderErrorAlert("Encountered error sending alert", e.message);
          this.setState({
            submitting: false,
          });
        });
    }
  };

  error = (label) => (this.state.errors && this.state.errors[label]) || false;

  change = (e, label = "") => {
    const l = label || e.currentTarget.name;
    const v = typeof e === "object" ? e.currentTarget.value : e;
    const state = this.state;
    if (state.errors && state.errors[l]) delete state.errors[l];
    this.setState({
      ...state,
      [l]: v,
    });
  };

  render() {
    if (!this.props.user) return null;

    const alertTypes = this.props.isForPaul
      ? ["car_enabled", "car_disabled"]
      : ["past_due", "card_expiring", "payment_received", "payment_upcoming"];

    return (
      <>
        <Modal isOpen={this.props.isOpen} toggle={this.props.onClose}>
          <ModalHeader toggle={this.props.onClose}>
            {this.props.isForPaul
              ? "Send Enable/Disable Alert"
              : "Test Payment Alerts"}
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="push_notification">Type</Label>
                <Input
                  type="select"
                  name="type"
                  onChange={(e) =>
                    this.setState({ type: e.currentTarget.value })
                  }
                >
                  <option value="">---</option>
                  {alertTypes.map((type) => (
                    <option key={`n${type}`} value={type}>
                      {type}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              onClick={this.submit}
              disabled={this.state.submitting}
            >
              Send{this.state.submitting ? "ing" : ""}
            </Button>
            <Button color="secondary" onClick={this.props.onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
