import React from "react";
import ContentWrapper from "../../Layout/ContentWrapper";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import "rc-pagination/assets/index.css";
import Datatable from "../../Tables/Datatable";
import swal from "sweetalert";
import SharedComponent from "../Shared";
import Moment from "react-moment";
import Switch from "react-switch";
import { Api } from "../../../Api/index";
export class CarDeliverySlots extends SharedComponent {
  state = {
    inputVal: this.props.inputValue,
    dropdownOpen: false,
    isLoading: true,
    items: [],
    data: {
      timeDate: "",
      newTimeDate: "",
    },
    modal: false,
    checked: false,
  };

  componentDidMount() {
    this.queryItems();
  }
  render() {
    const { isLoading, items } = this.state;
    return (
      <ContentWrapper>
        <div className="content-heading d-flex align-items-center justify-content-between">
          <div>Car Delivery Slots</div>
          <Button onClick={this.openCarSlotsModel} color="success">
            Add Slots
          </Button>
        </div>
        <Card className="card-default">
          <CardBody>
            <Form onSubmit={this.handleSubmit} className="mb-3">
              <Row className="d-flex align-items-center ">
                <Col md={4}>
                  <FormGroup>
                    <Label for="timeDate">Date</Label>
                    <Input
                      type="date"
                      name="timeDate"
                      id="timeDate"
                      value={this.state.data.timeDate}
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={2} className="d-flex">
                  <FormGroup className="mr-2">
                    <div
                      style={{ height: 30 }}
                      className="d-none d-md-block d-lg-block"
                    />
                    <Button color={"info"} type="submit" className="w-100">
                      Search
                    </Button>
                  </FormGroup>

                  <FormGroup>
                    <div
                      style={{ height: 30 }}
                      className="d-none d-md-block d-lg-block"
                    />
                    <Button
                      color={"success"}
                      onClick={this.resetData}
                      className="w-100"
                    >
                      Reset Data
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            {!isLoading ? (
              <React.Fragment>
                {items.length !== 0 ? (
                  <React.Fragment>
                    <Row>
                      <Col md={12}>
                        <Datatable options={this.renderOption()}>
                          <Table className="table table-bordered table-striped table-hover mb-4">
                            <thead>
                              <tr>
                                <th className="sort-alpha">ID</th>
                                <th className="sort-alpha">Date</th>
                                <th className="sort-alpha">Interval</th>
                                <th className="sort-alpha">Status</th>
                                <th className="sort-alpha">Control</th>
                              </tr>
                            </thead>
                            <tbody>
                              {items.map((item) => (
                                <tr key={item.id}>
                                  <td>{item.id}</td>
                                  <td>
                                    <Moment format="MM/DD/YYYY">
                                      {item.deliveryDate}
                                    </Moment>
                                  </td>
                                  <td>{item.interval}</td>
                                  <td className="text-center">
                                    <Switch
                                      onChange={() =>
                                        this.handleToggleSlotsStatus(item.id)
                                      }
                                      checked={item.status === "Active"}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <div className="d-flex align-items-center justify-content-between w-25">
                                      <Button
                                        onClick={() =>
                                          this.handleDelete(item.id)
                                        }
                                        color="danger"
                                        className="btn btn-warning d-inline-flex align-items-center"
                                      >
                                        <em className="fa-1x far fa-trash-alt" />
                                      </Button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Datatable>
                      </Col>
                    </Row>
                  </React.Fragment>
                ) : (
                  this.renderSadFace(true)
                )}
              </React.Fragment>
            ) : (
              this.renderLoader()
            )}
          </CardBody>
        </Card>
        <Modal isOpen={this.state.modal} toggle={this.openCarSlotsModel}>
          <ModalHeader toggle={this.openCarSlotsModel}>
            Select a Date
          </ModalHeader>
          <ModalBody>
            <Form>
              <Label>Add Car Delivery Slots</Label>
              <Input
                type="date"
                name="newTimeDate"
                value={this.state.data.newTimeDate}
                onChange={this.handleChange}
              />
              <Label className="mt-2">Interval</Label>
              <Input
                type="text"
                name="interval"
                value={this.state.data.interval}
                onChange={this.handleChange}
              />
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="info" onClick={this.AddCarSlots}>
              Add
            </Button>
            <Button color="secondary" onClick={this.openCarSlotsModel}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </ContentWrapper>
    );
  }
  queryItems = () => {
    const { timeDate } = this.state.data;
    Api.CarDeliverySlots.CarDeliverySlotsList(timeDate)
      .then((items) => this.setState({ items, isLoading: false }))
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  doSubmit = () => {
    this.queryItems();
  };
  resetData = () => {
    const data = this.state.data;
    data.timeDate = "";
    this.setState({ data });
    this.queryItems();
  };

  handleToggleSlotsStatus = (id) => {
    Api.CarDeliverySlots.ChangeSlotStatus(id)
      .then(() => {
        this.queryItems();
      })
      .catch((error) => {
        console.log(error.response);
        this.renderErrorAlert(
          "Ops!",
          "Something went wrong, please try again!"
        );
      });
  };

  openCarSlotsModel = () => {
    this.setState({ modal: !this.state.modal, data: {} });
  };

  AddCarSlots = () => {
    const { newTimeDate, interval } = this.state.data;
    Api.CarDeliverySlots.AddNewSlot({
      deliveryDate: newTimeDate,
      interval,
    })
      .then(() => {
        this.queryItems();
        this.openCarSlotsModel();
        this.renderSuccessAlert("Added successfully", "Click the button");
      })
      .catch(() => {
        this.renderErrorAlert(
          "Ops!",
          "Something went wrong, please try again!"
        );
      });
  };

  handleDeleteSlots = (id) => {
    Api.CarDeliverySlots.DeleteSlot(id)
      .then(() => {
        this.queryItems();
        this.renderSuccessAlert("Deleted successfully", "Click the button");
      })
      .catch(() => {
        this.renderErrorAlert(
          "Ops!",
          "Something went wrong, please try again!"
        );
      });
  };
  handleDelete = (id) => {
    swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.handleDeleteSlots(id);
      } else {
        swal("It was canceled by the deletion");
      }
    });
  };
}

CarDeliverySlots = withRouter(CarDeliverySlots);
