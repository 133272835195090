import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Input, Button } from "reactstrap";
import FormValidator from "../Forms/FormValidator.js";
import { apiUrlLogin } from "../../Api";

class Login extends Component {
  state = {
    disabled: false,
    loginForm: {
      email: "",
      password: ""
    },
    validationError: false
  };

  validateOnChange = event => {
    const input = event.target;
    const form = input.form;
    const value = input.value;

    const result = FormValidator.validate(input);

    this.setState({
      [form.name]: {
        ...this.state[form.name],
        [input.name]: value,
        errors: {
          ...this.state[form.name].errors,
          [input.name]: result
        }
      }
    });
  };

  onSubmit = e => {
    const { email, password } = this.state.loginForm;
    e.preventDefault();
    const ClientOAuth2 = require("client-oauth2");
    const form = e.target;
    const inputs = [...form.elements].filter(i =>
      ["INPUT"].includes(i.nodeName)
    );
    const { errors } = FormValidator.bulkValidate(inputs);
    this.setState({
      [form.name]: {
        ...this.state[form.name],
        errors
      }
    });
    const userAuth = new ClientOAuth2({
      clientId: "riot-admin",
      clientSecret: "riot-admin",
      accessTokenUri: `${apiUrlLogin}/connect/token`,
      scopes: ["admin", "offline_access"]
    });

    console.log('userAuth: ', userAuth)
    this.setState({ disabled: true, validationError: false });
    userAuth.owner
      .getToken(email, password)
      .then(response => {
        console.log(response);
        localStorage.setItem("token", response.data.access_token);
        localStorage.setItem("refToken", response.data.refresh_token);
        window.location = "/inventory";
      })
      .catch(err => {
        this.setState({
          disabled: false,
          validationError: true
        });
        console.log(err);
      });
  };

  hasError = (formName, inputName, method) => {
    return (
      this.state[formName] &&
      this.state[formName].errors &&
      this.state[formName].errors[inputName] &&
      this.state[formName].errors[inputName][method]
    );
  };

  render() {
    return (
      <div className="block-center mt-4 wd-xl">
        <div className="card card-flat">
          <div className="card-header text-center bg-dark">
            <h3 className="text-white mb-0">AVVA</h3>
          </div>
          <div className="card-body">
            <p className="text-center py-2">SIGN IN TO CONTINUE</p>
            <form className="mb-3" name="loginForm" onSubmit={this.onSubmit}>
              <div className="form-group">
                <Input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Enter email"
                  invalid={this.hasError("loginForm", "email", "required")}
                  onChange={this.validateOnChange}
                  data-validate='["required"]'
                  value={this.state.loginForm.email}
                />
                {this.hasError("loginForm", "email", "required") && (
                  <span className="invalid-feedback">
                    Email field is required
                  </span>
                )}
              </div>

              <div className="form-group">
                <Input
                  type="password"
                  id="id-password"
                  name="password"
                  className="form-control"
                  placeholder="Password"
                  invalid={this.hasError("loginForm", "password", "required")}
                  onChange={this.validateOnChange}
                  data-validate='["required"]'
                  value={this.state.loginForm.password}
                />
                {this.hasError("loginForm", "password", "required") && (
                  <span className="invalid-feedback">
                    Password field is required
                  </span>
                )}
                {this.state.validationError === true && (
                  <span className="text-danger mt-1">
                    The email or password you entered is incorrect.
                  </span>
                )}
              </div>
              <div className="clearfix">
                <div className="text-center">
                  <Link to="recover" className="text-muted">
                    Forgot your password?
                  </Link>
                </div>
              </div>
              <Button
                className="btn btn-block btn-primary mt-3"
                type="submit"
                color="info"
                disabled={this.state.disabled}
              >
                Login
              </Button>
            </form>
          </div>
        </div>
        <div className="p-3 text-center">
          <span className="mr-2">&copy;</span>
          <span>{new Date().getFullYear()}</span>
          <span className="mx-2">-</span>
          <span> AVVA </span>
        </div>
      </div>
    );
  }
}

export { Login };
