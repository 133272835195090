import React from "react";
import { Row, Col, Table, Card, CardBody, Badge, Collapse } from "reactstrap";
import ContentWrapper from "../../Layout/ContentWrapper";
import Link from "react-router-dom/Link";
import "rc-pagination/assets/index.css";
import SharedComponent from "../Shared";
import { Api } from "../../../Api/index";

export const statusToColor = (status) => {
  switch (status) {
    case "Accepted":
      return "success";
    case "New":
      return "info";
    case "Rejected":
      return "danger";
    case "Cancelled":
      return "warning";
    case "Pending":
      return "primary";
    default:
      return "muted";
  }
};

/**
 * Deal stages that are expanded by default
 */
const OPEN_DEALSTAGES = ["Run Insurance", "Review Finances", "Manual BG/MVR"];

class ApplicationsList extends SharedComponent {
  state = {
    inputVal: this.props.inputValue,
    isLoading: true,
    data: {
      name: "",
      uuid: "",
      status: "",
      showAll: false,
    },
    stages: undefined,
    showAll: undefined,
    modal: false,
    dropdown: false,
    syncing: false,
  };

  query = async () => {
    // To open the Agreement Pending to show the Panda Doc faking
    if (window.environment !== "production")
      OPEN_DEALSTAGES.push("Agreement Pending");
    const showAll = window.location.search === "?show=all";
    if (this.state.showAll !== showAll) {
      this.setState({
        showAll,
      });
      setTimeout(() => {
        this.queryItems();
      }, 200);
    }
  };

  async componentDidMount() {
    this.query();
    // this.ApplicationStatistics();
  }

  async componentDidUpdate() {
    this.query();
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  sync = () => {
    this.setState({
      syncing: true,
    });
    Api["@driveavva"].core.AppsManagement.HubspotSync()
      .then(() => this.setState({ syncing: false }))
      .catch(() => this.setState({ syncing: false }));
  };

  render() {
    const { isLoading, items, data } = this.state;
    return (
      <ContentWrapper>
        <div className="content-heading d-flex justify-content-between">
          <div>Applications List</div>
          <div className="d-flex align-items-center">
            {/* <Link
              style={{
                width: "100%",
                textDecoration: "none",
                fontSize: "1rem",
                marginRight: "1rem",
              }}
              to={`/applications${!this.state.showAll ? "?show=all" : ""}`}
            >
              Show {this.state.showAll ? "submitted" : "all"}?
            </Link> */}
            {/* <ButtonDropdown
              isOpen={this.state.dropdownOpen}
              toggle={this.toggle}
              disabled={items.length === 0}
            >
              <DropdownToggle caret color="primary">
                Actions
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem disabled={this.state.syncing} onClick={this.sync}>
                  Sync{this.state.syncing ? "ing" : ""} to Hubspot
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown> */}
          </div>
        </div>

        <Card className="card-default">
          <CardBody>
            {items ? (
              <>
                {Object.keys(items).map((key) => (
                  <ApplicationTable
                    key={key}
                    headline={`${key} (${items[key].length})`}
                    items={items[key]}
                    open={OPEN_DEALSTAGES.includes(key)}
                  />
                ))}
              </>
            ) : (
              this.renderLoader()
            )}
          </CardBody>
        </Card>
      </ContentWrapper>
    );
  }

  getDealStages = async () => {
    if (this.state.stages) return;
    this.setState({
      stages: (
        await Api["@driveavva"].core.AppsManagement.HubspotPipelineDetails()
      ).data,
    });
  };

  queryItems = async (show) => {
    await this.getDealStages();
    this.setState({
      isLoading: true,
    });
    Api["@driveavva"].core.AppsManagement.ApplicationsList(
      this.state.data.uuid,
      this.state.data.status,
      true
    )
      .then((list) => {
        if (list.status !== "success") {
          console.error(list);
        }

        const items = {};

        this.state.stages.forEach(([label, id]) => {
          if (!items[label]) items[label] = [];
        });
        console.log(`Got items`, list, this.state.stages, items);

        list.data.forEach((item) => {
          let stageName = "Unknown";
          this.state.stages.forEach(([label, id]) => {
            if (id === item.hubspot_deal_stage_id) {
              stageName = label;
              return;
            }
          });

          item["_hubspot_deal_stage"] = stageName;
          if (!items[stageName]) items[stageName] = [];
          items[stageName].push(item);
        });

        // Bubble import items to the top
        const sortedItems = {};

        Object.keys(items)
          .sort(
            (a, b) => OPEN_DEALSTAGES.includes(b) - OPEN_DEALSTAGES.includes(a)
          )
          .forEach((key) => {
            sortedItems[key] = items[key];
          });
        this.setState({
          isLoading: false,
          items: sortedItems,
        });
      })
      .catch((error) => {
        console.error(
          "[ApplicationsList.queryItems] Encountered error fetching applications",
          error
        );
        this.setState({ isLoading: false });
        console.log(error);
      });
  };
  doSubmit = () => {
    this.queryItems(1);
  };

  resetData = () => {
    const data = this.state.data;
    data.id = "";
    data.status = "";
    data.uuid = "";
    this.setState({ data });
    this.queryItems();
  };
}

export { ApplicationsList };

class ApplicationTable extends SharedComponent {
  state = {
    open: false,
  };

  renderHubspotStatusBadge(item) {
    const appFinances = Array.isArray(item.application_finances)
      ? item.application_finances.length
        ? item.application_finances[0]
        : null
      : item.application_finances;
    const finrec = appFinances
      ? appFinances.recommendation_override || appFinances.recommendation
      : false;

    return (
      <>
        <Badge>
          Deal Stage: {item._hubspot_deal_stage || "N/A"} | Screen:{" "}
          {item.status}
        </Badge>
        <br></br>
        {finrec && (
          <Badge
            color={
              finrec === "FAIL"
                ? "danger"
                : finrec === "PASS"
                ? "success"
                : "info"
            }
          >
            Financial Rec: {finrec}
          </Badge>
        )}
        {item.denial_reason && (
          <>
            <br></br>
            <Badge color="danger">Denial Reason: {item.denial_reason}</Badge>
          </>
        )}
        <br></br>
        {appFinances &&
          appFinances.fincalc_json &&
          appFinances.fincalc_json.config &&
          item.accounts &&
          item.accounts
            .filter(({ fincalc_json }) => !!fincalc_json)
            .map(({ fincalc_json }) => (
              <Badge
                color={
                  fincalc_json.recommendation === "PASS"
                    ? "success"
                    : fincalc_json.recommendation === "NOT_DETERMINED"
                    ? "info"
                    : "danger"
                }
              >
                {fincalc_json.draftableDays}/
                {appFinances.fincalc_json.graph.data.length}
              </Badge>
            ))}
      </>
    );
  }

  legalName(item) {
    if (!item || !item.first_name) return "N/A";
    return (
      item.first_name +
      " " +
      (item.middle_name && item.middle_name.length !== 1
        ? item.middle_name + " "
        : "") +
      item.last_name
    );
  }

  componentDidMount() {
    if (this.props.open && !this.state.open) {
      this.setState({ open: true });
    }
  }

  renderFakePandaDocs(item) {
    if (item._hubspot_deal_stage !== "Agreement Pending") return null;
    return (
      <div
        onClick={() => {
          this.renderConfirmAlert(
            `This will fully mimick a member signing and paying the initial deposit via Pandadocs. After it completes the Application will be moved to "Schedule Delivery" and the member will be in the members tab. Please allow a few seconds for it to process in the background`,
            async () => {
              Api["@driveavva"].core.AppsManagement.FakePandaDocs(item.uuid)
                .then(() =>
                  this.renderSuccessAlert(
                    "Success!",
                    "We have successfully queued the PandaDoc. Please allow 1-2 minutes for it to process.",
                    () => window.location.reload()
                  )
                )
                .catch((e) => {
                  console.info("Error", e);
                  this.renderErrorAlert("Error", e.error.message);
                });
            }
          );
        }}
        color="warn"
        className="btn btn-warning d-inline-flex align-items-center mt-1"
      >
        Fake PD
      </div>
    );
  }

  render() {
    return (
      <div className="mt-2">
        <div
          class={`py-2 px-2 d-flex justify-content-between ${
            this.state.open ? "bg-light" : "bg-white"
          }`}
          style={{ cursor: "pointer" }}
          onClick={() => this.setState({ open: !this.state.open })}
        >
          <h3 class="m-0">{this.props.headline}</h3>
          <h3>{this.state.open ? "-" : "+"}</h3>
        </div>
        <Collapse isOpen={this.state.open}>
          <Row>
            <Col md={12} className="table-responsive">
              <Table className="table table-bordered table-striped table-hover mb-4">
                <thead>
                  <tr>
                    <th className="sort-alpha">User</th>
                    <th className="sort-alpha" style={{ width: 350 }}>
                      Status
                    </th>
                    <th className="sort-alpha" style={{ width: 350 }}>
                      Vehicle
                    </th>
                    <th className="sort-alpha" style={{ width: 250 }}></th>
                    <th className="sort-alpha" style={{ width: 150 }}>
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.items.map((item) => (
                    <tr key={item.uuid}>
                      <td>
                        <div className="d-flex">
                          <h4 className="mr-2 mb-0">
                            {this.legalName(item.user_profiles)}
                          </h4>

                          <a
                            href={`/users/update/${item.user_profiles.user_id}`}
                          >
                            View User
                          </a>
                        </div>
                        <div>
                          {item.user_profiles.phone} {item.user_profiles.email}
                        </div>
                      </td>
                      <td>{this.renderHubspotStatusBadge(item)}</td>

                      <td>
                        {item.vehicles && (
                          <a
                            href={`/inventory/update/${item.vehicles.depricated_id}`}
                            className="d-flex align-items-center"
                          >
                            {item.vehicles.photo_url && (
                              <img
                                src={item.vehicles.photo_url}
                                height={30}
                                alt={`${item.uuid}'s vehicle`}
                              />
                            )}
                            <div className="ml-2">
                              <div>
                                {item.vehicles.year} {item.vehicles.make}{" "}
                                {item.vehicles.model}
                                <span className="ml-2">
                                  {this.renderCents(
                                    item.vehicles.monthly_payment
                                  )}
                                  /month
                                </span>
                              </div>
                              <div>
                                <i>{item.vehicles.vin}</i>
                              </div>
                            </div>
                          </a>
                        )}
                      </td>
                      <td>
                        <div>
                          {this.renderDate(item.updated_at, "Updated: ")}
                        </div>
                        <div>
                          {this.renderDate(item.submitted_at, "Submitted: ")}
                        </div>
                      </td>
                      <td className="text-center">
                        <div>
                          <Link
                            to={`/applications/${item.uuid}`}
                            color="primary"
                            className="btn btn-primary d-inline-flex align-items-center mr-1"
                          >
                            View
                          </Link>
                          {this.renderFakePandaDocs(item)}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Collapse>
      </div>
    );
  }
}
