import { getDataFromResponse, getAuthenticatedAxios } from "./helpers";
import api from "./apiEndPoint";

export const DeliveryRequestApiEndpoints = {
  DeliveryRequestList: (Status, page, currentPage, pageSize) => {
    let url = new URL(api.deliveryRequest);
    // Apply pagination
    if (Status) {
      url.searchParams.append("Status", Status);
    }
    url.searchParams.append("Page", page || currentPage);
    url.searchParams.append("PageSize", pageSize);
    return getAuthenticatedAxios()
      .get(url)
      .then(getDataFromResponse);
  },
  SendCarDelivery: (applicationId, requestId) =>
    getAuthenticatedAxios()
      .put(`${api.deliveryRequest}/${requestId}/delivered`, { applicationId })
      .then(getDataFromResponse),

  CarDeliveryRequests: (data) =>
    getAuthenticatedAxios()
      .put("/CarDeliveryRequests", data)
      .then(getDataFromResponse),
};
