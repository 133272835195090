import React from "react";
import ContentWrapper from "../../Layout/ContentWrapper";
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Table,
  Badge,
  DropdownToggle,
  ButtonDropdown,
  DropdownMenu,
  DropdownItem,
  Tooltip,
} from "reactstrap";
import Pagination from "rc-pagination";
import { Link, withRouter } from "react-router-dom";
import SharedComponent from "../Shared";
import "rc-pagination/assets/index.css";
import { Api } from "../../../Api/index";
import ImportModal from "./import.modal";
import ReactSwitch from "react-switch";
import SeedModal from "./Seed.modal";
import swal from "sweetalert";
import { ShareLink } from "../../Common/ShareLink";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { BulkAdd } from "../../../Api/@driveavva/core/car.api";

const DATETIME_FORMAT = new Intl.DateTimeFormat("en-US", {
  day: "2-digit",
  month: "long",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
});

export class InventoriesList extends SharedComponent {
  state = {
    inputVal: this.props.inputValue,
    dropdownOpen: false,
    isLoading: true,
    data: {},
    makes: [],
    models: [],
    styles: [],
    years: [],
    items: [],
    requestOptions: {
      currentPage: 1,
      totalPages: 1,
      totalItems: 0,
      pageSize: 50000,
    },
    importCarsModal: false,
    importEquipmentsTechnicalSpecs: false,
    importSeedsModal: false,
    statistics: {},
    status: [
      { id: 1, value: "Available" },
      { id: 2, value: "InUse" },
    ],
    overwrite: false,
    tooltips: {},
    resettingTiers: false,
    sendingToNewApi: false,
  };
  componentDidMount() {
    this.renderMakeAndStyleList();
    this.queryItems();
  }

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = this.state.items;
    const [removed] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, removed);

    items.map(this.moveCar);

    this.setState({
      items,
    });
  };

  moveCar = (car, order) => {
    Api.CarManagement.UpdateOrder(car.id, order).catch((e) => {
      console.warn(e);
      this.setState({ moving: false });
      this.renderErrorAlert("Ops!", "Something went wrong, please try again!");
    });
  };

  deleteCar = (car) => {
    swal({
      title: "Are you sure?",
      text: "There is no recovering this car once it is deleted",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        Api.CarManagement.DeleteCar(car.id)
          .then(() => {
            const items = this.state.items;
            items.splice(items.map((c) => c.id).indexOf(car.id), 1);
            this.setState({ items });
          })
          .catch((e) => {
            swal({
              title: "Whoops",
              text: e.response.data
                ? e.response.data
                : "Looks like we couldn't delete that car. Please try again",
              icon: "warning",
            });
          });
      }
    });
  };

  render() {
    const {
      isLoading,
      items,
      statistics,
      data,
      status,
      importCarsModal,
      importSeedsModal,
      importEquipmentsTechnicalSpecs,
    } = this.state;
    const { currentPage, pageSize, totalItems } = this.state.requestOptions;
    items && console.log(items);
    return (
      <ContentWrapper>
        <div className="content-heading d-flex justify-content-between">
          <div>Car Inventories</div>
          <div>
            <ButtonDropdown
              isOpen={this.state.dropdownOpen}
              toggle={this.dropdownToggle}
            >
              <DropdownToggle caret color="info">
                Import
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => this.toggleImportModal("importCarsModal")}
                >
                  Cars
                </DropdownItem>
                <DropdownItem
                  onClick={() =>
                    this.toggleImportModal("importEquipmentsTechnicalSpecs")
                  }
                >
                  Equipments and technical specs
                </DropdownItem>
                <DropdownItem
                  onClick={() => this.toggleImportModal("importSeedsModal")}
                >
                  From Seeds
                </DropdownItem>
                <DropdownItem
                  disabled={this.state.resettingTiers}
                  onClick={() => {
                    this.setState({ resettingTiers: true });
                    Api.CarManagement.ResetTiers()
                      .then(() => {
                        this.setState({ resettingTiers: false });
                        this.renderSuccessAlert(
                          "Success",
                          "All cars' tiers have been successfully updated based on their monthly payment amount"
                        );
                      })
                      .catch((e) => {
                        this.setState({ resettingTiers: false });
                        this.renderErrorAlert("Error", e.message);
                      });
                  }}
                >
                  Reset{this.state.resettingTiers ? "ing" : ""} Tiers
                </DropdownItem>
                <DropdownItem
                  disabled={this.state.sendingToNewApi}
                  onClick={() => {
                    this.setState({ sendingToNewApi: true });
                    BulkAdd(this.state.items)
                      .then(() => this.setState({ sendingToNewApi: false }))
                      .catch((e) => {
                        console.error(e);
                        this.setState({ sendingToNewApi: false });
                      });
                  }}
                >
                  {this.state.sendingToNewApi
                    ? "Pushing..."
                    : "Bulk Push Vehicles to New API"}{" "}
                </DropdownItem>
                <DropdownItem
                  disabled={this.state.bestpassSync}
                  onClick={() => {
                    this.setState({ bestpassSync: true });
                    Api["@driveavva"].new.Vehicles.SyncBestPass()
                      .then(() => this.setState({ bestpassSync: false }))
                      .catch((e) => {
                        console.error(e);
                        this.setState({ bestpassSync: false });
                      });
                  }}
                >
                  {this.state.bestpassSync
                    ? "Syncing..."
                    : "Sync BestPass Equipment IDs"}{" "}
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </div>
        </div>
        <Row>
          <Col md={3}>
            <div className="card flex-row align-items-center align-items-stretch border-0">
              <div className="col-8 py-3 bg-primary rounded-left">
                <h5 className="text-uppercase"> Available Cars </h5>
                <div className="h2 mt-0">{statistics.availableCarsCount}</div>
              </div>
              <div className="col-4 d-flex align-items-center bg-primary-dark justify-content-center rounded-right">
                <em className="fas fa-car-building fa-3x" />
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="card flex-row align-items-center align-items-stretch border-0">
              <div className="col-8 py-3 bg-purple rounded-left">
                <h5 className="text-uppercase">Cars In use</h5>
                <div className="h2 mt-0">{statistics.inUseCarsCount}</div>
              </div>
              <div className="col-4 d-flex align-items-center bg-purple-dark justify-content-center rounded-right">
                <em className="fas fa-car-garage fa-3x" />
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="card flex-row align-items-center align-items-stretch border-0">
              <div className="col-8 py-3 bg-purple rounded-left">
                <h5 className="text-uppercase">Total Cars</h5>
                <div className="h2 mt-0">{statistics.totalCarsCount}</div>
              </div>
              <div className="col-4 d-flex align-items-center bg-purple-dark justify-content-center rounded-right">
                <em className="fas fa-cars fa-3x" />
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="card flex-row align-items-center align-items-stretch border-0">
              <div className="col-8 py-3 bg-purple rounded-left">
                <h5 className="text-uppercase">Hidden Cars</h5>
                <div className="h2 mt-0">
                  {statistics.hideFromSearchCarsCount}
                </div>
              </div>
              <div className="col-4 d-flex align-items-center bg-purple-dark justify-content-center rounded-right">
                <em className="fas fa-mask fa-3x" />
              </div>
            </div>
          </Col>
        </Row>

        <Card className="card-default">
          <CardBody>
            <Form className="mb-3">
              <Row>
                {/* <Col md={3}>
                  <FormGroup>
                    <Label for="Make">Make</Label>
                    <Input
                      type="select"
                      name="make"
                      id="make"
                      value={data.make}
                      onChange={this.handleChange}
                    >
                      <option selected>Please Select</option>

                      {makes.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="model">Model</Label>
                    <Input
                      type="select"
                      name="model"
                      id="model"
                      value={data.model}
                      onChange={this.handleChange}
                      disabled={!data.make}
                    >
                      <option selected>Please Select</option>
                      {models.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="bodyStyle">Body Style</Label>
                    <Input
                      type="select"
                      name="bodyStyle"
                      id="bodyStyle"
                      value={data.bodyStyle}
                      onChange={this.handleChange}
                    >
                      <option selected>Please Select</option>
                      {styles.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col> */}
                <Col md={2}>
                  <FormGroup>
                    <Label for="vin">VIN #</Label>
                    <Input
                      type="text"
                      name="vin"
                      id="vin"
                      value={data.vin}
                      onChange={this.handleChange}
                    ></Input>
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label for="id">Id</Label>
                    <Input
                      type="text"
                      name="id"
                      id="id"
                      value={data.id}
                      onChange={this.handleChange}
                    ></Input>
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label for="status">Status</Label>
                    <Input
                      type="select"
                      name="status"
                      id="status"
                      value={data.status}
                      onChange={this.handleChange}
                    >
                      <option selected>Please Select</option>
                      {status.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.value}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label for="hideFromSearch">
                      Exclude Hidden Inventory?
                    </Label>
                    <ReactSwitch
                      className="d-block p-relative w-100"
                      checked={data.hideFromSearch}
                      onChange={(value) => {
                        this.handleChange({
                          currentTarget: {
                            name: "hideFromSearch",
                            value,
                          },
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                {/* <Col md={3}>
                  <FormGroup>
                    <Label for="Year">Year</Label>
                    <Input
                      type="select"
                      name="year"
                      id="year"
                      value={data.year}
                      onChange={this.handleChange}
                    >
                      <option selected>Please Select</option>
                      {years.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <Label for="mileageFrom">Mileage</Label>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Input
                          type="number"
                          name="mileageFrom"
                          id="mileageFrom"
                          value={data.mileageFrom}
                          onChange={this.handleChange}
                          placeholder="From"
                          min="0"
                        />
                        {errors && (
                          <span className="text-danger small">
                            {errors.mileageFrom}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Input
                          type="number"
                          name="mileageTo"
                          id="mileageTo"
                          value={data.mileageTo}
                          onChange={this.handleChange}
                          placeholder="To"
                          min="0"
                        />
                        {errors && (
                          <span className="text-danger small">
                            {errors.mileageTo}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col md={4}>
                  <Label for="monthlyPriceTo">Monthly Price </Label>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Input
                          type="number"
                          name="monthlyPriceFrom"
                          id="monthlyPriceFrom"
                          value={data.monthlyPriceFrom}
                          onChange={this.handleChange}
                          placeholder="From"
                          min="0"
                        />
                        {errors && (
                          <span className="text-danger small">
                            {errors.monthlyPriceFrom}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Input
                          type="number"
                          name="monthlyPriceTo"
                          id="monthlyPriceTo"
                          value={data.monthlyPriceTo}
                          onChange={this.handleChange}
                          placeholder="To"
                          min="0"
                        />
                        {errors && (
                          <span className="text-danger small">
                            {errors.monthlyPriceTo}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Col> */}
                <Col md={2}>
                  <FormGroup>
                    <div
                      style={{ height: 30 }}
                      className="d-none d-md-block d-lg-block"
                    />
                    <Button
                      color={"info"}
                      onClick={this.handleSubmit}
                      className="w-100"
                    >
                      Search
                    </Button>
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <div
                      style={{ height: 30 }}
                      className="d-none d-md-block d-lg-block"
                    />
                    <Button
                      color={"success"}
                      onClick={this.resetData}
                      className="w-100"
                    >
                      Reset Data
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            {!isLoading ? (
              <React.Fragment>
                {items.length !== 0 ? (
                  <React.Fragment>
                    <Row>
                      <Col md={12}>
                        <Table className="table table-bordered table-striped table-hover mb-4">
                          <thead>
                            <tr>
                              <th className="sort-alpha">Photo</th>
                              <th className="sort-alpha">VIN</th>
                              <th className="sort-alpha">Name</th>
                              <th className="sort-alpha">Monthly Payment</th>
                              <th className="sort-alpha">Status</th>
                              <th className="sort-alpha">Last Updated</th>
                              <th className="sort-alpha">Hide from Search?</th>
                              <th className="sort-alpha">Delivered?</th>
                              <th className="sort-alpha">Controls</th>
                            </tr>
                          </thead>
                          <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="droppable">
                              {(provided, snapshot) => (
                                <tbody
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  style={{
                                    background: snapshot.isDraggingOver
                                      ? "#edf1f2"
                                      : "white",
                                  }}
                                >
                                  {items.map((item, index) => (
                                    <Draggable
                                      key={item.id}
                                      draggableId={item.id}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <tr
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            border: "1px solid #dde6e9",
                                            padding: 10,
                                            background: snapshot.isDragging
                                              ? "#dde6e9"
                                              : "white",
                                            ...provided.draggableProps.style,
                                          }}
                                        >
                                          <td style={{ width: 100 }}>
                                            {item.photos &&
                                            item.photos.length ? (
                                              <img
                                                src={item.photos[0]}
                                                width="100%"
                                                alt={`${item.id}'s display`}
                                              />
                                            ) : null}
                                          </td>
                                          <td>
                                            <Link
                                              id={`vin-${item.id}`}
                                              to={`/inventory/update/${item.id}`}
                                            >
                                              {item.vin
                                                ? item.vin.slice(-8)
                                                : "coming-soon"}
                                            </Link>
                                            <Tooltip
                                              placement="right"
                                              autohide={false}
                                              isOpen={
                                                this.state.tooltips[
                                                  `vin-${item.id}`
                                                ]
                                              }
                                              target={`vin-${item.id}`}
                                              toggle={() => {
                                                const tooltips = Object.assign(
                                                  {},
                                                  this.state.tooltips
                                                );
                                                tooltips[`vin-${item.id}`] =
                                                  tooltips.hasOwnProperty(
                                                    `vin-${item.id}`
                                                  )
                                                    ? !tooltips[
                                                        `vin-${item.id}`
                                                      ]
                                                    : true;
                                                this.setState({
                                                  tooltips,
                                                });
                                              }}
                                            >
                                              {item.vin}
                                            </Tooltip>
                                          </td>
                                          <td>
                                            {item.year} {item.make} {item.model}
                                          </td>
                                          <td>
                                            <Badge>
                                              {item.tier} - $
                                              {item.monthlyPayment.toFixed(2)}
                                            </Badge>
                                          </td>
                                          <td>
                                            <Badge
                                              color={
                                                item.status === "InUse"
                                                  ? "warning"
                                                  : "info"
                                              }
                                            >
                                              {item.status}
                                            </Badge>
                                          </td>
                                          <td>
                                            {item.updatedAt &&
                                              DATETIME_FORMAT.format(
                                                new Date(item.updatedAt)
                                              )}
                                          </td>

                                          <td>
                                            {!snapshot.isDragging && (
                                              <label>
                                                <ReactSwitch
                                                  checked={item.hideFromSearch}
                                                  onChange={(checked) => {
                                                    Api.CarManagement.ToggleField(
                                                      item.id,
                                                      "HideFromSearch",
                                                      checked
                                                    ).then(() => {
                                                      const newitems = [];
                                                      for (
                                                        let i = 0;
                                                        i < items.length;
                                                        i++
                                                      ) {
                                                        const it = items[i];
                                                        if (it.id === item.id)
                                                          it.hideFromSearch =
                                                            !it.hideFromSearch;
                                                        newitems.push(it);
                                                      }
                                                      this.setState({
                                                        items: newitems,
                                                      });
                                                    });
                                                  }}
                                                />
                                              </label>
                                            )}
                                          </td>
                                          <td>
                                            {!snapshot.isDragging && (
                                              <label>
                                                <ReactSwitch
                                                  checked={item.delivered}
                                                  onChange={(checked) => {
                                                    Api.CarManagement.ToggleField(
                                                      item.id,
                                                      "Delivered",
                                                      checked,
                                                      item.vin
                                                    ).then(() => {
                                                      const newitems = [];
                                                      for (
                                                        let i = 0;
                                                        i < items.length;
                                                        i++
                                                      ) {
                                                        const it = items[i];
                                                        if (it.id === item.id)
                                                          it.delivered =
                                                            !it.delivered;
                                                        newitems.push(it);
                                                      }
                                                      this.setState({
                                                        items: newitems,
                                                      });
                                                    });
                                                  }}
                                                />
                                              </label>
                                            )}
                                          </td>
                                          <td>
                                            {!snapshot.isDragging && (
                                              <React.Fragment>
                                                <Link
                                                  to={`/inventory/update/${item.id}`}
                                                  color="warning"
                                                  className="btn btn-dark btn-sm d-inline-flex align-items-center mr-1"
                                                >
                                                  Details
                                                </Link>
                                                {item.status !== "InUse" && (
                                                  <ShareLink
                                                    size="sm"
                                                    link={`/search/${item.id}`}
                                                  />
                                                )}
                                                <Button
                                                  onClick={() =>
                                                    this.deleteCar(item)
                                                  }
                                                  color="danger"
                                                  disabled={
                                                    item.status !== "Available"
                                                  }
                                                  className="ml-1 btn btn-sm d-inline-flex align-items-center"
                                                >
                                                  Delete
                                                </Button>
                                              </React.Fragment>
                                            )}
                                          </td>
                                        </tr>
                                      )}
                                    </Draggable>
                                  ))}
                                </tbody>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </Table>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Pagination
                          showSizeChanger
                          defaultPageSize={pageSize}
                          defaultCurrent={currentPage}
                          onChange={this.handlePageChange}
                          total={totalItems}
                          className="float-right"
                        />
                      </Col>
                    </Row>
                  </React.Fragment>
                ) : (
                  this.renderSadFace(true)
                )}
              </React.Fragment>
            ) : (
              this.renderLoader()
            )}
          </CardBody>
        </Card>

        {importCarsModal && (
          <ImportModal
            title="Cars"
            isOpen={importCarsModal}
            onToggleModal={() => this.toggleImportModal("importCarsModal")}
            handleSelectFile={this.handleSelectFile}
            handleOverWrite={this.handleOverWrite}
            accept=".xlsx"
            sampleUrl="https://cdn.driveavva.com/templates/InventoryUploadTemplate_20210209.xlsx"
            onImport={this.importCars}
          />
        )}
        {importSeedsModal ? (
          <SeedModal
            title="Generate Cars"
            isOpen={importSeedsModal}
            onToggleModal={() => this.toggleImportModal("importSeedsModal")}
          />
        ) : undefined}
        {importEquipmentsTechnicalSpecs && (
          <ImportModal
            title="Equipments Technical Specs"
            isOpen={importEquipmentsTechnicalSpecs}
            onToggleModal={() =>
              this.toggleImportModal("importEquipmentsTechnicalSpecs")
            }
            handleSelectFile={this.handleSelectFile}
            accept=".xlsx"
            sampleUrl="https://cdn.driveavva.com/templates/VehicleEquipmentAndTechnicalSpecsTemplate_20210209.xlsx"
            onImport={this.importEquipmentsTechnicalSpecs}
          />
        )}
      </ContentWrapper>
    );
  }

  queryItems = (page) => {
    this.setState({
      isLoading: true,
    });
    const { pageSize, currentPage } = this.state.requestOptions;
    const { data } = this.state;
    Api.CarManagement.CarsList(data, page, currentPage, pageSize)
      .then((response) => {
        let items = response.items;
        console.log(items);
        this.setState({
          isLoading: false,
          items,
          requestOptions: {
            currentPage: response.currentPage,
            totalPages: response.totalPages,
            totalItems: response.totalItems,
            pageSize: response.pageSize,
          },
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
        this.renderErrorAlert(
          "Ops!",
          "Something went wrong, please try again!"
        );
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.queryItems(1);
  };

  handleChange = async ({ currentTarget: input }) => {
    if (input.name === "make") {
      this.getModelLookups(input.value);
    }
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };

  handlePageChange = async (page) => {
    const requestOptions = this.state.requestOptions;
    requestOptions.currentPage = page;
    this.setState({ requestOptions });
    this.queryItems();
  };

  handleSelectFile = (e) => {
    this.setState({
      iconUrl: URL.createObjectURL(e.target.files[0]),
      iconUrlSend: e.target.files[0],
    });
  };

  handleOverWrite = (e) => {
    this.setState((p) => ({
      overwrite: !p.overwrite,
    }));
  };

  toggleImportModal = (modalName) =>
    this.setState({ [modalName]: !this.state[modalName] });

  importCars = async () => {
    const { iconUrlSend, overwrite } = this.state;
    let formData = new FormData();
    formData.append("file", iconUrlSend);
    try {
      const results = await Api.CarManagement.ImportCars(formData, overwrite);
      this.setState({
        iconUrlSend: null,
        overwrite: false,
      });
      console.log(results);
      this.toggleImportModal("importCarsModal");
      this.renderSuccessAlert(
        "Added successfully",
        "The file was added successfully"
      );
    } catch (error) {
      console.log({ ...error });
      if (error.response.data) {
        this.renderErrorAlert("Ops!", error.response.data);
      } else {
        this.renderErrorAlert(
          "Ops!",
          "Something went wrong, please check file"
        );
      }
    }
  };
  resetData = () => {
    const requestOptions = this.state.requestOptions;
    const data = this.state.data;
    requestOptions.currentPage = 1;
    requestOptions.totalPages = 1;
    requestOptions.totalItems = 0;
    requestOptions.pageSize = 20;
    data.bodyStyle = "";
    data.make = "";
    data.status = "";
    data.mileageFrom = "";
    data.mileageTo = "";
    data.monthlyPriceFrom = "";
    data.monthlyPriceTo = "";
    data.model = "";
    data.year = "";
    data.vin = "";
    data.hideFromSearch = false;
    data.id = "";
    this.setState({ requestOptions, data });
    this.queryItems();
  };

  dropdownToggle = () =>
    this.setState({ dropdownOpen: !this.state.dropdownOpen });

  importEquipmentsTechnicalSpecs = async () => {
    const { iconUrlSend } = this.state;
    let formData = new FormData();
    formData.append("file", iconUrlSend);
    try {
      await Api.CarManagement.ImportEquipmentsTechnicalSpecs(formData);
      this.toggleImportModal("importEquipmentsTechnicalSpecs");
      this.renderSuccessAlert(
        "Added successfully",
        "The file was added successfully"
      );
    } catch (e) {
      this.renderErrorAlert("Ops!", "Something went wrong, please check file");
    }
  };
  /**
   * Updates the hide from search toggle directly
   *
   * @param {Car} item - The car to update via the API
   * @param {*} value - The value to set HideFromSearch on the Car
   */
  toggleHideFromSearch = async (item, value) => {
    item.hideFromSearch = value;
    Api.CarManagement.UpdateCar(item.id, item)
      .then(() => {})
      .catch((error) => {
        this.renderErrorAlert(
          "Ops!",
          error.response.data.Message ||
            "Something went wrong, please try again!"
        );
      });
  };
}

InventoriesList = withRouter(InventoriesList);
