import React from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Base from "../components/Layout/Base";
import BasePage from "../components/Layout/BasePage";
import { ApplicationDetails } from "../components/manages/applications/ApplicationDetailsNew";
import { UserDetails } from "../components/manages/users/UserDetails";
import { UsersList } from "../components/manages/users/UsersList";
import {
  Dashboard,
  ApplicationsList,
  Login,
  AdminDetails,
  AdminsList,
  CreateAdmin,
  NotificationsList,
  InventoriesList,
  InventoryDetails,
  DeliveryRequest,
  CarDeliverySlots,
  ClientsList,
  ClientDetails,
  MembersList,
  MembersDetail,
} from "./index";
import { ProtectedRoute } from "./ProtectedRoute";

const Routes = ({ location }) => {
  const currentKey = location.pathname.split("/")[1] || "/";
  const timeout = { enter: 500, exit: 500 };
  const animationName = "rag-fadeIn";
  const listofPages = ["/", "/auth/login"];
  if (listofPages.indexOf(location.pathname) > -1) {
    return (
      // Page Layout component wrapper
      <BasePage>
        <Switch location={location}>
          <Route path="/auth/login" component={Login} />
          <Redirect to="/auth/login" />
        </Switch>
      </BasePage>
    );
  } else {
    return (
      <Base>
        <TransitionGroup>
          <CSSTransition
            key={currentKey}
            timeout={timeout}
            classNames={animationName}
            exit={false}
          >
            <div>
              <Switch location={location}>
                <ProtectedRoute exact path="/dashboard" component={Dashboard} />

                {/* Admins routes */}
                <ProtectedRoute exact path="/admins" component={AdminsList} />
                <ProtectedRoute
                  exact
                  path="/admins/update/:id"
                  component={AdminDetails}
                />
                <ProtectedRoute
                  exact
                  path="/admins/create"
                  component={CreateAdmin}
                />
                {/* Users routes */}
                <ProtectedRoute exact path="/users" component={UsersList} />
                <ProtectedRoute
                  exact
                  path="/users/update/:id"
                  component={UserDetails}
                />
                {/* Applications routes */}
                <ProtectedRoute
                  exact
                  path="/applications"
                  component={ApplicationsList}
                />
                <ProtectedRoute
                  exact
                  path="/applications/:id"
                  component={ApplicationDetails}
                />

                {/* Notifications routes  */}
                <ProtectedRoute
                  exact
                  path="/notifications"
                  component={NotificationsList}
                />

                {/* inventories  routes */}
                <ProtectedRoute
                  exact
                  path="/inventory"
                  component={InventoriesList}
                />
                <ProtectedRoute
                  exact
                  path="/inventory/update/:id"
                  component={InventoryDetails}
                />

                <ProtectedRoute
                  exact
                  path="/delivery-request"
                  component={DeliveryRequest}
                />
                {/* Car Delivery Slots  */}
                <ProtectedRoute
                  exact
                  path="/cars/delivery-slots"
                  component={CarDeliverySlots}
                />
                {/* Members */}
                <ProtectedRoute exact path="/members" component={MembersList} />
                <ProtectedRoute exact path="/members/:id" component={MembersDetail} />

                {/* Clients */}
                <ProtectedRoute exact path="/clients" component={ClientsList} />

                <ProtectedRoute
                  exact
                  path="/clients/update/:id"
                  component={ClientDetails}
                />
                <Redirect to="/inventory" />
              </Switch>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Base>
    );
  }
};

export default withRouter(Routes);
