import React from "react";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import SharedComponent from "../Shared";
import {
  SmsModal,
  NotificationModal,
  BannerModal,
  RewardModal,
  PhoneUpdateModal,
  InsuranceModal,
  PaymentAlertModal,
  StripeModal,
} from "./modals";
import { Api } from "../../../Api/index";

export class MemberActionsButton extends SharedComponent {
  state = {
    modal: "",
    dropdownOpen: false,
  };

  deactivate = () => {
    const type =
      this.props.user.role === "APPLICANT" ? "activate" : "deactivate";
    this.renderConfirmAlert(
      `Are you sure you want to ${type} ${this.props.user.name} (${this.props.user.avva_id})?`,
      async () => {
        this.setState({ submitting: true });
        Api["@driveavva"].new.Users.ChangeRole(
          this.props.user.id,
          type === "activate" ? "MEMBER" : "APPLICANT"
        )
          .then(() => {
            this.renderSuccessAlert(
              "Success!",
              `${this.props.user.name} has been ${type}d.`,
              () => window.location.reload()
            );
          })
          .catch((e) => {
            this.renderErrorAlert("Encountered an error!", e.message);
          });
      }
    );
  };

  render() {
    if (!this.props.user)
      throw Error(`MemberActionsButton is missing user prop`);

    return (
      <>
        <ButtonDropdown
          isOpen={this.state.dropdownOpen}
          toggle={() =>
            this.setState({ dropdownOpen: !this.state.dropdownOpen })
          }
        >
          <DropdownToggle caret color="info">
            Actions
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => this.setState({ modal: "pauls" })}>
              Send Enable/Disable Notification
            </DropdownItem>
            <DropdownItem onClick={() => this.setState({ modal: "reward" })}>
              Send Reward
            </DropdownItem>
            {window.environment !== "production" && (
              <DropdownItem onClick={() => this.setState({ modal: "alerts" })}>
                Test Payment Alerts
              </DropdownItem>
            )}
            <DropdownItem
              onClick={() => this.setState({ modal: "notification" })}
            >
              Send Notification
            </DropdownItem>
            <DropdownItem onClick={() => this.setState({ modal: "banner" })}>
              Send Banner
            </DropdownItem>
            <DropdownItem onClick={() => this.setState({ modal: "insurance" })}>
              Update Insurance
            </DropdownItem>
            <DropdownItem onClick={() => this.setState({ modal: "phone" })}>
              Update Phone #
            </DropdownItem>
            <DropdownItem onClick={() => this.setState({ modal: "stripe" })}>
              Update Stripe Info
            </DropdownItem>
            <DropdownItem onClick={this.deactivate}>
              {this.props.user.role === "APPLICANT" ? "activate" : "deactivate"}
            </DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
        <RewardModal
          isOpen={this.state.modal === "reward"}
          user={this.props.user}
          onClose={() => this.setState({ modal: "" })}
        />
        <NotificationModal
          isOpen={this.state.modal === "notification"}
          user={this.props.user}
          onClose={() => this.setState({ modal: "" })}
        />
        <BannerModal
          isOpen={this.state.modal === "banner"}
          user={this.props.user}
          onClose={() => this.setState({ modal: "" })}
        />
        <PhoneUpdateModal
          isOpen={this.state.modal === "phone"}
          user={this.props.user}
          onClose={() => this.setState({ modal: "" })}
        />
        <InsuranceModal
          isOpen={this.state.modal === "insurance"}
          vehicle={this.props.user.vehicle}
          onClose={() => this.setState({ modal: "" })}
        />
        <PaymentAlertModal
          isOpen={this.state.modal === "pauls"}
          isForPaul
          user={this.props.user}
          onClose={() => this.setState({ modal: "" })}
        />
        {window.environment !== "production" && (
          <PaymentAlertModal
            isOpen={this.state.modal === "alerts"}
            user={this.props.user}
            onClose={() => this.setState({ modal: "" })}
          />
        )}
        <StripeModal
          isOpen={this.state.modal === "stripe"}
          user={this.props.user}
          onClose={() => this.setState({ modal: "" })}
        />
      </>
    );
  }
}
