import React, { Component } from "react";
import { Button, ModalHeader, ModalBody, ModalFooter, Modal } from "reactstrap";
import GoogleMapReact from "google-map-react";
function Marker(props) {
  const { color, name } = props;
  return (
    <React.Fragment>
      <div
        className="pin bounce"
        style={{ backgroundColor: color, cursor: "pointer" }}
        title={name}
      />
      <div className="pulse" />
    </React.Fragment>
  );
}
class ModalMap extends Component {
  render() {
    return (
      <Modal isOpen={this.props.isOpenMap} toggle={this.props.closeModalMap}>
        <ModalHeader toggle={this.props.closeModalMap}>
          {this.props.title}
        </ModalHeader>
        <ModalBody className="map-body">
          <GoogleMapReact
            defaultCenter={this.props.center}
            defaultZoom={this.props.zoom}
            name="AVVA"
          >
            <Marker lat={this.props.latitude} lng={this.props.longitude} />
          </GoogleMapReact>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.props.closeModalMap}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ModalMap;
