import * as helpers from "./helpers";
import { UserApiEndpoints } from "./users.api";
import { ClientApiEndpoints } from "./clients.api";
import { CarApiEndpoints } from "./cars.api";
import { LookUpsApiEndpoints } from "./lookups.api";
import { ApplicationApiEndpoints } from "./applications.api";
import { NotificationsApiEndpoints } from "./notifications.api";
import { DeliveryRequestApiEndpoints } from "./deliveryRequest.api";
import { CarDeliverySlotsApiEndpoints } from "./carDeliverySlots.api";
import core from "./@driveavva/core";
import n from "./@driveavva/new";

export const Api = {
  ...helpers,
  UsersManagement: UserApiEndpoints,
  ClientManagement: ClientApiEndpoints,
  CarManagement: CarApiEndpoints,
  LookUps: LookUpsApiEndpoints,
  ApplicationManagement: ApplicationApiEndpoints,
  Notifications: NotificationsApiEndpoints,
  DeliveryRequest: DeliveryRequestApiEndpoints,
  CarDeliverySlots: CarDeliverySlotsApiEndpoints,
  Seed: (wipe = false, cars = 0, demo = false) =>
    helpers
      .getAuthenticatedAxios()
      .post(
        `/Seed?wipe=${wipe}&cars=${
          demo && cars === 0 ? 1 : cars
        }&demo_mode=${demo}&users=0`
      ),
  /**
   * Shoe-horn to fit new api endpoints into the existing structure
   */
  "@driveavva": {
    core: {
      UsersManagement: core.UserApiEndpoints,
      AppsManagement: core.AppApiEndpoints,
      VehicleManagement: core.VehicleApiEndpoints,
      TransactionsManagement: core.TransactionsApiEndpoints,
    },
    new: {
      Users: n.UserApiEndpoints,
      Vehicles: n.VehicleApiEndpoints,
      Rewards: n.RewardApiEndpoints,
      Payments: n.PaymentApiEndpoints,
    },
  },
};
