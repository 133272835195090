import axios from "axios";
import formurlencoded from "form-urlencoded";
import httpService from "./httpServices";
export const apiUrl = process.env.REACT_APP_DEPRICATED_API_ENDPOINT;
export const apiUrlLogin = process.env.REACT_APP_DEPRICATED_OAUTH_ENDPOINT;
export const newApiUrl = process.env.REACT_APP_API_ENDPOINT;
const RefreshToken = async () => {
  // let jwt = localStorage.getItem("token");
  // let refreshToken = localStorage.getItem("refToken");
  // if (!refreshToken) return;
  // let Token = {
  //   client_id: "riot-admin",
  //   client_secret: "riot-admin",
  //   grant_type: "refresh_token",
  //   refresh_token: refreshToken,
  // };
  // // let reToken = formurlencoded(Token);
  // try {
  //   const response = await axios.post(`${apiUrlLogin}/connect/token`, reToken);
  //   httpService.setJwt(response.data.access_token);
  //   httpService.setRereshToken(response.data.refresh_token);
  //   jwt = response.data.access_token;
  //   // Since they aren't supporting async for getAuthenticatedAxios we can just do a reload so the token
  //   // is updated in the storage...
  //   console.log("Refreshed Token", jwt);
  //   window.location.reload();
  // } catch {
  //   console.warn(`Could not refresh token... Sending to login`);
  //   // window.location.href = "/login";
  // }
};

const client = (baseURL, headers) => {
  const a = axios.create({
    baseURL,
    headers,
  });
  a.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      if (error.response.data) return Promise.reject(error.response.data);
      if (error.response) {
        if (error.response && error.response.data && error.response.data.error)
          return Promise.reject(error.response.data.error);
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error.response.data);
      }
      // error does not contain a response obj
      else {
        return Promise.reject(error);
      }
    }
  );

  return a;
};

export const getAuthenticatedAxios = (toCore = false, newNew = false) => {
  let token = localStorage.getItem("token");
  var jwtDecode = require("jwt-decode");
  let expToken = false;

  let jwt = jwtDecode(token).exp;
  if (Date.now() >= jwt * 1000) {
    expToken = true;
  }
  if (token && expToken) {
    console.log("[Token is Expired]", expToken);
    RefreshToken();
  }

  let headers = {
    Authorization: `Bearer ${token}`,
  };

  if (newNew) {
    headers["Content-Type"] = "application/vnd.api+json";
    headers["AVVA"] = process.env.REACT_APP_NEW_API_KEY;
  } else if (toCore) {
    headers["Depricated-Admin"] = process.env.REACT_APP_CORE_API_KEY;
  }

  if (toCore) {
    if (window.location.hostname !== "admin.driveavva.com") {
      headers["CF-Access-Client-Id"] =
        process.env.REACT_APP_CF_ACCESS_CLIENT_ID;
      headers["CF-Access-Client-Secret"] =
        process.env.REACT_APP_CF_ACCESS_CLIENT_SECRET;
    }
  }

  return client(apiUrl, headers);
};

export const getUnAuthenticatedAxios = () => client(apiUrl);
export const getUnAuthenticatedAxiosLogin = () => client(apiUrlLogin);

export const getDataFromResponse = (response) => response.data;
