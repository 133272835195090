import { depricatedApiUrl, coreApiUrl, newApiUrl } from "../Api";

//Dashbaord
const ApplicationDashboard = `${depricatedApiUrl}/Applications?Status=New`;

// Users
const Users = `${depricatedApiUrl}/Admins`;

// Cars
const Cars = `${depricatedApiUrl}/Cars`;

// cars lookups
const carLookUps = `${depricatedApiUrl}/cars/lookup`;

//CarPhotos

const carPhotos = `${depricatedApiUrl}/CarPhotos`;

// device serial number
const deviceSerial = `${depricatedApiUrl}/Devices`;

// car reviews
const carReview = `${depricatedApiUrl}/CarReviews`;

// car delivery slots
const carDeliverySlots = `${depricatedApiUrl}/CarDeliverySlots`;

// delivery Request
const deliveryRequest = `${depricatedApiUrl}/CarDeliveryRequests`;

// application
const applications = `${depricatedApiUrl}/Applications`;

// Applications insurance-details
const insurance = `insurance-details`;
// attachments
const attachments = `${depricatedApiUrl}/ApplicationAttachments`;

// application history
const applicationHistory = `${depricatedApiUrl}/ApplicationGPSDevices`;

// phone location
const phoneLocation = `${depricatedApiUrl}/GPSNotifications`;

// application action
const applicationAction = `${depricatedApiUrl}/ApplicationActions`;

// aplication notes
const applicationNotes = `${depricatedApiUrl}/ApplicationNotes`;
// notifications
const scheduledMaintenances = `${depricatedApiUrl}/ScheduledMaintenances`;
// unscheduledMaintenances
const unscheduledMaintenances = `${depricatedApiUrl}/UnscheduledMaintenances`;
//incidents
const incidents = `${depricatedApiUrl}/Incidents`;

// long trip
const longTrip = `${depricatedApiUrl}/LongTrips`;

// Support
const support = `${depricatedApiUrl}/SupportTickets`;

//FeedbackTickets
const feedbackTickets = `${depricatedApiUrl}/FeedbackTickets`;

// Car Delivery Requests
const carDeliveryRequests = `${depricatedApiUrl}/CarDeliveryRequests`;
//contract
const contract = `${depricatedApiUrl}/Contracts`;

//PHONE LOCATION
const currnetPhoneLocation = `${depricatedApiUrl}/Locations`;

// Clients
const clients = `${depricatedApiUrl}/Clients`;
export default {
  ApplicationDashboard,
  Users,
  Cars,
  carLookUps,
  carPhotos,
  deviceSerial,
  carReview,
  carDeliverySlots,
  deliveryRequest,
  applications,
  attachments,
  applicationHistory,
  phoneLocation,
  applicationAction,
  applicationNotes,
  scheduledMaintenances,
  unscheduledMaintenances,
  incidents,
  longTrip,
  support,
  feedbackTickets,
  carDeliveryRequests,
  contract,
  insurance,
  currnetPhoneLocation,
  clients,
  "@driveavva": {
    core: {
      Users: `${coreApiUrl}/users`,
      Apps: `${coreApiUrl}/applications`,
      Vehicles: `${coreApiUrl}/vehicles`,
      Transactions: `${coreApiUrl}/transactions`,
    },
    new: {
      Users: `${newApiUrl}/users`,
      Members: `${newApiUrl}/members`,
      Banners: `${newApiUrl}/banners`,
      Notifications: `${newApiUrl}/notifications`,
      Vehicles: `${newApiUrl}/vehicle`,
      Rewards: `${newApiUrl}/reward`,
      Payments: `${newApiUrl}/payment`,
    },
  },
};
