import React from "react";
import ContentWrapper from "../../Layout/ContentWrapper";
import {
  Card,
  CardBody,
  Button,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  CardFooter,
} from "reactstrap";
import SharedComponent from "../Shared";
import { Api } from "../../../Api/index";

const ignoreKeys = [
  "user_id",
  "id",
  "role",
  "hubspot_contact_id",
  "created_at",
  "ssn_last4",
  "finicity_customer_id",
  "plaid_client_user_token",
  "plaid_client_user_id",
  "inflection_report_id",
  "victig_applicant_id",
  "_dl_number_spouse",
  "victig_report_id",
  "dl_state_spouse",
];

const currencyKeys = ["monthly_rent_payment", "monthly_car_payment"];

const disabledKeys = ["phone", "_ssn", "referred_by"];
class UserDetails extends SharedComponent {
  state = {
    data: {},
  };
  componentDidMount() {
    this.getUserDetails();
  }

  sanatizeCurrency = (data, toCents = false) => {
    Object.keys(data).forEach((key) => {
      if (currencyKeys.includes(key)) {
        data[key] = toCents ? Math.round(data[key] * 100) : data[key] / 100.0;
      }
    });

    return data;
  };

  doSubmit = () => {
    const { data } = this.state;
    console.log("update", data);
    let UserId = this.props.match.params.id;
    Api["@driveavva"].core.UsersManagement.UpdateUser(
      UserId,
      this.sanatizeCurrency(data, true)
    )
      .then(() => {
        this.renderSuccessAlert("Updated successfully", "Click the button");
      })
      .catch((e) => {
        this.renderErrorAlert("Ops!", e.error.message);
      });
  };

  render() {
    const { data } = this.state;

    if (!data) return null;

    return (
      <ContentWrapper>
        <div className="content-heading d-flex justify-content-between">
          <div>User Details</div>
        </div>
        <Form onSubmit={this.handleSubmit}>
          <Card className="card-default">
            <CardBody>
              <Row>
                {Object.keys(data)
                  .filter((k) => !ignoreKeys.includes(k))
                  .map((name) => (
                    <Col className="pt-2" md={4} sm={12}>
                      <FormGroup>
                        <Label for={name}>{name}</Label>
                        <Input
                          onChange={this.handleChange}
                          value={data[name]}
                          type={currencyKeys.includes(name) ? "number" : "text"}
                          name={name}
                          id={name}
                          disabled={disabledKeys.includes(name)}
                        />
                      </FormGroup>
                    </Col>
                  ))}
              </Row>
            </CardBody>
            <CardFooter className=" d-flex justify-content-between">
              <Button color="info" type="submit">
                Update
              </Button>
            </CardFooter>
          </Card>
        </Form>
      </ContentWrapper>
    );
  }
  getUserDetails = () => {
    let UserId = this.props.match.params.id;
    Api["@driveavva"].core.UsersManagement.UserDetails(UserId)
      .then((response) => {
        this.setState({
          data: this.sanatizeCurrency(response.data),
          iconUrl: response.photoUrl,
        });
      })
      .catch((e) => console.error("getUserDetails", e));
  };
}

export { UserDetails };
