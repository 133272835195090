import React from "react";
import {
  Row,
  Col,
  TabPane,
  ListGroup,
  ListGroupItem,
  Button,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import Moment from "react-moment";
import SharedComponent from "../Shared";
import { Api } from "../../../Api/index";
import ModalMap from "./ModalMap";
class Location extends SharedComponent {
  state = {
    items: [],
    modal: false,
    phoneModal: false,
    latitude: null,
    longitude: null,
    zoom: 11,
    center: {
      lat: 0,
      lng: 0,
    },
    phoneItems: [],
  };

  componentDidMount() {
    this.locationCarHistory();
    this.locationPhoneHistory();
  }

  render() {
    const { items, phoneItems } = this.state;
    return (
      <TabPane tabId="Location">
        <Row>
          <Col md={6} className="mt-3">
            <div className="d-flex align-items-center justify-content-between">
              <h3 className="mb-0">Car Location History</h3>
              <Button color="info" onClick={this.handlelocateCar}>
                Locate Car
              </Button>
            </div>
            <hr className="w-25 float-left" />
          </Col>
          <Col xs={6} className="mt-3">
            <div className="d-flex align-items-center justify-content-between">
              <h3 className="mb-0">Phone Location</h3>
            </div>
            <hr className="w-25 float-left" />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            {items.length !== 0 ? (
              <ListGroup className="mb-3">
                {items.map((item, index) => (
                  <ListGroupItem
                    key={index}
                    className="d-flex justify-content-between"
                  >
                    <div>
                      <h6 className="mb-2">#{item.id}</h6>
                      <h6 className="mt-4">{item.address}</h6>
                    </div>
                    <div>
                      <h6 className="mb-2 d-flex align-items-center justify-content-center">
                        <Moment format="MM/DD/YYYY">{item.locateTime}</Moment>
                      </h6>
                      <button
                        className="mb-2 text-info float-right btn"
                        onClick={() =>
                          this.handleMap(item.latitude, item.longitude)
                        }
                      >
                        View On Map
                      </button>
                    </div>
                  </ListGroupItem>
                ))}
              </ListGroup>
            ) : (
              this.renderSadFace(false)
            )}
          </Col>

          <Col md={6}>
            {phoneItems.length !== 0 ? (
              <ListGroup className="mb-3">
                {phoneItems.map((item, index) => (
                  <ListGroupItem
                    key={index}
                    className="d-flex justify-content-between"
                  >
                    <div>
                      <h6 className="mb-2">#{item.id}</h6>
                      <h6 className="mt-4">{item.deviceId}</h6>
                    </div>
                    <div>
                      <h6 className="mb-2 d-flex align-items-center justify-content-center">
                        <Moment format="MM/DD/YYYY">{item.createdAt}</Moment>
                      </h6>
                      <button
                        className="mb-2 text-info float-right btn"
                        onClick={() =>
                          this.handleMapPhone(item.latitude, item.longitude)
                        }
                      >
                        View On Map
                      </button>
                    </div>
                  </ListGroupItem>
                ))}
              </ListGroup>
            ) : (
              this.renderSadFace(false)
            )}
          </Col>
        </Row>

        <ModalMap
          title="Car Location"
          isOpenMap={this.state.modal}
          closeModalMap={() => this.handleMap(null, null)}
          center={this.state.center}
          zoom={this.state.zoom}
          latitude={this.state.latitude}
          longitude={this.state.longitude}
        />

        <ModalMap
          title="Phone Location"
          isOpenMap={this.state.phoneModal}
          closeModalMap={() => this.handleMapPhone(null, null)}
          center={this.state.center}
          zoom={this.state.zoom}
          latitude={this.state.latitude}
          longitude={this.state.longitude}
        />
      </TabPane>
    );
  }
  locationCarHistory = () => {
    let ApplicationId = this.props.id;
    Api.ApplicationManagement.ApplicationCarLocation(ApplicationId)
      .then((items) => {
        this.setState({ items });
      })
      .catch(console.log);
  };
  handleMap = (latitude, longitude) => {
    const center = this.state.center;
    center.lat = latitude;
    center.lng = longitude;
    this.setState({ modal: !this.state.modal, latitude, longitude, center });
  };
  handleMapPhone = (latitude, longitude) => {
    const center = this.state.center;
    center.lat = latitude;
    center.lng = longitude;
    this.setState({
      phoneModal: !this.state.phoneModal,
      latitude,
      longitude,
      center,
    });
  };

  locationPhoneHistory = () => {
    let ApplicationId = this.props.id;
    Api.ApplicationManagement.ApplicationPhoneLocation(ApplicationId)
      .then((phoneItems) => {
        this.setState({ phoneItems });
      })
      .catch(console.log);
  };

  handlelocateCar = () => {
    let ApplicationId = this.props.id;
    Api.ApplicationManagement.ApplicationCurrentCarLocation(ApplicationId)
      .then(() => {
        this.renderSuccessAlert(
          "The post has been successful",
          "Click the button"
        );
        this.locationCarHistory();
      })
      .catch((error) => {
        console.log(error);
        this.renderErrorAlert("Ops!", `${error.response.data.Message}`);
      });
  };
}

export default withRouter(Location);
