import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import SharedComponent from "../../Shared";
import { Api } from "../../../../Api/index";

export class StripeImportModal extends SharedComponent {
  state = {
    dealId: "",
    stripeCustomerId: "",
    stripePaymentIntentId: "",
    errorDeal: false,
    errorStripe: false,
    errorStripePaymentIntent: false,
    submitting: false,
  };

  submit = () => {
    if (!this.state.dealId || !/\d{10,}/.test(this.state.dealId)) {
      this.setState({
        errorDeal: "Please enter a valid Hubspot Deal Id",
      });
    } else if (
      !this.state.stripeCustomerId ||
      !this.state.stripeCustomerId.startsWith("cus_")
    ) {
      this.setState({
        errorStripe: "Please enter a valid Stripe Customer Id",
      });
    } else if (
      !this.state.stripePaymentIntentId ||
      !this.state.stripePaymentIntentId.startsWith("pi_")
    ) {
      this.setState({
        errorStripePaymentIntent:
          "Please enter a valid Stripe Payment Intent Id",
      });
    } else {
      this.setState({
        submitting: true,
        errorDeal: false,
        errorStripe: false,
        errorStripePaymentIntent: false,
      });
      Api["@driveavva"].new.Users.ImportFromStripeAndDeal(
        this.state.dealId,
        this.state.stripeCustomerId,
        this.state.stripePaymentIntentId
      )
        .then((data) => {
          this.renderSuccessAlert(
            `Member ${data.avva_id} has been imported successfully`,
            "Please allow 2-5 minutes for their subscription to be created in Stripe",
            () => (window.location = `/members/${data.member_id}`)
          );
          this.setState({
            submitting: false,
            dealId: "",
            stripeCustomerId: "",
            stripePaymentIntentId: "",
          });
          this.props.onClose();
        })
        .catch((e) => {
          console.warn(`Error importing member: ${e.message}`, e);
          this.setState({
            submitting: false,
            errorDeal: e.message,
          });
        });
    }
  };

  render() {
    return (
      <>
        <Modal isOpen={this.props.isOpen} toggle={this.props.onClose}>
          <ModalHeader toggle={this.props.onClose}>Import Member</ModalHeader>

          <ModalBody>
            <h4>
              This action will perform the following and should be used ONLY
              after a member has paid via PandaDocs
            </h4>
            <ol>
              <li>
                Setup the Member in the App using info from the Hubspot Deal
              </li>
              <li>Create the Member's Subscription in Stripe</li>
            </ol>
            <Form>
              <FormGroup>
                <Label
                  for="stripeCustomerId"
                  className={this.state.errorStripe ? "error" : ""}
                >
                  Stripe Customer Id
                </Label>
                <Input
                  name="stripeCustomerId"
                  className={this.state.errorStripe ? "error" : ""}
                  onChange={(e) =>
                    this.setState({
                      stripeCustomerId: e.currentTarget.value,
                      errorStripe: false,
                    })
                  }
                />
                {this.state.errorStripe && (
                  <span
                    className="error"
                    dangerouslySetInnerHTML={{ __html: this.state.errorStripe }}
                  />
                )}
              </FormGroup>
              <FormGroup>
                <Label
                  for="stripePaymentIntentId"
                  className={this.state.errorStripePaymentIntent ? "error" : ""}
                >
                  Stripe Payment Intent Id
                </Label>
                <Input
                  name="stripePaymentIntentId"
                  className={this.state.errorStripePaymentIntent ? "error" : ""}
                  onChange={(e) =>
                    this.setState({
                      stripePaymentIntentId: e.currentTarget.value,
                      errorStripePaymentIntent: false,
                    })
                  }
                />
                {this.state.errorStripePaymentIntent && (
                  <span
                    className="error"
                    dangerouslySetInnerHTML={{
                      __html: this.state.errorStripePaymentIntent,
                    }}
                  />
                )}
              </FormGroup>
              <FormGroup>
                <Label
                  for="dealId"
                  className={this.state.errorDeal ? "error" : ""}
                >
                  Hubspot Deal Id
                </Label>
                <Input
                  type="number"
                  name="dealId"
                  className={this.state.errorDeal ? "error" : ""}
                  onChange={(e) =>
                    this.setState({
                      dealId: e.currentTarget.value,
                      errorDeal: false,
                    })
                  }
                />
                {this.state.errorDeal && (
                  <span
                    className="error"
                    dangerouslySetInnerHTML={{ __html: this.state.errorDeal }}
                  />
                )}
                <p>
                  You can easily find the Hubspot Deal id in the browser when
                  you are viewing the deal you wish to import. The highlighted
                  portion below is the Deal Id
                  <div>
                    <i>
                      https://app.hubspot.com/contacts/000000/deal/
                      <span className="text-success">00000000000</span>
                    </i>
                  </div>
                </p>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              onClick={this.submit}
              disabled={this.state.submitting}
            >
              Import{this.state.submitting ? "ing" : ""}
            </Button>
            <Button color="secondary" onClick={this.props.onClose}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
