const Menu = [
  {
    heading: "Main Navigation",
  },
  // {
  //   name: "Dashboard",
  //   path: "/dashboard",
  //   icon: "fas fa-home",
  // },
  {
    name: "Inventory",
    path: "/inventory",
    icon: "fa fa-car",
  },
  {
    name: "Admins",
    path: "/admins",
    icon: "fas fa-users-cog",
  },
  {
    name: "Users",
    icon: "fas fa-users",
    path: "/users",
  },
  {
    name: "Applications",
    icon: "fas fa-file",
    path: "/applications",
  },
  {
    name: "Members",
    icon: "fas fa-user",
    path: "/members",
  },
  //   {
  //     name: "Notifications",
  //     icon: "fas fa-bell",
  //     path: "/notifications",
  //   },
  //   {
  //     name: "Delivery Request",
  //     icon: "fas fa-shipping-fast",
  //     path: "/delivery-request",
  //   },
  //   {
  //     name: "Car Delivery Slots",
  //     icon: "fas fa-calendar-times",
  //     path: "/cars/delivery-slots",
  //   },
];

export default Menu;
