import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";

class SendDeliveryModal extends Component {
  render() {
    return (
      <div>
        <Modal isOpen={this.props.isOpen} toggle={this.props.closeModal}>
          <ModalHeader toggle={this.props.closeModal}>
            Already have a delivery request
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md={12}>
                <div className="checkbox c-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      onChange={() =>
                        this.props.handleToggleCheckbox("sendEmail")
                      }
                      checked={this.props.sendEmail}
                    />
                    <span className="fa fa-check"></span>Send Email
                  </label>
                </div>
              </Col>
              <Col md={12}>
                <div className="checkbox c-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      onChange={() =>
                        this.props.handleToggleCheckbox("extendValidity")
                      }
                      checked={this.props.extendValidity}
                    />
                    <span className="fa fa-check"></span>Extend Validity
                  </label>
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.props.onSave}>
              Send
            </Button>
            <Button color="danger" onClick={this.props.closeModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default SendDeliveryModal;
