import React from "react";
import {
  TabPane,
  Input,
  Col,
  Row,
  Button,
  Badge,
  ListGroup,
  ListGroupItem,
  Form,
  FormGroup,
} from "reactstrap";
import Joi from "joi-browser";
import Moment from "react-moment";
import { withRouter } from "react-router-dom";
import SharedComponent from "../Shared";
import { Api } from "../../../Api/index";
class Actions extends SharedComponent {
  state = {
    data: {},
    sentToClient: false,
    errors: {},
    items: [],
    options: [
      { id: 1, label: "Pending", value: "Pending" },
      { id: 2, label: "Accepted", value: "Accepted" },
      { id: 3, label: "Rejected", value: "Rejected" },
    ],
  };
  schema = {
    notes: Joi.label("Notes"),
    status: Joi.required().label("Status"),
  };

  componentDidMount() {
    this.ActionsList();
  }

  render() {
    const { data, sentToClient, errors, items, options } = this.state;
    return (
      <TabPane tabId="Actions">
        <Row>
          <Col xs={12} className="mt-3">
            <h3 className="mb-0">New Action</h3>
            <hr className="w-25 float-left" />
          </Col>
        </Row>

        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col md={6}>
              <FormGroup>
                <label>Status</label>
                <Input
                  type="select"
                  name="status"
                  id="status"
                  value={data.status}
                  onChange={this.handleChange}
                >
                  <option disabled>
                    Select status
                  </option>
                  {options.map((option) => (
                    <option key={option.id} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Input>
                {errors && (
                  <span className="text-danger small">{errors.status}</span>
                )}
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <label>Notes</label>
                <Input
                  type="textarea"
                  name="notes"
                  id="notes"
                  rows="4"
                  onChange={this.handleChange}
                  value={data.notes}
                />
                {errors && (
                  <span className="text-danger small">{errors.notes}</span>
                )}
              </FormGroup>
            </Col>
            <Col md={12}>
              <div>
                <label>
                  <input
                    type="checkbox"
                    onChange={this.handleCheckboxChange}
                    checked={sentToClient}
                  />
                  Send to client
                </label>
              </div>
            </Col>
          </Row>
          <Button color="info" type="submit" className="float-right">
            Submit
          </Button>
          <div className="clearfix"></div>
        </Form>

        <Row>
          <Col xs={12}>
            <h3 className="mb-0">Actions History</h3>
            <hr className="w-25 float-left" />
          </Col>
        </Row>
        {items.length === 0 ? (
          this.renderSadFace(false)
        ) : (
          <Row>
            <Col md={12}>
              <ListGroup className="mb-3 mt-3 w-50">
                <ListGroup className="mb-3">
                  {items.map((item) => (
                    <ListGroupItem
                      key={Math.random()}
                      className="d-flex justify-content-between align-items-center"
                    >
                      <div>
                        <h6 className="mb-2">{item.adminName}</h6>
                        <p className={"mr-2"}>{item.notes}</p>
                        <Badge color="info">{item.status}</Badge>
                      </div>
                      <div className={"mt-3"}>
                        <h6 className="mb-2">
                          <Moment format="MM/DD/YYYY">{item.createdAt}</Moment>
                        </h6>
                        Sent to client:
                        <Badge color="success">
                          {item.sentToClient === true ? (
                            <span>True</span>
                          ) : (
                            <span>False</span>
                          )}
                        </Badge>
                      </div>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </ListGroup>
            </Col>
          </Row>
        )}
      </TabPane>
    );
  }
  ActionsList = () => {
    let applicationId = this.props.id;
    Api.ApplicationManagement.ApplicationActionList(applicationId)
      .then((items) => {
        this.setState({ items });
      })
      .catch(console.log);
  };

  doSubmit = () => {
    const {
      data: { notes, status },
      sentToClient,
    } = this.state;
    let applicationId = this.props.id;
    let action = {
      notes,
      sentToClient,
      applicationId,
      status,
    };
    Api.ApplicationManagement.AddApplicationAction(action)
      .then(() => {
        this.ActionsList();
        this.state.data.notes = "";
        this.state.data.status = "";
      })
      .catch(console.log);
  };
  handleCheckboxChange = (e) => {
    console.log('change?', this.state.sentToClient);
    this.setState((o) =>({ sentToClient: !o.sentToClient }));
  };
}

export default withRouter(Actions);
