import React from "react";
import { Card, CardBody, CardText, CardTitle, Col, Row } from "reactstrap";
import SharedComponent from "../Shared";
import { formatFullDate, formatPhone } from "./utils";

class SummaryMember extends SharedComponent {
  state = {
    consents: [],
    tooltip: false,
  };

  toggle = (type) => {
    this.setState({
      tooltip: this.state.tooltip === type ? false : type,
    });
  };

  render() {
    return (
      <Card style={{ width: "100%" }}>
        <CardBody>
          <CardTitle tag="h3">
            Member #{this.props.user.avva_id}'s Details
          </CardTitle>
          <CardText tag="div" className="mt-2">
            {this.props.user.profile && (
              <>
                <Row>
                  <Col xs={6}>
                    Legal Name: {this.props.user.profile.first_name}
                    {this.props.user.profile.middle_name
                      ? ` ${this.props.user.profile.middle_name}`
                      : ""}{" "}
                    {this.props.user.profile.last_name}
                  </Col>
                  <Col xs={6}>
                    Member Since:{" "}
                    {this.props.user.member_since_date
                      ? formatFullDate(this.props.user.member_since_date)
                      : null}
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    Phone:{" "}
                    {this.props.user.phone
                      ? formatPhone(this.props.user.phone)
                      : null}
                  </Col>
                  <Col xs={6}>Email: {this.props.user.email}</Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    Available Coin Amount:{" "}
                    {this.props.user.available_coin_amount}
                  </Col>
                  <Col xs={6}>
                    Lifetime Coin Amount: {this.props.user.lifetime_coin_amount}
                  </Col>
                </Row>
              </>
            )}
          </CardText>
        </CardBody>
      </Card>
    );
  }
}

export { SummaryMember };
