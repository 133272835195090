import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import SharedComponent from "../../Shared";
import { Api } from "../../../../Api/index";
import DateTimeSelector from "../DateTimePicker/DateTimeSelector";

const fields = [
  "insurance_policy_ends_at",
  "insurance_policy_number",
  "insurance_policy_starts_at",
  "roadside_contract_number",
  "roadside_provider_type",
];

export class InsuranceModal extends SharedComponent {
  state = {
    insurance_policy_ends_at: false,
    insurance_policy_number: false,
    insurance_policy_starts_at: false,
    roadside_contract_number: false,
    roadside_provider_type: false,
    errors: false,
    submitting: false,
  };

  validate = () => {
    const errors = {};

    fields.forEach((f) => {
      if (!this.state[f]) errors[f] = "Required";
    });

    if (Object.keys(errors).length) {
      this.setState({ errors });
      return false;
    }

    return {
      insurance_policy_number: this.state.insurance_policy_number,
      insurance_policy_ends_at: this.state.insurance_policy_ends_at,
      insurance_policy_starts_at: this.state.insurance_policy_starts_at,
      roadside_contract_number: this.state.roadside_contract_number,
      roadside_provider_type: this.state.roadside_provider_type,
    };
  };

  componentDidMount() {
    this.setState({ ...this.props.vehicle });
  }

  submit = () => {
    const payload = this.validate();
    if (payload) {
      console.log(payload);
      this.setState({ submitting: true });
      Api["@driveavva"].new.Vehicles.Update(this.props.vehicle.id, payload)
        .then(() => {
          this.renderSuccessAlert("Success!", "", () =>
            window.location.reload()
          );
          this.setState({
            submitting: false,
            errors: false,
          });
          this.props.onClose();
        })
        .catch((e) => {
          console.warn(e);
          this.renderErrorAlert(
            "Encountered Error updating vehicle",
            e.message
          );
          this.setState({
            submitting: false,
          });
        });
    }
  };

  error = (label) => (this.state.errors && this.state.errors[label]) || false;

  change = (e, label = "") => {
    const l = label || e.currentTarget.name;
    const v = typeof e === "object" ? e.currentTarget.value : e;
    const state = this.state;
    if (state.errors && state.errors[l]) delete state.errors[l];
    this.setState({
      ...state,
      [l]: v,
    });
  };

  render() {
    if (!this.props.vehicle) return null;

    return (
      <>
        <Modal isOpen={this.props.isOpen} toggle={this.props.onClose}>
          <ModalHeader toggle={this.props.onClose}>
            Update Insurance
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label
                  for="insurance_policy_number"
                  className={
                    this.error("insurance_policy_number") ? "error" : ""
                  }
                >
                  Policy #
                </Label>
                <Input
                  type="text"
                  defaultValue={this.props.vehicle.insurance_policy_number}
                  name="insurance_policy_number"
                  className={
                    this.error("insurance_policy_number") ? "error" : ""
                  }
                  onChange={this.change}
                />
                {this.error("insurance_policy_number") && (
                  <span
                    className="error"
                    dangerouslySetInnerHTML={{
                      __html: this.error("insurance_policy_number"),
                    }}
                  />
                )}
              </FormGroup>
              <FormGroup>
                <DateTimeSelector
                  defaultValue={this.props.vehicle.insurance_policy_starts_at}
                  error={this.error("insurance_policy_starts_at")}
                  date
                  dt_channel={(d) =>
                    this.change(
                      new Date(d).toISOString(),
                      "insurance_policy_starts_at"
                    )
                  }
                  label={"Starts At"}
                />
              </FormGroup>
              <FormGroup>
                <DateTimeSelector
                  defaultValue={this.props.vehicle.insurance_policy_ends_at}
                  date
                  error={this.error("insurance_policy_ends_at")}
                  dt_channel={(d) =>
                    this.change(
                      new Date(d).toISOString(),
                      "insurance_policy_ends_at"
                    )
                  }
                  label={"Ends At"}
                />
              </FormGroup>
              <FormGroup>
                <Label for="roadside_provider_type">Roadside Provider</Label>
                <Input
                  type="select"
                  defaultValue={this.props.vehicle.roadside_provider_type}
                  name="roadside_provider_type"
                  error={this.error("roadside_provider_type")}
                  onChange={this.change}
                >
                  <option value="CONNECT">Connect</option>
                  <option value="FORESIGHT">Foresight</option>
                </Input>
                {this.error("roadside_provider_type") && (
                  <span
                    className="error"
                    dangerouslySetInnerHTML={{
                      __html: this.error("roadside_provider_type"),
                    }}
                  />
                )}
              </FormGroup>
              <FormGroup>
                <Label
                  for="roadside_contract_number"
                  className={
                    this.error("roadside_contract_number") ? "error" : ""
                  }
                >
                  Roadside Contract #
                </Label>
                <Input
                  type="text"
                  defaultValue={this.props.vehicle.roadside_contract_number}
                  name="roadside_contract_number"
                  className={
                    this.error("roadside_contract_number") ? "error" : ""
                  }
                  onChange={this.change}
                />
                {this.error("roadside_contract_number") && (
                  <span
                    className="error"
                    dangerouslySetInnerHTML={{
                      __html: this.error("roadside_contract_number"),
                    }}
                  />
                )}
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              onClick={this.submit}
              disabled={this.state.submitting}
            >
              Updat{this.state.submitting ? "ing" : "e"}
            </Button>
            <Button color="secondary" onClick={this.props.onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
