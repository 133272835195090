import { getAuthenticatedAxios } from "../../helpers";
import api from "../../apiEndPoint";
import { encrypt, getAndDecryptDataFromResponse } from ".";

export const UserApiEndpoints = {
  UserList: () =>
    getAuthenticatedAxios(true)
      .get(`${api["@driveavva"].core.Users}`)
      .then(async (r) => await getAndDecryptDataFromResponse(r)),

  UserDetails: (UserId) =>
    getAuthenticatedAxios(true)
      .get(`${api["@driveavva"].core.Users}/${UserId}`)
      .then(async (r) => await getAndDecryptDataFromResponse(r)),

  UpdateUser: async (UserId, data) =>
    getAuthenticatedAxios(true)
      .put(`${api["@driveavva"].core.Users}/${UserId}`, data)
      .then(getAndDecryptDataFromResponse),
  Consents: async (UserId) =>
    getAuthenticatedAxios(true)
      .get(`${api["@driveavva"].core.Users}/${UserId}/consents`)
      .then(getAndDecryptDataFromResponse),
  Reports: async (UserId, type) =>
    getAuthenticatedAxios(true)
      .get(`${api["@driveavva"].core.Users}/${UserId}/reports?type=${type}`)
      .then(getAndDecryptDataFromResponse),
  RunReport: async (UserId, type) =>
    getAuthenticatedAxios(true)
      .post(`${api["@driveavva"].core.Users}/${UserId}/reports?type=${type}`)
      .then(getAndDecryptDataFromResponse),
};
