import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import SharedComponent from "../../Shared";
import { Api } from "../../../../Api/index";

export class RewardModal extends SharedComponent {
  state = {
    rewards: [],
    loading: true,
    type: "earnable",
    error: false,
    submitting: false,
    override: false,
    reward: false,
  };

  get = () => {
    Api["@driveavva"].new.Rewards.RewardList()
      .then((rewards) => {
        this.setState({ loading: false, rewards });
      })
      .catch(() => {
        this.renderErrorAlert(
          "Oops!",
          "Something went wrong, not able to get Rewards!"
        );
      });
  };

  submit = () => {
    if (!this.state.reward) {
      this.setState({
        error: "Please select a reward",
      });
    } else {
      this.setState({ submitting: true });
      Api["@driveavva"].new.Rewards.RewardModify(this.state.type, {
        user: this.props.user.id,
        reward: this.state.reward,
        override: this.state.override,
      })
        .then(() => {
          this.renderSuccessAlert("Success!");
          this.setState({
            type: "earnable",
            submitting: false,
            override: false,
            reward: false,
          });
          this.props.onClose();
        })
        .catch((e) => {
          console.info(JSON.stringify(e));
          this.setState({
            type: "earnable",
            submitting: false,
            override: true,
            reward: false,
          });
          if (
            e.message !==
            "This reward is already visible to the user in their MY REWARDS section. If you wish to show duplicate rewards re-submit"
          )
            this.props.onClose();
          this.renderErrorAlert("Encountered issue", e.message);
        });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      !prevProps.isOpen &&
      this.props.isOpen &&
      prevState.rewards.length === 0
    ) {
      this.get();
    }
  }

  render() {
    const selectedReward = this.state.reward
      ? this.state.rewards.find(({ id }) => id === Number(this.state.reward))
      : null;

    return (
      <>
        <Modal isOpen={this.props.isOpen} toggle={this.props.onClose}>
          <ModalHeader toggle={this.props.onClose}>Send Reward</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="reward" className={this.state.error ? "error" : ""}>
                  Reward
                </Label>
                <Input
                  type="select"
                  name="reward"
                  className={this.state.error ? "error" : ""}
                  disabled={this.state.loading}
                  onChange={(e) =>
                    this.setState({
                      reward: e.currentTarget.value,
                      error: false,
                    })
                  }
                >
                  {
                    <option value="">
                      {this.state.loading ? "Loading..." : "Select a Reward"}
                    </option>
                  }
                  {!this.state.loading &&
                    this.state.rewards.map((r) => (
                      <option key={r.id} value={r.id}>
                        {r.name} ({r.coin_amount} coins)
                      </option>
                    ))}
                </Input>
                {this.state.error ? (
                  <span className="error">{this.state.error}</span>
                ) : null}
              </FormGroup>
              <FormGroup>
                <Label for="type">App Section</Label>
                <Input
                  type="select"
                  name="type"
                  onChange={(e) =>
                    this.setState({ type: e.currentTarget.value })
                  }
                >
                  <option value="earnable">EARNABLE REWARDS</option>
                  <option value="my">MY REWARDS</option>
                </Input>
              </FormGroup>

              {selectedReward && this.state.type === "my" && (
                <div>
                  A notification will{" "}
                  {selectedReward.earned_notification_id ? "" : "not"} be sent
                  to the user
                </div>
              )}
              {selectedReward && this.state.type === "earnable" && (
                <div>
                  A notification will{" "}
                  {selectedReward.earn_notification_id ? "" : "not"} be sent to
                  the user
                </div>
              )}
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              onClick={this.submit}
              disabled={this.state.loading || this.state.submitting}
            >
              Submit{this.state.submitting ? "ing" : ""}
            </Button>
            <Button color="secondary" onClick={this.props.onClose}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
