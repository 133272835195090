import React, { Component } from "react";
import { Button } from "reactstrap";

const copyToClipboard = (link) => {
  console.log("Copied " + link);
  const el = document.createElement("textarea");
  el.value = link;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

const BASE_URL =
  window.location.host === "admin.driveavva.com"
    ? "https://driveavva.com"
    : window.location.host.includes("staging")
    ? "https://avva.staging.beanstalkdigital.com"
    : window.location.host.includes("qa")
    ? "https://avva.dev.beanstalkdigital.com"
    : "http://avva.local";

export class ShareLink extends Component {
  state = {
    showToast: false,
  };

  render() {
    const link = BASE_URL + this.props.link;
    return (
      <Button
        size={this.props.size ? this.props.size : "normal"}
        color={this.state.showToast ? "success" : "primary"}
        onClick={() => {
          copyToClipboard(link);
          this.setState({ showToast: true });
          setTimeout(() => this.setState({ showToast: false }), 2000);
        }}
      >
        {this.state.showToast ? "Copied!" : "Share Link"}
      </Button>
    );
  }
}
