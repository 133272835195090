import React from "react";
import {
  Row,
  Col,
  TabPane,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Joi from "joi-browser";
import Pagination from "rc-pagination";
import SharedComponent from "../Shared";
import { Api } from "../../../Api/index";
class UnscheduledM extends SharedComponent {
  state = {
    dropdownOpen: false,
    activeTab: "ScheduledM",
    inputVal: this.props.inputValue,
    isLoading: true,
    data: {
      ApplicationNumber: "",
      ApplicationDate: "",
      Description: "",
      serviceDate: "2020-02-07T00:00:00",
      description: "test",
    },
    items: [],
    requestOptions: {
      currentPage: 1,
      totalPages: 1,
      totalItems: 0,
      pageSize: 20,
    },
    viewModel: false,
    status: [{ name: "new" }, { name: "Scheduled" }, { name: "rejected" }],
  };
  schema = {
    ApplicationNumber: Joi.label("Make"),
    ApplicationDate: Joi.label("Application Date"),
    Description: Joi.label("Application Date"),
  };

  async componentDidMount() {
    this.queryItems();
  }

  render() {
    const { isLoading, items, data, status } = this.state;
    const { currentPage, pageSize, totalItems } = this.state.requestOptions;
    return (
      <TabPane tabId="UnscheduledM">
        <Form className="mb-3 mt-3" onSubmit={this.handleSubmit}>
          <Row>
            <Col md={2}>
              <FormGroup>
                <Label for="ApplicationNumber">APPLICATION NUMBER</Label>
                <Input
                  type="number"
                  name="ApplicationNumber"
                  id="ApplicationNumber"
                  onChange={this.handleChange}
                  value={data.ApplicationNumber}
                />
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label for="ApplicationDate">Date</Label>
                <Input
                  type="date"
                  name="ApplicationDate"
                  id="ApplicationDate"
                  onChange={this.handleChange}
                  value={data.ApplicationDate}
                />
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label for="Description">Description</Label>
                <Input
                  type="text"
                  name="Description"
                  id="Description"
                  onChange={this.handleChange}
                  value={data.Description}
                />
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label for="Status">Status</Label>
                <Input
                  type="select"
                  name="Status"
                  id="Status"
                  value={data.Status}
                  onChange={this.handleChange}
                >
                  <option selected>Select Please</option>
                  {status.map((option) => (
                    <option key={option.name} value={option.name}>
                      {option.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <div
                  style={{ height: 30 }}
                  className="d-none d-md-block d-lg-block"
                ></div>
                <Button type="submit" color={"info"} className="w-100">
                  Search
                </Button>
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <div
                  style={{ height: 30 }}
                  className="d-none d-md-block d-lg-block"
                ></div>
                <Button
                  color={"success"}
                  onClick={this.resetData}
                  className="w-100"
                >
                  Reset Data
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Form>

        {!isLoading ? (
          <React.Fragment>
            {items.length !== 0 ? (
              <React.Fragment>
                <Row>
                  <Col md={12}>
                    <Table className="table table-bordered table-striped table-hover mb-4">
                      <thead>
                        <tr>
                          <th className="sort-alpha">Application Number</th>
                          <th className="sort-alpha">Client Name</th>
                          <th className="sort-alpha">Client Phone</th>
                          <th className="sort-alpha">Car Make</th>
                          <th className="sort-alpha">Car Model</th>
                          <th className="sort-alpha">Preferred Date</th>
                          <th className="sort-alpha">Date</th>
                          <th className="sort-alpha">Status</th>
                          <th className="sort-alpha">Control</th>
                        </tr>
                      </thead>
                      <tbody>
                        {items.map((item) => (
                          <tr key={Math.random()}>
                            <td>{item.applicationNumber}</td>
                            <td>{item.clientName}</td>
                            <td>{item.clientPhone}</td>
                            <td>{item.carMake}</td>
                            <td>{item.carModel}</td>
                            <td>{item.serviceDate}</td>
                            <td>{item.description}</td>
                            <td>
                              <div className="badge badge-success ">
                                {item.status}
                              </div>
                            </td>
                            <td className="text-center">
                              <div>
                                <Button
                                  onClick={() => this.openModel(item.id)}
                                  color="warning"
                                  className="btn btn-warning d-inline-flex align-items-center"
                                >
                                  Details
                                </Button>
                                {item.status === "New" ? (
                                  <>
                                    <Button
                                      onClick={() =>
                                        this.handleApproveOrClose(
                                          item.id,
                                          "approve"
                                        )
                                      }
                                      color="success"
                                      className="btn  d-inline-flex align-items-center mx-1"
                                    >
                                      Schedule
                                    </Button>
                                    <Button
                                      onClick={() =>
                                        this.handleApproveOrClose(
                                          item.id,
                                          "reject"
                                        )
                                      }
                                      color="danger"
                                      className="btn  d-inline-flex align-items-center mx-1"
                                    >
                                      Reject
                                    </Button>
                                  </>
                                ) : null}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Pagination
                      showSizeChanger
                      defaultPageSize={pageSize}
                      defaultCurrent={currentPage}
                      onChange={this.handlePageChange}
                      total={totalItems}
                      className="float-right text-white"
                    />
                  </Col>
                </Row>
              </React.Fragment>
            ) : (
              this.renderSadFace(true)
            )}
          </React.Fragment>
        ) : (
          this.renderLoader()
        )}

        <Modal isOpen={this.state.viewModel} toggle={this.closeViewModel}>
          <ModalHeader toggle={this.toggleModal}>
            UnScheduled Maintenance
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label>Service Date</Label>
                <Input
                  type="date"
                  name="serviceDate"
                  onChange={this.handleChange}
                  value={data.serviceDate.split("T")[0]}
                />
              </FormGroup>
              <FormGroup>
                <Label>Location</Label>
                <Input
                  type="text"
                  name="location"
                  onChange={this.handleChange}
                  value={data.location}
                />
              </FormGroup>
              <FormGroup>
                <Label>Contact Name</Label>
                <Input
                  type="text"
                  name="contactName"
                  onChange={this.handleChange}
                  value={data.contactName}
                />
              </FormGroup>
              <FormGroup>
                <Label>Contact Phone</Label>
                <Input
                  type="text"
                  name="contactPhone"
                  onChange={this.handleChange}
                  value={data.contactPhone}
                />
              </FormGroup>
              <FormGroup>
                <Label>Description</Label>
                <Input
                  type="textarea"
                  name="description"
                  onChange={this.handleChange}
                  value={data.description}
                  rows={3}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.handleUpdate} color="warning">
              Update
            </Button>
            <Button color="secondary" onClick={this.closeViewModel}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </TabPane>
    );
  }

  handleUpdate = () => {
    const { data, id } = this.state;
    Api.Notifications.Unschedule.UpdateUnScheduled(id, {
      serviceDate: data.serviceDate,
      details: data.description,
      location: data.location,
      contactName: data.contactName,
      contactPhone: data.contactPhone,
    })
      .then(() => {
        this.setState({ viewModel: false });
        this.renderSuccessAlert("Updated successfully", "Click the button");
        this.queryItems();
      })
      .catch(() => {
        this.renderErrorAlert(
          "Ops!",
          "Something went wrong, please try again!"
        );
      });
  };
  doSubmit = () => {
    this.queryItems(1);
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.doSubmit();
  };
  resetData = () => {
    const requestOptions = this.state.requestOptions;
    const data = this.state.data;
    requestOptions.currentPage = 1;
    requestOptions.totalPages = 1;
    requestOptions.totalItems = 0;
    requestOptions.pageSize = 20;
    data.ApplicationNumber = "";
    data.ApplicationDate = "";
    data.Description = "";
    data.Status = "";
    this.setState({ requestOptions, data });
    this.queryItems();
  };

  openModel = (id) => {
    this.setState({ viewModel: true, id });
    Api.Notifications.Unschedule.UnScheduledDetails(id)
      .then((data) => {
        this.setState({ data });
      })
      .catch((error) => {
        this.renderErrorAlert("Ops!", `${error.response.data.Message}`);
      });
  };

  closeViewModel = () => {
    this.setState({ viewModel: false });
  };

  queryItems = (page) => {
    this.setState({
      isLoading: true,
    });
    const { pageSize, currentPage } = this.state.requestOptions;
    const {
      ApplicationNumber,
      ApplicationDate,
      Description,
      Status,
    } = this.state.data;
    Api.Notifications.Unschedule.UnscheduleList(
      ApplicationNumber,
      ApplicationDate,
      page,
      currentPage,
      pageSize,
      Description,
      Status
    )
      .then((response) => {
        let items = response.items;
        this.setState({
          isLoading: false,
          items,
          requestOptions: {
            currentPage: response.currentPage,
            totalPages: response.totalPages,
            totalItems: response.totalItems,
            pageSize: response.pageSize,
          },
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };
  handleApproveOrClose = (id, Case) => {
    Api.Notifications.Unschedule.ApproveOrCloseUnScheduled(id, Case)
      .then(() => {
        this.renderSuccessAlert(
          Case === "approve"
            ? "The request is approved"
            : "The request is close",
          "Click the button"
        );
        this.queryItems(1);
      })
      .catch((error) => {
        this.renderErrorAlert("Ops!", `${error.response.data.Message}`);
      });
  };
}

export default UnscheduledM;
