import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import SharedComponent from "../../Shared";
import { Api } from "../../../../Api/index";
import DateTimeSelector from "../DateTimePicker/DateTimeSelector";

export class CompleteDeliveryModal extends SharedComponent {
  state = {
    mileage_at_delivery_amount: false,
    delivered_at: false,
    error1: false,
    error2: false,
    submitting: false,
  };

  submit = () => {
    if (
      !this.state.mileage_at_delivery_amount ||
      this.state.mileage_at_delivery_amount < 0
    ) {
      this.setState({
        error1: "Please enter a valid mileage amount",
      });
    } else if (!this.state.delivered_at) {
      this.setState({
        error2: "Please enter a valid delivery date",
      });
    } else {
      this.setState({ submitting: true });
      Api["@driveavva"].new.Vehicles.Update(this.props.vehicle, {
        delivered_at: this.state.delivered_at,
        mileage_at_delivery_amount: this.state.mileage_at_delivery_amount,
      })
        .then(() => {
          this.renderSuccessAlert("Success!", "", () =>
            window.location.reload()
          );
          this.setState({
            submitting: false,
            delivered_at: false,
            mileage_at_delivery_amount: false,
            error2: false,
            error1: false,
          });
          this.props.onClose();
        })
        .catch((e) => {
          this.setState({
            submitting: false,
            error1: false,
            error2: e.errors[this.state.dealId].replace(/\n/g, "<div></div>"),
          });
        });
    }
  };

  render() {
    return (
      <>
        <Modal isOpen={this.props.isOpen} toggle={this.props.onClose}>
          <ModalHeader toggle={this.props.onClose}>
            Complete Delivery
          </ModalHeader>
          <ModalBody>
            <p>
              These values should come directly from the Docusign statement the
              member signed when the vehicle was delivered. The values will be
              used to properly calculate their mileage relative to the vehicle's
              onboard systems.
            </p>
            <Form>
              <FormGroup>
                <Label
                  for="odometer"
                  className={this.state.error1 ? "error" : ""}
                >
                  Odometer Reading
                </Label>
                <Input
                  type="number"
                  name="odometer"
                  className={this.state.error1 ? "error" : ""}
                  onChange={(e) =>
                    this.setState({
                      mileage_at_delivery_amount: e.currentTarget.value,
                      error1: false,
                    })
                  }
                />
                {this.state.error1 && (
                  <span
                    className="error"
                    dangerouslySetInnerHTML={{ __html: this.state.error1 }}
                  />
                )}
              </FormGroup>
              <FormGroup>
                <DateTimeSelector
                  error={this.state.error2}
                  dt_channel={(d) =>
                    this.setState({
                      delivered_at: new Date(d).toUTCString(),
                      error2: false,
                    })
                  }
                  label={"Delivered At"}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              onClick={this.submit}
              disabled={this.state.submitting}
            >
              Complet{this.state.submitting ? "ing" : "e"}
            </Button>
            <Button color="secondary" onClick={this.props.onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
