import React, { Component } from "react";
import ContentWrapper from "../../Layout/ContentWrapper";
import { Row, Col, TabContent, Nav, NavItem, NavLink } from "reactstrap";
import UnscheduledM from "./UnscheduledM";
import Incidents from "./Incidents";
import LongTrips from "./LongTrip";
import Support from "./Support";
import SeheduledM from "./ScheduledM";
import PilotFeedback from "./PilotFeedback";
class NotificationsList extends Component {
  state = {
    dropdownOpen: false,
    activeTab: "ScheduledM",
    isLoading: true,
    items: [],
    options: {
      paging: true, // Table pagination
      ordering: true, // Column ordering
      info: true, // Bottom left status text
      responsive: true,
      // Text translation options
      // Note the required keywords between underscores (e.g _MENU_)
      oLanguage: {
        sSearch: '<em className="fa fa-search"></em>',
        sLengthMenu: "_MENU_ records per page",
        info: "Showing page _PAGE_ of _PAGES_",
        zeroRecords: "Nothing found - sorry",
        infoEmpty: "No records available",
        infoFiltered: "(filtered from _MAX_ total records)",
        oPaginate: {
          sNext: '<em className="fa fa-caret-right"></em>',
          sPrevious: '<em className="fa fa-caret-left"></em>',
        },
      },
    },
  };

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };
  render() {
    return (
      <ContentWrapper>
        <div className="content-heading d-flex justify-content-between">
          <div>Notifications</div>
        </div>
        <Row>
          <Col xs={12}>
            <div className="card card-transparent">
              <Nav tabs justified>
                <NavItem>
                  <NavLink
                    className={
                      this.state.activeTab === "ScheduledM" ? "active" : ""
                    }
                    onClick={() => {
                      this.toggleTab("ScheduledM");
                    }}
                  >
                    Schedule
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={
                      this.state.activeTab === "UnscheduledM" ? "active" : ""
                    }
                    onClick={() => {
                      this.toggleTab("UnscheduledM");
                    }}
                  >
                    Unschedule
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={
                      this.state.activeTab === "Incidents" ? "active" : ""
                    }
                    onClick={() => {
                      this.toggleTab("Incidents");
                    }}
                  >
                    Incidents
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={
                      this.state.activeTab === "LongTrips" ? "active" : ""
                    }
                    onClick={() => {
                      this.toggleTab("LongTrips");
                    }}
                  >
                    Long Trips
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={
                      this.state.activeTab === "Support" ? "active" : ""
                    }
                    onClick={() => {
                      this.toggleTab("Support");
                    }}
                  >
                    Support
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={
                      this.state.activeTab === "PilotFeedback" ? "active" : ""
                    }
                    onClick={() => {
                      this.toggleTab("PilotFeedback");
                    }}
                  >
                    Pilot Feedback
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTab} className="bg-white">
                <SeheduledM />
                <UnscheduledM tabId="UnscheduledM" />
                <Incidents tabId="Incidents" />
                <LongTrips tabId="LongTrips" />
                <Support tabId="Support" />
                <PilotFeedback tabId="PilotFeedback" />
              </TabContent>
            </div>
          </Col>
        </Row>
      </ContentWrapper>
    );
  }
}

export { NotificationsList };
