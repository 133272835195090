import React from "react";
import ContentWrapper from "../../Layout/ContentWrapper";
import { Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import Datatable from "../../Tables/Datatable";
import SharedComponent from "../Shared";
import { Api } from "../../../Api/index";
class AdminsList extends SharedComponent {
  state = {
    dropdownOpen: false,
    isLoading: true,
    items: [],
  };

  componentDidMount() {
    this.getAdminsList();
  }

  render() {
    const { isLoading } = this.state;
    return (
      <ContentWrapper>
        <div className="content-heading d-flex justify-content-between">
          <div>Admins</div>
          <Link to="/admins/create" className="btn btn-primary">
            New Admin
          </Link>
        </div>
        <Card className="card-default">
          <CardBody>
            {!isLoading ? (
              <Datatable options={this.renderOption()}>
                <table className="table table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <th className="sort-alpha">Full Name</th>
                      <th className="sort-alpha">Phone</th>
                      <th className="sort-alpha">Email</th>
                      <th className="sort-alpha">Control</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.items.map((item, index) => (
                      <tr key={index}>
                        <td>{item.fullName}</td>
                        <td>{item.phoneNumber}</td>
                        <td>{item.email}</td>
                        <td className="text-center">
                          <div>
                            <Link
                              to={`/admins/update/${item.id}`}
                              color="warning"
                              className="btn btn-warning d-inline-flex align-items-center"
                            >
                              Details
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Datatable>
            ) : (
              this.renderLoader()
            )}
          </CardBody>
        </Card>
      </ContentWrapper>
    );
  }
  getAdminsList = () => {
    Api.UsersManagement.UserList()
      .then((response) => {
        this.setState({
          items: response.items,
          isLoading: false,
        });
      })
      .catch(console.log);
  };
}

export { AdminsList };
