import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import SharedComponent from "../../Shared";
import { Api } from "../../../../Api/index";

export class StripeModal extends SharedComponent {
  state = {
    stripe_customer_id: "",
    stripe_subscription_id: "",
    error1: false,
    error2: false,
    submitting: false,
  };

  submit = () => {
    if (
      !this.state.stripe_customer_id ||
      !/cus_(\w|\d){10,}/.test(this.state.stripe_customer_id)
    ) {
      this.setState({
        error1: "Please enter a valid Stripe Customer Id",
      });
    } else if (
      !this.state.stripe_subscription_id ||
      !/sub_(\w|\d){10,}/.test(this.state.stripe_subscription_id)
    ) {
      this.setState({
        error2: "Please enter a valid Stripe Subscription Id",
      });
    } else {
      this.setState({ submitting: true });
      Api["@driveavva"].new.Users.UpdateStripe(this.props.user.id, {
        stripe_customer_id: this.state.stripe_customer_id,
        stripe_subscription_id: this.state.stripe_subscription_id,
      })
        .then(() => {
          this.renderSuccessAlert(
            "Success!",
            `${this.props.user.name}'s Stripe info has been updated`,
            () => window.location.reload()
          );
        })
        .catch((e) => {
          this.setState({
            submitting: false,
            error: e.message,
          });
        });
    }
  };

  render() {
    return (
      <>
        <Modal isOpen={this.props.isOpen} toggle={this.props.onClose}>
          <ModalHeader toggle={this.props.onClose}>
            Update {this.props.user.name}'s Stripe Info
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label
                  for="stripe_customer_id"
                  className={this.state.error ? "error" : ""}
                >
                  Stripe Customer Id
                </Label>
                <Input
                  type="text"
                  name="stripe_customer_id"
                  value={this.state.stripe_customer_id}
                  onChange={(e) =>
                    this.setState({ stripe_customer_id: e.target.value })
                  }
                  className={this.state.error1 ? "error" : ""}
                />
                {this.state.error1 && (
                  <span
                    className="error"
                    dangerouslySetInnerHTML={{ __html: this.state.error1 }}
                  />
                )}
              </FormGroup>
              <FormGroup>
                <Label
                  for="stripe_subscription_id"
                  className={this.state.error ? "error" : ""}
                >
                  Stripe Subscription Id
                </Label>
                <Input
                  type="text"
                  name="stripe_subscription_id"
                  value={this.state.stripe_subscription_id}
                  onChange={(e) =>
                    this.setState({ stripe_subscription_id: e.target.value })
                  }
                  className={this.state.error2 ? "error" : ""}
                />
                {this.state.error2 && (
                  <span
                    className="error"
                    dangerouslySetInnerHTML={{ __html: this.state.error2 }}
                  />
                )}
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              onClick={this.submit}
              disabled={this.state.submitting}
            >
              Updat{this.state.submitting ? "ing" : "e"}
            </Button>
            <Button color="secondary" onClick={this.props.onClose}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
