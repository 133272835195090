import React from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardText,
  CardSubtitle,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Collapse,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";
import { Api } from "../../../../Api/index";
import SharedComponent from "../../Shared";
import { FinancialResultGraph } from "../graphs/FinancialResultGraph";
import { SummaryApplicant } from "./SummaryApplicant";
import { SummaryApplication } from "./SummaryApplication";
import { SummaryFinancialReport } from "./SummaryFinancialReport";
import { SummaryVehicle } from "./SummaryVehicle";
import { Transactions } from "./Transactions";
import { SummaryAccount } from "./SummaryAccount";

class Summary extends SharedComponent {
  updateRecommendation(isPass = false) {
    this.renderConfirmAlert(
      `Are you sure you want to ${isPass ? "pass" : "fail"} this application?`,
      async () => {
        await Api[
          "@driveavva"
        ].core.AppsManagement.UpdateFinancialRecommendation(
          this.props.app.id,
          isPass ? "PASS" : "FAIL"
        ).then(() => window.location.reload());
      }
    );
  }

  appFinances() {
    return Array.isArray(this.props.app.application_finances)
      ? this.props.app.application_finances.length
        ? this.props.app.application_finances[0]
        : null
      : this.props.app.application_finances;
  }

  renderRecommendation(finrec) {
    const appFinances = this.appFinances();
    return (
      <>
        <span
          className={
            finrec === "PASS"
              ? "text-success"
              : finrec === "FAIL"
              ? "text-danger"
              : "text-info"
          }
        >
          {finrec === "PASS" ? "✅" : finrec === "FAIL" ? "🚫" : "🔍"} {finrec}
        </span>
        {finrec !== "PASS" && appFinances.recommendation_reason && (
          <span class="ml-1" style={{ fontSize: 16 }}>
            ({appFinances.recommendation_reason})
          </span>
        )}
      </>
    );
  }

  render() {
    const appFinances = this.appFinances();

    const finrec = appFinances
      ? appFinances.recommendation_override || appFinances.recommendation
      : false;

    console.log(this.props);
    return (
      <>
        <Row>
          <Col className="d-flex">
            <div style={{ width: "100%", display: "flex" }}>
              <SummaryApplicant
                application={this.props.app.id}
                user={this.props.user}
                vehicle={this.props.vehicle}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card style={{ width: "100%" }}>
              <CardBody>
                <CardTitle tag="h3">
                  Financials{finrec && this.renderRecommendation(finrec)}
                </CardTitle>
                {finrec === "NOT_DETERMINED" && (
                  <div className="my-1 d-flex">
                    <Button
                      color="success"
                      onClick={() => this.updateRecommendation(true)}
                    >
                      Update Recommendation to PASS
                    </Button>
                    <Button
                      className="ml-3"
                      color="danger"
                      onClick={() => this.updateRecommendation(false)}
                    >
                      Update Recommendation to FAIL
                    </Button>
                  </div>
                )}
                {finrec === "NOT_DETERMINED" && (
                  <Row>
                    <Col>
                      <p>
                        User indicated that they will not be keeping their
                        current vehicle. Normally they would fail our
                        recommendation but since we are unable to accurately
                        determine their vehicle payment this requires manual
                        review to determine if the user's current vehicle
                        payment is gone can they afford an AVVA membership.
                      </p>
                      <p>
                        <b>
                          Use the guidelines below to help determine if the user
                          is eligible or not
                        </b>
                      </p>
                      <ul>
                        <li>
                          Determine if the user accurately reported their
                          vehicle payment of{" "}
                          {this.renderCents(
                            this.props.user.monthly_car_payment
                          )}
                        </li>
                        <li>
                          Identify any other possible monthly deductions (Such
                          as insurance)
                        </li>
                        <li>
                          Use the graph and account transactions below to
                          determine if the user should be financial eligible
                        </li>
                      </ul>
                    </Col>
                  </Row>
                )}
                {appFinances &&
                  appFinances.fincalc_json &&
                  appFinances.fincalc_json.config && (
                    <>
                      <FinancialResultGraph
                        {...appFinances.fincalc_json}
                        vehiclePayment={this.props.vehicle.monthly_payment}
                        currentVehiclePayment={
                          !this.props.user.is_keeping_current_vehicle &&
                          this.props.user.monthly_car_payment
                        }
                      />

                      <div className="mt-3" style={{ fontSize: 12 }}>
                        The thresholds for a pass for this application are{" "}
                        <b>
                          Draftable Days {">="}{" "}
                          {appFinances.fincalc_json.config.approvalDays} days
                        </b>
                        <span className="mx-1">and</span>
                        <b>
                          Total Days {">="}{" "}
                          {appFinances.fincalc_json.config.days} days
                        </b>
                      </div>
                    </>
                  )}
                {this.props.app.accounts.length ? (
                  this.props.app.accounts.map((a, index) => (
                    <SummaryAccount
                      account={a}
                      app={this.props.app}
                      appFinances={appFinances}
                      wasUsedToDetermine={false}
                    />
                  ))
                ) : (
                  <h2>No Accounts found for application</h2>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* <SummaryFinancialReport
          report={this.props.report}
          id={this.props.app.id}
        /> */}
      </>
    );
  }
}

export { Summary };
