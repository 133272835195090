import React from "react";
import { Table } from "reactstrap";

const Notifications = ({ notifications }) => (
  <div>
    <h3>Notifications</h3>
    {notifications && notifications.length !== 0 ? (
      <Table className="table table-striped table-bordered table-hover mb-4">
        <thead>
          <tr>
            <th className="sort-alpha">Title</th>
            <th className="sort-alpha">Body</th>
            <th className="sort-alpha">Sent At</th>
            <th className="sort-alpha">User Dismissed At</th>
            <th className="sort-alpha">Visible in App?</th>
          </tr>
        </thead>
        <tbody>
          {notifications.map((notification, index) => (
            <tr key={`user_notifications_${index}`}>
              <td>{notification.notification.title}</td>
              <td>{notification.notification.body}</td>
              <td>
                {notification.created_at
                  ? new Date(notification.created_at + "Z").toLocaleString()
                  : "N/A"}
              </td>
              <td>
                {notification.dismissed_at
                  ? new Date(notification.dismissed_at + "Z").toLocaleString()
                  : "N/A"}
              </td>
              <td>{!notification.dismissed_at ? "✅" : "🚫"}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    ) : (
      <p>No notifications</p>
    )}
  </div>
);

export default Notifications;
