import { getAuthenticatedAxios } from "../../helpers";
import api from "../../apiEndPoint";
import { encrypt, getAndDecryptDataFromResponse } from ".";

export const TransactionsApiEndpoints = {
  Correct: (id, corrected_type) =>
    getAuthenticatedAxios(true)
      .patch(`${api["@driveavva"].core.Transactions}/${id}/correct`, {
        corrected_type,
      })
      .then(getAndDecryptDataFromResponse),
  BulkUpdate: (payload) => {
    return getAuthenticatedAxios(true)
      .patch(`${api["@driveavva"].core.Transactions}/bulk/correct`, {
        ...payload,
      })
      .then(getAndDecryptDataFromResponse);
  },
};
