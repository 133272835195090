import { getAuthenticatedAxios } from "../../helpers";
import api from "../../apiEndPoint";
// import { encrypt } from ".";
// import { getAndDecryptDataFromResponse } from "../../../Api/@driveavva/core";

export const RewardApiEndpoints = {
  RewardList: () =>
    getAuthenticatedAxios(true, true)
      .get(`${api["@driveavva"].new.Rewards}`)
      .then((r) => r.data),
  RewardApprove: (RewardId) =>
    getAuthenticatedAxios(true, true)
      .post(`${api["@driveavva"].new.Rewards}/${RewardId}/approve`)
      .then(async (r) => {
        return r.data;
      }),
  RewardModify: (type, data) =>
    getAuthenticatedAxios(true, true)
      .post(`${api["@driveavva"].new.Rewards}/${type}`, data)
      .then(async (r) => {
        return r.data;
      }),
};
