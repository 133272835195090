import { getDataFromResponse, getAuthenticatedAxios } from "./helpers";
import { AddOrUpdate, Remove } from "./@driveavva/core/car.api";
import api from "./apiEndPoint";

export const CarApiEndpoints = {
  CarsList: (data, page, currentPage, pageSize) => {
    let url = new URL(api.Cars);
    // Apply filters
    if (data.make) {
      url.searchParams.append("Make", data.make);
    }
    if (data.model) {
      url.searchParams.append("Model", data.model);
    }
    if (data.year) {
      url.searchParams.append("Year", data.year);
    }
    if (data.mileageFrom) {
      url.searchParams.append("mileageFrom", data.mileageFrom);
    }
    if (data.mileageTo) {
      url.searchParams.append("mileageTo", data.mileageTo);
    }
    if (data.bodyStyle) {
      url.searchParams.append("bodyStyle", data.bodyStyle);
    }
    if (data.monthlyPriceFrom) {
      url.searchParams.append("monthlyPriceFrom", data.monthlyPriceFrom);
    }
    if (data.monthlyPriceTo) {
      url.searchParams.append("monthlyPriceTo", data.monthlyPriceTo);
    }
    if (data.status) {
      url.searchParams.append("Status", data.status);
    }

    if (data.vin) {
      url.searchParams.append("VIN", data.vin);
    }

    if (data.hideFromSearch) {
      url.searchParams.append("ExcludeHidden", data.hideFromSearch);
    }

    if (data.id) {
      url.searchParams.append("Id", data.id);
    }
    // Apply pagination
    url.searchParams.append("Page", page || currentPage);
    url.searchParams.append("PageSize", pageSize);
    return getAuthenticatedAxios()
      .get(url)
      .then(getDataFromResponse);
  },
  ImportCars: (data, overwrite) =>
    getAuthenticatedAxios()
      .post(`${api.Cars}/import?overwrite=${overwrite}`, data)
      .then(getDataFromResponse),

  CarDetails: (CarId) =>
    getAuthenticatedAxios()
      .get(`${api.Cars}/${CarId}`)
      .then(getDataFromResponse),

  UpdateCar: async (CarId, c) => {
    console.log("UpdateCar", c);
    const [res] = await Promise.all([
      getAuthenticatedAxios().put(`${api.Cars}/${CarId}`, c),
      AddOrUpdate(CarId, c),
    ]);
    console.log(res);
    return res;
  },

  CarImages: (CarId) =>
    getAuthenticatedAxios()
      .get(`${api.carPhotos}?CarId=${CarId}`)
      .then(getDataFromResponse),

  DeleteCarImage: (CarId) =>
    getAuthenticatedAxios()
      .delete(`${api.carPhotos}/${CarId}`)
      .then(getDataFromResponse),

  AddCarImage: (data) =>
    getAuthenticatedAxios()
      .post(`${api.carPhotos}`, data)
      .then(getDataFromResponse),

  DefaultPhoto: (photoId, carId) =>
    getAuthenticatedAxios()
      .put(`${api.Cars}/${carId}/DefaultPhoto`, { photoId })
      .then((d) => {
        const r = getDataFromResponse(d);
        console.log(r);
        return r;
      }),

  CarApplicationHistory: (CarId) =>
    getAuthenticatedAxios()
      .get(`${api.Cars}/${CarId}/history`)
      .then(getDataFromResponse),

  AddCarGPS: (data) =>
    getAuthenticatedAxios()
      .post(`${api.deviceSerial}/install-device`, data)
      .then(getDataFromResponse),

  CarGPSList: (CarId) =>
    getAuthenticatedAxios()
      .get(`${api.deviceSerial}/devices?CarId=${CarId}`)
      .then(getDataFromResponse),

  CarGPSDelete: (DeviceHistoryId) =>
    getAuthenticatedAxios()
      .put(`${api.deviceSerial}/${DeviceHistoryId}/uninstall-device`)
      .then(getDataFromResponse),

  CarReviews: (CarId) =>
    getAuthenticatedAxios()
      .get(`${api.carReview}?CarId=${CarId}`)
      .then(getDataFromResponse),

  CarReviewDetails: (CarId) =>
    getAuthenticatedAxios()
      .get(`${api.carReview}/${CarId}`)
      .then(getDataFromResponse),

  AddCarReview: (data) =>
    getAuthenticatedAxios()
      .post(`${api.carReview}`, data)
      .then(getDataFromResponse),

  DeleteCarReview: (CarId) =>
    getAuthenticatedAxios()
      .delete(`${api.carReview}/${CarId}`)
      .then(getDataFromResponse),

  UpdateCarReview: (CarId, data) =>
    getAuthenticatedAxios()
      .put(`${api.carReview}/${CarId}`, data)
      .then(getDataFromResponse),

  ImportEquipmentsTechnicalSpecs: (data) =>
    getAuthenticatedAxios()
      .post(`${api.Cars}/import-equipments-technicalSpecs`, data)
      .then(getDataFromResponse),

  DeleteCar: async (CarId) => {
    const res = await getAuthenticatedAxios()
      .delete(`${api.Cars}/${CarId}`)
      .then(getDataFromResponse);
    console.log(res);
    await Remove(CarId);
    return res;
  },

  ToggleSearchVisibilty: async (CarId, is_public) => {
    const [r] = await Promise.all([
      getAuthenticatedAxios()
        .put(`${api.Cars}/${CarId}/ToggleSearchVisibility`)
        .then(getDataFromResponse),
      AddOrUpdate(CarId, { is_public }, false),
    ]);
    return r;
  },

  LockOrUnlock: (CarId) =>
    getAuthenticatedAxios()
      .put(`${api.Cars}/${CarId}/LockOrUnlock`)
      .then(getDataFromResponse),

  Attachments: async (CarId, Type) =>
    getAuthenticatedAxios()
      .get(`${api.Cars}/${CarId}/attachments?type=${Type}`)
      .then(getDataFromResponse),

  UploadAttachment: (CarId, data) =>
    getAuthenticatedAxios()
      .post(`${api.Cars}/${CarId}/attachments`, data)
      .then(getDataFromResponse),

  GenerateRoadsideCard: (CarId, data) =>
    getAuthenticatedAxios()
      .post(`${api.Cars}/${CarId}/attachments/roadside`, data)
      .then(getDataFromResponse),

  DeleteAttachment: (CarId, AttachmentId) =>
    getAuthenticatedAxios()
      .delete(`${api.Cars}/${CarId}/attachments/${AttachmentId}`)
      .then(getDataFromResponse),
  ResetTiers: () =>
    getAuthenticatedAxios()
      .get(`${api.Cars}/ResetTiers`)
      .then(getDataFromResponse),
  UpdateAttachment: async (CarId, AttachmentId, data) => {
    const [res] = await Promise.all([
      getAuthenticatedAxios()
        .put(`${api.Cars}/${CarId}/attachments/${AttachmentId}`, data)
        .then(getDataFromResponse),
      data.get("Order") === "1" && data.get("Type") === "Photo"
        ? AddOrUpdate(CarId, { photo_url: data.get("Url") }, false)
        : null,
    ]);
    return res;
  },

  ToggleField: async (CarId, Field, value, vin = null) => {
    const [r] = await Promise.all([
      getAuthenticatedAxios()
        .put(`${api.Cars}/${CarId}/Toggle${Field}`)
        .then(getDataFromResponse),
      ["Delivered", "HideFromSearch"].includes(Field)
        ? AddOrUpdate(
            CarId,
            {
              [Field === "Delivered" ? "status" : "is_public"]:
                Field === "Delivered"
                  ? value
                    ? "delivered"
                    : vin
                    ? "available"
                    : "comingsoon"
                  : !value,
            },
            false
          )
        : null,
    ]);
    console.log(r);
    return r;
  },
  UpdateOrder: (CarId, Order) =>
    getAuthenticatedAxios()
      .put(`${api.Cars}/${CarId}/order?order=${Order}`)
      .then(getDataFromResponse),
};
