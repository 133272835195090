import axios from "axios";

const tokenKey = "token";

axios.defaults.headers.common["token"] = getJwt();

function getJwt() {
  return localStorage.getItem(tokenKey);
}

function setJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

function deleteJwt() {
  localStorage.removeItem(tokenKey);
}
function setRereshToken(refreshToken) {
  localStorage.setItem("refToken", refreshToken);
}
axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;
  if (!expectedError) {
    console.log("Logging the error", error);
  }
  return Promise.reject(error);
});

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  getJwt,
  setJwt,
  deleteJwt,
  setRereshToken,
};
