import React from "react";
import { Route, Redirect } from "react-router-dom";
import httpServices from "../Api/httpServices";
function getCurrentUser() {
    try {
        const jwt = httpServices.getJwt();
        return jwt
    } catch (ex) {
        return null;
    }
}

const ProtectedRoute = ({ path, component: Component, render, ...rest }) => {
    return (
        <React.Fragment>
            <Route
                {...rest}
                render={props => {
                    if (!getCurrentUser())
                        return (
                            <Redirect
                                to={{
                                    pathname: "/auth/login",
                                    state: { from: props.location }
                                }}
                            />
                        );
                    return Component ? <Component {...props} /> : render(props);
                }}
            />
        </React.Fragment>
    );
};
export { ProtectedRoute };
