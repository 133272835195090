import React from "react";
import ContentWrapper from "../Layout/ContentWrapper";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import getServices from "../../Api/getServices";
import SharedComponent from "../manages/Shared";
import Joi from "joi-browser";

class Dashboard extends SharedComponent {
  state = {
    dropdownOpen: false,
    isLoading: true,
    items: [],
    itemsGPS: [],
    requestOptions: {
      currentPage: 1,
      totalPages: 1,
      totalItems: 0,
      pageSize: 20,
    },
    data: {
      ApplicationId: "",
    },
    applications: [],
  };
  schema = {
    ApplicationId: Joi.label("Application Number"),
  };

  async componentDidMount() {
    try {
      const response = await getServices.getLastNewApplicationDashboard();
      this.queryItems(1);
      this.setState({ items: response.data.items, isLoading: false });
    } catch (error) {
      console.log(error);
    }
  }
  renderGPSTable() {
    const { itemsGPS } = this.state;
    if (itemsGPS.length !== 0) {
      return (
        <Col md={12}>
          <table className="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th className="sort-alpha">Longitude</th>
                <th className="sort-alpha">Latitude</th>
                <th className="sort-alpha">Direction</th>
                <th className="sort-alpha">Created</th>
                <th className="sort-alpha">Car Make</th>
                <th className="sort-alpha">Car Model</th>
                <th className="sort-alpha">Client Name</th>
                <th className="sort-alpha">Client Phone</th>
                <th className="sort-alpha">Control</th>
              </tr>
            </thead>
            <tbody>
              {itemsGPS.map((item) => (
                <tr key={item.applicationId}>
                  <td>{item.long}</td>
                  <td>{item.lat}</td>
                  <td>{item.direction}</td>
                  <td>{item.created}</td>
                  <td>{item.carMake}</td>
                  <td>{item.carModel}</td>
                  <td>{item.clientFirstName}</td>
                  <td>{item.clientPhone}</td>
                  <td className="text-center">
                    <div>
                      <Link
                        to={`/applications/update/${item.applicationId}`}
                        color="warning"
                        className="btn btn-warning d-inline-flex align-items-center"
                      >
                        Details
                      </Link>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      );
    } else {
      return <Col md={12}>{this.renderSadFace(false)}</Col>;
    }
  }
  renderTable() {
    const { items } = this.state;
    if (items.length !== 0) {
      return (
        <Col md={12}>
          <table className="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th className="sort-alpha">Name</th>
                <th className="sort-alpha">Car</th>
                <th className="sort-alpha">Loan #</th>
                <th className="sort-alpha">DL Request Code</th>
                <th className="sort-alpha">GPS #</th>
                <th className="sort-alpha">Last Updated</th>
                <th className="sort-alpha">Actions</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item) => (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>{item.year} {item.make} {item.model}{item.series ? ` ${item.series}` : ''}</td>
                  <td>{item.loanNumber}</td>
                  <td><a href={`https://www.decisionlogic.com/ReportDetails.aspx?return=Reports&requestCode=${item.requestCode}`} target="_blank" rel="noopener noreferrer">{item.requestCode}</a></td>
                  <td>{item.deviceSerial ? item.deviceSerial : 'No Device Linked'}</td>
                  <td>{new Date(item.updatedAt).toLocaleString()}</td>
                  <td className="text-center">
                    <div>
                      <Link
                        to={`/applications/update/${item.id}`}
                        color="primary"
                        className="btn btn-primary d-inline-flex align-items-center mr-1"
                      >
                        Details
                                    </Link>
                      <Link to={`/inventories/update/${item.carId}`} className="btn btn-muted d-inline-flex align-items-center">
                        View Car
                                    </Link>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Col>
      );
    } else {
      return this.renderSadFace(false);
    }
  }
  render() {
    const { isLoading, items } = this.state;
    return (
      <ContentWrapper>
        <div className="content-heading d-flex justify-content-between">
          <div>
            Welcome Back
            <div className="mt-1"></div>
          </div>
        </div>
        <Card className="card-default">
          <div className="mt-4 ml-3 mr-3 d-flex justify-content-between">
            <div>New Applications</div>
            <Link to="/applications" className="btn btn-info">
              View all Applications
            </Link>
          </div>
          <CardBody>
            {items.length !== 0 ? "" : ""}
            {!isLoading ? <Row>{this.renderTable()}</Row> : this.renderLoader()}
          </CardBody>
        </Card>
        <div className="mt-5">
          <h4>GPS Notification list</h4>
        </div>
        <Card className="card-default">
          <CardBody>
            {!isLoading ? (
              <Row>{this.renderGPSTable()}</Row>
            ) : (
              this.renderLoader()
            )}
          </CardBody>
        </Card>
      </ContentWrapper>
    );
  }

  doSubmit = () => {
    this.queryItems(1);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.doSubmit();
  };
  queryItems = async (page) => {
    this.setState({
      isLoading: true,
    });
    const { pageSize, currentPage } = this.state.requestOptions;
    try {
      const response = await getServices.getLatestGpsNotifications(
        page,
        currentPage,
        pageSize
      );
      let itemsGPS = response.data.items;
      this.setState({
        isLoading: false,
        itemsGPS,
        requestOptions: {
          currentPage: response.data.currentPage,
          totalPages: response.data.totalPages,
          totalItems: response.data.totalItems,
          pageSize: response.data.pageSize,
        },
      });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };
}

export { Dashboard };
