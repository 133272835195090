import { getDataFromResponse, getAuthenticatedAxios } from "./helpers";
import api from "./apiEndPoint";

export const ApplicationApiEndpoints = {
  ApplicationsList: (
    Id,
    Status,
    page,
    currentPage,
    pageSize,
    ClientName,
    GPSSerial
  ) => {
    let url = new URL(api.applications);
    if (Id) {
      url.searchParams.append("Id", Id);
    }
    if (Status) {
      url.searchParams.append("Status", Status);
    }
    if (ClientName) {
      url.searchParams.append("ClientName", ClientName);
    }
    if (GPSSerial) {
      url.searchParams.append("GPSSerial", GPSSerial);
    }
    // Apply pagination
    url.searchParams.append("Page", page || currentPage);
    url.searchParams.append("PageSize", pageSize);
    return getAuthenticatedAxios()
      .get(url)
      .then(getDataFromResponse);
  },

  ApplicationDetails: (ApplicationId) =>
    getAuthenticatedAxios()
      .get(`${api.applications}/${ApplicationId}`)
      .then(getDataFromResponse),

  ApplicationStatistics: (Status) =>
    getAuthenticatedAxios()
      .get(`${api.applications}/statistics?Status=${Status}`)
      .then(getDataFromResponse),

  ApplicationAttachmentList: (
    type,
    ApplicationId,
    page,
    currentPage,
    pageSize
  ) => {
    let url = new URL(api.attachments);
    if (type) {
      url.searchParams.append("Type", type);
    }
    url.searchParams.append("Page", page || currentPage);
    url.searchParams.append("PageSize", pageSize);
    url.searchParams.append("ApplicationId", ApplicationId);
    return getAuthenticatedAxios()
      .get(url)
      .then(getDataFromResponse);
  },

  AddApplicationAttachment: (data) =>
    getAuthenticatedAxios()
      .post(`${api.attachments}`, data)
      .then(getDataFromResponse),

  ApplicationPaymentList: (ApplicationId) =>
    getAuthenticatedAxios()
      .get(`${api.applications}/${ApplicationId}/payments`)
      .then(getDataFromResponse),

  ApplicationCarLocation: (ApplicationId) =>
    getAuthenticatedAxios()
      .get(`${api.applicationHistory}/${ApplicationId}/car-location-history`)
      .then(getDataFromResponse),

  ApplicationPhoneLocation: (ApplicationId) =>
    getAuthenticatedAxios()
      .get(`${api.currnetPhoneLocation}/${ApplicationId}`)
      .then(getDataFromResponse),

  ApplicationCurrentCarLocation: (ApplicationId) =>
    getAuthenticatedAxios()
      .get(`${api.applicationHistory}/${ApplicationId}/locate-car`)
      .then(getDataFromResponse),

  ApplicationGpsNotifications: (ApplicationId) => {
    let url = new URL(`${api.phoneLocation}`);
    if (ApplicationId) url.searchParams.append("ApplicationId", ApplicationId);
    return getAuthenticatedAxios()
      .get(url)
      .then(getDataFromResponse);
  },

  ApplicationActionList: (ApplicationId) =>
    getAuthenticatedAxios()
      .get(`${api.applicationAction}?ApplicationId=${ApplicationId}`)
      .then(getDataFromResponse),

  AddApplicationAction: (data) =>
    getAuthenticatedAxios()
      .post(api.applicationAction, data)
      .then(getDataFromResponse),

  ApplicationNoteList: (ApplicationId) =>
    getAuthenticatedAxios()
      .get(`${api.applicationNotes}?ApplicationId=${ApplicationId}`)
      .then(getDataFromResponse),

  AddApplicationNote: (data) =>
    getAuthenticatedAxios()
      .post(api.applicationNotes, data)
      .then(getDataFromResponse),

  ApplicationSendDeliveryRequest: (ApplicationId) =>
    getAuthenticatedAxios()
      .post(api.carDeliveryRequests, { applicationId: ApplicationId })
      .then(getDataFromResponse),

  ApplicationInsuranceDetails: (ApplicationId) =>
    getAuthenticatedAxios()
      .get(
        `${api.applications}/${api.insurance}?ApplicationId=${ApplicationId}`
      )
      .then(getDataFromResponse),

  ApplicationUpdateInsuranceDetails: (ApplicationId, data) =>
    getAuthenticatedAxios()
      .put(
        `${api.applications}/${ApplicationId}/application-insurance-details`,
        data
      )
      .then(getDataFromResponse),

  ApplicationSendOTP: (ApplicationId, PhoneNumber) => {
    let url = new URL(`${api.applications}/send-otp`);
    url.searchParams.append("ApplicationId", ApplicationId);
    url.searchParams.append("PhoneNumber", PhoneNumber);

    return getAuthenticatedAxios()
      .put(url, null)
      .then(getDataFromResponse);
  },

  ApplicationEnableOrDisableCar: (id, status) =>
    getAuthenticatedAxios()
      .put(`${api.applicationHistory}/${id}/enable-disable`, {
        enabled: status,
      })
      .then(getDataFromResponse),

  ApplicationSendContract: (ApplicationId) =>
    getAuthenticatedAxios()
      .put(`${api.contract}/${ApplicationId}`, null)
      .then(getDataFromResponse),

  ApplicationGetContract: (ApplicationId) =>
    getAuthenticatedAxios()
      .get(`${api.contract}/${ApplicationId}`)
      .then(getDataFromResponse),
};
