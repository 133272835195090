import React from "react";
import { TabPane, Row, Col, ListGroup, ListGroupItem, Badge } from "reactstrap";
import { withRouter } from "react-router-dom";
import Moment from "react-moment";
import SharedComponent from "../Shared";
import { Api } from "../../../Api/index";
class PaymentsHistory extends SharedComponent {
  state = {
    items: [],
    loading: true,
  };
  componentDidMount() {
    let applicationId = this.props.id;
    Api.ApplicationManagement.ApplicationPaymentList(applicationId)
      .then((items) => {
        this.setState({ loading: false, items });
      })
      .catch((error) => {
        // this.renderErrorAlert("Payment History", error.response.data.Message);
        console.log(error);
        this.setState({ loading: false });
      });
  }
  render() {
    const { items } = this.state;
    return (
      <TabPane tabId="PaymentHistory">
        <Row>
          <Col xs={12} className="mt-3">
            <h3 className="mb-0">Payment History</h3>
            <hr className="w-25 float-left" />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {this.state.loading ? undefined :
            items.length !== 0 ? (
              <ListGroup className="mb-3 w-50">
                {items.map((item) => (
                  <ListGroupItem key={`phistory-${item.id}`} className="d-flex justify-content-between">
                    <div>
                      <h6 className="mb-1">#{item.reference}</h6>
                      <Badge color="danger">{item.status}</Badge>
                    </div>
                    <div>
                      <h6 className="mb-1">
                        <Moment format="MM/DD/YYYY">{item.paymentDate}</Moment>
                      </h6>
                      <h6 className="mb-1 text-danger float-right">
                        ${item.amount}
                      </h6>
                    </div>
                  </ListGroupItem>
                ))}
              </ListGroup>
            ) : (
              this.renderSadFace(false)
            )}
          </Col>
        </Row>
      </TabPane>
    );
  }
}

export default withRouter(PaymentsHistory);
