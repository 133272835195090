import React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Button, Col, Row } from "reactstrap";
import SharedComponent from "../../Shared";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class SecurePDFViewer extends SharedComponent {
  state = {
    page: 1,
    pageCount: 1,
    scale: 1.0,
    width: 0,
  };

  wrapperRef = React.createRef();

  nextPage = () => {
    if (this.state.page < this.state.pageCount) {
      this.setState((e) => ({ page: e.page + 1 }));
    }
  };

  prevPage = () => {
    if (this.state.page > 1) {
      this.setState((e) => ({ page: e.page - 1, pageLoading: true }));
    }
  };

  zoomIn = () => {
    this.setState((prevState) => ({ scale: prevState.scale + 0.25 }));
  }

  zoomOut = () => {
    this.setState((prevState) => ({ scale: prevState.scale - 0.25 }));
  }

  componentDidMount() {
    this.setState({ width: this.wrapperRef.current.getBoundingClientRect().width });
  }

  render() {
    console.log(this.state);
    return (
      <div className="d-flex flex-column modal-child" ref={this.wrapperRef}>
        <div className="pdf-wrapper">
          <div className="zoom-controls-wrapper">
            <Button
              onClick={this.zoomIn}
            >
              +
            </Button>
            <Button
              onClick={this.zoomOut}
              disabled={this.state.scale <= 1.0}
            >
              -
            </Button>
          </div>
          {
            this.state.pageCount > 1 
            ?
              (
                <Row className="pdf-page-nav">
                  <Col xs={12} className="d-flex justify-content-center mb-2">
                    <Button
                      onClick={this.prevPage}
                      disabled={this.state.page <= 1}
                      className="mr-2"
                    >
                      Previous Page
                    </Button>
                    <Button
                      onClick={this.nextPage}
                      disabled={this.state.page === this.state.pageCount}
                    >
                      Next Page
                    </Button>
                  </Col>
                </Row>
              )
            :
              null
          }
          <Row className="mb-4" style={{ flex: "1" }}>
            <Col xs={12}>
              <Document
                file={this.props.file}
                className="pdf-container"
                onLoadSuccess={({ numPages }) =>
                  this.setState({ pageCount: numPages })
                }
                onContextMenu={(e) => e.preventDefault()}
              >
                <Page pageNumber={this.state.page} loading={"Loading Page..."}  scale={this.state.scale} width={this.state.width} />
              </Document>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <p className="mr-4 text-center">
                Showing Page {this.state.page} of {this.state.pageCount}
              </p>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export { SecurePDFViewer };
