import React from "react";
import {
  Row,
  Col,
  TabPane,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Joi from "joi-browser";
import Pagination from "rc-pagination";
import { withRouter } from "react-router-dom";
import SharedComponent from "../Shared";
import { Api } from "../../../Api/index";
class Attachments extends SharedComponent {
  state = {
    dropdownOpen: false,
    inputVal: this.props.inputValue,
    isLoading: true,
    data: {
      type: "",
    },
    options: [
      { id: 1, label: "General", value: "General" },
      { id: 2, label: "Bank", value: "Bank" },
    ],
    items: [],
    requestOptions: {
      currentPage: 1,
      totalPages: 1,
      totalItems: 0,
      pageSize: 20,
    },
    modal: false,
  };
  schema = {
    type: Joi.label("type"),
    Types: Joi.label("Types"),
  };

  async componentDidMount() {
    this.queryItems();
  }

  handleSelectFile = (e) => {
    this.setState({
      iconUrl: URL.createObjectURL(e.target.files[0]),
      iconUrlSend: e.target.files[0],
    });
  };
  AddImageModel = () => {
    this.setState({ modal: !this.state.modal });
  };

  render() {
    const { isLoading, items, data, errors, options } = this.state;
    const { currentPage, pageSize, totalItems } = this.state.requestOptions;
    return (
      <TabPane tabId="Attachments">
        <Form className="mb-3 mt-3" onSubmit={this.handleSubmit}>
          <Row>
            <Col md={4}>
              <FormGroup>
                <label>Type</label>
                <Input
                  type="select"
                  name="type"
                  id="type"
                  value={data.type}
                  onChange={this.handleChange}
                >
                  <option>Please select</option>
                  {options.map((option) => (
                    <option key={option.id} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Input>
                {errors && (
                  <span className="text-danger small">{errors.status}</span>
                )}
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <div
                  style={{ height: 30 }}
                  className="d-none d-md-block d-lg-block"
                ></div>
                <Button type="submit" color={"info"} className="w-100">
                  Search
                </Button>
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <div
                  style={{ height: 30 }}
                  className="d-none d-md-block d-lg-block"
                ></div>
                <Button
                  color={"success"}
                  onClick={this.resetData}
                  className="w-100"
                >
                  Reset Data
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Form>

        <Row>
          <Col md={12} className="mt-3">
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="mb-0">Application Attachments</h3>
              <Button color={"info"} onClick={this.AddImageModel}>
                Add Attachment
              </Button>
            </div>
            <hr className="w-25 float-left" />
          </Col>
        </Row>

        {!isLoading ? (
          <>
            {items.length !== 0 ? (
              <>
                <Row>
                  <Col md={12}>
                    <Table className="table table-bordered table-striped table-hover mb-4">
                      <thead>
                        <tr>
                          <th className="sort-alpha">id</th>
                          <th className="sort-alpha">Type</th>
                          <th className="sort-alpha">File Name</th>
                          <th className="sort-alpha">Control</th>
                        </tr>
                      </thead>
                      <tbody>
                        {items.map((item) => (
                          <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.type}</td>
                            <td>{item.fileName}</td>
                            <td>
                              {/* <a
                                className="btn"
                                href={item.fullUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="fas fa-download" /> Download
                              </a> */}
                              <Button
                                className="btn"
                                href={item.url}
                                // onClick={() => this.setState({ viewedFile: item.url })}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="fas fa-eye" /> View
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Pagination
                      showSizeChanger
                      defaultPageSize={pageSize}
                      defaultCurrent={currentPage}
                      onChange={this.handlePageChange}
                      total={totalItems}
                      className="float-right"
                    />
                  </Col>
                </Row>
              </>
            ) : (
              this.renderSadFace(true)
            )}
          </>
        ) : (
          this.renderLoader()
        )}

        <Modal isOpen={this.state.viewedFile}>
          <ModalHeader>Secure File Viewer</ModalHeader>
          <ModalBody>
            <iframe title="View" src={this.state.viewedFile} />
          </ModalBody>
          <ModalFooter>
            <Button color="info" onClick={() => this.setState({viewedFile: null})}>
              Close
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.modal} toggle={this.AddImageModel}>
          <ModalHeader toggle={this.toggleModal}>Upload Attachment</ModalHeader>
          <ModalBody>
            <Form>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <label>Type</label>
                    <Input
                      type="select"
                      name="Types"
                      id="Types"
                      value={data.Types}
                      onChange={this.handleChange}
                    >
                      <option selected disabled>
                        Select type
                      </option>
                      {options.map((option) => (
                        <option key={option.id} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Input>
                    {errors && (
                      <span className="text-danger small">{errors.status}</span>
                    )}
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <Label>Attachment</Label>
                  <Input
                    type={"file"}
                    name={"image"}
                    onChange={this.handleSelectFile}
                  />
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="info" onClick={this.addNewAttatchment}>
              Add
            </Button>
            <Button color="secondary" onClick={this.AddImageModel}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </TabPane>
    );
  }

  queryItems = async (page) => {
    this.setState({
      isLoading: true,
    });

    let ApplicationId = this.props.id;
    const { pageSize, currentPage } = this.state.requestOptions;
    const { type } = this.state.data;
    Api.ApplicationManagement.ApplicationAttachmentList(
      type,
      ApplicationId,
      page,
      currentPage,
      pageSize
    )
      .then((response) => {
        let items = response.items;
        this.setState({
          isLoading: false,
          items,
          requestOptions: {
            currentPage: response.currentPage,
            totalPages: response.totalPages,
            totalItems: response.totalItems,
            pageSize: response.pageSize,
          },
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };
  doSubmit = () => {
    this.queryItems(1);
  };
  resetData = () => {
    const requestOptions = this.state.requestOptions;
    const data = this.state.data;
    requestOptions.currentPage = 1;
    requestOptions.totalPages = 1;
    requestOptions.totalItems = 0;
    requestOptions.pageSize = 20;
    data.type = "";
    this.setState({ requestOptions, data });
    this.queryItems();
  };

  addNewAttatchment = () => {
    const { Types } = this.state.data;
    let applicationId = this.props.id;
    let formData = new FormData();
    formData.append("ApplicationId", applicationId);
    formData.append("Type", Types);
    formData.append("File", this.state.iconUrlSend);
    Api.ApplicationManagement.AddApplicationAttachment(formData)
      .then(() => {
        this.queryItems(1);
        this.AddImageModel();
      })
      .catch(console.log);
  };
}

export default withRouter(Attachments);
