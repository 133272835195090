import { getAuthenticatedAxios } from "../../helpers";
import api from "../../apiEndPoint";
// import { encrypt } from ".";
// import { getAndDecryptDataFromResponse } from "../../../Api/@driveavva/core";

export const PaymentApiEndpoints = {
  Send: (type, user, override) =>
    getAuthenticatedAxios(true, true)
      .post(`${api["@driveavva"].new.Payments}/${type}/send`, {
        user,
        override,
      })
      .then((r) => r.data),
};
