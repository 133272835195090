import { getAuthenticatedAxios } from "../../helpers";
import api from "../../apiEndPoint";
// import { encrypt } from ".";
// import { getAndDecryptDataFromResponse } from "../../../Api/@driveavva/core";

export const VehicleApiEndpoints = {
  VehicleDetail: (unit_id) =>
    getAuthenticatedAxios(true, true)
      .get(`${api["@driveavva"].new.Vehicles}/${unit_id}`)
      .then((r) => r.data),
  CreateVehicle: (payload) =>
    getAuthenticatedAxios(true, true)
      .post(`${api["@driveavva"].new.Vehicles}`, payload)
      .then((r) => r.data),
  Update: (unit_id, data) =>
    getAuthenticatedAxios(true, true)
      .post(`${api["@driveavva"].new.Vehicles}/${unit_id}`, data)
      .then(async (r) => {
        return r.data;
      }),
  SyncBestPass: () =>
    getAuthenticatedAxios(true, true)
      .post(`${api["@driveavva"].new.Vehicles}/sync-bestpass-ids`)
      .then(async (r) => {
        return r.data;
      }),
};
