import React from "react";
import {
  Row,
  Col,
  TabPane,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Joi from "joi-browser";
import Pagination from "rc-pagination";
import getServices from "../../../Api/getServices";
import SharedComponent from "../Shared";
import Moment from "react-moment";
import { Api } from "../../../Api/index";
class Support extends SharedComponent {
  state = {
    dropdownOpen: false,
    activeTab: "ScheduledM",
    inputVal: this.props.inputValue,
    isLoading: true,
    data: {
      ApplicationNumber: "",
      CreatedAt: "",
      TypeId: "",
      comment: "",
    },
    items: [],
    requestOptions: {
      currentPage: 1,
      totalPages: 1,
      totalItems: 0,
      pageSize: 20,
    },
    viewModel: false,
    types: [],
    status: [{ name: "New" }, { name: "Closed" }],
  };

  schema = {
    ApplicationNumber: Joi.label("Application Number"),
    TypeId: Joi.label("Type ID"),
    CreatedAt: Joi.label("CreatedAt"),
    typeId: Joi.label("type id"),
  };
  componentDidMount() {
    this.queryItems();
    this.LookUpType();
  }
  LookUpType = async () => {
    try {
      const response = await getServices.getLookUpTypeSupport();
      this.setState({ types: response.data });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { isLoading, items, data, types, status } = this.state;
    const { currentPage, pageSize, totalItems } = this.state.requestOptions;
    return (
      <TabPane tabId="Support">
        <Form className="mb-3 mt-3" onSubmit={this.handleSubmit}>
          <Row>
            <Col md={2}>
              <FormGroup>
                <Label for="ApplicationNumber">APPLICATION NUMBER</Label>
                <Input
                  type="text"
                  name="ApplicationNumber"
                  id="ApplicationNumber"
                  onChange={this.handleChange}
                  value={data.ApplicationNumber}
                />
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label for="CreatedAt">Date</Label>
                <Input
                  type="date"
                  name="CreatedAt"
                  id="CreatedAt"
                  onChange={this.handleChange}
                  value={data.CreatedAt}
                />
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label for="TypeId">Type</Label>
                <Input
                  type="select"
                  name="TypeId"
                  id="TypeId"
                  value={data.TypeId}
                  onChange={this.handleChange}
                >
                  <option selected>Select Type</option>
                  {types.map((option) => (
                    <option key={option.name} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label for="Status">Status</Label>
                <Input
                  type="select"
                  name="Status"
                  id="Status"
                  value={data.Status}
                  onChange={this.handleChange}
                >
                  <option selected>Select Status</option>
                  {status.map((option) => (
                    <option key={option.name} value={option.name}>
                      {option.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <div
                  style={{ height: 30 }}
                  className="d-none d-md-block d-lg-block"
                ></div>
                <Button type="submit" color={"info"} className="w-100">
                  Search
                </Button>
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <div
                  style={{ height: 30 }}
                  className="d-none d-md-block d-lg-block"
                ></div>
                <Button
                  color={"success"}
                  onClick={this.resetData}
                  className="w-100"
                >
                  Reset Data
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Form>

        {!isLoading ? (
          <React.Fragment>
            {items.length !== 0 ? (
              <React.Fragment>
                <Row>
                  <Col md={12}>
                    <Table className="table table-bordered table-striped table-hover mb-4">
                      <thead>
                        <tr>
                          <th className="sort-alpha">Application Number</th>
                          <th className="sort-alpha">Client Name</th>
                          <th className="sort-alpha">Client Phone</th>
                          <th className="sort-alpha">Car Make</th>
                          <th className="sort-alpha">Car Model</th>
                          <th className="sort-alpha">Comment</th>
                          <th className="sort-alpha">Type</th>
                          <th className="sort-alpha">Status</th>
                          <th className="sort-alpha">Date</th>
                          <th className="sort-alpha">Control</th>
                        </tr>
                      </thead>
                      <tbody>
                        {items.map((item) => (
                          <tr key={item.id}>
                            <td>{item.applicationNumber}</td>
                            <td>{item.clientName}</td>
                            <td>{item.clientPhone}</td>
                            <td>{item.carMake}</td>
                            <td>{item.carModel}</td>
                            <td>{item.comment}</td>
                            <td>{item.type}</td>
                            <td>
                              <div className="badge badge-success ">
                                {item.status}
                              </div>
                            </td>
                            <td>
                              <Moment format="MM/DD/YYYY">
                                {item.createdAt}
                              </Moment>
                            </td>
                            <td className="text-center">
                              <div>
                                <Button
                                  onClick={() => this.openModel(item.id)}
                                  color="warning"
                                  className="btn btn-warning d-inline-flex align-items-center"
                                >
                                  Details
                                </Button>
                                <Button
                                  onClick={() =>
                                    this.handleClose(item.id, "close")
                                  }
                                  color="danger"
                                  className="btn  d-inline-flex align-items-center mx-1"
                                >
                                  Close
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Pagination
                      showSizeChanger
                      defaultPageSize={pageSize}
                      defaultCurrent={currentPage}
                      onChange={this.handlePageChange}
                      total={totalItems}
                      className="float-right text-white"
                    />
                  </Col>
                </Row>
              </React.Fragment>
            ) : (
              this.renderSadFace(true)
            )}
          </React.Fragment>
        ) : (
          this.renderLoader()
        )}

        <Modal isOpen={this.state.viewModel} toggle={this.closeViewModel}>
          <ModalHeader toggle={this.toggleModal}>Support</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="TypeId">Type</Label>
                <Input
                  type="select"
                  name="typeId"
                  id="typeId"
                  value={data.typeId}
                  // onChange={this.handleChange}
                >
                  {types.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      selected={data.typeId}
                    >
                      {option.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label>Comment</Label>
                <Input
                  type="textarea"
                  name="comment"
                  // onChange={this.handleChange}
                  value={data.comment}
                  rows={5}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            {/* <Button color="warning">
              Update
            </Button> */}
            <Button color="secondary" onClick={this.closeViewModel}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </TabPane>
    );
  }

  openModel = (id) => {
    this.setState({ viewModel: true, id });
    Api.Notifications.Support.SupportDetails(id)
      .then((data) => this.setState({ data }))
      .catch((error) => {
        this.renderErrorAlert("Ops!", `${error.response.data.Message}`);
      });
  };

  doSubmit = () => {
    this.queryItems(1);
  };
  resetData = () => {
    const requestOptions = this.state.requestOptions;
    const data = this.state.data;
    requestOptions.currentPage = 1;
    requestOptions.totalPages = 1;
    requestOptions.totalItems = 0;
    requestOptions.pageSize = 20;
    data.ApplicationNumber = "";
    data.CreatedAt = "";
    data.TypeId = "";
    data.Status = "";
    this.setState({ requestOptions, data });
    this.queryItems();
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.doSubmit();
  };
  queryItems = (page) => {
    this.setState({
      isLoading: true,
    });
    const { pageSize, currentPage } = this.state.requestOptions;
    const { ApplicationNumber, CreatedAt, TypeId, Status } = this.state.data;
    Api.Notifications.Support.SupportList(
      ApplicationNumber,
      CreatedAt,
      page,
      currentPage,
      pageSize,
      TypeId,
      Status
    )
      .then((response) => {
        let items = response.items;
        this.setState({
          isLoading: false,
          items,
          requestOptions: {
            currentPage: response.currentPage,
            totalPages: response.totalPages,
            totalItems: response.totalItems,
            pageSize: response.pageSize,
          },
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  closeViewModel = () => {
    this.setState({ viewModel: false });
  };

  handleClose = (id, Case) => {
    Api.Notifications.Support.CloseSupport(id, Case)
      .then(() => {
        this.renderSuccessAlert("The request is close", "Click the button");
        this.queryItems(1);
      })
      .catch((error) => {
        this.renderErrorAlert("Ops!", `${error.response.data.Message}`);
      });
  };
}

export default Support;
