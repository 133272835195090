import React from "react";
import swal from "sweetalert";
import getServices from "../../Api/getServices";
import { Row, Col, Button } from "reactstrap";
import { Api } from "../../Api/index";

function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

export const NUMBER_FORMATER = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
class SharedComponent extends React.Component {
  state = {
    data: {},
  };
  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };

  renderCents(num, allowNegative) {
    return NUMBER_FORMATER.format(
      (num / 100) * (!allowNegative && num < 0 ? -1 : 1)
    );
  }

  renderDate(maybeDate, prefix = "", noTime = false) {
    if (!maybeDate) return "";
    const date =
      maybeDate instanceof Date
        ? maybeDate
        : new Date(maybeDate.endsWith("Z") ? maybeDate : maybeDate + "Z");
    if (isNaN(date)) return "Invalid date";
    const now = new Date();
    const thisYear = date.getFullYear() == now.getFullYear();
    const today =
      date.getDate() == now.getDate() &&
      date.getMonth() == now.getMonth() &&
      thisYear;
    return (
      prefix +
      ((today
        ? `Today`
        : `${date.getMonth() + 1}/${date.getDate()}${
            thisYear && !noTime ? "" : "/" + date.getFullYear()
          }`) +
        (!noTime ? ` @ ${formatAMPM(date)}` : ""))
    );
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.doSubmit();
  };
  renderSuccessAlert(title, text, onConfirm = null) {
    return swal({
      icon: "success",
      title,
      text,
      buttons: {
        confirm: "Confirm",
      },
    }).then(() => onConfirm && typeof onConfirm === "function" && onConfirm());
  }
  renderConfirmAlert(title, onConfirm = null, onClose = null) {
    return swal({
      icon: "warning",
      title,
      buttons: true,
    }).then(async (confirm) => {
      if (confirm && typeof onConfirm === "function") {
        swal(await onConfirm(), { icon: "success" });
      } else if (typeof onClose === "function") {
        await onClose();
      }
    });
  }
  renderErrorAlert(title, text) {
    return swal({
      icon: "error",
      title,
      text,
      buttons: {
        confirm: "Confirm",
      },
      dangerMode: true,
    });
  }

  renderOption() {
    return {
      paging: true, // Table pagination
      ordering: true, // Column ordering
      info: true, // Bottom left status text
      responsive: true,
      // Text translation options
      // Note the required keywords between underscores (e.g _MENU_)
      oLanguage: {
        sSearch: '<em className="fa fa-search"></em>',
        sLengthMenu: "_MENU_ records per page",
        info: "Showing page _PAGE_ of _PAGES_",
        zeroRecords: "Nothing found - sorry",
        infoEmpty: "No records available",
        infoFiltered: "(filtered from _MAX_ total records)",
        oPaginate: {
          sNext: '<em className="fa fa-caret-right"></em>',
          sPrevious: '<em className="fa fa-caret-left"></em>',
        },
      },
    };
  }

  renderLoader() {
    return (
      <small
        className="d-flex align-items-center justify-content-center"
        style={{ height: 450 }}
      >
        <sup>
          <em className="fa fa-cog fa-3x fa-spin text-primary"></em>
        </sup>
        <em className="fa fa-cog fa-6x fa-spin text-primary"></em>
        <em className="fa fa-cog fa-3x fa-spin text-primary"></em>
      </small>
    );
  }

  renderMakeAndStyleList = async () => {
    try {
      const Makes = await Api.LookUps.Lookups("makes", null);
      const Styles = await Api.LookUps.Lookups("styles", null);
      const Years = await Api.LookUps.Lookups("years", null);
      const Statistics = await getServices.getStatisticsCar();
      this.setState({
        makes: Makes,
        styles: Styles,
        years: Years,
        statistics: Statistics.data,
      });
    } catch (error) {
      this.renderErrorAlert("Ops!", "Something went wrong, please try again!");
    }
  };

  getModelLookups = (value) => {
    Api.LookUps.Lookups("models", value)
      .then((response) => {
        this.setState({ models: response });
      })
      .catch(console.log);
  };

  renderSadFace(propCheck) {
    return (
      <Row>
        <Col md={12}>
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ height: 450 }}
          >
            <img src="/img/sad.png" height="150" className="mb-3" alt="sad" />
            <h4 style={{ color: "#cfdbe2" }}>No results found</h4>
            {propCheck === true ? (
              <Button
                color="info"
                onClick={this.resetData}
                className="reset-btn"
              >
                Reset Data
              </Button>
            ) : null}
          </div>
        </Col>
      </Row>
    );
  }
  handlePageChange = async (page) => {
    const requestOptions = this.state.requestOptions;
    requestOptions.currentPage = page;
    this.setState({ requestOptions });
    this.queryItems();
  };
}

export default SharedComponent;
