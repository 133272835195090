import { getDataFromResponse, getAuthenticatedAxios } from "./helpers";
import api from "./apiEndPoint";

export const CarDeliverySlotsApiEndpoints = {
  CarDeliverySlotsList: (timeDate) => {
    let url = new URL(api.carDeliverySlots);
    // Apply pagination
    if (timeDate) {
      url.searchParams.append("Date", timeDate);
    }
    return getAuthenticatedAxios()
      .get(url)
      .then(getDataFromResponse);
  },
  ChangeSlotStatus: (SlotId) =>
    getAuthenticatedAxios()
      .put(`${api.carDeliverySlots}/${SlotId}/toggle-status`, null)
      .then(getDataFromResponse),

  AddNewSlot: (data) =>
    getAuthenticatedAxios()
      .post(`${api.carDeliverySlots}`, data)
      .then(getDataFromResponse),

  DeleteSlot: (SlotId) =>
    getAuthenticatedAxios()
      .delete(`${api.carDeliverySlots}/${SlotId}`)
      .then(getDataFromResponse),
};
