// import crypto from "@driveavva/crypto";
import { UserApiEndpoints } from "./users.api";
import { VehicleApiEndpoints } from "./vehicles.api";
import { RewardApiEndpoints } from "./rewards.api";
import { PaymentApiEndpoints } from "./payments.api";
export default {
  UserApiEndpoints,
  VehicleApiEndpoints,
  RewardApiEndpoints,
  PaymentApiEndpoints,
};
