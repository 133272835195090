import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
  Label,
  Button,
} from "reactstrap";

import SharedComponent from "../../Shared";
import { SvgIconsDropdown } from "../SvgIconsDropdown/SvgIconsDropdown";
import { Api } from "../../../../Api/index";

const REQUIRED_FIELDS = ["title"];

/**
 * Our shoe horn in to allow for easy pre-filling of notifications that contain unique fields
 */
const PREFILLED_NOTIFICATIONS = ["_tollsuccess", "_tollfail"];

class NotificationModal extends SharedComponent {
  state = {
    form: {},
    existingId: "",
    errors: {},
    submitting: false,
    loadingNotifications: false,
    loadedNotifications: false,
    selectedNotification: false,
    notifications: [],
  };

  getNotifications = () => {
    this.setState({ loadingNotifications: true });
    Api["@driveavva"].new.Users.Notifications()
      .then((res) => {
        this.setState({
          notifications: res,
          loadingNotifications: false,
          loadedNotifications: true,
        });
      })
      .catch(() => {
        this.setState({
          loadingNotifications: false,
          loadedNotifications: true,
        });
      });
  };

  onChange = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.currentTarget.name]: e.currentTarget.value,
      },
      errors: {
        ...this.state.errors,
        [e.currentTarget.name]: false,
      },
    });
  };

  error = (name) => this.state.errors[name] || false;

  validate = () => {
    if (this.state.existingId) return true;
    const e = {};
    REQUIRED_FIELDS.forEach((key) => {
      if (!this.state.form[key] || this.state.form[key] === "")
        e[key] = "Required";
    });
    const hasErrors = Object.keys(e).length !== 0;
    this.setState({ errors: hasErrors ? e : {} });

    return !hasErrors;
  };

  submit = () => {
    if (this.validate()) {
      this.setState({ submitting: true });
      console.info(`Submitting...`, this.state.form);
      Api["@driveavva"].new.Users.Send(
        "Notification",
        this.state.existingId &&
          !PREFILLED_NOTIFICATIONS.includes(this.state.existingId)
          ? this.state.existingId
          : this.state.form,
        this.props.user.id
      )
        .then(() => {
          this.renderSuccessAlert("New notification added successfully");
          this.setState({ submitting: false, data: {}, existingId: null });
          this.props.onClose();
        })
        .catch((e) => {
          this.setState({ submitting: false, data: {}, existingId: null });
          this.props.onClose();
          this.renderErrorAlert(
            "Couldn't fully deliver message",
            "Something went wrong sending the push notification. The user still will see the notification inside their app and have a message badge on their app icon. Please do not re-send and reach out for dev support instead"
          );
        });
    }
  };

  canSend = () =>
    this.props.user &&
    this.props.user.device &&
    this.props.user.device.expo_push_token;

  componentDidUpdate(prevProps, prevState) {
    if (
      !prevProps.isOpen &&
      this.props.isOpen &&
      !this.state.loadedNotifications
    ) {
      this.getNotifications();
    }
  }

  /**
   * Determines whether the form should be shown or not. We currrently only
   * hide it when notifications are preselected and are NOT our shoe-horned custom
   * notifications (such as the ones for tolls)
   */
  showForm = () => {
    if (!this.state.existingId || this.state.existingId === "") return true;
    if (PREFILLED_NOTIFICATIONS.includes(this.state.existingId)) return true;
    return false;
  };

  selectedNotification = () => {
    if (
      !this.state.existingId ||
      this.state.existingId === "" ||
      PREFILLED_NOTIFICATIONS.includes(this.state.existingId)
    )
      return false;
    return this.state.notifications.find(
      (n) => n.id === parseInt(this.state.existingId)
    );
  };

  onExistingNotificationChange = (e) => {
    const newState = {
      existingId: e.currentTarget.value,
      selectedNotification: null,
    };

    if (PREFILLED_NOTIFICATIONS.includes(e.currentTarget.value)) {
      switch (e.currentTarget.value) {
        case "_tollsuccess":
          newState.form = {
            title: "Toll Fees Charged",
            icon: "AccountingBills",
            body: "You have been charged $x.xx for tolls from x/x-x/x",
            // This is so it doesn't show up in the notification selection list
            external_reference_id: `tollsuccess_${Date.now()}`,
          };
          break;
        case "_tollfail":
          newState.form = {
            title: "Toll Payment Failed",
            icon: "AccountingBills",
            body: "$xx.xx charge was unsuccessful",
            // This is so it doesn't show up in the notification selection list
            external_reference_id: `tollfail_${Date.now()}`,
          };
          break;
      }
    } else {
      newState.selectedNotification = this.state.notifications.find(
        (n) => n.id === parseInt(e.currentTarget.value)
      );
    }

    console.log("Updating state", newState);

    this.setState(newState);
  };

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.onClose}>
        <ModalHeader>Send a Notification</ModalHeader>
        <ModalBody>
          {this.canSend() ? (
            this.state.loadingNotifications ? (
              <div>Loading...</div>
            ) : (
              <>
                <Form>
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="push_notification">
                          Existing Notification(s)
                        </Label>
                        <Input
                          type="select"
                          name="existingId"
                          onChange={this.onExistingNotificationChange}
                        >
                          <option value="">---</option>
                          <option value="_tollsuccess">
                            [Prefill] Toll Charge
                          </option>
                          <option value="_tollfail">
                            [Prefill] Toll Payment Fail
                          </option>
                          {this.state.notifications.map((notification) => (
                            <option
                              key={`n${notification.id}`}
                              value={notification.id}
                            >
                              {notification.title}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                      {this.showForm() && (
                        <>
                          <FormGroup>
                            <Label
                              className={this.error("title") ? "error" : ""}
                            >
                              Title
                            </Label>
                            <Input
                              type="text"
                              name="title"
                              value={this.state.form.title || ""}
                              onChange={this.onChange}
                              className={this.error("title") ? "error" : ""}
                            />
                            {this.error("title") ? (
                              <span className="error">
                                {this.error("title")}
                              </span>
                            ) : null}
                          </FormGroup>
                          <SvgIconsDropdown
                            name="icon"
                            value={this.state.form.icon || ""}
                            onChange={this.onChange}
                          />
                          <FormGroup>
                            <Label for="body">Body</Label>
                            <Input
                              type="textarea"
                              name="body"
                              id="body"
                              value={this.state.form.body || ""}
                              onChange={this.onChange}
                              className={this.error("body") ? "error" : ""}
                            />
                            {this.error("body") ? (
                              <span className="error">
                                {this.error("body")}
                              </span>
                            ) : null}
                          </FormGroup>
                        </>
                      )}
                    </Col>
                  </Row>
                </Form>
                {this.state.selectedNotification && (
                  <div>
                    <div class="d-flex my-2 p-2 bg-light border border-dark rounded">
                      <img
                        src={`/img/svg_icons/${this.state.selectedNotification.icon}.svg`}
                        style={{
                          height: "30px",
                          marginRight: "10px",
                        }}
                      />
                      <div>
                        <h4>{this.state.selectedNotification.title}</h4>
                        <div>{this.state.selectedNotification.body}</div>
                      </div>
                    </div>
                  </div>
                )}
                <div>
                  This will send a push notification immediately to the user's{" "}
                  {this.props.user.device.device_model}. It will appear as both
                  a push notification and in their notifications within the app.
                  <br></br>
                  <b>
                    Please double check the above fields before pressing the
                    send button
                  </b>
                </div>
              </>
            )
          ) : (
            <div>
              Cannot send a notification to this user as they either have not
              installed the app or have not yet given us permission as we do not
              have a notification token to send them a push notification to.
              {this.props.user.device && (
                <pre>
                  Device<br></br>
                  {JSON.stringify(this.props.user.device, null, 2)}
                </pre>
              )}
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          {this.canSend() && (
            <Button
              color="info"
              onClick={this.submit}
              disabled={this.state.submitting}
            >
              Send{this.state.submitting ? "ing..." : ""}
            </Button>
          )}
          <Button
            color="secondary"
            onClick={this.props.onClose}
            disabled={this.state.submitting}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export { NotificationModal };
