import React from "react";
import { Collapse, Button } from "reactstrap";
import SharedComponent, { NUMBER_FORMATER } from "../../Shared";
import {
  Area,
  XAxis,
  YAxis,
  Label,
  ReferenceArea,
  Tooltip,
  AreaChart,
  ReferenceLine,
} from "recharts";

export class FinancialResultGraph extends SharedComponent {
  state = {
    summaryOpen: true,
    simulatedOpen: false,
  };

  render() {
    if (!this.props.id && !this.props.graph && this.props.vehiclePayment)
      throw Error("Invalid");

    const showSimulated =
      this.props.currentVehiclePayment &&
      this.props.recommendation === "NOT_DETERMINED";

    return (
      <div className="w-full bg-light rounded-md shadow-sm">
        <Button
          onClick={() =>
            this.setState((e) => ({
              summaryOpen: !e.summaryOpen,
            }))
          }
          className="mb-2 d-flex justify-content-between py-2"
          style={{ width: "100%", textAlign: "left" }}
          color={this.state.summaryOpen ? "dark" : "secondary"}
        >
          Summary Graph ({this.props.draftableDays}/
          {this.props.graph.data.length} Draftable Days)
        </Button>
        {/* <Collapse isOpen={this.state.summaryOpen} className="bg-light p-2"> */}
        <Graph
          graph={this.props.graph}
          vehiclePayment={this.props.vehiclePayment / 100}
        />
        {/* </Collapse> */}
        {showSimulated && (
          <>
            <Button
              onClick={() =>
                this.setState((e) => ({
                  simulatedOpen: !e.simulatedOpen,
                }))
              }
              className="mb-2 d-flex justify-content-between py-2"
              style={{ width: "100%", textAlign: "left" }}
              color={this.state.simulatedOpen ? "dark" : "secondary"}
            >
              Simulated Summary Graph (
              {this.props.draftableDaysWithoutCurrentVehicle}/
              {this.props.graph.data.length} Draftable Days)
            </Button>
            {/*<Collapse
              isOpen={this.state.simulatedOpen}
              className="bg-light p-2"
            > */}
            <Graph
              graph={this.props.graph}
              vehiclePayment={this.props.vehiclePayment / 100}
              currentVehiclePayment={this.props.currentVehiclePayment / 100}
            />
            {/* </Collapse> */}
          </>
        )}
      </div>
    );
  }
}

class Graph extends React.Component {
  state = {
    chartWidth: 0,
    listener: null,
    offset: null,
    ticks: [0, 0],
  };

  renderCents(num, allowNegative) {
    return NUMBER_FORMATER.format(
      (num / 100) * (!allowNegative && num < 0 ? -1 : 1)
    );
  }

  constructor(props) {
    super(props);
    this.wrapper = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => {
      const listener = window.addEventListener("resize", () => {
        console.log("resize");
        this.setState({
          chartWidth: this.wrapper.current.offsetWidth - 15,
        });
      });
      this.setState({
        chartWidth: this.wrapper.current.offsetWidth - 15,
        ticks: this.setTicks(),
        offset: this.setGradientOffset(),
      });
    }, 250);
  }

  componentWillUnmount() {
    this.state.listener &&
      window.removeEventListener("resize", this.state.listener);
  }

  // componentDidUpdate(prevProps) {
  //   if (this.props.graph !== prevProps.graph) {
  //     this.setState({
  //       ticks: this.setTicks(),
  //       offset: this.setGradientOffset(),
  //     });
  //   }
  // }

  setGradientOffset() {
    const data = this.props.graph.data;
    const dataMax = Math.max(
      ...data.map(
        (i) =>
          i.balance -
          this.props.vehiclePayment +
          (this.props.currentVehiclePayment || 0)
      )
    );
    const dataMin = Math.min(
      ...data.map(
        (i) =>
          i.balance -
          this.props.vehiclePayment +
          (this.props.currentVehiclePayment || 0)
      )
    );

    if (dataMax <= 0) {
      return 0;
    }
    if (dataMin >= 0) {
      return 1;
    }

    return dataMax / (dataMax - dataMin);
  }

  setTicks() {
    // There's no real number bigger than plus Infinity
    var lowest = Number.POSITIVE_INFINITY;
    var highest = Number.NEGATIVE_INFINITY;
    var tmp;
    this.props.graph.data.forEach(({ balance }) => {
      if (balance < lowest) lowest = balance;
      if (balance > highest) highest = balance;
    });

    if (lowest > 0) return [0, highest];

    return [lowest, 0, highest];
  }

  renderTooltip({ active, payload, label }) {
    if (active && payload && payload.length) {
      const result =
        payload[0].payload[
          this.props.currentVehiclePayment
            ? "resultWithCurrentVehicle"
            : "result"
        ];
      return (
        <div className="bg-white border px-2 py-1">
          <h4>{payload[0].payload.date.split("T")[0]}</h4>
          <table>
            <tr>
              <td>Balance:</td>
              <td
                style={{ textAlign: "right" }}
                className={
                  payload[0].value > 0 ? "text-success" : "text-danger"
                }
              >
                ${payload[0].value}
              </td>
            </tr>
            <tr>
              <td>AVVA Payment:</td>
              <td style={{ textAlign: "right" }} className="text-danger">
                $-{this.props.vehiclePayment}
              </td>
            </tr>
            {this.props.currentVehiclePayment && (
              <tr>
                <td>Current Payment:</td>
                <td style={{ textAlign: "right" }} class="text-success">
                  ${this.props.currentVehiclePayment}
                </td>
              </tr>
            )}
            <tr>
              <td>Left Over:</td>
              <td
                style={{ textAlign: "right" }}
                className={result > 0 ? "text-success" : "text-danger"}
              >
                ${result}
              </td>
            </tr>
          </table>
        </div>
      );
    }
    return null;
  }

  render() {
    const gradientId = Math.random().toString();
    return (
      <div className="w-full" ref={this.wrapper}>
        <AreaChart
          data={this.props.graph.data}
          className="relative"
          width={this.state.chartWidth}
          height={250}
          margin={{
            top: 30,
            right: 0,
            left: 10,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
              <stop
                offset={this.state.offset}
                stopColor="#00D180"
                stopOpacity={0.8}
              />
              <stop
                offset={this.state.offset}
                stopColor="#FF4D4D"
                stopOpacity={0.8}
              />
            </linearGradient>
          </defs>
          <XAxis dataKey="day" tick={false} />
          <YAxis
            dataKey="balance"
            domain={["dataMin", "dataMax"]}
            name="Balance ($)"
            ticks={this.state.ticks}
            tickFormatter={(t) => `$${t}`}
            axisLine
          />
          <Tooltip content={(p) => this.renderTooltip(p)} />
          {this.props.graph.months.map((month, i) => (
            <ReferenceArea
              key={month.label}
              x1={month.start}
              x2={month.end}
              fillOpacity={0.2 + i * 0.3}
              isFront={false}
              stroke="#ACACAC"
            >
              <Label value={month.label} position="top" />
            </ReferenceArea>
          ))}
          {/* Important to render last as it will then show above the rest since this translates to just a g in a svg */}
          <Area
            isAnimationActive={false}
            type="monotone"
            dataKey="balance"
            stroke="#000"
            activeDot
            baseLine={0}
            fill={`url(#${gradientId})`}
          />

          <ReferenceLine
            y={this.props.vehiclePayment}
            stroke="#7E2CFF"
            isFront
            strokeWidth={2}
          >
            <Label value={`$${this.props.vehiclePayment}`} position="left" />
          </ReferenceLine>
          {this.props.currentVehiclePayment && (
            <ReferenceLine
              y={this.props.currentVehiclePayment}
              stroke="#000"
              isFront
              strokeWidth={2}
            >
              <Label value={`$${this.props.vehiclePayment}`} position="left" />
            </ReferenceLine>
          )}
        </AreaChart>
      </div>
    );
  }
}
