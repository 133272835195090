import React from "react";
import ContentWrapper from "../../Layout/ContentWrapper";
import {
  Card,
  CardBody,
  Button,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  CardFooter,
} from "reactstrap";
import SharedComponent from "../Shared";
import { Api } from "../../../Api/index";
class AdminDetails extends SharedComponent {
  state = {
    data: {},
  };
  componentDidMount() {
    this.getAdminDetails();
  }

  doSubmit = () => {
    const { data } = this.state;
    let UserId = this.props.match.params.id;
    let user = {
      id: UserId,
      email: data.email,
      phoneNumber: data.phoneNumber,
      fullName: data.fullName,
    };
    Api.UsersManagement.UpdateUser(UserId, user)
      .then(() => {
        this.renderSuccessAlert("Updated successfully", "Click the button");
        this.props.history.push("/admins");
      })
      .catch(() => {
        this.renderErrorAlert(
          "Ops!",
          "Something went wrong, please try again!"
        );
      });
  };

  render() {
    const { data } = this.state;
    return (
      <ContentWrapper>
        <div className="content-heading d-flex justify-content-between">
          <div>User Details</div>
        </div>
        <Form onSubmit={this.handleSubmit}>
          <Card className="card-default">
            <CardBody>
              <Row>
                <Col className="pt-2" md={4} sm={12}>
                  <FormGroup>
                    <Label for="fullName">Full Name </Label>
                    <Input
                      onChange={this.handleChange}
                      value={data.fullName}
                      type="text"
                      name="fullName"
                      id="id-fullName"
                    />
                  </FormGroup>
                </Col>
                <Col className="pt-2" md={4} sm={12}>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      onChange={this.handleChange}
                      value={data.email}
                      type="text"
                      name="email"
                      id="id-email"
                    />
                  </FormGroup>
                </Col>
                <Col className="pt-2" md={4} sm={12}>
                  <FormGroup>
                    <Label for="Phone">Phone</Label>
                    <Input
                      onChange={this.handleChange}
                      value={data.phoneNumber}
                      type="text"
                      name="phoneNumber"
                      id="id-Phone"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter className=" d-flex justify-content-between">
              <Button onClick={this.resetPassword} color="info">
                Reset Password
              </Button>
              <Button color="info" type="submit">
                Update
              </Button>
            </CardFooter>
          </Card>
        </Form>
      </ContentWrapper>
    );
  }
  resetPassword = () => {
    let UserId = this.props.match.params.id;
    Api.UsersManagement.ResetPassword(UserId)
      .then((response) => {
        this.renderSuccessAlert(
          "You resetted your password successfully!",
          `Your new password is (${response.password})`
        );
      })
      .catch(() => {
        this.renderErrorAlert(
          "Ops!",
          "Something went wrong, please try again!"
        );
      });
  };
  getAdminDetails = () => {
    let UserId = this.props.match.params.id;
    Api.UsersManagement.AdminDetails(UserId)
      .then((response) => {
        this.setState({ data: response, iconUrl: response.photoUrl });
      })
      .catch(console.log);
  };
}

export { AdminDetails };
