import "core-js/es6/string";
import "core-js/es6/array";
import "core-js/es6/map";
import "core-js/es6/set";
import "core-js/es6/object";
import "core-js/es6/promise";
import "core-js/es7/object";
import "core-js/es7/array";
import "raf/polyfill";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import App from "./App";

import "./i18n";

import configureStore from "./store/store";

Window.prototype.environment =
  window.location.host === "admin.driveavva.com"
    ? "production"
    : window.location.host.includes("local")
    ? "local"
    : window.location.host.includes("qa")
    ? "dev"
    : "staging";

Window.prototype.isProduction = window.environment === "production";
Window.prototype.isStaging = window.environment === "staging";
Window.prototype.isLocal = window.environment === "local";
Window.prototype.isDevelopment = window.environment === "dev";

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("app")
);
