import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import SharedComponent from "../../Shared";
import { Api } from "../../../../Api/index";

export class ImportModal extends SharedComponent {
  state = {
    dealId: "",
    error: false,
    submitting: false,
  };

  submit = () => {
    if (!this.state.dealId || !/\d{11}/.test(this.state.dealId)) {
      this.setState({
        error: "Please enter a valid dealId",
      });
    } else {
      this.setState({ submitting: true });
      Api["@driveavva"].new.Users.ImportFromDeal(this.state.dealId)
        .then(() => {
          this.renderSuccessAlert("Success!");
          this.setState({
            submitting: false,
            dealId: "",
            error: false,
          });
          this.props.onClose();
        })
        .catch((e) => {
          this.setState({
            submitting: false,
            dealId: "",
            error: e.errors[this.state.dealId].replace(/\n/g, "<div></div>"),
          });
        });
    }
  };

  render() {
    return (
      <>
        <Modal isOpen={this.props.isOpen} toggle={this.props.onClose}>
          <ModalHeader toggle={this.props.onClose}>Import Member</ModalHeader>
          <ModalBody>
            <p>
              You can easily find this id in the browser when you are viewing
              the deal you wish to import. The highlighted portion below is the
              Deal Id
              <div>
                <i>
                  https://app.hubspot.com/contacts/000000/deal/
                  <span className="text-success">00000000000</span>
                </i>
              </div>
            </p>
            <Form>
              <FormGroup>
                <Label for="dealId" className={this.state.error ? "error" : ""}>
                  Hubspot Deal Id
                </Label>
                <Input
                  type="number"
                  name="dealId"
                  className={this.state.error ? "error" : ""}
                  onChange={(e) =>
                    this.setState({
                      dealId: e.currentTarget.value,
                      error: false,
                    })
                  }
                />
                {this.state.error && (
                  <span
                    className="error"
                    dangerouslySetInnerHTML={{ __html: this.state.error }}
                  />
                )}
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              onClick={this.submit}
              disabled={this.state.submitting}
            >
              Import{this.state.submitting ? "ing" : ""}
            </Button>
            <Button color="secondary" onClick={this.props.onClose}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
