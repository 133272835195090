// import crypto from "@driveavva/crypto";
import { UserApiEndpoints } from "./users.api";
import { AppApiEndpoints } from "./apps.api";
import { VehicleApiEndpoints } from "./car.api";
import { TransactionsApiEndpoints } from "./transactions.api";

// export const { encrypt, decrypt } = crypto({
//   password: "4198cf5625218984a5e96765",
//   log: true,
// });

export const getAndDecryptDataFromResponse = async (r) => {
  // const password = localStorage.getItem("token");
  // const { decrypt } = crypto({
  //   password,
  //   iv: "4198cf5625218984a5e96765",
  //   salt: "89b529f9a698",
  //   log: true,
  // });
  // r.data = await decrypt(r.data);
  return r.data;
};

export default {
  UserApiEndpoints,
  AppApiEndpoints,
  VehicleApiEndpoints,
  TransactionsApiEndpoints,
  getAndDecryptDataFromResponse
};
