const svgIconNames = [
  "AccountingBills",
  "AlarmBell1",
  "AlarmBellOutline",
  "AlertTriangle",
  "AvvaButterfly",
  "AvvaButterflyOutline",
  "BloodDropHealth",
  "Camera1Images",
  "CarEngine8",
  "CarRepairTowTruck",
  "CarRepairWash3",
  "CarRetro",
  "CarToolSteeringWheel",
  "Check1",
  "Close",
  "Coin",
  "Faceid",
  "Fingerprint",
  "HeadphonesCustomerSupportHuman1",
  "HeadphonesCustomerSupportInterface",
  "Loading",
  "LocationTarget",
  "MessagesPeoplePersonBubbleCircle1",
  "MultipleUsers1",
  "OfficeBusinessCard",
  "PinMaps",
  "QuestionCircle",
  "Search",
  "Settings",
  "SingleManUsers",
  "ToolsWrenchScrewdriverToolsConstructions",
];

const tempData = [
  {
    id: 1,
    title: "Notification 1",
    sub_title: "Sub title 1",
    action_url: "https://www.google.com",
    body: "Body 1",
    data_json: { key: "value" },
  },
  {
    id: 2,
    title: "Notification 2",
    sub_title: "Sub title 2",
    action_url: "https://www.google.com",
    body: "Body 2",
    data_json: { key: "value" },
  },
  {
    id: 3,
    title: "Notification 3",
    sub_title: "Sub title 3",
    action_url: "https://www.google.com",
    body: "Body 3",
    data_json: { key: "value" },
  },
  {
    id: 4,
    title: "Notification 4",
    sub_title: "Sub title 4",
    action_url: "https://www.google.com",
    body: "Body 4",
    data_json: { key: "value" },
  },
];

const displayName = (iconName) =>
  iconName.replace(/-/g, " ").replace(/\b\w/g, (l) => l.toUpperCase());

const cleanPayload = (dirtyObj) =>
  Object.fromEntries(Object.entries(dirtyObj).filter(([k, v]) => v !== null));

const isValidUrl = (url) => {
  if (!url) return false;
  const result = url.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return result !== null;
};

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const formatDate = (date) => {
  const arr = date.split("-");
  const month_index = parseInt(arr[1], 10) - 1;
  return `${months[month_index]} '${arr[0].slice(-2)}`;
};

const formatFullDate = (date) => {
  const arr = date.split("-");
  const day = arr[2].split("T");
  const month_index = parseInt(arr[1], 10) - 1;

  return `${months[month_index]} ${day[0]}, ${arr[0]}`;
};

const formatPhone = (phone) => {
  if (phone) {
    return phone.replace(/(\d{1})(\d{3})(\d{3})(\d{3})/, "$1 ($2) $3-$4");
  }
  return phone;
};

const displayColor = (statusMsg) => {
  switch (statusMsg) {
    case "OK":
      return "primary";
    case "WARNING":
      return "warning";
    case "PENDING":
      return "warning";
    case "SUCCESS":
      return "success";
    case "DANGER":
      return "danger";
    case "FAILED":
      return "danger";
    default:
      return null;
  }
};

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

const centsToDollars = (cents) => formatter.format(parseInt(cents, 10) / 100);

export {
  svgIconNames,
  tempData,
  displayName,
  cleanPayload,
  isValidUrl,
  formatDate,
  formatFullDate,
  formatPhone,
  displayColor,
  centsToDollars,
};
