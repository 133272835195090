import http from "./httpServices";
import api from "./apiEndPoint";

const token = http.getJwt();
let headers = {
  Authorization: `Bearer ${token}`,
};

// render dashboard items (New Application)
function getLastNewApplicationDashboard() {
  return http.get(api.ApplicationDashboard, { headers });
}
function getStatisticsCar() {
  return http.get(`${api.Cars}/statistics`, { headers });
}

function getLookUpTypeSupport() {
  return http.get(`${api.support}/ticket-types/lookups`, { headers });
}
function getLatestGpsNotifications(page, currentPage, pageSize) {
  let url = new URL(`${api.phoneLocation}/latest`);
  url.searchParams.append("Page", page || currentPage);
  url.searchParams.append("PageSize", pageSize);
  return http.get(url, { headers });
}

export default {
  getLastNewApplicationDashboard,
  getLatestGpsNotifications,
  getLookUpTypeSupport,
  getStatisticsCar,
};
