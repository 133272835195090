import React from "react";
import Select from "react-select";
import { components } from "react-select";
import { Label, FormGroup } from "reactstrap";

import { displayName, svgIconNames } from "../utils";

const { SingleValue, Option } = components;

class SvgIconsDropdown extends React.Component {
  state = {
    selectedOption: null,
  };

  onChange = (selectedOption) => {
    this.setState({ selectedOption });
    this.props.onChange({
      currentTarget: {
        value: selectedOption.value,
        name: this.props.name,
      },
    });
  };

  IconSingleValue = (props) => (
    <SingleValue {...props}>
      <img
        src={props.data.image}
        style={{
          height: "15px",
          marginRight: "10px",
        }}
      />
      {props.data.label}
    </SingleValue>
  );

  IconOption = (props) => (
    <Option {...props}>
      <img
        src={props.data.image}
        style={{
          height: "15px",
          marginRight: "10px",
        }}
      />
      {props.data.label}
    </Option>
  );

  componentDidUpdate(prevProps) {
    if (
      prevProps.value !== this.props.value &&
      (!this.state.selectedOption ||
        this.props.value !== this.state.selectedOption.value)
    ) {
      this.setState({
        selectedOption: {
          value: this.props.value,
          label: this.props.value,
          image: `/img/svg_icons/${this.props.value}.svg`,
        },
      });
    }
  }

  render() {
    const name = this.props.name || "icon";
    return (
      <FormGroup>
        <Label for={name}>Icon</Label>
        <Select
          value={this.state.selectedOption}
          name={name}
          components={{
            SingleValue: this.IconSingleValue,
            Option: this.IconOption,
          }}
          options={svgIconNames.map((iconName) => ({
            value: iconName,
            label: iconName,
            image: `/img/svg_icons/${iconName}.svg`,
          }))}
          onChange={this.onChange}
        />
      </FormGroup>
    );
  }
}

export { SvgIconsDropdown };
