import React from 'react';

class TransactionCheckbox extends React.Component {
  
  state = {
    checked: false
  }

  setChecked = () => {
    this.setState((prevState) => ({ checked: !prevState.checked }),
      () => this.props.onChange(this.state.checked));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.checked !== this.props.checked) {
      this.setState({ checked: this.props.checked });
    }
  }

  render() {
    return (
      <input
        type="checkbox"
        checked={this.props.checked}
        onChange={() => this.setChecked(!this.state.checked)}
      />
    );
  }
};

export default TransactionCheckbox;