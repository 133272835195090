import React from "react";
import ContentWrapper from "../../Layout/ContentWrapper";
import {
  ButtonDropdown,
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Link } from "react-router-dom";
import Datatable from "../../Tables/Datatable";
import SharedComponent from "../Shared";
import { Api } from "../../../Api/index";
class UsersList extends SharedComponent {
  state = {
    dropdownOpen: false,
    dropdownToggle: false,
    isLoading: true,
    items: [],
  };

  componentDidMount() {
    this.getUsersList();
  }

  dropdownToggle = () =>
    this.setState({ dropdownOpen: !this.state.dropdownOpen });

  render() {
    const { isLoading } = this.state;
    return (
      <ContentWrapper>
        <div className="content-heading d-flex justify-content-between">
          <div>Users</div>
        </div>
        <Card className="card-default">
          <CardBody>
            {!isLoading ? (
              <Datatable options={this.renderOption()}>
                <table className="table table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <th className="sort-alpha">Full Name</th>
                      <th className="sort-alpha">Phone</th>
                      <th className="sort-alpha">Type</th>
                      <th className="sort-alpha">Created</th>
                      <th className="sort-alpha">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.items.map((item, index) => (
                      <tr key={index}>
                        <td>{item.first_name + " " + item.last_name}</td>
                        <td>{item.phone}</td>
                        <td>{item.role}</td>
                        <td>
                          {new Date(item.created_at).toLocaleDateString()}
                        </td>
                        <td className="text-center">
                          <Link
                            to={`/users/update/${item.user_id}`}
                            color="info"
                            className="btn btn-dark btn-sm d-inline-flex align-items-center mr-1"
                          >
                            Details
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Datatable>
            ) : (
              this.renderLoader()
            )}
          </CardBody>
        </Card>
      </ContentWrapper>
    );
  }
  getUsersList = () => {
    Api["@driveavva"].core.UsersManagement.UserList()
      .then((response) => {
        console.log("GOT RESPONSE", response);
        this.setState({
          items: response.data,
          isLoading: false,
        });
      })
      .catch(console.log);
  };
}

export { UsersList };
