import React from "react";
import ContentWrapper from "../../Layout/ContentWrapper";
import {
  Badge,
  ButtonDropdown,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import ReactJson from "react-json-view";
import SharedComponent from "../Shared";
import { Api } from "../../../Api/index";
import { Summary } from "./details/Summary";
import { Documents } from "./details/Documents";
import { BackgroundChecks } from "./details/BackgroundChecks";
import { MVRReport } from "./details/MVRReport";
import { PlaidAssetReports } from "./details/PlaidAssetReports";

const TABS = [
  "Summary",
  // "Bank Accounts",
  "Background Checks",
  "MVR Report",
  "Documents",
  "Plaid Asset Reports",
  "Debug",
];

const APPLICATION_STATUSES = [
  "created",
  "about",
  "vehicle_select",
  "vehicle_change",
  "accounts",
  "accounts_additional",
  "payment",
  "verify",
  "info",
  "underwriting",
  "approved",
  "complete",
  "verify_update",
  "denied",
  "processing",
  "first_responder_badge",
  "upload_dl",
];

class ApplicationDetails extends SharedComponent {
  state = {
    activeTab: TABS[0],
    data: null,
    stages: [],
    isLoading: true,
    dropdownOpen: false,
    debugDropdownOpen: false,
    changing: false,
  };

  getTabCount(tab) {
    let amount;
    if (this.state.data)
      switch (tab) {
        case "Bank Accounts":
          amount = this.state.data.accounts
            ? this.state.data.accounts.length
            : null;
          break;
        case "Documents":
          amount = this.state.data.assets
            ? this.state.data.assets.length
            : null;
          break;
        default:
          amount = null;
          break;
      }

    return amount !== null ? ` (${amount})` : null;
  }

  getDetails() {
    Promise.all([
      Api["@driveavva"].core.AppsManagement.AppDetails(
        this.props.match.params.id
      ),
      Api["@driveavva"].core.AppsManagement.HubspotPipelineDetails(),
    ])
      .then(([res, pipeline]) => {
        console.log("We got error");
        if (res.status === "error") {
          this.setState({ error: res.message });
        } else {
          this.setState({
            isLoading: false,
            data: res.data,
            stages: pipeline.data,
            activeTab:
              TABS.find((t) => window.location.hash === `#${encodeURI(t)}`) ||
              TABS[0],
          });
        }
      })
      .catch((error) => {
        this.setState({ error: error.message });
      });
  }

  update(data, changing = "deal") {
    console.log("update", data);
    this.setState({ changing });
    Api["@driveavva"].core.AppsManagement.UpdateApp(
      this.props.match.params.id,
      data
    ).then((e) => {
      console.log("Updated", e);
      this.setState({
        changing: null,
        data: Object.assign(this.state.data, e.data),
      });
    });
  }

  componentDidMount() {
    this.getDetails();
  }

  toggle = (name = "dropdownOpen") => {
    this.setState({
      [name]: !this.state[name],
    });
  };

  hubspotStage() {
    if (this.state.stages) {
      let maybe = this.state.stages.find(
        ([name, id]) => id === this.state.data.hubspot_deal_stage_id
      );
      return maybe ? maybe[0] : "Unknown";
    } else {
      return "loading...";
    }
  }

  render() {
    const { data, stages, error } = this.state;

    if (error) return <div>{error}</div>;
    if (!data) return <div>Loading...</div>;

    return (
      <ContentWrapper>
        <div className="content-heading d-flex justify-content-between">
          <div>{data.uuid}</div>
          <div>
            <Badge className="mr-4" color="light">
              Last Update -{" "}
              {new Date(data.last_status_update_at).toDateString()}
            </Badge>
            {stages && (
              <Badge className="mr-4" color="light">
                Current Deal Stage - {this.hubspotStage()}
              </Badge>
            )}
            {data.hubspot_deal_id && (
              <ButtonDropdown
                isOpen={this.state.dropdownOpen}
                toggle={() => this.toggle()}
                disabled={this.state.changing === "deal"}
              >
                <DropdownToggle caret color="info">
                  {this.state.changing === "deal"
                    ? "Changing..."
                    : "Change Hubspot Deal Stage"}
                </DropdownToggle>
                <DropdownMenu
                  modifiers={{
                    setMaxHeight: {
                      enabled: true,
                      order: 890,
                      fn: (data) => {
                        return {
                          ...data,
                          styles: {
                            ...data.styles,
                            overflow: "auto",
                            maxHeight: 200,
                          },
                        };
                      },
                    },
                  }}
                >
                  {stages.map(([name, id]) => (
                    <DropdownItem
                      key={id}
                      onClick={() => this.update({ hubspot_deal_stage_id: id })}
                    >
                      {name}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </ButtonDropdown>
            )}
          </div>
        </div>
        <Nav tabs>
          {TABS.map((tab) => (
            <NavItem key={tab}>
              <NavLink
                active={this.state.activeTab === tab}
                href={`/applications/${this.state.data.uuid}#${encodeURI(tab)}`}
                onClick={() => this.setState({ activeTab: tab })}
              >
                {tab}
                {this.getTabCount(tab)}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="Summary">
            {this.state.activeTab === "Summary" && (
              <Summary
                app={this.state.data}
                user={this.state.data.user_profiles}
                vehicle={this.state.data.vehicles}
              />
            )}
          </TabPane>
          {/* <TabPane tabId="Bank Accounts">
            {this.state.activeTab === "Bank Accounts" && (
              <BankAccounts accounts={this.state.data.accounts} />
            )}
          </TabPane> */}
          <TabPane tabId="Documents">
            {this.state.activeTab === "Documents" && (
              <Documents
                documents={this.state.data.assets}
                applicationId={this.state.data.uuid}
              />
            )}
          </TabPane>
          <TabPane tabId="Background Checks">
            {this.state.activeTab === "Background Checks" && (
              <BackgroundChecks userId={this.state.data.applicant_id} />
            )}
          </TabPane>
          <TabPane tabId="MVR Report">
            {this.state.activeTab === "MVR Report" && (
              <MVRReport userId={this.state.data.applicant_id} />
            )}
          </TabPane>
          <TabPane tabId="Plaid Asset Reports">
            {this.state.activeTab === "Plaid Asset Reports" && (
              <PlaidAssetReports applicationId={this.state.data.id} />
            )}
          </TabPane>
          <TabPane tabId="Debug">
            {this.state.activeTab === "Debug" && (
              <>
                <Nav pills className="mb-4">
                  <Dropdown
                    nav
                    isOpen={
                      this.state.debugDropdownOpen &&
                      this.state.changing !== "step"
                    }
                    toggle={() => this.toggle("debugDropdownOpen")}
                  >
                    <DropdownToggle nav caret>
                      Chang{this.state.changing === "step" ? "ing" : "e"} Step
                    </DropdownToggle>
                    <DropdownMenu>
                      {APPLICATION_STATUSES.map((status) => (
                        <DropdownItem
                          key={status}
                          onClick={() => this.update({ status }, "step")}
                          disabled={status === this.state.data.status}
                        >
                          {status === this.state.data.status
                            ? `Current Step (${status})`
                            : status}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                  {/* <NavItem>
                    <NavLink href="#">Link</NavLink>
                  </NavItem> */}
                </Nav>
                <ReactJson src={this.state.data} />
              </>
            )}
          </TabPane>
        </TabContent>
      </ContentWrapper>
    );
  }
}

export { ApplicationDetails };
