import React from "react";
import {
  Row,
  Col,
  TabPane,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Pagination from "rc-pagination";
import Moment from "react-moment";
import SharedComponent from "../Shared";
import { Api } from "../../../Api/index";
class Incidents extends SharedComponent {
  state = {
    dropdownOpen: false,
    activeTab: "ScheduledM",
    inputVal: this.props.inputValue,
    isLoading: true,
    data: {
      ApplicationNumber: "",
      ApplicationDate: "",
      serviceDate: "2020-02-07T00:00:00",
      description: "test",
    },
    items: [],
    requestOptions: {
      currentPage: 1,
      totalPages: 1,
      totalItems: 0,
      pageSize: 20,
    },
    viewModel: false,
  };

  async componentDidMount() {
    this.queryItems();
  }

  render() {
    const { isLoading, items, data } = this.state;
    const { currentPage, pageSize, totalItems } = this.state.requestOptions;
    return (
      <TabPane tabId="Incidents">
        <Form className="mb-3 mt-3" onSubmit={this.handleSubmit}>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="ApplicationNumber">APPLICATION NUMBER</Label>
                <Input
                  type="number"
                  name="ApplicationNumber"
                  id="ApplicationNumber"
                  onChange={this.handleChange}
                  value={data.ApplicationNumber}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="ApplicationDate">Date</Label>
                <Input
                  type="date"
                  name="ApplicationDate"
                  id="ApplicationDate"
                  onChange={this.handleChange}
                  value={data.ApplicationDate}
                />
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <div
                  style={{ height: 30 }}
                  className="d-none d-md-block d-lg-block"
                ></div>
                <Button type="submit" color={"info"} className="w-100">
                  Search
                </Button>
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <div
                  style={{ height: 30 }}
                  className="d-none d-md-block d-lg-block"
                ></div>
                <Button
                  color={"success"}
                  onClick={this.resetData}
                  className="w-100"
                >
                  Reset Data
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Form>

        {!isLoading ? (
          <React.Fragment>
            {items.length !== 0 ? (
              <React.Fragment>
                <Row>
                  <Col md={12}>
                    <Table className="table table-bordered table-striped table-hover mb-4">
                      <thead>
                        <tr>
                          <th className="sort-alpha">Application Number</th>
                          <th className="sort-alpha"> Date</th>
                          <th className="sort-alpha">Description</th>
                          <th className="sort-alpha">Client Name</th>
                          <th className="sort-alpha">Client Phone</th>
                          <th className="sort-alpha">Car Make</th>
                          <th className="sort-alpha">Car Model</th>
                          <th className="sort-alpha">Control</th>
                        </tr>
                      </thead>
                      <tbody>
                        {items.map((item) => (
                          <tr key={item.id}>
                            <td>{item.applicationNumber}</td>
                            <td>
                              <Moment format="MM/DD/YYYY">
                                {item.serviceDate}
                              </Moment>
                            </td>
                            <td>{item.description}</td>
                            <td>{item.clientName}</td>
                            <td>{item.clientPhone}</td>
                            <td>{item.carMake}</td>
                            <td>{item.carModel}</td>
                            <td className="text-center">
                              <div>
                                <Button
                                  onClick={() => this.openModel(item.id)}
                                  color="warning"
                                  className="btn btn-warning d-inline-flex align-items-center"
                                >
                                  Details
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Pagination
                      showSizeChanger
                      defaultPageSize={pageSize}
                      defaultCurrent={currentPage}
                      onChange={this.handlePageChange}
                      total={totalItems}
                      className="float-right text-white"
                    />
                  </Col>
                </Row>
              </React.Fragment>
            ) : (
              this.renderSadFace(true)
            )}
          </React.Fragment>
        ) : (
          this.renderLoader()
        )}

        <Modal isOpen={this.state.viewModel} toggle={this.closeViewModel}>
          <ModalHeader toggle={this.toggleModal}>Incident</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label>Service Date</Label>
                <Input
                  type="date"
                  name="serviceDate"
                  value={data.serviceDate.split("T")[0]}
                />
              </FormGroup>

              <FormGroup>
                <Label>Description</Label>
                <Input
                  type="textarea"
                  name="description"
                  value={data.description}
                  rows={5}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.closeViewModel}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </TabPane>
    );
  }
  openModel = (id) => {
    this.setState({ viewModel: true });
    Api.Notifications.Incidents.IncidentsDetails(id)
      .then((data) => this.setState({ data }))
      .catch((error) => {
        this.renderErrorAlert("Ops!", `${error.response.data.Message}`);
      });
  };

  closeViewModel = () => {
    this.setState({ viewModel: false });
  };

  queryItems = (page) => {
    this.setState({
      isLoading: true,
    });

    const { pageSize, currentPage } = this.state.requestOptions;
    const { ApplicationNumber, ApplicationDate } = this.state.data;

    Api.Notifications.Incidents.IncidentsList(
      ApplicationNumber,
      ApplicationDate,
      page,
      currentPage,
      pageSize
    )
      .then((response) => {
        let items = response.items;
        this.setState({
          isLoading: false,
          items,
          requestOptions: {
            currentPage: response.currentPage,
            totalPages: response.totalPages,
            totalItems: response.totalItems,
            pageSize: response.pageSize,
          },
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };
  doSubmit = () => {
    this.queryItems(1);
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.doSubmit();
  };
  resetData = () => {
    const requestOptions = this.state.requestOptions;
    const data = this.state.data;
    requestOptions.currentPage = 1;
    requestOptions.totalPages = 1;
    requestOptions.totalItems = 0;
    requestOptions.pageSize = 20;
    data.ApplicationNumber = "";
    data.ApplicationDate = "";
    this.setState({ requestOptions, data });
    this.queryItems();
  };
}

export default Incidents;
