import React from "react";
import { Row, Col, TabPane, Button, Table } from "reactstrap";
import { withRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import SharedComponent from "../Shared";
import { Api } from "../../../Api/index";
import UploadAttachmentModal from "./UploadAttachmentModal";

export default class Documents extends SharedComponent {
  state = {
    items: [],
    modal: false,
    deleting: null,
  };

  componentDidMount() {
    let CarId = this.props.match.params.id;
    Api.CarManagement.Attachments(CarId, "Document")
      .then((response) => {
        this.setState({ items: response });
      })
      .catch(console.log);
  }

  delete = (attachmentId) => {
    let CarId = this.props.match.params.id;
    this.setState({ deleting: attachmentId });
    Api.CarManagement.DeleteAttachment(CarId, attachmentId)
      .then((r) => {
        const items = this.state.items.filter((m) => m.id !== attachmentId);
        this.setState({ items, deleting: false });
      })
      .catch((e) => {
        this.setState({ deleting: false });
        this.renderErrorAlert(
          "Ops!",
          e.response.data
            ? e.response.data
            : "Something went wrong, please try again!"
        );
      });
  };

  closeModal = () => {
    this.setState({ modal: !this.state.modal });
  };

  render() {
    return (
      <TabPane tabId="Documents">
        <Row>
          <Col md={12} className="mt-3">
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="mb-0">Documents</h3>
              <Button color={"info"} onClick={this.closeModal}>
                Upload Document
              </Button>
            </div>
            <hr />
          </Col>
        </Row>
        {this.state.items && this.state.items.length > 0 ? (
          <Table>
            <thead>
              <th>File</th>
              <th>Uploaded</th>
              <th>Actions</th>
            </thead>
            <tbody>
              {this.state.items.map((img, index) => (
                <tr key={`d-${index}`}>
                  <td>{img.url.split("/").pop()}</td>
                  <td>{new Date(img.createdAt).toLocaleString()}</td>
                  <td>
                    <a
                      href={img.url}
                      color="info"
                      className="btn btn-info mr-1"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View
                    </a>
                    <Button
                      className="delete-btn"
                      onClick={() => this.delete(img.id)}
                      color="danger"
                      disabled={this.state.deleting === img.id}
                    >
                      {this.state.deleting === img.id
                        ? "Deleting..."
                        : "Delete"}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          this.renderSadFace(false)
        )}

        <UploadAttachmentModal
          car={this.props.match.params.id}
          type="Document"
          isOpen={this.state.modal}
          allowMultiple
          onClose={() => this.closeModal()}
          onSuccess={(r) => {
            this.state.items.push(...r);
            this.setState({ items: this.state.items, modal: null });
          }}
          onError={() =>
            this.renderErrorAlert(
              "Oops!",
              "Something went wrong, please try again!"
            )
          }
        />
      </TabPane>
    );
  }
}

Documents = withRouter(Documents);
