import React from "react";
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Row,
  Button,
} from "reactstrap";
import { formatFullDate } from "../utils";

const VehicleDetails = ({ vehicle }) => (
  <Card style={{ width: "100%" }}>
    <CardBody>
      <CardTitle tag="h3">
        {vehicle ? vehicle.vehicle_name : "Vehicle"}
      </CardTitle>
      <CardText tag="div" className="mt-2">
        {vehicle ? (
          <Row>
            <Col xs={4}>
              <img src={vehicle.vehicle_photo_url} height="100" alt="vehicle" />
            </Col>
            <Col xs={8}>
              <Row>
                <Col>
                  <div>
                    <b>Driven: </b>{" "}
                    {vehicle.mileage_driven_amount
                      ? vehicle.mileage_driven_amount.toLocaleString("en-US") +
                        "mi"
                      : null}
                  </div>
                  <div>
                    <b>Daily Average: </b>{" "}
                    {vehicle.mileage_daily_average_amount
                      ? vehicle.mileage_daily_average_amount.toLocaleString(
                          "en-US"
                        ) + "mi"
                      : null}
                  </div>
                  <div>
                    <b>Allowed: </b>{" "}
                    {vehicle.mileage_allowed_amount
                      ? vehicle.mileage_allowed_amount.toLocaleString("en-US") +
                        "mi"
                      : null}
                  </div>
                  <div>
                    <b>Remaining: </b>{" "}
                    {vehicle.mileage_remaining_amount
                      ? vehicle.mileage_remaining_amount.toLocaleString(
                          "en-US"
                        ) + "mi"
                      : null}
                  </div>
                </Col>
                <Col>
                  <div>
                    <b>Rec Driven: </b>{" "}
                    {vehicle.mileage_rec_driven_amount
                      ? vehicle.mileage_rec_driven_amount.toLocaleString(
                          "en-US"
                        ) + "mi"
                      : null}
                  </div>
                  <div>
                    <b>Rec Daily Average: </b>{" "}
                    {vehicle.mileage_rec_daily_average_amount
                      ? vehicle.mileage_rec_daily_average_amount.toLocaleString(
                          "en-US"
                        ) + "mi"
                      : null}
                  </div>
                  <div>
                    <b>Odometer Delivery: </b>{" "}
                    {vehicle.mileage_at_delivery_amount
                      ? vehicle.mileage_at_delivery_amount.toLocaleString(
                          "en-US"
                        ) + "mi"
                      : null}
                  </div>
                  <div>
                    <b>Updated At: </b>{" "}
                    {vehicle.mileage_updated_at
                      ? new Date(
                          vehicle.mileage_updated_at + "Z"
                        ).toLocaleString()
                      : null}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <p>No vehicle.</p>
        )}
      </CardText>
    </CardBody>
  </Card>
);

const BottomRow = ({ vehicle }) => (
  <div style={{ display: "flex" }}>
    <Card style={{ width: "100%" }}>
      <CardBody>
        <CardTitle tag="h4">Geotab</CardTitle>
        <CardText tag="div" className="mt-2">
          <Row xs={6}>
            <Col xs={6}>
              {vehicle.vehicle.gps_device_id ? (
                <ul className="no-style-list">
                  <li>
                    Last Known Location: {vehicle.last_known_lat},
                    {vehicle.last_known_lng}
                  </li>
                  <li>
                    GPS Device Id:{" "}
                    {vehicle.vehicle.gps_device_id || "Not installed"}
                  </li>
                  <li>
                    <a
                      href={`https://my.geotab.com/avva/#device,id:${vehicle.vehicle.gps_device_id}`}
                      target="_blank"
                    >
                      Open in Geotab
                    </a>
                  </li>
                </ul>
              ) : (
                <div>No GPS Device Installed</div>
              )}
            </Col>
          </Row>
        </CardText>
      </CardBody>
    </Card>

    <Card style={{ width: "100%" }}>
      <CardBody>
        <CardTitle tag="h4">Insurance Policy</CardTitle>
        <CardText tag="div" className="mt-2">
          <Row xs={6}>
            <Col xs={6}>
              <ul className="no-style-list">
                <li>Number: {vehicle.insurance_policy_number}</li>
                <li>
                  Begin:{" "}
                  {vehicle.insurance_policy_starts_at
                    ? formatFullDate(vehicle.insurance_policy_starts_at)
                    : null}
                </li>
                <li>
                  End:{" "}
                  {vehicle.insurance_policy_ends_at
                    ? formatFullDate(vehicle.insurance_policy_ends_at)
                    : null}
                </li>
              </ul>
            </Col>
          </Row>
        </CardText>
      </CardBody>
    </Card>

    <Card style={{ width: "100%" }}>
      <CardBody>
        <CardTitle tag="h4">Roadside Assistance</CardTitle>
        <CardText tag="div" className="mt-2">
          <Row xs={6}>
            <Col xs={6}>
              <ul className="no-style-list">
                <li>Contract Number: {vehicle.roadside_contract_number}</li>
                <li>Provider Type: {vehicle.roadside_provider_type}</li>
              </ul>
            </Col>
          </Row>
        </CardText>
      </CardBody>
    </Card>
  </div>
);

const Vehicle = ({ vehicle }) => (
  <>
    <VehicleDetails vehicle={vehicle} />
    {vehicle ? <BottomRow vehicle={vehicle} /> : null}
  </>
);

export default Vehicle;

/* <pre>{JSON.stringify(vehicle, null, 2)}</pre> */
/*
  <Col xs={6}>Last Updated: {vehicle.updated_at ? formatFullDate(vehicle.updated_at) : null}</Col>
  <Col xs={6}>Created At: {vehicle.created_at ? formatFullDate(vehicle.created_at) : null}</Col>
*/
