import React from "react";
import ContentWrapper from "../../Layout/ContentWrapper";
import {
  Card,
  CardBody,
  Button,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  CardFooter,
} from "reactstrap";
import SharedComponent from "../Shared";
import { Api } from "../../../Api/index";
class CreateAdmin extends SharedComponent {
  state = {
    data: {},
  };
  doSubmit = () => {
    const { data } = this.state;
    let user = {
      email: data.email,
      phoneNumber: data.phoneNumber,
      fullName: data.fullName,
    };
    Api.UsersManagement.CreateNewUser(user)
      .then(() => {
        this.renderSuccessAlert(
          "New user added successfully",
          "Click the button"
        );
        this.props.history.push("/admins");
      })
      .catch(() => {
        this.renderErrorAlert(
          "Oops!",
          "Something went wrong, please try again!"
        );
      });
  };

  render() {
    const { data } = this.state;
    return (
      <ContentWrapper>
        <div className="content-heading d-flex justify-content-between">
          <div>New Admin</div>
        </div>
        <Form onSubmit={this.handleSubmit}>
          <Card className="card-default">
            <CardBody>
              <Row>
                <Col className="pt-2" md={4} sm={12}>
                  <FormGroup>
                    <Label for="fullName">Full Name </Label>
                    <Input
                      onChange={this.handleChange}
                      value={data.fullName}
                      type="text"
                      name="fullName"
                      id="id-fullName"
                    />
                  </FormGroup>
                </Col>
                <Col className="pt-2" md={4} sm={12}>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      onChange={this.handleChange}
                      value={data.email}
                      type="text"
                      name="email"
                      id="id-email"
                    />
                  </FormGroup>
                </Col>
                <Col className="pt-2" md={4} sm={12}>
                  <FormGroup>
                    <Label for="Phone">Phone</Label>
                    <Input
                      onChange={this.handleChange}
                      value={data.phoneNumber}
                      type="text"
                      name="phoneNumber"
                      id="id-Phone"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter className=" d-flex justify-content-between">
              <Button className="float-right" color="info" type="submit">
                Add
              </Button>
            </CardFooter>
          </Card>
        </Form>
      </ContentWrapper>
    );
  }
}

export { CreateAdmin };
