import React from "react";
import ContentWrapper from "../../Layout/ContentWrapper";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  Table
} from "reactstrap";
import { Link } from "react-router-dom";
import SharedComponent from "../Shared";
import Pagination from "rc-pagination";
import { Api } from "../../../Api/index";
class ClientsList extends SharedComponent {
  state = {
    isLoading: true,
    data: {
      SearchTerm: ""
    },
    items: [],
    requestOptions: {
      currentPage: 1,
      totalPages: 1,
      totalItems: 0,
      pageSize: 20
    }
  };
  componentDidMount() {
    this.queryItems();
  }

  queryItems = page => {
    this.setState({
      isLoading: true
    });
    const { pageSize, currentPage } = this.state.requestOptions;
    const { SearchTerm } = this.state.data;
    Api.ClientManagement.ClientList(SearchTerm, page, pageSize, currentPage)
      .then(response => {
        let items = response.items;
        this.setState({
          isLoading: false,
          items,
          requestOptions: {
            currentPage: response.currentPage,
            totalPages: response.totalPages,
            totalItems: response.totalItems,
            pageSize: response.pageSize
          }
        });
      })
      .catch(error => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };
  doSubmit = () => {
    this.queryItems(1);
  };
  handleSubmit = e => {
    e.preventDefault();
    this.doSubmit();
  };

  resetData = () => {
    const requestOptions = this.state.requestOptions;
    const data = this.state.data;
    requestOptions.currentPage = 1;
    requestOptions.totalPages = 1;
    requestOptions.totalItems = 0;
    requestOptions.pageSize = 20;
    data.SearchTerm = "";
    this.setState({ requestOptions, data });
    this.queryItems();
  };

  render() {
    const { isLoading, data } = this.state;
    const { currentPage, pageSize, totalItems } = this.state.requestOptions;
    return (
      <ContentWrapper>
        <div className="content-heading d-flex justify-content-between">
          <div>Clients</div>
        </div>
        <Card className="card-default">
          <CardBody>
            <Form className="mb-3 mt-3" onSubmit={this.handleSubmit}>
              <Row>
                <Col md={2}>
                  <FormGroup>
                    <Label for="SearchTerm">Search Term</Label>
                    <Input
                      type="text"
                      name="SearchTerm"
                      id="SearchTerm"
                      onChange={this.handleChange}
                      value={data.SearchTerm}
                    />
                  </FormGroup>
                </Col>
                <Col md="auto">
                  <FormGroup>
                    <div
                      style={{ height: 30 }}
                      className="d-none d-md-block d-lg-block"
                    />
                    <Button type="submit" color={"info"} className="w-100">
                      Search
                    </Button>
                  </FormGroup>
                </Col>
                <Col md="auto">
                  <FormGroup>
                    <div
                      style={{ height: 30 }}
                      className="d-none d-md-block d-lg-block"
                    />
                    <Button
                      color={"success"}
                      onClick={this.resetData}
                      className="w-100"
                    >
                      Reset Data
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            {!isLoading ? (
              this.state.items.length === 0 ? (
                this.renderSadFace(true)
              ) : (
                  <React.Fragment>
                    <Row>
                      <Col md={12}>
                        <Table className="table table-bordered table-striped table-hover mb-4">
                          <thead>
                            <tr>
                              <th className="sort-alpha">ID</th>
                              <th className="sort-alpha">First Name</th>
                              <th className="sort-alpha">Last Name</th>
                              <th className="sort-alpha">Middle Name Initial</th>
                              <th className="sort-alpha">Phone Number</th>
                              <th className="sort-alpha">Email</th>
                              <th className="sort-alpha">Control</th>
                            </tr>
                          </thead>

                          <tbody>
                            {this.state.items.map((item, index) => (
                              <tr key={index}>
                                <td>{item.id}</td>
                                <td>{item.firstName}</td>
                                <td>{item.lastName}</td>
                                <td>{item.middleNameInitial}</td>
                                <td>{item.phoneNumber}</td>
                                <td>{item.email}</td>
                                <td className="text-center">
                                  <div>
                                    <Link
                                      to={`/clients/update/${item.id}`}
                                      color="warning"
                                      className="btn btn-warning d-inline-flex align-items-center"
                                    >
                                      Details
                                  </Link>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Pagination
                          showSizeChanger
                          defaultPageSize={pageSize}
                          defaultCurrent={currentPage}
                          onChange={this.handlePageChange}
                          total={totalItems}
                          className="float-right"
                        />
                      </Col>
                    </Row>
                  </React.Fragment>
                )
            ) : (
                this.renderLoader()
              )}
          </CardBody>
        </Card>
      </ContentWrapper>
    );
  }
}

export { ClientsList };
