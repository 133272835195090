import React from "react";
import {
  Button,
  Col,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
  Tooltip,
} from "reactstrap";
import { Api } from "../../../../Api/index";
import SharedComponent from "../../Shared";

export class KeyedTable extends SharedComponent {
  render() {
    const thed = Array.isArray(this.props.data)
      ? this.props.data
      : [this.props.data];

    if (!thed || !thed[0]) return <div>No Data</div>;
    const keys = Object.keys(thed[0]).filter(
      (k) =>
        ![
          "Offenses",
          "Names",
          "Addresses",
          "DOBS",
          "isPrimary",
          "jurisdiction",
          "persons",
        ].includes(k)
    );

    return (
      <Table dark={this.props.dark}>
        <thead>
          <tr>
            {keys.map((key, i) => (
              <th key={`th=${i}`}>
                {key
                  .replace(/([A-Z])/g, " $1")
                  // uppercase the first character
                  .replace(/^./, function (str) {
                    return str.toUpperCase();
                  })}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {thed.map((data, row) => (
            <tr key={row}>
              {keys.map((key, i) => (
                <td key={`d-${i}`}>
                  {this.props.renderItem
                    ? this.props.renderItem(key, data[key], row)
                    : data[key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
}

class ReportPerson extends SharedComponent {
  state = {
    openCriminalTab: 0,
    tooltipOpen: null,
  };

  render() {
    const { data } = this.props;
    return (
      <>
        <Row>
          <Col>
            <KeyedTable data={data.names} />
          </Col>
          <Col>
            <KeyedTable data={data.addresses} />
          </Col>
        </Row>
        <h4>Criminal Records</h4>
        <Nav tabs>
          {data.criminalRecords.map((record, i) => {
            const open = this.state.openCriminalTab === i;
            return (
              <NavItem key={i}>
                <NavLink
                  className={open ? "active" : ""}
                  onClick={() =>
                    this.setState({
                      openCriminalTab: open ? 0 : i,
                    })
                  }
                >
                  {record.criminalRecordSourceType} - {record.docNumber}
                </NavLink>
              </NavItem>
            );
          })}
        </Nav>
        <TabContent activeTab={this.state.openCriminalTab}>
          {data.criminalRecords.map((record, i) => (
            <TabPane tabId={i} style={{ overflowX: "scroll" }} key={`r-${i}`}>
              <h4>Details</h4>
              <KeyedTable data={record} />
              <h4>Offenses ({record.Offenses.length})</h4>
              <KeyedTable
                data={record.Offenses}
                renderItem={(key, data, i) => {
                  if (key === "offenseSubclassDescription" && data) {
                    return (
                      <>
                        <span
                          style={{
                            textDecoration: "underline",
                            color: "blue",
                            cursor: "help",
                          }}
                          id={`otool-${i}`}
                        >
                          More Info
                        </span>
                        <Tooltip
                          placement="left"
                          isOpen={this.state.tooltipOpen === i}
                          target={`otool-${i}`}
                          toggle={() =>
                            this.setState({
                              tooltipOpen:
                                this.state.tooltipOpen === i ? null : i,
                            })
                          }
                        >
                          {data}
                        </Tooltip>
                      </>
                    );
                  }
                  return data;
                }}
              />
            </TabPane>
          ))}
        </TabContent>
      </>
    );
  }
}

class Report extends SharedComponent {
  state = {
    personOpen: 0,
  };

  text = () => {
    let text = `Report #${this.props.data.location} - `;

    if (this.props.data.persons && this.props.data.persons.length) {
      const peopleCount = this.props.data.persons.length;
      const criminalRecordCount = this.props.data.persons
        .map((p) => p.criminalRecords.length)
        .reduce((p, c) => p + c);
      console.log(criminalRecordCount);
      text += `${peopleCount} ${
        peopleCount === 1 ? "Person" : "People"
      } found with ${criminalRecordCount} Criminal Record${
        criminalRecordCount === 1 ? "" : "s"
      }`;
    } else {
      text += "No Criminal Records Found";
    }

    return text;
  };

  format = (type, data) => {
    if (type === "name")
      return `${data.firstName}${
        data.middleName ? ` ${data.middleName} ` : " "
      }${data.lastName}`;
    return `${data.address1}, ${data.city}, ${data.state} ${data.zipCode}`;
  };

  personName = (person) => {
    let name = person.names.find((n) => n.isPrimary);
    if (!name) name = person.names[0];

    return this.format("name", name);
  };

  personAddress = (person) => {
    if (!person.addresses || !person.addresses.length) return "No Address";
    let address = person.addresses.find((n) => n.isPrimary);
    if (!address) address = person.addresses[0];

    return this.format("address", address);
  };

  render() {
    const { data, onToggle, isOpen } = this.props;
    return (
      <div>
        <Button
          onClick={onToggle}
          className="mb-2"
          style={{ width: "100%", textAlign: "left" }}
          color={isOpen ? "dark" : "secondary"}
        >
          <div className="d-flex justify-content-between align-items-center my-2">
            <h4 className="m-0">{this.text()}</h4>
            <h4 className="m-0">
              Executed On {new Date(data.dateExecuted).toLocaleString()}
            </h4>
          </div>
        </Button>
        <Collapse isOpen={isOpen} className="p-3 bg-white">
          <h4>Details</h4>
          <KeyedTable data={data} />
          {data.persons && (
            <>
              <h4>People</h4>
              <Nav tabs>
                {data.persons.map((p, i) => (
                  <NavItem key={`pe-${i}`}>
                    <NavLink
                      className={i === this.state.personOpen ? "active" : ""}
                      onClick={() =>
                        this.setState({
                          personOpen: this.state.personOpen === i ? 0 : i,
                        })
                      }
                    >
                      {this.personName(p)} @ {this.personAddress(p)}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
              <TabContent activeTab={this.state.personOpen}>
                {data.persons.map((p, i) => (
                  <TabPane tabId={i} key={`tp-${i}`}>
                    <ReportPerson
                      data={p}
                      report={data}
                      isOpen={
                        data.persons.length === 1 || this.state.personOpen === i
                      }
                      onToggle={() =>
                        this.setState({
                          personOpen: this.state.personOpen === i ? false : i,
                        })
                      }
                    />
                  </TabPane>
                ))}
              </TabContent>
            </>
          )}
        </Collapse>
      </div>
    );
  }
}

class BackgroundChecks extends SharedComponent {
  state = {
    loading: true,
    disableButton: false,
    openReportId: null,
    reports: [],
  };

  componentWillMount() {
    Api["@driveavva"].core.UsersManagement.Reports(
      this.props.userId,
      "inflection_bg"
    )
      .then((r) => {
        console.log("Retrieve Reports", r.data);
        this.setState({
          loading: false,
          reports: r.data,
          openReportId: r.data.length === 1 ? r.data[0].key : null,
        });
      })
      .catch((e) => {
        console.log(e);
        this.renderErrorAlert(
          "Encountered an error loading reports",
          e.message
        );
      });
  }

  getAlertText = () => {
    let message = "";
    if (this.state.reports.length) {
      message += `You last ran a report on ${new Date(
        this.state.reports[0].dateExecuted
      ).toLocaleString()}. If you want to run another report press the button below.`;
    }
    message +=
      " This action will cost no more than $2.00 and be ran via Inflection";

    return message;
  };

  runReport = () =>
    new Promise((resolve, reject) => {
      this.setState({ disableButton: true });
      Api["@driveavva"].core.UsersManagement.RunReport(
        this.props.userId,
        "inflection_bg"
      ).then((result) => {
        console.log("Ran Report", result);
        this.setState({
          reports: [result.data, ...this.state.reports],
          disableButton: false,
        });
        resolve("Successfully received a background report");
      });
    });

  render() {
    return (
      <div>
        <div className="d-flex justify-content-between align-items-center my-2">
          <h3>Reports</h3>
          <Button
            color="primary"
            disabled={this.state.disableButton || this.state.loading}
            onClick={() =>
              this.renderConfirmAlert(this.getAlertText(), this.runReport)
            }
          >
            Request Background Check
          </Button>
        </div>
        {this.state.loading && <div>Loading...</div>}
        {!this.state.loading && this.state.reports.length === 0 && (
          <div>No Reports Found. Generate one with the button above</div>
        )}
        {!this.state.loading && this.state.reports.length !== 0 && (
          <div>
            {this.state.reports.map((report, i) => {
              const isOpen = this.state.openReportId === report.key;
              return (
                <Report
                  data={report}
                  key={`report-${i}`}
                  isOpen={isOpen}
                  onToggle={() =>
                    this.setState({ openReportId: isOpen ? null : report.key })
                  }
                />
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

export { BackgroundChecks };
