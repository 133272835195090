import React from "react";
import SharedComponent from "../../Shared";
import { Collapse } from "reactstrap";
import { AppApiEndpoints } from "../../../../Api/@driveavva/core/apps.api";
import ReactJson from "react-json-view";

export class PlaidAssetReports extends SharedComponent {
  state = {
    reports: [],
    open: false,
    loading: true,
  };
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    AppApiEndpoints.GetPlaidAssetReports(this.props.applicationId).then((r) => {
      console.info("Got Reports", r);
      this.setState({
        reports: r.data,
        loading: false,
      });
    });
  }

  render() {
    return (
      <div>
        <h3>Plaid Asset Reports</h3>
        {this.state.loading && <div>Loading...</div>}
        {!this.state.loading && this.state.reports.length === 0 && (
          <div>No Reports</div>
        )}
        {!this.state.loading && this.state.reports.length > 0 && (
          <div>
            {this.state.reports.map(({ report }) => (
              <>
                <h4
                  class="p-2 border pointer hover:bg-dark"
                  onClick={() =>
                    this.setState((e) => ({
                      open:
                        e.open === report.asset_report_id
                          ? false
                          : report.asset_report_id,
                    }))
                  }
                >
                  Asset Report #{report.asset_report_id}{" "}
                  {this.renderDate(report.date_generated)}
                </h4>
                <Collapse isOpen={report.asset_report_id === this.state.open}>
                  <ReactJson src={report} />
                </Collapse>
              </>
            ))}
          </div>
        )}
      </div>
    );
  }
}
