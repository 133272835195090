import React from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
  Label,
  Badge,
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  ListGroup,
  ListGroupItem,
  Collapse,
} from "reactstrap";
import SharedComponent from "../../Shared";
import { SvgIconsDropdown } from "../SvgIconsDropdown/SvgIconsDropdown";
import DateTimeSelector from "../DateTimePicker/DateTimeSelector";
import { cleanPayload, isValidUrl } from "../utils";
import { Api } from "../../../../Api/index";
import { displayColor } from "../utils";

/**
 * The map of the ScreenTypes found in native/src/@types/ScreenType.ts
 */
const APP_PAGE_ACTION_URLS = [
  "Dashboard",
  "Profile",
  "YourInformation",
  "PaymentHistory",
  "MaintenanceAndService",
  "Settings",
  "Rewards",
  "RewardSuccess",
  "FAQs",
];
export class BannerModal extends SharedComponent {
  state = {
    statusDropdownOpen: false,
    icon: "",
    external: false,
  };

  handleBannerIconChange = (name) => {
    this.setState({ ...this.state, icon: name });
  };

  // banners
  handleBannerDateTimeChange = (dateTime) => {
    this.setState({ ...this.state, dateTime: dateTime.toString() });
  };

  handleBannerActionUrlChange = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      action_url: e.target.value.substring(0, 60),
    });
  };

  handleBannerActionTextChange = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      action_text: e.target.value.substring(0, 60),
    });
  };

  handleBannerStartDateTimeChange = (dateTime) => {
    this.setState({ ...this.state, start_date: dateTime.toString() });
  };

  handleBannerEndDateTimeChange = (dateTime) => {
    this.setState({ ...this.state, end_date: dateTime.toString() });
  };

  handleBannerMessageChange = (e) => {
    e.preventDefault();
    this.setState({ ...this.state, message: e.target.value.substring(0, 60) });
  };

  toggleStatusDropdown = (e) => {
    e.preventDefault();
    this.setState({
      statusDropdownOpen: !this.state.statusDropdownOpen,
    });
  };

  handleStatusClick = (e) => {
    this.setState({ statusMsg: e.target.innerText.toUpperCase() });
  };

  resetBannerModal = () => {
    this.setState({
      statusMsg: null,
      isOpen: true,
      message: "",
      message_error: false,
      action_url: "",
      action_url_error: false,
      action_text: "",
      action_text_error: false,
      start_date: "",
      end_date: "",
      statusDropdownOpen: false,
      icon: "",
    });
  };

  sendBanner = (e) => {
    e.preventDefault();

    const {
      icon,
      action_url,
      action_text,
      start_date,
      end_date,
      message,
      statusMsg,
      external,
    } = this.state;
    const newState = {};

    newState.message_error = !message || message === "";
    newState.action_url_error =
      action_text && action_url && external && !isValidUrl(action_url);
    newState.action_text_error =
      action_url && (!action_text || action_text === "");

    if (Object.keys(newState).find((k) => newState[k])) {
      console.info(`Found Error(s)`, newState);
      this.setState({ ...newState });
      return;
    }

    console.log(start_date);

    const dirtyObj = {
      icon: icon ? icon : null,
      action_url:
        !action_url || action_url === ""
          ? null
          : external
          ? action_url
          : // Handle special case for app pages
            // See native/src/components/Banners/components/Banner/Banner.tsx
            `goto:${action_url}`,
      action_text: !action_text || action_text === "" ? null : action_text,
      start_date:
        !start_date || start_date === ""
          ? null
          : new Date(start_date).toUTCString(),
      end_date:
        !end_date || end_date === "" ? null : new Date(end_date).toUTCString(),
      content: message === "" ? null : message,
      user_array: this.props.user_id ? [this.props.user_id] : null,
      type: statusMsg ? statusMsg : null,
    };

    const payload = cleanPayload(dirtyObj);

    Api["@driveavva"].new.Users.Send("Banner", payload, this.props.user_id)
      .then(() => {
        this.renderSuccessAlert(
          "Banner sent!",
          "Please allow up to 5 seconds for banner to display in the user's app"
        );
        this.props.onClose();
        this.resetBannerModal();
      })
      .catch((e) => {
        console.log(e);
        this.renderErrorAlert(
          "Oops!",
          "Something went wrong, please try again!"
        );
      });
  };

  componentDidUpdate() {}

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.onClose}>
        <ModalHeader toggle={this.props.onClose}>Banners</ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label className={this.state.message_error ? "error" : ""}>
                    Message *
                  </Label>
                  <Input
                    type="textarea"
                    name="banner_message"
                    id="banner_message"
                    required
                    className={this.state.message_error ? "error" : ""}
                    value={this.state.message}
                    onChange={this.handleBannerMessageChange}
                  />
                  {this.state.message_error ? (
                    <span className="error">Required Field</span>
                  ) : null}
                  <br />

                  <div className="display-icon-selection">
                    <SvgIconsDropdown
                      icon_channel={this.handleBannerIconChange}
                    />
                  </div>

                  <Label
                    className={this.state.action_text_error ? "error" : ""}
                  >
                    Action Text
                  </Label>
                  <Input
                    type="textarea"
                    name="banner_action_text"
                    id="banner_action_text"
                    className={this.state.action_text_error ? "error" : ""}
                    value={this.state.action_text}
                    onChange={this.handleBannerActionTextChange}
                  />
                  {this.state.action_text_error ? (
                    <span className="error">Required Field</span>
                  ) : null}

                  {this.state.action_text && (
                    <>
                      <FormGroup>
                        <FormGroup>
                          <div className="checkbox c-checkbox">
                            <label>
                              <input
                                type="checkbox"
                                onChange={() =>
                                  this.setState((s) => ({
                                    ...s,
                                    external: !s.external,
                                  }))
                                }
                                checked={this.state.external}
                              />
                              <span className="fa fa-check"></span>External
                              Link?
                            </label>
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <Label
                            className={
                              this.state.action_url_error ? "error" : ""
                            }
                          >
                            Action Url
                          </Label>
                          {this.state.external ? (
                            <Input
                              type="url"
                              className={
                                this.state.action_url_error ? "error" : ""
                              }
                              placeholder="https://example.com"
                              name="banner_action_url"
                              id="banner_action_url"
                              value={this.state.action_url}
                              onChange={this.handleBannerActionUrlChange}
                            />
                          ) : (
                            <Input
                              type="select"
                              className={
                                this.state.action_url_error ? "error" : ""
                              }
                              name="banner_action_url"
                              id="banner_action_url"
                              value={this.state.action_url}
                              onChange={this.handleBannerActionUrlChange}
                            >
                              {APP_PAGE_ACTION_URLS.map((page) => (
                                <option key={page} value={page}>
                                  {page}
                                </option>
                              ))}
                            </Input>
                          )}

                          {this.state.action_url_error ? (
                            <span className="error">Invalid URL</span>
                          ) : null}
                        </FormGroup>
                      </FormGroup>
                    </>
                  )}
                  <br />

                  <DateTimeSelector
                    dt_channel={this.handleBannerStartDateTimeChange}
                    label={"Start Time"}
                  />
                  <DateTimeSelector
                    dt_channel={this.handleBannerEndDateTimeChange}
                    label={"End Time"}
                  />
                  <br />
                  <ButtonDropdown
                    isOpen={this.state.statusDropdownOpen}
                    className="mr-2"
                    toggle={this.toggleStatusDropdown}
                  >
                    <DropdownToggle caret color="info">
                      Status
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>
                        <Badge
                          color="primary"
                          pills="true"
                          onClick={this.handleStatusClick}
                        >
                          OK
                        </Badge>
                      </DropdownItem>
                      <DropdownItem>
                        <Badge
                          color="warning"
                          pills="true"
                          onClick={this.handleStatusClick}
                        >
                          WARNING
                        </Badge>
                      </DropdownItem>
                      <DropdownItem>
                        <Badge
                          color="success"
                          pills="true"
                          onClick={this.handleStatusClick}
                        >
                          SUCCESS
                        </Badge>
                      </DropdownItem>
                      <DropdownItem>
                        <Badge
                          color="danger"
                          pills="true"
                          onClick={this.handleStatusClick}
                        >
                          DANGER
                        </Badge>
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                  {this.state.statusMsg ? (
                    <Badge
                      pills="true"
                      color={displayColor(this.state.statusMsg)}
                    >
                      {this.state.statusMsg}
                    </Badge>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex align-items-center justify-content-between">
          <div>
            <Button className="mr-1" color="info" onClick={this.sendBanner}>
              Submit
            </Button>
            <Button color="secondary" onClick={this.props.onClose}>
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}
