import { getDataFromResponse, getAuthenticatedAxios } from "./helpers";
import api from "./apiEndPoint";

export const ClientApiEndpoints = {
  ClientList: (SearchTerm, page, pageSize, currentPage) => {
    let url = new URL(`${api.clients}`);
    if (SearchTerm) url.searchParams.append("SearchTerm", SearchTerm);
    url.searchParams.append("Page", page || currentPage);
    url.searchParams.append("PageSize", pageSize);
    return getAuthenticatedAxios()
      .get(`${url}`)
      .then(getDataFromResponse);
  },
  ClientDetails: (ClientId) =>
    getAuthenticatedAxios()
      .get(`${api.clients}/${ClientId}`)
      .then(getDataFromResponse),

  UpdateClient: (ClientId, data) =>
    getAuthenticatedAxios()
      .put(`${api.clients}/${ClientId}`, data)
      .then(getDataFromResponse),
};
