import React, { Component } from 'react'
import { Form, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, ListGroup, ListGroupItem, ListGroupItemHeading } from "reactstrap";
import { Api } from "../../../Api/index";

export default class SeedModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      total: 0,
      wipe: false,
      demo: false,
      res: undefined
    }
  }

  render() {
    return this.state.res ? this.state.res === 'error' ? (
      <Modal isOpen={this.props.isOpen} toggle={this.props.onToggleModal}>
        <ModalHeader toggle={this.props.onToggleModal}>{this.props.title}</ModalHeader>
        <ModalBody>
          <h3>Oops!</h3>
          <p>Looks like we encountered an error creating those seeds.</p>
        </ModalBody>
        <ModalFooter className="d-flex align-items-center justify-content-between">
          <div>
            <Button className="mr-1" color="info" onClick={this.props.onToggleModal}>
              Close
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    ) : (
      <Modal isOpen={this.props.isOpen} toggle={this.props.onToggleModal}>
        <ModalHeader toggle={this.props.onToggleModal}>{this.props.title}</ModalHeader>
        <ModalBody>
          <h3>Success!</h3>
          <ListGroup>
            <ListGroupItemHeading>Results</ListGroupItemHeading>
            <ListGroupItem>Took {this.state.res.durationInMS}ms</ListGroupItem>
            { this.state.res.numberOfCarsAdded ? <ListGroupItem>Created {this.state.res.numberOfCarsAdded} Cars</ListGroupItem> : null}
            { this.state.res.NumberOfUsersAdded ?<ListGroupItem>Created {this.state.res.NumberOfUsersAdded} Users</ListGroupItem> : null}
            {/* <ListGroupItem>Created {this.state.res.numberOfCars} Cars</ListGroupItem> */}
          </ListGroup>
        </ModalBody>
        <ModalFooter className="d-flex align-items-center justify-content-between">
          <div>
            <Button className="mr-1" color="info" onClick={this.props.onToggleModal}>
              Close
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    ) : (
      <Modal isOpen={this.props.isOpen} toggle={ this.props.onToggleModal } >
        <ModalHeader toggle={this.props.onToggleModal}>{this.props.title}</ModalHeader>
        <ModalBody>
          <Form>
            {!this.state.demo && <Label for="count">How many Cars would you like to add?</Label>}
            {!this.state.demo && <Input type="number" min="1" max="2000" name="count" onChange={(e) => this.setState({ total: parseInt(e.target.value) })} />}
            <Label check className="mt-3">
              <Input type="checkbox" name="overwrite" onChange={(e) => this.setState(e => ({ wipe: !e.wipe})) } />{' '}
              Remove all cars not assigned to an application before seeding?
            </Label>
            <Label check className="mt-3">
              <Input type="checkbox" name="demo" onChange={(e) => this.setState(e => ({ demo: !e.demo }))} />{' '}
              Use the AVVA wrapped cars only?
            </Label>
          </Form>
        </ModalBody>
        <ModalFooter className="d-flex align-items-center justify-content-between">
          <div>
            <Button className="mr-1" color="info" disabled={this.state.loading} onClick={() => {
                this.setState({ loading: true })
                Api.Seed(this.state.wipe, this.state.total, this.state.demo).then((res) => {
                  console.log('[Seed] Done', res);
                  this.setState({ loading: false, res: res.data })
                }).catch(e => {
                  this.setState({ res: 'error', loading: false});
                  console.warn(e);
                })
              }}>
                  {this.state.loading ? 'Importing...' : 'Import' }
            </Button>
              <Button color="secondary" disabled={this.state.loading} onClick={this.props.onToggleModal}>
                  Cancel
          </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}