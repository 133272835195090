import React from "react";
import { Badge, Card, CardBody } from "reactstrap";
import SharedComponent from "../../Shared";
import Datatable from "../../../Tables/Datatable";
import { formatFullDate, displayColor } from "../utils";

class Logins extends SharedComponent {
  render() {
    return (
      <div>
        <h3>Logins</h3>
        <Card className="card-default no-border">
          <CardBody>
            {this.props.logins && this.props.logins.length > 0 ? (
              <Datatable options={this.renderOption()}>
                <table className="table table-striped table-bordered table-hover mb-4">
                  <thead>
                    <tr>
                      <th className="sort-alpha">Status</th>
                      <th className="sort-alpha">Type</th>
                      <th className="sort-alpha">Time</th>
                      <th className="sort-alpha">Location</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.logins.map((login, index) => (
                      <tr key={`user_login_${index}`}>
                        <td>
                          {login.status_type ? (
                            <Badge
                              pills="true"
                              color={displayColor(login.status_type)}
                            >
                              {login.status_type}
                            </Badge>
                          ) : null}
                          {login.reason || ""}
                        </td>
                        <td>{login.type}</td>
                        <td>
                          {new Date(login.created_at + "Z").toLocaleString()}
                        </td>
                        <td>
                          {login.request.city} {login.request.postal_code}
                          {login.request.metro_code
                            ? "-" + login.request.metro_code
                            : ""}{" "}
                          {login.request.state} @ {login.request.lat},
                          {login.request.lng}
                          <br></br>
                          {login.request.ip}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Datatable>
            ) : (
              <p>No logins</p>
            )}
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default Logins;
