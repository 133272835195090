import { getAuthenticatedAxios } from "../../helpers";
import api from "../../apiEndPoint";
import { getAndDecryptDataFromResponse } from ".";

const BODY_STYLE_ALTERNATIVE_NAME_WRANGLER = {
  pickup: ["crewcabpickup", "extendedcabpickup", "truck"],
  van: ["passengervan", "cargovan"],
  suv: ["suv"],
  sedan: ["sedan"],
};
const BODY_STYLE_ALERNATIVE_NAME_WRANGLER_ITERATOR = Object.keys(
  BODY_STYLE_ALTERNATIVE_NAME_WRANGLER
);

const convertBodyStyle = (style) => {
  if (!style) return "sedan";
  const sanatized = style.toLowerCase().replace(" ", "");
  const maybeAlt = BODY_STYLE_ALERNATIVE_NAME_WRANGLER_ITERATOR.find((type) =>
    BODY_STYLE_ALTERNATIVE_NAME_WRANGLER[type].includes(sanatized)
  );
  return maybeAlt || "sedan";
};

export const map = (d) => {
  const payload = {};
  payload.status = d.delivered
    ? "delivered"
    : !d.vin
    ? "comingsoon"
    : "available";
  payload.vin = d.vin ? d.vin.toUpperCase() : null;
  payload.make = d.make.toUpperCase();
  payload.model = d.model.toUpperCase();
  payload.year = d.year;
  payload.body_style = convertBodyStyle(d.bodyStyle);
  payload.monthly_payment = Math.floor(d.monthlyPayment * 100);
  payload.mileage = d.mileage;
  payload.is_public = !d.hideFromSearch;
  payload.depricated_id = d.id;
  payload.trim = d.trim ? d.trim.toUpperCase() : null;
  payload.color = d.exteriorColor ? d.exteriorColor.toUpperCase() : null;
  payload.photo_url = d.photos && d.photos.length ? d.photos[0] : null;
  // Added for syncing to Hubspot for auto member agreement generation
  // payload.adjusted_capitalized_cost =
  //   Math.floor(d.adjustedCapitalizedCost * 100) || 0;
  // payload.capitalized_cost_reduction =
  //   Math.floor(d.capitalizedCostReduction * 100) || 0;
  // payload.depreciation = Math.floor(d.depreciation * 100) || 0;
  // payload.gross_capitalized_cost =
  //   Math.floor(d.grossCapitalizedCost * 100) || 0;
  // payload.monthly_sales_tax = Math.floor(d.monthlySales * 100) || 0;
  // payload.rent_charge =
  //   Math.floor(d.rentChargeOverDepreciationCharged * 100) || 0;
  // payload.residual_value = Math.floor(d.vehicleResidualValue * 100) || 0;
  // payload.total_payments = Math.floor(d.totalPaymentsOverLease * 100) || 0;

  console.log("Mapped", { d, payload });

  return payload;
};

export const AddOrUpdate = (id, data, shouldMap = true) =>
  getAuthenticatedAxios(true)
    .patch(
      `${api["@driveavva"].core.Vehicles}/${id}`,
      shouldMap ? map(data) : data
    )
    .then(getAndDecryptDataFromResponse);
export const BulkAdd = (data) =>
  getAuthenticatedAxios(true)
    .post(`${api["@driveavva"].core.Vehicles}`, data.map(map))
    .then(getAndDecryptDataFromResponse);
export const Remove = (id) =>
  getAuthenticatedAxios(true)
    .delete(`${api["@driveavva"].core.Vehicles}/${id}`)
    .then(getAndDecryptDataFromResponse);
export const VehicleApiEndpoints = {
  AddOrUpdate,
  BulkAdd,
  Remove,
};
