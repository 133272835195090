import { getDataFromResponse, getAuthenticatedAxios } from "./helpers";
import api from "./apiEndPoint";

export const LookUpsApiEndpoints = {
  Lookups: (type, value) => {
    let url = new URL(`${api.carLookUps}/${type}`);
    if (value) url.searchParams.append("Make", value);
    return getAuthenticatedAxios()
      .get(url)
      .then(getDataFromResponse);
  },
};
