import React from "react";
import {
  Row,
  Col,
  TabPane,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Joi from "joi-browser";
import Pagination from "rc-pagination";
import Moment from "react-moment";
import { Api } from "../../../Api/index";
import SharedComponent from "../Shared";
class LongTrips extends SharedComponent {
  state = {
    dropdownOpen: false,
    activeTab: "ScheduledM",
    inputVal: this.props.inputValue,
    isLoading: true,
    data: {
      id: 0,
      applicationId: "",
      comment: "",
      CreatedAt: "",
      StartedDate: "",
      EndDate: "",
      distance: 0,
      startDate: "",
      endDate: "",
      status: "",
      Status: "",
    },
    items: [],
    requestOptions: {
      currentPage: 1,
      totalPages: 1,
      totalItems: 0,
      pageSize: 20,
    },
    modal: false,
    viewModel: false,
    longTripId: null,
    Status: [{ name: "New" }, { name: "Active" }, { name: "Closed" }],
  };
  schema = {
    ApplicationId: Joi.label("ApplicationId"),
    CreatedAt: Joi.label("CreatedAt"),
  };
  async componentDidMount() {
    this.queryItems();
  }
  doSubmit = () => {
    this.queryItems(1);
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.doSubmit();
  };

  render() {
    const { isLoading, items, data, Status } = this.state;
    const { currentPage, pageSize, totalItems } = this.state.requestOptions;
    return (
      <TabPane tabId="LongTrips">
        <Form className="mb-3 mt-3" onSubmit={this.handleSubmit}>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="ApplicationId">Application Id</Label>
                <Input
                  type="text"
                  name="ApplicationId"
                  id="ApplicationId"
                  onChange={this.handleChange}
                  value={data.ApplicationNumber}
                />
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label for="CreatedAt">Created At</Label>
                <Input
                  type="date"
                  name="CreatedAt"
                  id="CreatedAt"
                  onChange={this.handleChange}
                  value={data.CreatedAt}
                />
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label for="StartedDate">Started Date</Label>
                <Input
                  type="date"
                  name="StartedDate"
                  id="StartedDate"
                  onChange={this.handleChange}
                  value={data.StartedDate}
                />
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label for="EndDate">End Date</Label>
                <Input
                  type="date"
                  name="EndDate"
                  id="EndDate"
                  onChange={this.handleChange}
                  value={data.EndDate}
                />
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label for="Status">Status</Label>
                <Input
                  type="select"
                  name="Status"
                  id="Status"
                  value={data.Status}
                  onChange={this.handleChange}
                >
                  <option selected>Select Please</option>
                  {Status.map((option) => (
                    <option key={option.name} value={option.name}>
                      {option.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <div
                  style={{ height: 30 }}
                  className="d-none d-md-block d-lg-block"
                ></div>
                <Button type="submit" color={"info"} className="w-100">
                  Search
                </Button>
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <div
                  style={{ height: 30 }}
                  className="d-none d-md-block d-lg-block"
                ></div>
                <Button
                  color={"success"}
                  onClick={this.resetData}
                  className="w-100"
                >
                  Reset Data
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </Form>

        {!isLoading ? (
          <React.Fragment>
            {items.length !== 0 ? (
              <React.Fragment>
                <Row>
                  <Col md={12}>
                    <Table className="table table-bordered table-striped table-hover mb-4">
                      <thead>
                        <tr>
                          <th className="sort-alpha">Application ID</th>
                          <th className="sort-alpha">Client Name</th>
                          <th className="sort-alpha">Client Phone</th>
                          <th className="sort-alpha">Car Make</th>
                          <th className="sort-alpha">Car Model</th>
                          <th className="sort-alpha">Date</th>
                          <th className="sort-alpha">Distance</th>
                          <th className="sort-alpha">Comment</th>
                          <th className="sort-alpha">Status</th>
                          <th className="sort-alpha">Control</th>
                        </tr>
                      </thead>
                      <tbody>
                        {items.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.applicationId}</td>
                              <td>{item.clientName}</td>
                              <td>{item.clientPhone}</td>
                              <td>{item.carMake}</td>
                              <td>{item.carModel}</td>
                              <td>
                                <Moment format="MM/DD/YYYY">
                                  {item.createdAt}
                                </Moment>
                              </td>
                              <td>{item.distance}</td>
                              <td>{item.comment}</td>
                              <td>
                                <div className="badge badge-success ">
                                  {item.status}
                                </div>
                              </td>
                              <td className="text-center">
                                <div>
                                  <Button
                                    onClick={() => this.openModel(item.id)}
                                    color="warning"
                                    className="btn d-inline-flex align-items-center mr-1"
                                  >
                                    Details
                                  </Button>
                                  {item.status === "New" && (
                                    <>
                                      <Button
                                        onClick={() =>
                                          this.handleApproveOrClose(
                                            item.id,
                                            "approve"
                                          )
                                        }
                                        color="success"
                                        className="btn  d-inline-flex align-items-center mr-1"
                                      >
                                        Approve
                                      </Button>
                                      <Button
                                        onClick={() =>
                                          this.handleApproveOrClose(
                                            item.id,
                                            "close"
                                          )
                                        }
                                        color="danger"
                                        className="btn  d-inline-flex align-items-center mr-1"
                                      >
                                        Reject
                                      </Button>
                                    </>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Pagination
                      showSizeChanger
                      defaultPageSize={pageSize}
                      defaultCurrent={currentPage}
                      onChange={this.handlePageChange}
                      total={totalItems}
                      className="float-right text-white"
                    />
                  </Col>
                </Row>
              </React.Fragment>
            ) : (
              this.renderSadFace(true)
            )}
          </React.Fragment>
        ) : (
          this.renderLoader()
        )}

        <Modal isOpen={this.state.viewModel} toggle={this.closeViewModel}>
          <ModalHeader toggle={this.toggleModal}>Edit Long Trip</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label>Distance</Label>
                <Input
                  type="text"
                  name="distance"
                  onChange={this.handleChange}
                  value={data.distance}
                />
              </FormGroup>
              <FormGroup>
                <Label>Created At</Label>

                <Input
                  type="date"
                  name="createdAt"
                  onChange={this.handleChange}
                  value={data.createdAt ? data.createdAt.split("T")[0] : ""}
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <Label>Stared Date</Label>

                <Input
                  type="date"
                  name="startDate"
                  onChange={this.handleChange}
                  value={data.startDate ? data.startDate.split("T")[0] : ""}
                />
              </FormGroup>

              <FormGroup>
                <Label>End Date</Label>
                <Input
                  type="date"
                  name="endDate"
                  onChange={this.handleChange}
                  value={data.endDate.split("T")[0]}
                />
              </FormGroup>
              <FormGroup>
                <Label>Comment</Label>
                <Input
                  type="textarea"
                  name="comment"
                  onChange={this.handleChange}
                  value={data.comment}
                  rows={5}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={this.updateLongTrip}>
              Update
            </Button>
            <Button color="secondary" onClick={this.closeViewModel}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </TabPane>
    );
  }
  updateLongTrip = () => {
    const { data, longTripId } = this.state;
    Api.Notifications.LongTrips.UpdateLongTrip(longTripId, {
      distance: Number(data.distance),
      startDate: data.startDate,
      endDate: data.endDate,
      comment: data.comment,
    })
      .then(() => {
        this.setState({ viewModel: false });
        this.renderSuccessAlert("Update successfully", "Click the button");
        this.queryItems();
      })
      .catch((error) => {
        this.renderErrorAlert("Ops!", `${error.response.data.Message}`);
      });
  };

  openModel = (id) => {
    this.setState({ viewModel: true, longTripId: id });
    Api.Notifications.LongTrips.LongTripDetails(id)
      .then((data) => this.setState({ data }))
      .catch((error) =>
        this.renderErrorAlert("Ops!", `${error.response.data.Message}`)
      );
  };

  closeViewModel = () => {
    this.setState({ viewModel: false });
  };

  queryItems = (page) => {
    this.setState({
      isLoading: true,
    });

    const { pageSize, currentPage } = this.state.requestOptions;
    const {
      ApplicationId,
      CreatedAt,
      Status,
      StartedDate,
      EndDate,
    } = this.state.data;
    Api.Notifications.LongTrips.LongTripsList(
      ApplicationId,
      CreatedAt,
      page,
      currentPage,
      pageSize,
      Status,
      StartedDate,
      EndDate
    )
      .then((response) => {
        let items = response.items;
        this.setState({
          isLoading: false,
          items,
          requestOptions: {
            currentPage: response.currentPage,
            totalPages: response.totalPages,
            totalItems: response.totalItems,
            pageSize: response.pageSize,
          },
        });
      })
      .catch((error) => this.setState({ isLoading: false }));
  };
  doSubmit = () => {
    this.queryItems(1);
  };
  resetData = () => {
    const requestOptions = this.state.requestOptions;
    const data = this.state.data;
    requestOptions.currentPage = 1;
    requestOptions.totalPages = 1;
    requestOptions.totalItems = 0;
    requestOptions.pageSize = 20;
    data.ApplicationId = "";
    data.CreatedAt = "";
    data.StartedDate = "";
    data.EndDate = "";
    data.Status = "";
    this.setState({ requestOptions, data });
    this.queryItems();
  };
  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };

  handleApproveOrClose = (id, Case) => {
    Api.Notifications.LongTrips.ApproveOrCloseLongTrip(id, Case)
      .then(() => {
        this.renderSuccessAlert(
          Case === "approve"
            ? "The request is approved"
            : "The request is close",
          "Click the button"
        );
        this.queryItems();
      })
      .catch((error) => {
        this.renderErrorAlert("Ops!", `${error.response.data.Message}`);
      });
  };
}

export default LongTrips;
