import React from "react";
import {
  TabPane,
  Input,
  Col,
  Row,
  Button,
  Badge,
  ListGroup,
  ListGroupItem,
  Form,
  FormGroup,
} from "reactstrap";
import Joi from "joi-browser";
import Moment from "react-moment";
import { withRouter } from "react-router-dom";
import SharedComponent from "../Shared";
import { Api } from "../../../Api/index";
class Notes extends SharedComponent {
  state = {
    data: {},
    sentToClient: false,
    errors: {},
    items: [],
  };
  schema = {
    notes: Joi.required().label("Notes"),
  };

  componentDidMount() {
    this.NotesList();
  }

  render() {
    const { data, sentToClient, errors, items } = this.state;
    return (
      <TabPane tabId="Notes">
        <Row>
          <Col md={12} className="mt-3">
            <h3 className="mb-0">New Note</h3>
            <hr className="w-25 float-left" />
          </Col>
        </Row>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col md={12}>
              <FormGroup>
                <label>Notes</label>
                <Input
                  type="textarea"
                  name="notes"
                  id="notes"
                  rows="4"
                  onChange={this.handleChange}
                  value={data.notes}
                />
                {errors && (
                  <span className="text-danger small">{errors.notes}</span>
                )}
              </FormGroup>
            </Col>
            <Col md={12}>
              <div>
                <label>
                  <input
                    type="checkbox"
                    onChange={this.handleCheckboxChange}
                    checked={sentToClient}
                  />
                   Send to client
                </label>
              </div>
            </Col>
          </Row>
          <Button color="info" type="submit" className="float-right">
            Submit
          </Button>
          <div className="clearfix"></div>
        </Form>

        <Row>
          <Col xs={12}>
            <h3 className="mb-0">Notes History</h3>
            <hr className="w-25 float-left" />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            {items.length !== 0 ? (
              <ListGroup className="mb-3 w-50">
                {items.map((item, index) => (
                  <ListGroupItem
                    key={index}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <div>
                      <h6 className="mb-2">{item.adminName}</h6>
                      <Badge color="info">{item.notes}</Badge>
                    </div>
                    <div className={"mt-3"}>
                      <h6 className="mb-2">
                        <Moment format="MM/DD/YYYY">{item.createdAt}</Moment>
                      </h6>
                      Sent to client:
                      <Badge color="success">
                        {item.sentToClient ? (
                          <span>True</span>
                        ) : (
                          <span>False</span>
                        )}
                      </Badge>
                    </div>
                  </ListGroupItem>
                ))}
              </ListGroup>
            ) : (
              this.renderSadFace(false)
            )}
          </Col>
        </Row>
      </TabPane>
    );
  }
  NotesList = () => {
    let applicationId = this.props.id;
    Api.ApplicationManagement.ApplicationNoteList(applicationId)
      .then((items) => {
        this.setState({ items });
      })
      .catch(console.log);
  };

  doSubmit = () => {
    const {
      data: { notes },
      sentToClient,
    } = this.state;
    let applicationId = this.props.id;
    let note = {
      notes,
      sentToClient,
      applicationId,
    };
    Api.ApplicationManagement.AddApplicationNote(note)
      .then(() => {
        this.NotesList();
        this.state.data.notes = "";
      })
      .catch(console.log);
  };
  handleCheckboxChange = (e) => {
    this.setState({ sentToClient: e.target.checked });
  };
}

export default withRouter(Notes);
