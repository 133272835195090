import React from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ListGroup,
  ListGroupItem,
  Card,
  CardBody,
  CardFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import SharedComponent from "../../Shared";

export class SupaModal extends SharedComponent {
  state = {
    formData: null,
    activeTab: "add",
    modifyData: null,
    submission: null,
    performing: false,
    activeData: null,
  };

  renderDeleteConfirm(data, i) {
    return this.renderConfirmAlert(
      `Are you sure you want to delete ${this.props.name} #${data.id}?`,
      async () => {
        this.setState({ performing: "delete" });
        await this.props.onDelete(data, i);
        this.setState({ performing: false });
      }
    );
  }

  updateData(key, value) {
    this.setState({
      submission: {
        ...this.state.submission,
        [key]: value,
      },
    });
  }

  render() {
    const hasExisting =
      this.props.data &&
      Array.isArray(this.props.data) &&
      !!this.props.data.length;
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.onClose}>
        <ModalHeader>{this.props.name}</ModalHeader>
        <ModalBody>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={this.state.activeTab === "add" ? "active" : ""}
                onClick={() => this.setState({ activeTab: "add" })}
                style={{ cursor: "pointer" }}
              >
                Add
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={this.state.activeTab === "view" ? "active" : ""}
                style={{ cursor: !hasExisting ? "not-allowed" : "pointer" }}
                onClick={() =>
                  hasExisting && this.setState({ activeTab: "view" })
                }
                disabled={!hasExisting}
              >
                View
                {hasExisting && ` (${this.props.data.length})`}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="add">
              {this.props.renderForm(this.updateData)}
            </TabPane>
            <TabPane tabId="edit">
              <Button
                className="mb-4"
                disabled={this.state.performing}
                onClick={() => this.setState({ activeTab: "view" })}
              >
                {"< "}Back
              </Button>
              {this.props.renderForm(this.updateData, this.state.submission)}
            </TabPane>
            <TabPane tabId="view">
              {hasExisting &&
                this.props.data.map((data, i) => (
                  <Card className="mb-2">
                    <CardBody
                      style={{ cursor: "pointer" }}
                      onClick={() => this.setState({ activeData: i })}
                    >
                      {this.props.renderItem(data, i)}
                    </CardBody>
                    {this.state.activeData === i && (
                      <CardFooter>
                        <Button
                          color="info"
                          onClick={() =>
                            this.setState({
                              activeTab: "edit",
                              submission: data,
                            })
                          }
                          disabled={this.state.performing}
                        >
                          Edit
                        </Button>
                        <Button
                          color="danger"
                          onClick={() => this.renderDeleteConfirm(data, i)}
                          disabled={this.state.performing}
                        >
                          Delete
                        </Button>
                      </CardFooter>
                    )}
                  </Card>
                ))}
            </TabPane>
          </TabContent>
        </ModalBody>
        <ModalFooter>
          {this.state.activeTab === "add" && (
            <Button
              color="info"
              onClick={() => this.props.onAdd(this.state.submission)}
              disabled={this.state.performing}
            >
              Add{this.state.performing === "add" ? "ing" : ""}
            </Button>
          )}
          {this.state.activeTab === "update" && (
            <Button
              color="info"
              onClick={() => this.props.onUpdate(this.state.submission)}
              disabled={this.state.performing}
            >
              Updat{this.state.performing === "update" ? "ing" : "e"}
            </Button>
          )}
          <Button
            color="secondary"
            onClick={() => this.props.onClose()}
            disabled={this.state.performing}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
