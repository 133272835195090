import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ListGroup,
  ListGroupItem,
  Button,
  Collapse,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { BannerModal } from "./BannerModal";
import SharedComponent from "../../Shared";
import { Api } from "../../../../Api/index";
import { displayColor } from "../utils";

export class ActiveBannersModal extends SharedComponent {
  state = {
    loading: true,
    openActions: false,
    editting: false,
    deleting: false,
    showModal: false,
    banner: null,
    section: "active",
    active: [],
    upcoming: [],
  };

  deleteBanner = (id) => {
    this.setState({ deleting: true, openActions: false });
    Api["@driveavva"].new.Users.DeleteBanner(id)
      .then(() => {
        this.getBanners();
        this.renderSuccessAlert("Deleted Banner!");
        this.setState({ deleting: false });
      })
      .catch((e) => {
        this.renderErrorAlert("Couldnt delete banner", e.message);
        this.setState({ deleting: false });
      });
  };

  getBanners = () => {
    Api["@driveavva"].new.Users.Banners(this.props.userId)
      .then((banners) => {
        console.info(`Got ${banners.length} banners`, banners);
        const now = new Date();
        const active = [];
        const upcoming = [];
        for (let i = banners.length - 1; i >= 0; i--) {
          const b = banners[i];
          b.start_date = b.start_date + ".000Z";
          b.end_date = b.end_date + ".000Z";
          if (
            (!b.start_date || new Date(b.start_date) < now) &&
            (!b.end_date || new Date(b.end_date) > now)
          ) {
            active.push(b);
          } else {
            upcoming.push(b);
          }
        }
        this.setState({ loading: false, active, upcoming });
      })
      .catch(() => {
        this.renderErrorAlert(
          "Oops!",
          "Something went wrong, not able to get Notifications!"
        );
      });
  };

  renderSection = (type) => (
    <ListGroupItem>
      <div className={`d-flex justify-content-between align-items-center mb-2`}>
        <h6>
          {type} ({this.state[type].length})
        </h6>
        {this.state[type].length > 0 && (
          <Button
            onClick={() =>
              this.setState({
                section: this.state.section === type ? "" : type,
              })
            }
          >
            {this.state.section === type ? "Close" : "View"}
          </Button>
        )}
      </div>
      <Collapse isOpen={this.state.section === type}>
        <ListGroup>
          {this.state[type].map((b) => (
            <ListGroupItem key={b.id} className="position-relative p-0">
              <div
                style={{ opacity: 0.5, zIndex: 1 }}
                className={`position-absolute top-0 left-0 w-100 h-100 rounded bg-${displayColor(
                  b.type
                )}`}
              ></div>
              <div
                className={`d-flex justify-content-between align-items-center p-4 position-relative`}
                style={{ zIndex: 5 }}
              >
                <div>
                  <h4>{b.content}</h4>
                  <i>
                    {b.start_date &&
                      `${new Date(b.start_date).toLocaleString()}-${
                        b.end_date
                          ? new Date(b.end_date).toLocaleString()
                          : "na"
                      }`}
                  </i>
                </div>
                <ButtonDropdown
                  isOpen={this.state.openActions === b.id}
                  toggle={() =>
                    this.setState({
                      openActions:
                        this.state.openActions === b.id ? false : b.id,
                    })
                  }
                >
                  <DropdownToggle caret color="primary">
                    Actions
                  </DropdownToggle>
                  <DropdownMenu>
                    {/* <DropdownItem
                      disabled={this.state.editting}
                      color="info"
                      className=""
                      onClick={() => {
                        console.log(b);
                        this.setState({
                          banner: b,
                          openActions: false,
                          showModal: true,
                        });
                      }}
                    >
                      Edit
                    </DropdownItem> */}
                    <DropdownItem
                      disabled={this.state.deleting}
                      color="danger"
                      onClick={() => {
                        this.renderConfirmAlert("Are you sure?", () => {
                          this.deleteBanner(b.id);
                        });
                      }}
                    >
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
              </div>
            </ListGroupItem>
          ))}
        </ListGroup>
      </Collapse>
    </ListGroupItem>
  );

  componentDidMount() {
    this.getBanners();
  }

  render() {
    return (
      <>
        <BannerModal
          isOpen={this.state.showModal}
          onClose={() => this.setState({ showModal: false, banner: null })}
          banner={this.state.banner}
          userId={this.props.userId}
        />
        <Modal isOpen={this.props.isOpen} toggle={this.props.onClose}>
          <ModalHeader toggle={this.props.onClose}>Banners</ModalHeader>
          <ModalBody>
            {this.state.loading && !this.state.error && <h4>Loading...</h4>}
            {!this.state.loading && (
              <ListGroup>
                {this.renderSection("active")}
                {this.renderSection("upcoming")}
              </ListGroup>
            )}
            {this.state.error && <h4>{this.state.error}</h4>}
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              onClick={() => {
                this.setState({ showModal: true });
                this.props.onClose();
              }}
            >
              New
            </Button>
            <Button color="secondary" onClick={this.props.onClose}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
