import React, { Component } from "react";
import ContentWrapper from "../../Layout/ContentWrapper";
import {
  Row,
  Col,
  TabContent,
  Nav,
  NavItem,
  NavLink,
  Button,
} from "reactstrap";
import InventoryTab from "./InventoryTab";
import UploadAttachmentModal from "./UploadAttachmentModal";
import Images from "./Images";
import Documents from "./Documents";
import { withRouter } from "react-router-dom";
import { Api } from "../../../Api/index";
import ReactSwitch from "react-switch";
import { ShareLink } from "../../Common/ShareLink";
// import { ShareLink } from "../../Common/ShareLink";

export class InventoryDetails extends Component {
  state = {
    dropdownOpen: false,
    activeTab: "InventoryDetails",
    data: {},
    detailedModelVisible: false,
    isLocking: false,
    isGenerating: false,
  };

  changeLanguage = (lng) => {
    this.props.i18n.changeLanguage(lng);
  };

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  async componentDidMount() {
    this.getCarDetails();
  }

  getCarDetails = async () => {
    const carId = this.props.match.params.id;
    try {
      const response = await Api.CarManagement.CarDetails(carId);
      this.setState({ data: response });
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { data } = this.state;
    if (!data.id) return null;
    return (
      <ContentWrapper>
        <div className="content-heading d-flex justify-content-between align-content-center">
          <div>
            {data.vin}
            <small>
              {data.make} {data.model} {data.year}
            </small>
          </div>

          <Col className="d-flex ml-4 justify-content-end align-content-center">
            <div className="d-flex mr-4 align-items-center">
              <small className="mr-2 mt-1">Hide From Search?</small>
              <ReactSwitch
                checked={data.hideFromSearch}
                onChange={(checked) => {
                  Api.CarManagement.ToggleField(
                    data.id,
                    "HideFromSearch",
                    checked
                  ).then(() => {
                    const newData = Object.assign({}, data);
                    newData.hideFromSearch = checked;
                    this.setState({
                      data: newData,
                    });
                  });
                }}
              />
            </div>
            <div className="d-flex mr-4 align-items-center">
              <small className="mr-2 mt-1">Delivered?</small>
              <ReactSwitch
                checked={data.delivered}
                onChange={(checked) => {
                  Api.CarManagement.ToggleField(
                    data.id,
                    "Delivered",
                    checked,
                    data.vin
                  ).then(() => {
                    const newData = Object.assign({}, data);
                    newData.delivered = checked;
                    this.setState({
                      data: newData,
                    });
                  });
                }}
              />
            </div>
            {data.detailedReportUrl ? (
              <div>
                <a
                  href={data.detailedReportUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-tiny btn-info mr-2"
                >
                  View Report
                </a>
                <Button
                  onClick={() => this.setState({ detailedModelVisible: true })}
                  className="text-muted"
                >
                  Change Report
                </Button>
              </div>
            ) : (
              <Button
                onClick={() => this.setState({ detailedModelVisible: true })}
              >
                Upload Detailed Report
              </Button>
            )}
            {/* <Button
              className="ml-2"
              color={this.state.data.locked ? "danger" : "success"}
              onClick={async () => {
                this.setState({ isLocking: true });
                const res = await Api.CarManagement.LockOrUnlock(
                  this.state.data.id
                );
                const nudeData = Object.assign({}, this.state.data);
                nudeData.locked = res;
                this.setState({ isLocking: false, data: nudeData });
              }}
            >
              {`${this.state.data.locked ? "Lock" : "Unlock"}${
                this.state.isLocking ? "ing" : "ed"
              }`}
            </Button> */}
            {!this.state.data.locked && (
              <div className="ml-2">
                <ShareLink link={`/search/${data.id}`} />
              </div>
            )}
            <Button
              className="ml-2"
              onClick={async () => {
                this.setState({ isLocking: true });
                const res = await Api.CarManagement.GenerateRoadsideCard(
                  this.state.data.id,
                  {
                    vin: this.state.data.vin,
                  }
                );
                const nudeData = Object.assign({}, this.state.data);
                nudeData.locked = res;
                this.setState({ isLocking: false, data: nudeData });
              }}
            >{`Generat${
              this.state.isGenerating ? "ing" : "e"
            } Roadside Card`}</Button>
          </Col>
        </div>
        <Row>
          <Col xs={12}>
            <div className="card card-transparent">
              <Nav tabs justified>
                <NavItem>
                  <NavLink
                    className={
                      this.state.activeTab === "InventoryDetails"
                        ? "active"
                        : ""
                    }
                    onClick={() => {
                      this.toggleTab("InventoryDetails");
                    }}
                  >
                    Car Details
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={
                      this.state.activeTab === "Images" ? "active" : ""
                    }
                    onClick={() => {
                      this.toggleTab("Images");
                    }}
                  >
                    Images
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={
                      this.state.activeTab === "Documents" ? "active" : ""
                    }
                    onClick={() => {
                      this.toggleTab("Documents");
                    }}
                  >
                    Documents
                  </NavLink>
                </NavItem>

                {/* <NavItem>
                  <NavLink
                    className={this.state.activeTab === "ApplicationsHistory" ? "active" : ""}
                    onClick={() => { this.toggleTab("ApplicationsHistory") }}
                  >
                    Application History
                  </NavLink>
                </NavItem> */}
                {/* <NavItem>
                  <NavLink
                    className={this.state.activeTab === "GPS" ? "active" : ""}
                    onClick={() => { this.toggleTab("GPS") }}
                  >
                    GPS Device
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={this.state.activeTab === "CarReviews" ? "active" : ""}
                    onClick={() => { this.toggleTab("CarReviews") }}
                  >
                    Car Reviews
                  </NavLink>
                </NavItem> */}
              </Nav>
              <TabContent activeTab={this.state.activeTab} className="bg-white">
                <InventoryTab tabId="InventoryDetails" />
                <Images tabId="Images" />
                <Documents tabId="Documents" />
                {/* <ApplicationsHistory tabId="ApplicationsHistory" /> */}
                {/* <GpsDevice tabId="GPS" />
                <CarReviews tapId="CarReviews" /> */}
              </TabContent>
            </div>
          </Col>
        </Row>
        <UploadAttachmentModal
          car={this.props.match.params.id}
          type="Report"
          isOpen={this.state.detailedModelVisible}
          onClose={() => this.closeModal()}
          onSuccess={(r) => {
            const data = this.state.data;
            data.detailedReportUrl = r[0].url;
            data.detailedReportId = r[0].id;
            this.setState({
              data,
              detailedModelVisible: false,
            });
          }}
          onError={() =>
            this.renderErrorAlert(
              "Oops!",
              "Something went wrong, please try again!"
            )
          }
        />
      </ContentWrapper>
    );
  }
}

InventoryDetails = withRouter(InventoryDetails);
