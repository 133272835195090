import React from "react";
import {
  TabPane,
  Button,
  Form,
  Row,
  FormGroup,
  Label,
  Input,
  Col,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import SharedComponent from "../Shared";
import { Api } from "../../../Api/index";
export default class InventoryTab extends SharedComponent {
  state = {
    data: {},
    errors: {},
    makes: [],
    models: [],
    years: [],
    styles: [],
  };
  async componentDidMount() {
    this.getInventoryDetails();
    this.renderMakeAndStyleList();
  }

  render() {
    const { data, errors } = this.state;
    return (
      <TabPane tabId="InventoryDetails">
        <Form onSubmit={this.handleSubmit} className="pt-3 pb-3">
          <Row>
            {this.sortInputFields().map((field, index) => {
              return (
                <Col key={index} md={6} sm={12}>
                  <FormGroup>
                    <Label>{field.label}</Label>
                    {field.multi && (
                      <Input
                        type={field.type || "text"}
                        name={field.name}
                        id={`id-${field.name}`}
                        value={data[field.name]}
                        onChange={this.handleChange}
                        disabled={field.disabled}
                      >
                        {field.options.map((option, index) => (
                          <option
                            key={index}
                            value={option}
                            selected={data[field.name]}
                          >
                            {option}{" "}
                          </option>
                        ))}
                      </Input>
                    )}

                    {!field.multi && (
                      <Input
                        type={field.type || "text"}
                        name={field.name}
                        id={`id-${field.name}`}
                        value={data[field.name]}
                        onChange={this.handleChange}
                        disabled={field.disabled}
                      />
                    )}

                    {errors && (
                      <span style={{ color: "red" }}>
                        {" "}
                        {errors[field.name]}
                      </span>
                    )}
                  </FormGroup>
                </Col>
              );
            })}
            {this.getCKEditorFields().map((field, index) => {
              return (
                <Col md={12} sm={12} key={"ckeditorfileds-" + index}>
                  <FormGroup>
                    <Label>{field.label}</Label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={`${data[field.name]}`}
                      onChange={(event, editor) =>
                        this.handleChangeDescription(event, editor, field.name)
                      }
                    />
                    {errors && (
                      <span style={{ color: "red" }}>
                        {" "}
                        {errors[field.name]}
                      </span>
                    )}
                  </FormGroup>
                </Col>
              );
            })}
          </Row>
          <Row>
            <Col md={12} className="d-flex justify-content-end">
              <Button color="info">Save Changes</Button>
            </Col>
          </Row>
        </Form>
      </TabPane>
    );
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.doSubmit(this.state.data);
  };
  handleChange = ({ currentTarget: input }) => {
    if (input.name === "make") {
      this.getModelLookups(input.value);
    }
    const data = { ...this.state.data };
    if (input.type === "number") {
      data[input.name] = Number(input.value);
    } else {
      data[input.name] = input.value;
    }
    this.setState({ data });
  };
  toggleHideFromSearch = (isChecked) => {
    const data = { ...this.state.data };
    data.hideFromSearch = isChecked;
    this.doSubmit(data).then(() => this.setState({ data }));
  };
  doSubmit = (data) => {
    return new Promise((resolve, reject) => {
      let CarId = this.props.match.params.id;
      console.log("manages/inventories/InventoryTab.doSubmit", CarId);
      Api.CarManagement.UpdateCar(CarId, data)
        .then((e) => {
          console.log(e);
          this.renderSuccessAlert("Updated successfully", "Click the button");
          resolve();
        })
        .catch((error) => {
          console.error(error);
          this.renderErrorAlert(
            "Ops!",
            error.response
              ? error.response.data.Message
              : "Something went wrong, please try again!"
          );
          reject();
        });
    });
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };
  getInventoryDetails = () => {
    let CarId = this.props.match.params.id;
    Api.CarManagement.CarDetails(CarId)
      .then((response) => {
        console.log(response);
        this.setState({ data: response, iconUrl: response.photoUrl });
        this.getModelLookups(response.make);
      })
      .catch(console.log);
  };
  getInputFields = () => {
    return [
      {
        label: "Unit ID",
        name: "id",
        type: "text",
        options: [],
        multi: false,
        disabled: true,
      },
      {
        label: "Year",
        name: "year",
        type: "number",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Make",
        name: "make",
        type: "select",
        options: this.state.makes || [],
        multi: true,
        disabled: false,
      },
      {
        label: "Body Style",
        name: "bodyStyle",
        type: "select",
        options: this.state.styles || [],
        multi: true,
        disabled: false,
      },
      {
        label: "Model",
        name: "model",
        type: "select",
        options: this.state.models || [],
        multi: true,
        disabled: false,
      },
      {
        label: "Series",
        name: "series",
        type: "text",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Equip",
        name: "equip",
        type: "text",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Mileage",
        name: "mileage",
        type: "number",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Exterior Color",
        name: "exteriorColor",
        type: "text",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Interior Color",
        name: "interiorColor",
        type: "text",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Vin",
        name: "vin",
        type: "text",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Monthly Payment",
        name: "monthlyPayment",
        type: "number",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Enterprise Whole Sale Asking Price",
        name: "enterpriseWholeSaleAskingPrice",
        type: "number",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Black Book Estimated Current Clean Whole Sale Value",
        name: "blackBookEstimatedCurrentCleanWholesaleValue",
        type: "number",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "BlackBook Estimated 48-Month Residual Value",
        name: "blackBookEstimated48MonthResidualValue",
        type: "number",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "BlackBook Estimated Retail Value - Clean",
        name: "blackBookEstimatedRetailValue",
        type: "number",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Amount Due At Signing",
        name: "amountDueAtSigning",
        type: "number",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Total Payments Over Lease",
        name: "totalPaymentsOverLease",
        type: "number",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Gross Capitalize Cost",
        name: "grossCapitalizeCost",
        type: "number",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Capitalized Cost Reduction",
        name: "capitalizedCostReduction",
        type: "number",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Adjusted Capitalized Cost",
        name: "adjustedCapitalizedCost",
        type: "number",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Vehicle Residual Value Used in Payment Calculation",
        name: "vehicleResidualValue",
        type: "number",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Depreciation & Amortization Charged Over Lease Term",
        name: "depreciation",
        type: "number",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Rent Charge Over Depreciation Charged",
        name: "rentChargeOverDepreciationCharged",
        type: "number",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Total of Base Monthly Payments",
        name: "totalOfBaseMonthlyPayments",
        type: "number",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Number Of Lease Payments",
        name: "numberOfLeasePayments",
        type: "number",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Base Monthly Rent",
        name: "baseMonthlyRent",
        type: "number",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Monthly Sales/Use Tax",
        name: "monthlySales",
        type: "number",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Monthly Insurance Premium",
        name: "monthlyInsurancePremium",
        type: "number",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Condition",
        name: "conditionRating",
        type: "number",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Condition Comment",
        name: "conditionComment",
        type: "text",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Mechanical Issues",
        name: "mechanicalIssues",
        type: "text",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Transmission Type",
        name: "transmissionType",
        type: "text",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Vehicle Doors",
        name: "vehicleDoors",
        type: "text",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Engine",
        name: "engine",
        type: "text",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Cylinders",
        name: "cylinders",
        type: "text",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Torque",
        name: "torque",
        type: "text",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Horse Power",
        name: "horsePower",
        type: "text",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Drive Type",
        name: "driveType",
        type: "text",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Base Curb Weight",
        name: "baseCurbWeight",
        type: "text",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Anti Brake System",
        name: "antiBrakeSystem",
        type: "text",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Fuel Type",
        name: "fuelType",
        type: "text",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Fuel Capacity",
        name: "fuelCapacity",
        type: "text",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Highway Mileage",
        name: "highwayMileage",
        type: "text",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "City Mileage",
        name: "cityMileage",
        type: "text",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "MSRP",
        name: "msrp",
        type: "text",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Invoice Price",
        name: "invoicePrice",
        type: "text",
        options: [],
        multi: false,
        disabled: false,
      },
      {
        label: "Review Rating",
        name: "reviewRating",
        type: "number",
        options: [],
        multi: false,
        disabled: false,
      },
      // { label: "License Plate Number", name: "licensePlateNumber", type: "number", options: [], multi: false, disabled: false },
      // { label: "Condition Report Prepared By", name: "conditionReportPreparedBy", type: "number", options: [], multi: false, disabled: false },
    ];
  };
  sortInputFields = () => {
    return this.getInputFields().sort((a, b) => a.label.localeCompare(b.label));
  };

  getCKEditorFields = () => {
    return [
      { label: "Technical Specs", name: "technicalSpecs" },
      { label: "Equipments", name: "equipments" },
      { label: "Description", name: "description" },
    ];
  };
  handleChangeDescription = (event, editor, fieldName) => {
    const data = { ...this.state.data };
    data[fieldName] = editor.getData();
    this.setState({ data });
  };
}
InventoryTab = withRouter(InventoryTab);
