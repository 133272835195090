import React from "react";
import {
  Badge,
  Button,
  ButtonDropdown,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  Collapse,
} from "reactstrap";
// import FontAwesomeIcon from "@fortawesome/fontawesome-free";
import SharedComponent from "../../Shared";
import { Api } from "../../../../Api/index";
// import ReactSwitch from "react-switch";
import TransactionCheckbox from "./TransactionCheckbox";
import BulkEditCheckbox from "./BulkEditCheckbox";

const BADGE_COLOR_TO_TYPE = {
  income: "success",
  expense: "warning",
  payroll: "info",
  other: "dark",
};

const TRANSACTION_TYPES = [
  "payroll",
  "vehicle_payment",
  "vehicle_insurance",
  "expense",
  "income",
  "rent",
  "other",
];

class Transactions extends SharedComponent {
  state = {
    open: false,
    summary: [],
    filterType: [],
    filterCategory: [],
    filterSubCategory: [],
    categories: [],
    subCategories: [],
    types: [],
    dropdownOpen: false,
    transDropdownOpen: undefined,
    updatingTransactions: [],
    filteredTransactions: [],
    transactions: [],
    total: 0,
    bulkTotal: 0,
    query: "",
    syncing: false,
    hidePendingTransactions: false,
    bulkEditIds: [],
    updatingBulk: false,
    bulkEditDropdownOpen: false,
    selectAllTransactions: false,
    toggleShowPendingSyncUI: false,
    ellipsisDropdownOpen: false,
  };

  toggleFilterDropdown(dp) {
    this.setState({
      dropdownOpen: this.state.dropdownOpen === dp ? false : dp,
    });
  }

  clearFilters() {
    this.setState({
      filterType: [],
      filterCategory: [],
      filterSubCategory: [],
      query: "",
      total: this.calculateTotal(this.state.transactions),
      bulkTotal: 0,
      filteredTransactions: [],
      hidePendingTransactions: false,
      bulkEditIds: [],
      bulkEditDropdownOpen: false,
      selectAllTransactions: false,
      ellipsisDropdownOpen: false,
    });
    setTimeout(() => this.setDropdowns(), 0);
  }

  sortTransactions(transactions) {
    return transactions;
    // return sort(transactions).desc((t) => t.date);
  }

  removeTransactionFromUpdating(id, data) {
    const updatingTransactions = this.state.updatingTransactions;
    updatingTransactions.splice(updatingTransactions.indexOf(id));
    if (data) {
      const index = this.state.transactions.findIndex((t) => t.id === data.id);
      const filteredTransactions = [...this.state.filteredTransactions];
      const fIndex = this.state.filteredTransactions.findIndex(
        (t) => t.id === data.id
      );
      const transactions = [...this.state.transactions];
      transactions[index] = data;

      if (fIndex > -1) filteredTransactions[index] = data;
      this.setState({
        updatingTransactions,
        transactions,
        filteredTransactions,
      });

      if (this.state.filterType) {
        setTimeout(() => {
          this.filterTransactions(
            this.state.filterType,
            this.state.filterCategory,
            this.state.filterSubCategory
          );
        }, 0);
      }
    } else {
      this.setState({
        updatingTransactions,
      });
    }
  }

  correctTransaction(id, type) {
    this.setState({
      updatingTransactions: [...this.state.updatingTransactions, id],
    });
    Api["@driveavva"].core.TransactionsManagement.Correct(id, type)
      .then((d) => {
        if (!this.state.types.includes(type))
          this.setState({ types: [type, ...this.state.types] });
        this.removeTransactionFromUpdating(id, d.data);
      })
      .catch((e) => {
        this.renderErrorAlert(
          `Correction Error`,
          `Encountered an error updating transaction #${id} to type ${type} - ${e.message}`
        );
        this.removeTransactionFromUpdating(id);
      });
  }

  filterTransactions(
    filterType,
    filterCategory,
    filterSubCategory,
    query = this.state.query
  ) {
    let total = 0;
    const filteredTransactions = this.state.transactions.filter((s) => {
      let isValid = true;

      // Filter by category only
      if (filterCategory.length && !filterSubCategory.length && s.category) {
        isValid =
          s.category.filter((s) => filterCategory.includes(s)).length > 0;
        // Filter by both
      } else if (
        filterCategory.length &&
        filterSubCategory.length &&
        s.category &&
        s.sub_category
      ) {
        isValid =
          s.category.filter((s) => filterCategory.includes(s)).length > 0 &&
          s.sub_category.filter((s) => filterSubCategory.includes(s)).length >
            0;
        // Filter by sub
      } else if (filterSubCategory.length && s.sub_category) {
        isValid =
          s.sub_category.filter((s) => filterSubCategory.includes(s)).length >
          0;
      }
      if (this.state.hidePendingTransactions && s.is_pending) isValid = false;

      if (filterType.length)
        isValid = s.corrected_type
          ? filterType.includes(s.corrected_type)
          : filterType.includes(s.type);
      if (query !== "") isValid = s.name.toLowerCase().includes(query);

      if (isValid) total += s.amount;

      return isValid;
    });

    console.log("Filtered", {
      filterType,
      filteredTransactions,
      filterCategory,
      filterSubCategory,
    });

    this.setState({
      filterType,
      filterCategory,
      filteredTransactions: this.sortTransactions(filteredTransactions),
      filterSubCategory,
      total,
      query,
      bulkEditIds: this.state.selectAllTransactions
        ? this.sortTransactions(filteredTransactions).map((t) => t.id)
        : [],
      ellipsisDropdownOpen: false,
    });
  }

  filter(type, f = "type") {
    const filterCategory =
      f === "cat"
        ? this.state.filterCategory.includes(type)
          ? this.state.filterCategory.filter((f) => f !== type)
          : [...this.state.filterCategory, type]
        : this.state.filterCategory;
    const filterType =
      f === "type"
        ? this.state.filterType.includes(type)
          ? this.state.filterType.filter((f) => f !== type)
          : [...this.state.filterType, type]
        : this.state.filterType;
    const filterSubCategory =
      f === "sub-cat"
        ? this.state.filterSubCategory.includes(type)
          ? this.state.filterSubCategory.filter((f) => f !== type)
          : [...this.state.filterSubCategory, type]
        : this.state.filterSubCategory;
    this.filterTransactions(filterType, filterCategory, filterSubCategory);
    setTimeout(() => this.setDropdowns(), 0);
  }

  search = (e) => {
    e && e.preventDefault();
    this.filterTransactions(
      this.state.filterType,
      this.state.filterCategory,
      this.state.filterSubCategory,
      (e && e.currentTarget.value ? e.currentTarget.value : "").toLowerCase()
    );
  };

  sync = () => {
    this.setState({ syncing: true });
    Api["@driveavva"].core.AppsManagement.TransactionsSync(this.props.app.id)
      .then((e) => {
        this.setState({ syncing: false });
        this.renderSuccessAlert(
          "Success!",
          `Refreshed/Resynced Transactions`,
          () => window.location.reload()
        );
      })
      .catch((e) => {
        this.renderErrorAlert(
          "Encountered an error syncing transactions",
          e.message
        );
        this.setState({ syncing: false });
      });
  };

  setDropdowns() {
    const categories = [];
    const subCategories = [];
    const types = [];
    this.state.transactions.forEach((t) => {
      if (t.type && !types.includes(t.type)) types.push(t.type);
      if (t.corrected_type && !types.includes(t.corrected_type))
        types.push(t.corrected_type);
      t.category.forEach((c) => {
        if (c && !categories.includes(c)) {
          categories.push(c);
        }
      });
      if (t.sub_category)
        t.sub_category.forEach((c) => {
          if (c && !subCategories.includes(c)) {
            subCategories.push(c);
          }
        });
    });
    this.setState({
      categories,
      subCategories,
      types,
    });
  }

  bulkUpdate = async (transactionType) => {
    this.setState({ updatingBulk: true });
    const payload = {
      corrected_type: transactionType,
      ids: this.state.bulkEditIds,
    };

    try {
      await Api["@driveavva/core"].TransactionsManagement.BulkUpdate(payload);

      const that = this; // for scope issue
      const updatedTransactions = this.state.transactions.map((transaction) => {
        if (that.state.bulkEditIds.includes(transaction.id)) {
          transaction.corrected_type = transactionType;
        }
        return transaction;
      });

      // set bulk updates
      this.setState({
        updatingBulk: false,
        transactions: updatedTransactions,
        bulkEditIds: [],
        bulkEditDropdown: false,
        selectAllTransactions: false,
      });
    } catch (e) {
      console.warn(e);
      this.renderErrorAlert(
        `Encountered an error updating ${this.state.bulkEditIds.length} transactions`,
        e.message
      );
      this.setState({
        updatingBulk: false,
        bulkEditIds: [],
        bulkEditDropdown: false,
        selectAllTransactions: false,
      });
    }
  };

  toggleBulkEdit = () => {
    this.setState((prevState) => ({
      bulkEditDropdownOpen: !prevState.bulkEditDropdownOpen,
    }));
  };

  isFilteredTransactions = () => {
    const isFiltered =
      this.state.filterType.length !== 0 ||
      this.state.filterCategory.length !== 0 ||
      this.state.filterSubCategory.length !== 0 ||
      this.state.query !== "" ||
      this.state.hidePendingTransactions;

    const transactions = isFiltered
      ? this.state.filteredTransactions
      : this.state.transactions;

    return transactions;
  };

  // check if Select All checkbox should be checked
  verifyAllChecked = () => {
    const transactions = this.isFilteredTransactions();
    this.setState({
      selectAllTransactions:
        transactions.length === this.state.bulkEditIds.length,
    });
  };

  manageBulkEditIds = (checked, trans_id, amount) => {
    // needs to also check if all transactions are checked or not to set the selectAllTransactions state
    if (checked) {
      this.setState(
        {
          bulkTotal: this.state.bulkTotal + amount,
          bulkEditIds: [...this.state.bulkEditIds, trans_id],
        },
        () => {
          this.verifyAllChecked();
        }
      );
    } else {
      this.setState(
        {
          bulkEditIds: this.state.bulkEditIds.filter((id) => id !== trans_id),
          bulkTotal: this.state.bulkTotal - amount,
        },
        () => {
          this.verifyAllChecked();
        }
      );
    }
  };

  markAllTransactionsChecked = () => {
    const transactions = this.isFilteredTransactions();
    const bulkEditIds = transactions.map((t) => t.id);
    this.setState({ bulkEditIds });
  };

  clearAllTransactionsChecked = () => {
    this.setState({
      bulkEditIds: [],
    });
  };

  selectAllTransactions = (/*checked*/) => {
    this.setState(
      (prevState) => ({
        selectAllTransactions: !prevState.selectAllTransactions,
        bulkTotal: this.calculateTotal(prevState.transactions),
      }),
      () => {
        this.state.selectAllTransactions
          ? this.markAllTransactionsChecked()
          : this.clearAllTransactionsChecked();
      }
    );
  };

  handleShowPendingSyncUI = () => {
    this.setState((prevState) => ({
      toggleShowPendingSyncUI: !prevState.toggleShowPendingSyncUI,
    }));
  };

  showPendingTransactions = () => {
    this.setState(
      (prevState) => ({
        hidePendingTransactions: !prevState.hidePendingTransactions,
      }),
      () => {
        setTimeout(() => this.search());
      }
    );
  };

  toggleEllipsisDropdown = () => {
    this.setState((prevState) => ({
      ellipsisDropdownOpen: !prevState.ellipsisDropdownOpen,
    }));
  };

  componentDidMount() {
    this.setState({
      transactions: this.sortTransactions(this.props.transactions),
      total: this.calculateTotal(this.props.transactions),
    });
    setTimeout(() => this.setDropdowns(), 0);
  }

  calculateTotal(transactions) {
    return transactions.reduce(
      (a, b) => (typeof a === "number" ? a : 0) + b.amount
    );
  }

  render() {
    const isFiltered =
      this.state.filterType.length !== 0 ||
      this.state.filterCategory.length !== 0 ||
      this.state.filterSubCategory.length !== 0 ||
      this.state.query !== "" ||
      this.state.hidePendingTransactions;
    const transactions = isFiltered
      ? this.state.filteredTransactions
      : this.state.transactions;

    return (
      <Row>
        <Col>
          <Button
            onClick={() =>
              this.setState((e) => ({
                open: !e.open,
              }))
            }
            className="mb-2 d-flex justify-content-between py-2"
            style={{ width: "100%", textAlign: "left" }}
            color={this.state.open ? "dark" : "secondary"}
          >
            <h4 class="m-0">
              Transactions{" "}
              {this.renderCents(
                this.state.bulkEditIds.length
                  ? this.state.bulkTotal
                  : this.state.total
              )}{" "}
              ({transactions.length}/{this.props.transactions.length})
            </h4>
          </Button>
        </Col>
        <Collapse isOpen={this.state.open} className="bg-light p-2">
          <Container fluid className="padding-1 bg-light">
            <Row>
              <Col>
                <div>
                  User's Monthly Income:{" "}
                  {this.renderCents(
                    this.props.app.user_profiles.monthly_income
                  )}
                </div>
                <div>
                  User's Monthly Vehicle Payment:{" "}
                  {this.renderCents(
                    this.props.app.user_profiles.monthly_car_payment
                  )}{" "}
                  (
                  {this.props.app.user_profiles.is_keeping_current_vehicle
                    ? "Keeping"
                    : "Not Keeping"}
                  )
                </div>
                <div>
                  Will AVVA's vehicle replace current vehicle?:{" "}
                  {this.props.app.user_profiles.is_keeping_current_vehicle
                    ? "No"
                    : "Yes"}
                </div>
              </Col>
              <Col
                xs="8"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                {/** sync & clear buttons */}
                <Form
                  onSubmit={this.search}
                  style={{
                    padding: "0 0",
                    width: "100%",
                    marginBottom: "1rem",
                  }}
                >
                  <Input
                    type="text"
                    name="search"
                    id="search"
                    onChange={this.search}
                    value={this.state.query}
                    placeholder="Search by description"
                  />
                </Form>
              </Col>
            </Row>
            <Row>
              <Col>
                {isFiltered && (
                  <ul className="mtb-1-p-0">
                    {this.state.filterCategory.map((f, i) => (
                      <Badge
                        color="info"
                        className="mr-1"
                        key={`info-badge-${i}`}
                      >
                        {f}
                      </Badge>
                    ))}
                    {this.state.filterSubCategory.map((f, i) => (
                      <Badge
                        color="primary"
                        className="mr-1"
                        key={`primary-badge-${i}`}
                      >
                        {f}
                      </Badge>
                    ))}
                    {this.state.filterType.map((f, i) => (
                      <Badge
                        color="muted"
                        className="mr-1"
                        key={`muted-badge-${i}`}
                      >
                        {f}
                      </Badge>
                    ))}
                  </ul>
                )}
              </Col>
              <Col xs={8}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  {(isFiltered || this.state.bulkEditIds.length !== 0) && (
                    <Button
                      className="mr-2"
                      color="danger"
                      onClick={() => this.clearFilters()}
                    >
                      Reset
                    </Button>
                  )}
                  <ButtonDropdown
                    isOpen={this.state.dropdownOpen === "category"}
                    className="mr-2"
                    toggle={() => this.toggleFilterDropdown("category")}
                  >
                    <DropdownToggle caret color="info">
                      Filter By Category
                    </DropdownToggle>
                    <DropdownMenu
                      modifiers={{
                        setMaxHeight: {
                          enabled: true,
                          order: 890,
                          fn: (data) => {
                            return {
                              ...data,
                              styles: {
                                ...data.styles,
                                overflow: "auto",
                                maxHeight: 200,
                              },
                            };
                          },
                        },
                      }}
                    >
                      {this.state.categories.map((s) => (
                        <DropdownItem
                          key={s}
                          onClick={() => this.filter(s, "cat")}
                        >
                          <FormGroup check>
                            <Input
                              readOnly
                              type="checkbox"
                              checked={this.state.filterCategory.includes(s)}
                            />
                            <Label check>{s}</Label>
                          </FormGroup>
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </ButtonDropdown>
                  <ButtonDropdown
                    isOpen={this.state.dropdownOpen === "sub-category"}
                    className="mr-2"
                    toggle={() => this.toggleFilterDropdown("sub-category")}
                  >
                    <DropdownToggle caret color="primary">
                      Filter By Sub-Category
                    </DropdownToggle>
                    <DropdownMenu
                      modifiers={{
                        setMaxHeight: {
                          enabled: true,
                          order: 890,
                          fn: (data) => {
                            return {
                              ...data,
                              styles: {
                                ...data.styles,
                                overflow: "auto",
                                maxHeight: 200,
                              },
                            };
                          },
                        },
                      }}
                    >
                      {this.state.subCategories.map((s) => (
                        <DropdownItem key={s}>
                          <FormGroup
                            check
                            onClick={() => this.filter(s, "sub-cat")}
                          >
                            <Input
                              type="checkbox"
                              readOnly
                              checked={this.state.filterSubCategory.includes(s)}
                            />
                            <Label check>{s}</Label>
                          </FormGroup>
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </ButtonDropdown>
                  <ButtonDropdown
                    isOpen={this.state.dropdownOpen === "type"}
                    toggle={() => this.toggleFilterDropdown("type")}
                  >
                    <DropdownToggle caret color="muted">
                      Filter By Type(s)
                    </DropdownToggle>
                    <DropdownMenu>
                      {this.state.types.map((s) => (
                        <DropdownItem key={s}>
                          <FormGroup check onClick={() => this.filter(s)}>
                            <Input
                              type="checkbox"
                              readOnly
                              checked={this.state.filterType.includes(s)}
                            />
                            <Label check>{s}</Label>
                          </FormGroup>
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </ButtonDropdown>
                  <ButtonDropdown>
                    <Dropdown
                      isOpen={this.state.ellipsisDropdownOpen}
                      toggle={this.toggleEllipsisDropdown}
                    >
                      <DropdownToggle>
                        <svg
                          width="25"
                          height="25"
                          viewBox="0 0 128 512"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M64 360c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zm0-160c30.9 0 56 25.1 56 56s-25.1 56-56 56s-56-25.1-56-56s25.1-56 56-56zM120 96c0 30.9-25.1 56-56 56S8 126.9 8 96S33.1 40 64 40s56 25.1 56 56z" />
                        </svg>
                      </DropdownToggle>
                      <DropdownMenu>
                        <ul className="ellipsis-dropdown">
                          <li onClick={this.showPendingTransactions}>
                            {this.state.hidePendingTransactions
                              ? "Show"
                              : "Hide"}{" "}
                            Pending
                          </li>
                          {this.props.app && (
                            <li onClick={() => this.sync()}>
                              Sync{this.state.syncing ? "ing" : ""}
                            </li>
                          )}
                        </ul>
                      </DropdownMenu>
                    </Dropdown>
                  </ButtonDropdown>
                </div>
              </Col>
            </Row>
          </Container>
          <Col xs={12} style={{ zIndex: 0 }} className="relative">
            <div class="transaction-table">
              <table className="bg-white">
                <thead className="bg-gray">
                  <tr>
                    <th>
                      <BulkEditCheckbox
                        className="select-all-bulk-edit-checkbox"
                        checked={this.state.selectAllTransactions}
                        onChange={this.selectAllTransactions}
                      />
                      {this.state.bulkEditIds.length > 0 ? (
                        <Form className="abs-pos-bulk-edit">
                          <ButtonDropdown>
                            <Dropdown
                              isOpen={this.state.bulkEditDropdownOpen}
                              toggle={this.toggleBulkEdit}
                              color="danger"
                              disabled={this.state.updatingBulk}
                            >
                              <DropdownToggle
                                caret
                                style={{ backgroundColor: "#fff" }}
                              >
                                Chang{this.state.updatingBulk ? "ing" : "e"}{" "}
                                {this.state.bulkEditIds.length} Transaction
                                Types
                              </DropdownToggle>
                              <DropdownMenu>
                                {TRANSACTION_TYPES.map((s) => (
                                  <DropdownItem
                                    key={`bulk_edit_item_${s}`}
                                    onClick={() => this.bulkUpdate(s)}
                                  >
                                    <Badge color={BADGE_COLOR_TO_TYPE[s]}>
                                      {s}
                                    </Badge>
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            </Dropdown>
                          </ButtonDropdown>
                        </Form>
                      ) : null}
                    </th>
                    <th>Type</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Balance</th>
                    <th>Description</th>
                    <th>Category</th>
                    <th>Sub-Category</th>
                    <th>Pending?</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions.length === 0 && (
                    <h4 className="ml-2 mt-2">
                      No transactions found matching your filtered types. Please
                      different types or clear the filter
                    </h4>
                  )}
                  {transactions.map((t, i) => (
                    <tr key={`transaction-${i}`}>
                      <td>
                        <TransactionCheckbox
                          onChange={(checked) =>
                            this.manageBulkEditIds(checked, t.id, t.amount)
                          }
                          checked={this.state.bulkEditIds.includes(t.id)}
                        />
                      </td>
                      <td>
                        <ButtonDropdown
                          isOpen={
                            this.state.transDropdownOpen === t.id &&
                            !this.state.updatingTransactions.includes(t.id)
                          }
                          className="text-small mt-1"
                          toggle={() =>
                            this.setState({
                              transDropdownOpen: this.state.transDropdownOpen
                                ? undefined
                                : t.id,
                            })
                          }
                        >
                          <DropdownToggle
                            caret
                            color="transparent"
                            size="sm"
                            disabled={this.state.updatingTransactions.includes(
                              t.id
                            )}
                          >
                            <Badge
                              color={
                                this.state.updatingTransactions.includes(t.id)
                                  ? "gray"
                                  : BADGE_COLOR_TO_TYPE[
                                      t.corrected_type || t.type
                                    ]
                              }
                            >
                              {this.state.updatingTransactions.includes(t.id)
                                ? "Correcting Type..."
                                : t.corrected_type || t.type}
                            </Badge>
                          </DropdownToggle>
                          <DropdownMenu>
                            {TRANSACTION_TYPES.map((s) => (
                              <DropdownItem
                                key={s}
                                onClick={() => this.correctTransaction(t.id, s)}
                              >
                                {s}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </ButtonDropdown>
                      </td>
                      <td>{t.date}</td>
                      <td
                        className={
                          t.amount > 0 ? "text-success" : "text-danger"
                        }
                      >
                        {this.renderCents(t.amount, true)}
                      </td>
                      <td
                        className={
                          t.is_pending
                            ? "text-info"
                            : t.balance > 0
                            ? "text-success"
                            : "text-danger"
                        }
                      >
                        {t.is_pending
                          ? "n/a"
                          : this.renderCents(t.balance, true)}
                      </td>
                      <td>{t.name}</td>
                      <td>{t.category ? t.category.join(", ") : null}</td>
                      <td>
                        {t.sub_category ? t.sub_category.join(", ") : null}
                      </td>
                      <td>{t.is_pending ? "Yes" : "No"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Col>
        </Collapse>
      </Row>
    );
  }
}

export { Transactions };
