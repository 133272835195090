import React from "react";
import {
  Badge,
  Button,
  Col,
  Form,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { Api } from "../../../Api/index";
import SharedComponent from "../Shared";

const formatSize = (s) => {
  if (s === 0) {
    return "0 Bytes";
  }
  const k = 1000;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(s) / Math.log(k));
  return parseFloat((s / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

const statusToColor = {
  Uploading: "info",
  Uploaded: "success",
  Error: "danger",
  Pending: "secondary",
};

const Status = (status) => {
  const s = status || "Pending";
  return <Badge color={statusToColor[s]}>{s}</Badge>;
};

export default class UploadAttachmentModel extends SharedComponent {
  state = {
    files: [],
    statuses: [],
    loading: false,
    canExit: true,
    inputKey: 0,
    includeWatermark: true,
  };

  upload = () => {
    this.setState({ loading: true, canExit: false });
    const promises = [];
    let delayer = 0;
    for (let i = 0; i < this.state.files.length; i++) {
      if (!this.state.statuses[i]) {
        let formData = new FormData();
        formData.append("Type", this.props.type);
        formData.append("Order", i + 1);
        formData.append("IncludeWatermark", this.state.includeWatermark);
        formData.append("File", this.state.files[i]);
        promises.push(
          // eslint-disable-next-line no-loop-func
          new Promise((resolve) => {
            setTimeout(() => {
              this.state.statuses[i] = "Uploading";
              this.setState({ statuses: this.state.statuses });
              Api.CarManagement.UploadAttachment(this.props.car, formData)
                .then((r) => {
                  const newFile = this.state.files[i];
                  newFile.url = r.url;
                  newFile.id = r.id;
                  newFile.createdAt = r.createdAt;
                  newFile.order = r.order;
                  this.state.files[i] = newFile;
                  this.state.statuses[i] = "Uploaded";
                  this.setState({
                    status: this.state.statuses,
                    files: this.state.files,
                  });
                  resolve();
                })
                .catch((e) => {
                  this.state.statuses[i] = "Error";
                  console.log("Error", e);
                  this.setState({ status: e });
                  resolve();
                });
            }, delayer * 700);
          })
        );
        delayer++;
      }
    }

    Promise.all(promises)
      .then((r) => {
        this.setState({ loading: false, canExit: true });
      })
      .catch(() => {
        this.setState({ loading: false, canExit: true });
        this.props.onError();
      });
  };

  close = () => {
    if (this.state.canExit) {
      if (this.state.files.length) {
        this.props.onSuccess(this.state.files);
      } else {
        this.props.onClose();
      }
      this.setState({
        files: [],
        statuses: [],
      });
    }
  };

  render() {
    const isMultiple = this.props.allowMultiple;
    const howmanytoupload = this.state.files.reduce(
      (total, file, i) => (!this.state.statuses[i] ? total + 1 : total),
      0
    );
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.close}>
        <ModalHeader toggle={this.close}>
          Upload {this.props.type}
          {isMultiple && "(s)"}
        </ModalHeader>
        <ModalBody>
          {this.state.files.length !== 0 && (
            <Row>
              <Table>
                <thead>
                  <tr>
                    <th>File Name</th>
                    <th>Size</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.files.map((f, i) => (
                    <tr key={i}>
                      <td>{f.name}</td>
                      <td>{formatSize(f.size)}</td>
                      <td>{Status(this.state.statuses[i])}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Row>
          )}
          {!this.state.loading && (
            <Form>
              <Row>
                <Col md={12} className="mb-3">
                  <Label>File{isMultiple && "(s)"}</Label>
                  <Input
                    key={this.state.inputKey}
                    type="file"
                    name="image"
                    multiple={isMultiple}
                    accept={this.props.type === "Photo" ? ".jpg,.jpeg" : ".pdf"}
                    onChange={(e) => {
                      this.setState({
                        files: [...this.state.files, ...e.target.files],
                        inputKey: new Date().getTime(),
                      });
                    }}
                  />
                  {this.props.type === "Photo" ? (
                    <Label check className="mt-3">
                      <Input
                        type="checkbox"
                        name="water"
                        checked={this.state.includeWatermark}
                        onChange={() =>
                          this.setState({
                            includeWatermark: !this.state.includeWatermark,
                          })
                        }
                      />{" "}
                      Include AVVA watermark?
                    </Label>
                  ) : null}
                  <FormText color="muted">
                    Please ensure the file{isMultiple && "(s)"} are under 50mb.
                  </FormText>
                </Col>
              </Row>
            </Form>
          )}
        </ModalBody>
        <ModalFooter>
          {this.state.canExit && <Button onClick={this.close}>Close</Button>}
          {howmanytoupload > 0 && (
            <Button
              color="info"
              onClick={this.upload}
              disabled={this.state.loading}
            >
              {this.state.loading
                ? "Uploading..."
                : "Upload " +
                  howmanytoupload +
                  " " +
                  this.props.type +
                  (howmanytoupload === 1 ? "" : "s")}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}
