import React from "react";
import {
  Badge,
  Button,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { Api } from "../../../Api/index";
import SharedComponent from "../Shared";

export default class UploadFuelImageModal extends SharedComponent {
  state = {
    file: undefined,
    loading: false,
    error: null,
    canExit: true,
  };

  upload = () => {
    this.setState({ loading: true, canExit: false });
    let formData = new FormData();
    formData.set("file", this.state.file);
    Api["@driveavva"].new.Vehicles.UploadAppImage(
      this.props.car.unit_id,
      formData
    )
      .then((r) => {
        console.info(`Uploaded photo. Closing modal...`);
        this.close();
      })
      .catch((e) => {
        this.setState({
          loading: false,
          error: "Couldnt upload image. Please check developer console",
        });
      });
  };

  close = () => {
    if (this.state.canExit) {
      if (this.state.file) {
        this.props.onSuccess(this.state.file);
      } else {
        this.props.onClose();
      }
      this.setState({
        file: null,
      });
    }
  };

  render() {
    const makeID = this.props.car
      ? MAKE_TO_MAKEID_MAP[this.props.car.make.toUpperCase()]
      : false;

    const fuelHref = this.props.car
      ? `https://stock.fuelapi.com/vehicles/list?${
          makeID ? `make_id=${makeID}&` : ""
        }year=${this.props.car.year}&model=${this.props.car.model}`
      : false;

    return (
      <Modal isOpen={this.props.isOpen} toggle={this.close}>
        <ModalHeader toggle={this.close}>Upload {this.props.type}</ModalHeader>
        <ModalBody>
          {this.props.car && (
            <Form>
              <div class="row">
                <div class="mb-3 col-md-12">
                  <h3>Instructions</h3>
                  Follow the steps below exactly to ensure that the app
                  experience is correct. Failure to follow the steps below could
                  result in a less-than-ideal experience for the AVVA member.
                  <ol class="px-3 mt-3">
                    <li>
                      Navigate to{" "}
                      <a target="'_blank" href={fuelHref}>
                        Fuel
                      </a>
                    </li>
                    <li>
                      Press the VIEW button (Selecting a specific trim is
                      optional)
                    </li>
                    <li>
                      Check the <b>Color 2400 Angle 32 png</b> filter on the
                      left
                    </li>
                    <li>
                      Download the {this.props.car.exterior_color} png (If{" "}
                      {this.props.car.exterior_color} is not available use the
                      white png instead)
                    </li>
                    <li>
                      MOST IMPORTANT: Use the image resizer PSD to ensure proper
                      sizing as Fuel includes a watermark and the image being
                      off center
                    </li>
                    <li>
                      Upload the exported png from Photoshop into the file box
                      below
                    </li>
                    <li>Press submit</li>
                  </ol>
                  <div class="row">
                    <div class="mb-3 col-md-12">
                      <label>Fuel Color 2400 Angle 32 png Image</label>
                      <Input
                        name="image"
                        accept=".png"
                        type="file"
                        class="form-control-file"
                        data-np-checked="1"
                        onChange={(e) => {
                          this.setState({
                            file: e.target.file,
                          });
                        }}
                      />
                      <small class="form-text text-muted">
                        Please ensure the file(s) are under 50mb.
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </ModalBody>
        <ModalFooter>
          {this.state.canExit && <Button onClick={this.close}>Close</Button>}
          <Button
            color="info"
            onClick={this.upload}
            disabled={this.state.loading}
          >
            {this.state.loading ? "Uploading..." : "Upload"}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.doSubmit(this.state.data);
  };
}
const MAKE_TO_MAKEID_MAP = {
  ACURA: 5,
  "ALFA ROMEO": 65,
  "ASTON MARTIN": 41,
  AUDI: 6,
  BENTLEY: 45,
  BMW: 1,
  BUGATTI: 48,
  BUICK: 7,
  CADILLAC: 8,
  CHEVEROLET: 2,
  CHRYSLER: 9,
  CITROEN: 76,
  CODA: 57,
  CUPRA: 81,
  DACIA: 79,
  DAEWOO: 3,
  DODGE: 10,
  FERRARI: 11,
  FIAT: 58,
  FISKER: 59,
  FORD: 12,
  GEM: 54,
  GENESIS: 66,
  GM: 39,
  GMC: 13,
  HONDA: 14,
  HUMMER: 42,
  HYUNDAI: 15,
  INFINITI: 16,
  ISUZU: 17,
  JAGUAR: 18,
  JEEP: 19,
  KARMA: 69,
  KIA: 20,
  LAMBORGHINI: 49,
  LANCIA: 72,
  "LAND ROVER": 21,
  LEXUS: 22,
  LINCOLN: 23,
  LOTUS: 55,
  LUCIS: 78,
  MASERATI: 43,
  MAZDA: 24,
  MCLAREN: 68,
  "MERCEDEZ-BENZ": 25,
  MERCURY: 26,
  MINI: 44,
  MITSUBISHI: 27,
  NISSAN: 4,
  OLDSMOBILE: 28,
  OPEL: 82,
  PAGANI: 40,
  PEUGEOT: 80,
  PLYMOUTH: 29,
  POLESTAR: 73,
  PONTIAC: 30,
  PORSCHE: 31,
  RAM: 60,
  "RAM TRUCKS": 56,
  RENAULT: 74,
  RIVIAN: 70,
  "ROLLS-ROYCE": 51,
  S5: 67,
  SAAB: 32,
  SALEEN: 46,
  SATURN: 33,
  SCION: 47,
  SEAT: 75,
  SEDAN: 63,
  SKODA: 71,
  SMART: 52,
  SPYKER: 50,
  SRT: 61,
  SUBARU: 34,
  SUZUKI: 35,
  TESLA: 53,
  TOYOTA: 36,
  VAUXHALL: 77,
  VOLKSWAGEN: 37,
  VOLVO: 38,
};
