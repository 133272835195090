import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../store/actions/actions";

import ToggleFullscreen from "../Common/ToggleFullscreen";
import HeaderRun from "./Header.run";

class Header extends Component {
  componentDidMount() {
    HeaderRun();
  }

  toggleCollapsed = e => {
    e.preventDefault();
    this.props.actions.toggleSetting("isCollapsed");
    this.resize();
  };

  resize() {
    // all IE friendly dispatchEvent
    var evt = document.createEvent("UIEvents");
    evt.initUIEvent("resize", true, false, window, 0);
    window.dispatchEvent(evt);
    // modern dispatchEvent way
    // window.dispatchEvent(new Event('resize'));
  }

  render() {
    return (
      <header className="topnavbar-wrapper">
        {/* START Top Navbar */}
        <nav className="navbar topnavbar">
          {/* START navbar header */}
          <div className="navbar-header">
            <div className="navbar-brand">
              <div className="brand-logo">
                <Link to="/dashboard" className="text-white">
                  AVVA
                </Link>
              </div>
              <div className="brand-logo-collapsed">
                <Link to="/dashboard" className="text-white">
                  AVVA
                </Link>
              </div>
            </div>
          </div>
          {/* END navbar header */}

          {/* START Left navbar */}
          <ul className="navbar-nav mr-auto flex-row">
            <li className="nav-item">
              {/* Button used to collapse the left sidebar. Only visible on tablet and desktops */}
              <div className="nav-link d-none d-md-block d-lg-block d-xl-block"
                onClick={this.toggleCollapsed}
              >
                <em className="fas fa-bars"></em>
              </div>
              {/* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
              <div
                className="nav-link sidebar-toggle d-md-none"
                onClick={this.toggleAside}
              >
                <em className="fas fa-bars"></em>
              </div>
            </li>
            {/* START lock screen */}
            <li className="nav-item d-none d-md-block">
              <Link to="/auth/login" title="Lock screen" className="nav-link">
                <em className="icon-lock"></em>
              </Link>
            </li>
            {/* END lock screen */}
          </ul>
          {/* END Left navbar */}
          {/* START Right Navbar */}
          <ul className="navbar-nav flex-row">
            {/* Fullscreen (only desktops) */}
            <li className="nav-item d-none d-md-block">
              <ToggleFullscreen className="nav-link" />
            </li>
          </ul>
          {/* END Right Navbar */}
        </nav>
        {/* END Top Navbar */}
      </header>
    );
  }
}

Header.propTypes = {
  actions: PropTypes.object,
  settings: PropTypes.object
};

const mapStateToProps = state => ({ settings: state.settings });
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
