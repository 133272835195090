import React from "react";
import { TabPane, Form, Row, FormGroup, Label, Input, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import FormComponent from "../../FormComponent/FormComponent";
import { Api } from "../../../Api/index";
class ApplicationsTab extends FormComponent {
  state = {
    data: null,
    currentAddress: null,
    previousAddress: null,
    currentWork: null,
    previousWork: null,
    priorAutoInsurance: null,
    previousAddressYears: "",
    previousAddressMonths: "",
    currentAddressYears: "",
    currentAddressMonths: "",
    currentWorkYears: "",
    currentWorkMonths: "",
  };

  componentDidMount() {
    let ApplicationId = this.props.id;
    Api.ApplicationManagement.ApplicationDetails(ApplicationId)
      .then((response) => {
        console.log('[ApplicationsTab.js ApplicationDetails] Retrieved details for App #' + ApplicationId, response);
        this.setState({
          currentAddress: response.currentAddress,
          previousAddress: response.previousAddress,
          currentWork: response.currentWork,
          previousWork: response.previousWork,
          priorAutoInsurance: response.priorAutoInsurance,
          data: response,
          previousAddressYears: response.previousAddress.placePeriod.years,
          previousAddressMonths: response.previousAddress.placePeriod.months,
          currentAddressYears: response.currentAddress.placePeriod.years,
          currentAddressMonths: response.currentAddress.placePeriod.months,
          currentWorkYears: response.currentWork.employmentPeriod.years,
          currentWorkMonths: response.currentWork.employmentPeriod.months,
        });
        localStorage.setItem("carId", response.carId);
      })
      .catch(console.log);
  }

  render() {
    const {
      data,
      currentAddress,
      previousAddress,
      currentWork,
      previousWork,
      previousAddressYears,
      previousAddressMonths,
      currentAddressYears,
      currentAddressMonths,
      currentWorkYears,
      currentWorkMonths,
      priorAutoInsurance,
    } = this.state;

    if (!data) return null;


    return (
      <TabPane tabId="ApplicationDetails">
        <Row>
          <Col xs={12} className="mt-3">
            <h3 className="mb-0">Application Details</h3>
            <hr className="w-25 float-left" />
          </Col>
        </Row>
        <Form>
          <Row>
            <Col md={12}>
              <FormGroup>
                <h4>Client Information</h4>
              </FormGroup>
            </Col>
            <Col md={6} sm={12}>
              <FormGroup>
                <Label for="firstName">First Name </Label>
                <Input
                  readOnly
                  value={data.firstName}
                  type="text"
                  name="firstName"
                  id="firstName"
                />
              </FormGroup>
            </Col>
            <Col md={6} sm={12}>
              <FormGroup>
                <Label for="lastName">Last Name </Label>
                <Input
                  readOnly
                  value={data.lastName}
                  type="text"
                  name="lastName"
                  id="lastName"
                />
              </FormGroup>
            </Col>
            <Col md={4} sm={12}>
              <FormGroup>
                <Label for="middleNameInitial">Middle Initial </Label>
                <Input
                  readOnly
                  value={data.middleNameInitial}
                  type="text"
                  name="middleNameInitial"
                  id="middleNameInitial"
                />
              </FormGroup>
            </Col>
            <Col md={4} sm={12}>
              <FormGroup>
                <Label for="email">Email Address </Label>
                <Input
                  readOnly
                  value={data.email}
                  type="email"
                  name="email"
                  id="email"
                />
              </FormGroup>
            </Col>
            <Col md={4} sm={12}>
              <FormGroup>
                <Label for="phoneNumber">Mobile Number </Label>
                <Input
                  readOnly
                  value={data.phoneNumber}
                  type="text"
                  name="phoneNumber"
                  id="phoneNumber"
                />
              </FormGroup>
            </Col>
            <Col md={6} sm={12}>
              <Label for="residentialStatus">Residential Status</Label>
              <FormGroup>
                <label className="c-radio mr-3">
                  <Input
                    readOnly
                    id="residentialStatus"
                    type="radio"
                    name="residentialStatus"
                    defaultValue="HomeOwner"
                    checked={data.residentialStatus === "HomeOwner"}
                  />
                  {data.residentialStatus === "HomeOwner" ? <span className="fa fa-check mr-1"></span> : undefined}HomeOwner
                </label>
                <label className="c-radio mr-3">
                  <Input
                    readOnly
                    id="residentialStatus"
                    type="radio"
                    name="residentialStatus"
                    defaultValue="Rent"
                    checked={data.residentialStatus === "Rent"}
                  />
                  {data.residentialStatus === "Rent" ? <span className="fa fa-check mr-1"></span> : undefined}Rent
                </label>
                <label className="c-radio mr-3">
                  <Input
                    readOnly
                    id="residentialStatus"
                    type="radio"
                    name="residentialStatus"
                    defaultValue="Family"
                    checked={data.residentialStatus === "Family"}
                  />
                  {data.residentialStatus === "Family" ? <span className="fa fa-check mr-1"></span> : undefined}Family
                </label>
                <label className="c-radio mr-3">
                  <Input
                    readOnly
                    id="residentialStatus"
                    type="radio"
                    name="residentialStatus"
                    defaultValue="Other"
                    checked={data.residentialStatus === "Other"}
                  />
                  {data.residentialStatus === "Other" ? <span className="fa fa-check mr-1"></span> : undefined}Other
                </label>
              </FormGroup>
            </Col>
            <Col md={6} sm={12}>
              <Label for="maritalStatus">Marital Status</Label>
              <FormGroup>
                <label className="c-radio mr-3">
                  <Input
                    readOnly
                    id="maritalStatus"
                    type="radio"
                    name="maritalStatus"
                    defaultValue="Married"
                    checked={data.maritalStatus === "Married"}
                  />
                  {data.maritalStatus === "Married" ? <span className="fa fa-check mr-1"></span> : undefined}Married
                </label>
                <label className="c-radio mr-3">
                  <Input
                    readOnly
                    id="maritalStatus"
                    type="radio"
                    name="maritalStatus"
                    defaultValue="Single"
                    checked={data.maritalStatus === "Single"}
                  />
                  {data.maritalStatus === "Single" ? <span className="fa fa-check mr-1"></span> : undefined}Single
                </label>
                <label className="c-radio mr-3">
                  <Input
                    readOnly
                    id="maritalStatus"
                    type="radio"
                    name="maritalStatus"
                    defaultValue="Divorced"
                    checked={data.maritalStatus === "Divorced"}
                  />
                  {data.maritalStatus === "Divorced" ? <span className="fa fa-check mr-1"></span> : undefined}Divorced
                </label>
                <label className="c-radio mr-3">
                  <Input
                    readOnly
                    id="maritalStatus"
                    type="radio"
                    name="maritalStatus"
                    defaultValue="Widowed"
                    checked={data.maritalStatus === "Widowed"}
                  />
                  {data.maritalStatus === "Widowed" ? <span className="fa fa-check mr-1"></span> : undefined}Widowed
                </label>
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <h4>Present Address</h4>
              </FormGroup>
            </Col>
            <Col md={6} sm={12}>
              <FormGroup>
                <Label for="line1">AddressLine1 </Label>
                <Input
                  readOnly
                  value={currentAddress.line1}
                  type="text"
                  name="line1"
                  id="line1"
                />
              </FormGroup>
            </Col>
            <Col md={6} sm={12}>
              <FormGroup>
                <Label for="line2">AddressLine2</Label>
                <Input
                  readOnly
                  value={currentAddress.line2}
                  type="text"
                  name="line2"
                  id="line2"
                />
              </FormGroup>
            </Col>
            <Col md={6} sm={12}>
              <FormGroup>
                <Label for="apartmentNumber">Apt#</Label>
                <Input
                  readOnly
                  value={currentAddress.apartmentNumber}
                  type="text"
                  name="apartmentNumber"
                  id="apartmentNumber"
                />
              </FormGroup>
            </Col>
            <Col md={6} sm={12}>
              <FormGroup>
                <Label for="city">City</Label>
                <Input
                  readOnly
                  value={currentAddress.city}
                  type="text"
                  name="city"
                  id="city"
                />
              </FormGroup>
            </Col>
            <Col md={6} sm={12}>
              <FormGroup>
                <Label for="state">State</Label>
                <Input
                  readOnly
                  value={currentAddress.state}
                  type="text"
                  name="state"
                  id="state"
                />
              </FormGroup>
            </Col>
            <Col md={6} sm={12}>
              <FormGroup>
                <Label for="zip">Zip</Label>
                <Input
                  readOnly
                  value={currentAddress.zip}
                  type="text"
                  name="zip"
                  id="zip"
                />
              </FormGroup>
            </Col>
            <Col md={6} sm={12}>
              <FormGroup>
                <Label for="county">County </Label>
                <Input
                  readOnly
                  value={currentAddress.county}
                  type="text"
                  name="county"
                  id="county"
                />
              </FormGroup>
            </Col>
            <Col md={6} sm={12}>
              <Label>Times at Address</Label>
              <Row>
                <Col md={6}>
                  <FormGroup className={"d-flex align-items-center"}>
                    <Label className={"mr-2"} for="years">
                      Years
                    </Label>
                    <Input
                      readOnly
                      value={currentAddressYears}
                      type="number"
                      name="years"
                      id="years"
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className={"d-flex align-items-center"}>
                    <Label className={"mr-2"} for="months">
                      Months
                    </Label>
                    <Input
                      readOnly
                      value={currentAddressMonths}
                      type="number"
                      name="months"
                      id="months"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col md={12}>
              <FormGroup>
                <h4>Previous Address</h4>
              </FormGroup>
            </Col>
            <Col md={6} sm={12}>
              <FormGroup>
                <Label for="line1">AddressLine1 </Label>
                <Input
                  readOnly
                  value={previousAddress.line1}
                  type="text"
                  name="line1"
                  id="line1"
                />
              </FormGroup>
            </Col>
            <Col md={6} sm={12}>
              <FormGroup>
                <Label for="line2">AddressLine2</Label>
                <Input
                  readOnly
                  value={previousAddress.line2}
                  type="text"
                  name="line2"
                  id="line2"
                />
              </FormGroup>
            </Col>
            <Col md={6} sm={12}>
              <FormGroup>
                <Label for="apartmentNumber">Apt#</Label>
                <Input
                  readOnly
                  value={previousAddress.apartmentNumber}
                  type="text"
                  name="apartmentNumber"
                  id="apartmentNumber"
                />
              </FormGroup>
            </Col>
            <Col md={6} sm={12}>
              <FormGroup>
                <Label for="city">City</Label>
                <Input
                  readOnly
                  value={previousAddress.city}
                  type="text"
                  name="city"
                  id="city"
                />
              </FormGroup>
            </Col>
            <Col md={6} sm={12}>
              <FormGroup>
                <Label for="state">State</Label>
                <Input
                  readOnly
                  value={previousAddress.state}
                  type="text"
                  name="state"
                  id="state"
                />
              </FormGroup>
            </Col>
            <Col md={6} sm={12}>
              <FormGroup>
                <Label for="zip">Zip</Label>
                <Input
                  readOnly
                  value={previousAddress.zip}
                  type="text"
                  name="zip"
                  id="zip"
                />
              </FormGroup>
            </Col>
            <Col md={6} sm={12}>
              <FormGroup>
                <Label for="county">County </Label>
                <Input
                  readOnly
                  value={previousAddress.county}
                  type="text"
                  name="county"
                  id="county"
                />
              </FormGroup>
            </Col>
            <Col md={6} sm={12}>
              <Label>Times at Address</Label>
              <Row>
                <Col md={6}>
                  <FormGroup className={"d-flex align-items-center"}>
                    <Label className={"mr-2"} for="years">
                      Years
                    </Label>
                    <Input
                      readOnly
                      value={previousAddressYears}
                      type="number"
                      name="years"
                      id="years"
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className={"d-flex align-items-center"}>
                    <Label className={"mr-2"} for="months">
                      Months
                    </Label>
                    <Input
                      readOnly
                      value={previousAddressMonths}
                      type="number"
                      name="months"
                      id="months"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col md={12}>
              <FormGroup>
                <h4>Driver Information</h4>
              </FormGroup>
            </Col>

            <Col md={4} sm={12}>
              <FormGroup>
                <Label for="driversLicense">Drivers License #</Label>
                <Input
                  readOnly
                  value={data.driverLicenseNumber}
                  type="text"
                  name="driverLicenseNumber"
                  id="driverLicenseNumber"
                />
              </FormGroup>
            </Col>
            <Col md={4} sm={12}>
              <FormGroup>
                <Label for="driverLicenseState">Drivers License State</Label>
                <Input
                  readOnly
                  value={data.driverLicenseState}
                  type="text"
                  name="driverLicenseState"
                  id="driverLicenseState"
                />
              </FormGroup>
            </Col>
            <Col md={4} sm={12}>
              <FormGroup>
                <Label for="driverLicenseExpiryDate">
                  Drivers License Expiration Date
                </Label>
                <Input
                  readOnly
                  value={data.driverLicenseExpiryDate}
                  type="text"
                  name="driverLicenseExpiryDate"
                  id="driverLicenseExpiryDate"
                />
              </FormGroup>
            </Col>
            <Col md={6} sm={12}>
              <Label for="isExistingCarPayment">
                Is there an existing car payment that will stop once Riot car is
                delivered
              </Label>
              <FormGroup>
                <label className="c-radio mr-3">
                  <Input
                    readOnly
                    id="inlineradio10"
                    type="radio"
                    name="isExistingCarPayment"
                    defaultValue="yes"
                    checked={data.isExistingCarPayment}
                  />
                  {data.isExistingCarPayment ? <span className="fa fa-check mr-1"></span> : undefined}Yes
                </label>
                <label className="c-radio mr-3">
                  <Input
                    readOnly
                    id="inlineradio20"
                    type="radio"
                    name="isExistingCarPayment"
                    defaultValue="no"
                    checked={data.isExistingCarPayment === false}
                  />
                  {!data.isExistingCarPayment ? <span className="fa fa-check mr-1"></span> : undefined}No
                </label>
              </FormGroup>
            </Col>
            <Col md={4} sm={12}>
              <FormGroup>
                <Label for="existingCarPayment">
                  Existing Car Payment
                </Label>
                <Input
                  readOnly
                  value={data.existingCarPayment}
                  type="text"
                  name="existingCarPayment"
                  id="existingCarPayment"
                />
              </FormGroup>
            </Col>
            <Col md={6} sm={12}>
              <Label for="existingcarpayment">
                Have you been convicted of a crime?
              </Label>
              <FormGroup>
                <label className="c-radio mr-3">
                  <Input
                    readOnly
                    id="convicted"
                    type="radio"
                    name="i-radio-1"
                    defaultValue="yes"
                    checked={data.isCrimeConvicted}
                  />
                  {data.isCrimeConvicted ? <span className="fa fa-check mr-1"></span> : undefined}Yes
                </label>
                <label className="c-radio mr-3">
                  <Input
                    readOnly
                    id="convicted"
                    type="radio"
                    name="i-radio-1"
                    defaultValue="no"
                    checked={data.isCrimeConvicted === false}
                  />
                  {!data.isCrimeConvicted ? <span className="fa fa-check mr-1"></span> : undefined}No
                </label>
              </FormGroup>
            </Col>
            <Col md={6} sm={12}>
              <FormGroup>
                <Label for="Rent">Rent/Mtg Pmt</Label>
                <Input
                  readOnly
                  value={data.rentPayment}
                  type="number"
                  name="rentPayment"
                  id="rentPayment"
                />
              </FormGroup>
            </Col>
            <Col md={4} sm={12}>
              <FormGroup>
                <Label for="priorAutoInsuranceCompany">Prior Auto Insurance Company</Label>
                <Input
                  readOnly
                  value={priorAutoInsurance.company}
                  type="text"
                  name="priorAutoInsuranceCompany"
                  id="priorAutoInsuranceCompany"
                />
              </FormGroup>
            </Col>
            <Col md={4} sm={12}>
              <FormGroup>
                <Label for="priorAutoInsurancePolicyNumber">Prior Auto Insurance Policy #</Label>
                <Input
                  readOnly
                  value={priorAutoInsurance.policyNumber}
                  type="text"
                  name="priorAutoInsurancePolicyNumber"
                  id="priorAutoInsurancePolicyNumber"
                />
              </FormGroup>
            </Col>
            <Col md={4} sm={12}>
                <Label for="priorAutoInsuranceLength">How long was the prior auto insurance policy in effect?</Label>
                <FormGroup>
                  <label className="c-radio mr-3">
                    <Input
                      readOnly
                      id="priorAutoInsuranceLength"
                      type="radio"
                      name="priorAutoInsuranceLength"
                      defaultValue="LessThanSixMonths"
                      checked={priorAutoInsurance['length'] === "LessThanSixMonths"}
                    />
                  {priorAutoInsurance['length'] === "LessThanSixMonths" ? <span className="fa fa-check mr-1"></span> : undefined}{'<'} 6 Months
                </label>
                  <label className="c-radio mr-3">
                    <Input
                      readOnly
                      id="priorAutoInsuranceLength"
                      type="radio"
                      name="priorAutoInsuranceLength"
                      defaultValue="SixToElevenMonths"
                      checked={priorAutoInsurance['length'] === "SixToElevenMonths"}
                    />
                  {priorAutoInsurance['length'] === "SixToElevenMonths" ? <span className="fa fa-check mr-1"></span> : undefined}6 - 11 Months
                </label>
                  <label className="c-radio mr-3">
                    <Input
                      readOnly
                      id="priorAutoInsuranceLength"
                      type="radio"
                      name="priorAutoInsuranceLength"
                      defaultValue="TwelveToSeventeenMonths"
                      checked={priorAutoInsurance['length'] === "TwelveToSeventeenMonths"}
                    />
                  {priorAutoInsurance['length'] === "TwelveToSeventeenMonths" ? <span className="fa fa-check mr-1"></span> : undefined}12 - 17 Months
                </label>
                  <label className="c-radio mr-3">
                    <Input
                      readOnly
                      id="priorAutoInsuranceLength"
                      type="radio"
                      name="priorAutoInsuranceLength"
                      defaultValue="EighteenToTwentyThreeMonths"
                      checked={priorAutoInsurance['length'] === "EighteenToTwentyThreeMonths"}
                    />
                  {priorAutoInsurance['length'] === "EighteenToTwentyThreeMonths" ? <span className="fa fa-check mr-1"></span> : undefined}18 - 20 Months
                </label>
                  <label className="c-radio mr-3">
                    <Input
                      readOnly
                      id="priorAutoInsuranceLength"
                      type="radio"
                      name="priorAutoInsuranceLength"
                      defaultValue="MoreThanTwentyFourMonths"
                      checked={priorAutoInsurance['length'] === "MoreThanTwentyFourMonths"}
                    />
                  {priorAutoInsurance['length'] === "MoreThanTwentyFourMonths" ? <span className="fa fa-check mr-1"></span> : undefined}{'>'} 24 Months
                </label>
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <h4>Current Work</h4>
              </FormGroup>
            </Col>
            <Col md={4} sm={12}>
              <FormGroup>
                <Label for="employerName">Employer Name</Label>
                <Input
                  readOnly
                  value={currentWork.employerName}
                  type="text"
                  name="employerName"
                  id="employerName"
                />
              </FormGroup>
            </Col>
            <Col md={8} sm={12}>
              <Label for="employmentType">Employment Type</Label>
              <FormGroup>
                <label className="c-radio mr-3">
                  <Input
                    readOnly
                    id="employmentType"
                    type="radio"
                    name="i-radio-3"
                    defaultValue="Employed"
                    checked={currentWork.employmentType === "Employed"}
                  />
                  {currentWork.employmentType === "Employed" ? <span className="fa fa-check mr-1"></span> : undefined}Employed
                </label>
                <label className="c-radio mr-3">
                  <Input
                    readOnly
                    id="employmentType"
                    type="radio"
                    name="i-radio-3"
                    defaultValue="Unemployed"
                    checked={currentWork.employmentType === "Unemployed"}
                  />
                  {currentWork.employmentType === "Unemployed" ? <span className="fa fa-check mr-1"></span> : undefined}Unemployed
                </label>
                <label className="c-radio mr-3">
                  <Input
                    readOnly
                    id="employmentType"
                    type="radio"
                    name="i-radio-3"
                    defaultValue="Self-employed"
                    checked={currentWork.employmentType === "Self-employed"}
                  />
                  {currentWork.employmentType === "Self-employed" ? <span className="fa fa-check mr-1"></span> : undefined}Self-employed
                </label>
                <label className="c-radio mr-3">
                  <Input
                    readOnly
                    id="employmentType"
                    type="radio"
                    name="i-radio-3"
                    defaultValue="Military"
                    checked={currentWork.employmentType === "Military"}
                  />
                  {currentWork.employmentType === "Military" ? <span className="fa fa-check mr-1"></span> : undefined}Military
                </label>
                <label className="c-radio mr-3">
                  <Input
                    readOnly
                    id="employmentType"
                    type="radio"
                    name="i-radio-3"
                    defaultValue="Retired"
                    checked={currentWork.employmentType === "Retired"}
                  />
                  {currentWork.employmentType === "Retired" ? <span className="fa fa-check mr-1"></span> : undefined}Retired
                </label>
                <label className="c-radio mr-3">
                  <Input
                    readOnly
                    id="employmentType"
                    type="radio"
                    name="i-radio-3"
                    defaultValue="Other"
                    checked={currentWork.employmentType === "Other"}
                  />
                  {currentWork.employmentType === "Other" ? <span className="fa fa-check mr-1"></span> : undefined}Other
                </label>
              </FormGroup>
            </Col>
            <Col md={4} sm={12}>
              <FormGroup>
                <Label for="salary">Salary</Label>
                <Input
                  readOnly
                  value={currentWork.salary}
                  type="number"
                  name="salary"
                  id="salary"
                />
              </FormGroup>
            </Col>
            {/* <Col md={8} sm={12}>
              <Label for=">Salary">Salary Type</Label>
              <FormGroup>
                <label className="c-radio mr-3">
                  <Input
                    readOnly
                    id="Salary"
                    type="radio"
                    name="i-radio-4"
                    defaultValue="Weekly"
                    checked={currentWork.salaryType === "Weekly"}
                  />
                  <span className="fa fa-check"></span>Weekly
                </label>
                <label className="c-radio mr-3">
                  <Input
                    readOnly
                    id="Salary"
                    type="radio"
                    name="i-radio-4"
                    defaultValue="Bi-Weekly"
                    checked={currentWork.salaryType === "Bi-Weekly"}
                  />
                  <span className="fa fa-check"></span> Bi-Weekly
                </label>
                <label className="c-radio mr-3">
                  <Input
                    readOnly
                    id="Salary"
                    type="radio"
                    name="i-radio-4"
                    defaultValue="Monthly"
                    checked={currentWork.salaryType === "Monthly"}
                  />
                  <span className="fa fa-check"></span>Monthly
                </label>
                <label className="c-radio mr-3">
                  <Input
                    readOnly
                    id="Salary"
                    type="radio"
                    name="i-radio-4"
                    defaultValue="Annually"
                    checked={currentWork.salaryType === "Annually"}
                  />
                  <span className="fa fa-check"></span> Annually
                </label>
              </FormGroup>
            </Col> */}
            <Col md={6} sm={12}>
              <FormGroup>
                <Label for="occupation">Occupation</Label>
                <Input
                  readOnly
                  value={currentWork.occupation}
                  type="text"
                  name="occupation"
                  id="occupation"
                />
              </FormGroup>
            </Col>
            <Col md={6} sm={12}>
              <FormGroup>
                <Label for="phone">Work Phone</Label>
                <Input
                  readOnly
                  value={currentWork.phone}
                  type="number"
                  name="phone"
                  id="phone"
                />
              </FormGroup>
            </Col>
            <Col md={6} sm={12}>
              <Label>Times at Address</Label>
              <Row>
                <Col md={6}>
                  <FormGroup className={"d-flex align-items-center"}>
                    <Label className={"mr-2"} for="Yrs">
                      Yrs
                    </Label>
                    <Input
                      readOnly
                      value={currentWorkYears}
                      type="text"
                      name="years"
                      id="years"
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className={"d-flex align-items-center"}>
                    <Label className={"mr-2"} for="months">
                      Mos
                    </Label>
                    <Input
                      readOnly
                      value={currentWorkMonths}
                      type="text"
                      name="months"
                      id="months"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>

            <Col md={12}>
              <FormGroup>
                <h4>Previous Work</h4>
              </FormGroup>
            </Col>
            <Col md={4} sm={12}>
              <FormGroup>
                <Label for="employerName">Employer Name</Label>
                <Input
                  readOnly
                  value={previousWork.employerName}
                  type="text"
                  name="employerName"
                  id="employerName"
                />
              </FormGroup>
            </Col>
            <Col md={8} sm={12}>
              <Label for="employmentType">Employment Type</Label>
              <FormGroup>
                <label className="c-radio mr-3">
                  <Input
                    readOnly
                    id="employmentType"
                    type="radio"
                    name="i-radio-3"
                    defaultValue="Employed"
                    checked={previousWork.employmentType === "Employed"}
                  />
                  {previousWork.employmentType === "Employed" ? <span className="fa fa-check mr-1"></span> : undefined}Employed
                </label>
                <label className="c-radio mr-3">
                  <Input
                    readOnly
                    id="employmentType"
                    type="radio"
                    name="i-radio-3"
                    defaultValue="Unemployed"
                    checked={previousWork.employmentType === "Unemployed"}
                  />
                  {previousWork.employmentType === "Unemployed" ? <span className="fa fa-check mr-1"></span> : undefined}Unemployed
                </label>
                <label className="c-radio mr-3">
                  <Input
                    readOnly
                    id="employmentType"
                    type="radio"
                    name="i-radio-3"
                    defaultValue="Self-employed"
                    checked={previousWork.employmentType === "Self-employed"}
                  />
                  {previousWork.employmentType === "Self-employed" ? <span className="fa fa-check mr-1"></span> : undefined}Self-employed
                </label>
                <label className="c-radio mr-3">
                  <Input
                    readOnly
                    id="employmentType"
                    type="radio"
                    name="i-radio-3"
                    defaultValue="Military"
                    checked={previousWork.employmentType === "Military"}
                  />
                  {previousWork.employmentType === "Military" ? <span className="fa fa-check mr-1"></span> : undefined}Military
                </label>
                <label className="c-radio mr-3">
                  <Input
                    readOnly
                    id="employmentType"
                    type="radio"
                    name="i-radio-3"
                    defaultValue="Retired"
                    checked={previousWork.employmentType === "Retired"}
                  />
                  {previousWork.employmentType === "Retired" ? <span className="fa fa-check mr-1"></span> : undefined}Retired
                </label>
                <label className="c-radio mr-3">
                  <Input
                    readOnly
                    id="employmentType"
                    type="radio"
                    name="i-radio-3"
                    defaultValue="Other"
                    checked={previousWork.employmentType === "Other"}
                  />
                  {previousWork.employmentType === "Other" ? <span className="fa fa-check mr-1"></span> : undefined}Other
                </label>
              </FormGroup>
            </Col>
            <Col md={4} sm={12}>
              <FormGroup>
                <Label for="salary">Salary</Label>
                <Input
                  readOnly
                  value={previousWork.salary}
                  type="number"
                  name="salary"
                  id="salary"
                />
              </FormGroup>
            </Col>
            {/* <Col md={8} sm={12}>
              <Label for=">Salary">Salary Type</Label>
              <FormGroup>
                <label className="c-radio mr-3">
                  <Input
                    readOnly
                    id="Salary"
                    type="radio"
                    name="i-radio-5"
                    defaultValue="Weekly"
                    checked={previousWork.salaryType === "Weekly"}
                  />
                  <span className="fa fa-check"></span>Weekly
                </label>
                <label className="c-radio mr-3">
                  <Input
                    readOnly
                    id="Salary"
                    type="radio"
                    name="i-radio-5"
                    defaultValue="Bi-Weekly"
                    checked={previousWork.salaryType === "Bi-Weekly"}
                  />
                  <span className="fa fa-check"></span> Bi-Weekly
                </label>
                <label className="c-radio mr-3">
                  <Input
                    readOnly
                    id="Salary"
                    type="radio"
                    name="i-radio-5"
                    defaultValue="Monthly"
                    checked={previousWork.salaryType === "Monthly"}
                  />
                  <span className="fa fa-check"></span>Monthly
                </label>
                <label className="c-radio mr-3">
                  <Input
                    readOnly
                    id="Salary"
                    type="radio"
                    name="i-radio-5"
                    defaultValue="Annually"
                    checked={previousWork.salaryType === "Annually"}
                  />
                  <span className="fa fa-check"></span> Annually
                </label>
              </FormGroup>
            </Col> */}
            <Col md={6} sm={12}>
              <FormGroup>
                <Label for="occupation">Occupation</Label>
                <Input
                  readOnly
                  value={previousWork.occupation}
                  type="text"
                  name="occupation"
                  id="occupation"
                />
              </FormGroup>
            </Col>
            <Col md={6} sm={12}>
              <FormGroup>
                <Label for="phone">Work Phone</Label>
                <Input
                  readOnly
                  value={previousWork.phone}
                  type="number"
                  name="phone"
                  id="phone"
                />
              </FormGroup>
            </Col>
            {/*<Col md={6} sm={12}>*/}
            {/*    <FormGroup>*/}
            {/*        <Label for="previousLengthofEmployment">Previous Length of Employment</Label>*/}
            {/*        <Input
              readOnly*/}
            {/*            value={data.previousLengthofEmployment}*/}
            {/*            type="text"*/}
            {/*            name="previousLengthofEmployment"*/}
            {/*            id="previousLengthofEmployment"*/}
            {/*        />*/}
            {/*    </FormGroup>*/}
            {/*</Col>*/}
            <Col md={6} sm={12}>
              <FormGroup>
                <Label for="otherIncomeMonthly">Other Income Monthly</Label>
                <Input
                  readOnly
                  value={data.otherIncomeMonthly}
                  type="text"
                  name="otherIncomeMonthly"
                  id="otherIncomeMonthly"
                />
              </FormGroup>
            </Col>
            <Col md={6} sm={12}>
              <FormGroup>
                <Label for="sourceofOtherIncome">Source of Other Income</Label>
                <Input
                  readOnly
                  value={data.sourceofOtherIncome}
                  type="text"
                  name="sourceofOtherIncome"
                  id="sourceofOtherIncome"
                />
              </FormGroup>
            </Col>
            <Col md={6} sm={12}>
              <FormGroup>
                <Label>Routing Number</Label>
                <Input
                  readOnly
                  value={data.routingNumber}
                  type="number"
                  name="routingNumber"
                  id="routingNumber"
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Home Phone</Label>
                <Input
                  readOnly
                  value={data.homePhone}
                  type="number"
                  name="homePhone"
                  id="homePhone"
                />
              </FormGroup>
            </Col>

            <Col md={12}>
              <FormGroup>
                <h4>Background Check</h4>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label>Found</Label>
                <Input
                  readOnly
                  value={data.offencesCount}
                  type="number"
                  name="offencesCount"
                  id="offencesCount"
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label>BC Type</Label>
                <Input
                  readOnly
                  value={data.backgroundCheckType}
                  type="backgroundCheckType"
                  name="backgroundCheckType"
                  id="backgroundCheckType"
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label>Offence</Label>
                <Input
                  readOnly
                  value={data.offence}
                  type="text"
                  name="offence"
                  id="offence"
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label>Comments</Label>
                <Input
                  readOnly
                  value={data.offenceComments}
                  type="text"
                  name="offenceComments"
                  id="offenceComments"
                />
              </FormGroup>
            </Col>

            <Col md={12}>
              <FormGroup>
                <h4>Bank Review</h4>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label>Type</Label>
                <Input
                  readOnly
                  value={data.bankReviewType}
                  type="text"
                  name="bankReviewType"
                  id="bankReviewType"
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label>Request Code</Label>
                <Input
                  readOnly
                  value={data.requestCode}
                  type="text"
                  name="requestCode"
                  id="requestCode"
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label>Current Balance</Label>
                <Input
                  readOnly
                  value={data.currentBalance}
                  type="text"
                  name="currentBalance"
                  id="currentBalance"
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label>Avarage Balance</Label>
                <Input
                  readOnly
                  value={data.averageBalance}
                  type="text"
                  name="averageBalance"
                  id="averageBalance"
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label>Overdraft</Label>
                <Input
                  readOnly
                  value={data.overdraft}
                  type="text"
                  name="overdraft"
                  id="overdraft"
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label>Automobile Loan Debit</Label>
                <Input
                  readOnly
                  value={data.automobileLoanDebit}
                  type="text"
                  name="automobileLoanDebit"
                  id="automobileLoanDebit"
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label>Affordability Index</Label>
                <Input
                  readOnly
                  value={data.totalDebits}
                  type="number"
                  name="totalDebits"
                  id="totalDebits"
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </TabPane>
    );
  }
}

export default withRouter(ApplicationsTab);
