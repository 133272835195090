import React from "react";
import { render } from "react-dom";
import { Button, Table } from "reactstrap";
import api from "../../../../Api/apiEndPoint";
import { SecurePDFViewer } from "./SecurePDFViewer";
import SharedComponent from "../../Shared";
import { AppApiEndpoints } from "../../../../Api/@driveavva/core/apps.api";

/**
 * Used to display images
 */
function toDataURL(url, headers) {
  return new Promise((resolve) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    if (headers) {
      Object.keys(headers).forEach((key) => {
        xhr.setRequestHeader(key, headers[key]);
      });
    }
    xhr.responseType = "blob";
    xhr.send();
  });
}

/**
 * Converts bytes into a human readable format
 *
 * @see https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
 * @since 0.0.0
 * @author randers
 * @todo move to @driveavva/shared
 */
function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
}

class Documents extends SharedComponent {
  state = {
    wrapper: null,
    loading: null,
  };
  constructor(props) {
    super(props);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside(e) {
    if (this.state.wrapper && !this.state.wrapper.contains(e.target)) {
      this.state.wrapper.remove();
    }
  }
  componentDidMount() {
    document.body.addEventListener("click", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.handleClickOutside);
  }

  showDocument(d) {
    const wrapper = document.createElement("div");
    const isImage = ["image/png", "image/jpeg", "image/jpg"].includes(
      d.assets.type
    );
    wrapper.id = d.id;
    wrapper.style.position = "fixed";
    wrapper.style.top = "50%";
    wrapper.style.left = "50%";
    wrapper.style.width = "50vw";
    wrapper.style.height = "80vh";
    wrapper.style.overflow = "hidden";
    wrapper.style.transform = "translate(-50%, -50%)";
    wrapper.style.zIndex = 9999;
    wrapper.style.background = "white";
    wrapper.style.boxShadow =
      "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px";

    // Disable context menu on images so user can't save the image
    if (isImage) {
      wrapper.oncontextmenu = (e) => {
        e.preventDefault();
      };
      wrapper.ondrag = (e) => e.preventDefault();
    }

    this.setState({ wrapper, loading: d.id });

    if (d.assets.type === "application/json") {
      AppApiEndpoints.GetFile(this.props.applicationId, d.assets.id).then(
        (f) => {
          this.setState({ loading: null });
          render(
            <pre>{JSON.stringify(f, null, 2)}</pre>,
            document.body.appendChild(wrapper)
          );
        }
      );
    } else if (d.assets.type === "application/pdf") {
      this.setState({ loading: null });
      render(
        <SecurePDFViewer
          file={{
            url: `${api["@driveavva"].core.Apps}/${this.props.applicationId}/assets/${d.assets.id}`,
            httpHeaders: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Depricated-Admin": process.env.REACT_APP_API_SECURE_KEY,
            },
          }}
        />,
        document.body.appendChild(wrapper)
      );
    } else if (isImage) {
      toDataURL(
        `${api["@driveavva"].core.Apps}/${this.props.applicationId}/assets/${d.assets.id}`,
        {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Depricated-Admin": process.env.REACT_APP_API_SECURE_KEY,
        }
      ).then((f) => {
        this.setState({ loading: null });
        render(
          <img
            src={f}
            alt={d.assets.name}
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
            }}
            onContextMenu={(e) => e.preventDefault()}
            draggable={false}
          />,
          document.body.appendChild(wrapper)
        );
      });
    } else {
      alert(`${d.assets.type} is not yet supported for secure viewing`);
    }
  }
  render() {
    if (!this.props.documents || !this.props.applicationId) return null;
    console.log("Render Documents", this.props.documents);

    return (
      <Table className="bg-white">
        <thead>
          <tr>
            <th>Name</th>
            <th>Size</th>
            <th>Type</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {this.props.documents.map((document) => (
            <tr key={document.id}>
              <td>{document.assets.name}</td>
              <td>{humanFileSize(document.assets.size)}</td>
              <td>{document.type}</td>
              <td>
                <Button
                  onClick={() => this.showDocument(document)}
                  color="info"
                  disabled={this.state.loading === document.id}
                >
                  {this.state.loading === document.id ? "Loading..." : "View"}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
}

export { Documents };
