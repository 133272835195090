import React from 'react';
import SharedComponent from '../../Shared';
import { Api } from '../../../../Api/index';

class Debug extends SharedComponent {

  state = {
    data: {},
  }

  componentDidMount() {
    console.log('this.props: ', this.props);
    this.getUser(this.props.id);
  }

  getUser = async (userId) => {
    try {
      const data = await Api["@driveavva"].new.Users.UserDetails(userId);
      console.log('data: ', data);
      this.setState({ data }, () => { console.log(this.state.data) });
    } catch (error) {
      this.setState({ data: error });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('debug did update state: ', this.state);
  }

  render() {
    return (
      <div>
        <h3>Debug</h3>
        <pre>{JSON.stringify(this.state.data, null, 2)}</pre>
      </div>
    );
  }
}

export default Debug;