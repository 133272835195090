import { getAuthenticatedAxios } from "../../helpers";
import api from "../../apiEndPoint";
import { encrypt, getAndDecryptDataFromResponse } from ".";

export const AppApiEndpoints = {
  ApplicationsList: (Name, Status, ShowAll = false) => {
    let url = new URL(api["@driveavva"].core.Apps);
    if (Name) {
      url.searchParams.append("id", Name);
    }
    if (ShowAll) {
      url.searchParams.append("show", "all");
    }
    if (Status) {
      url.searchParams.append("status", Status);
    }
    console.log(url.toString(), ShowAll);
    // Apply pagination
    return getAuthenticatedAxios(true)
      .get(url.toString())
      .then(async (r) => await getAndDecryptDataFromResponse(r));
  },

  AppDetails: (AppId) =>
    getAuthenticatedAxios(true)
      .get(`${api["@driveavva"].core.Apps}/${AppId}`)
      .then(async (r) => await getAndDecryptDataFromResponse(r)),

  HubspotPipelineDetails: () =>
    getAuthenticatedAxios(true)
      .get(`${api["@driveavva"].core.Apps}/hubspot-pipeline`)
      .then(async (r) => await getAndDecryptDataFromResponse(r)),

  HubspotSync: (id = false) =>
    getAuthenticatedAxios(true)
      .post(
        `${api["@driveavva"].core.Apps}/hubspot-sync${id ? `?id=${id}` : ""}`
      )
      .then(async (r) => await getAndDecryptDataFromResponse(r)),

  UpdateApp: async (AppId, data) =>
    getAuthenticatedAxios(true)
      .put(`${api["@driveavva"].core.Apps}/${AppId}`, data)
      .then(getAndDecryptDataFromResponse),

  GetFile: (AppId, assetId) =>
    getAuthenticatedAxios(true)
      .get(`${api["@driveavva"].core.Apps}/${AppId}/assets/${assetId}`)
      .then(getAndDecryptDataFromResponse),

  TransactionsSync: (AppId, assetId) =>
    getAuthenticatedAxios(true)
      .get(`${api["@driveavva"].core.Apps}/${AppId}/transactions-sync`)
      .then(getAndDecryptDataFromResponse),
  ReRunPlaidReport: (AppId) =>
    getAuthenticatedAxios(true)
      .get(`${api["@driveavva"].core.Apps}/${AppId}/rerun-plaid-report`)
      .then(getAndDecryptDataFromResponse),
  UpdateFinancialRecommendation: async (AppId, recommendation) =>
    getAuthenticatedAxios(true)
      .post(
        `${api["@driveavva"].core.Apps}/${AppId}/update-financial-recommendation`,
        { recommendation }
      )
      .then(getAndDecryptDataFromResponse),
  GetPlaidAssetReports: async (AppId) =>
    getAuthenticatedAxios(true)
      .get(`${api["@driveavva"].core.Apps}/${AppId}/plaid-asset-reports`)
      .then(getAndDecryptDataFromResponse),
  FakePandaDocs: async (AppId) =>
    getAuthenticatedAxios(true)
      .post(`${api["@driveavva"].core.Apps}/${AppId}/fake-pandadocs`)
      .then(getAndDecryptDataFromResponse),
  ChangeVehicle: async (AppId, payload) =>
    getAuthenticatedAxios(true)
      .post(`${api["@driveavva"].core.Apps}/${AppId}/change-vehicle`, payload)
      .then(getAndDecryptDataFromResponse),
};
