import React from "react";
import { Table, Button } from "reactstrap";
import { Api } from "../../../Api/index";
import SharedComponent from "../Shared";
import { RewardModal } from "./modals";
import { formatFullDate } from "./utils";

class UserRewards extends SharedComponent {
  state = {
    modalOpen: false,
    approving: false,
  };

  approve = (id) => {
    this.setState({ approving: id });
    Api["@driveavva"].new.Rewards.RewardApprove(id)
      .then((res) => {
        this.setState({ approving: false });
        window.location.reload();
      })
      .catch(() => {
        this.renderErrorAlert(
          "Oops!",
          "Something went wrong, unable to approve reward"
        );
      });
  };

  getAppSection = (reward) => {
    if (reward.earnable_at && !reward.earned_at && !reward.claimed_at)
      return "EARNABLE REWARDS";
    if (reward.earned_at && !reward.claimed_at) return "MY REWARDS";
    if (reward.claimed_at) return "Claimed (Not visible)";
    return "N/A";
  };

  render() {
    const hasRewards =
      this.props.data.user_rewards && this.props.data.user_rewards.length > 0;
    const hasRedemptions =
      this.props.data.reward_redemptions &&
      this.props.data.reward_redemptions.length > 0;

    return (
      <>
        <RewardModal
          isOpen={this.state.modalOpen}
          onClose={() => this.setState({ modalOpen: !this.state.modalOpen })}
        />
        <div className="d-flex justify-content-between mb-2 align-items-center">
          <h3 className="m-0">Rewards</h3>
          {/* <Button
            size="sm"
            color="info"
            onClick={() => this.setState({ modalOpen: true })}
          >
            Issue Reward
          </Button> */}
        </div>
        <Table className="table table-striped table-bordered table-hover mb-4">
          <thead>
            <tr>
              <th className="sort-alpha">Id</th>
              <th className="sort-alpha">Name</th>
              <th className="sort-alpha">Coin Amount</th>
              <th className="sort-alpha">App Section</th>
              {/* <th className="sort-alpha">Approved At</th> */}
              {/* <th className="sort-alpha">Claimed?</th> */}
              {/* <th className="sort-alpha">Actions</th> */}
            </tr>
          </thead>
          <tbody>
            {!hasRewards && <div className="ml-2 my-1">No rewards yet</div>}
            {hasRewards &&
              this.props.data.user_rewards.map((user_reward, index) => (
                <tr key={`user_rewards_${index}`}>
                  <td>{user_reward.id}</td>
                  <td>{user_reward.reward.name}</td>
                  <td>{user_reward.coin_amount}</td>
                  <td>{this.getAppSection(user_reward)}</td>
                  {/* <td>
                    {user_reward.earnable_at
                      ? new Date(user_reward.earnable_at + "Z").toLocaleString()
                      : null}
                  </td>
                  <td>
                    {user_reward.approved_at
                      ? new Date(user_reward.approved_at).toLocaleString()
                      : null}
                  </td>
                  <td>
                    {user_reward.claimed_at
                      ? new Date(user_reward.claimed_at).toLocaleString()
                      : "No"}
                  </td>
                  <td>
                    {!user_reward.approved_at && !user_reward.claimed_at && (
                      <Button
                        className="mr-1"
                        color="success"
                        onClick={() => this.approve(user_reward.id)}
                        disabled={this.approving === user_reward.id}
                      >
                        Approv
                        {this.approving === user_reward.id ? "ing..." : "e"}
                      </Button>
                    )}
                  </td> */}
                </tr>
              ))}
          </tbody>
        </Table>
        <div className="d-flex justify-content-between mb-2 align-items-center">
          <h3 className="m-0">Reward Redemptions</h3>
          {/* <Button
            size="sm"
            color="info"
            onClick={() => this.setState({ modalOpen: true })}
          >
            Issue Reward
          </Button> */}
        </div>
        <Table className="table table-striped table-bordered table-hover mb-4">
          <thead>
            <tr>
              <th className="sort-alpha">Id</th>
              <th className="sort-alpha">Name</th>
              <th className="sort-alpha">Amount</th>
              <th className="sort-alpha">Issued At</th>
              <th className="sort-alpha">Order #</th>
            </tr>
          </thead>
          <tbody>
            {!hasRedemptions && (
              <div className="ml-2 my-1">No reward redemptions yet</div>
            )}
            {hasRedemptions &&
              this.props.data.reward_redemptions.map((d, index) => (
                <tr key={`r${d.id}`}>
                  <td>{d.id}</td>
                  <td>{d.reward_name}</td>
                  <td>${d.coin_amount}</td>
                  <td>{new Date(d.created_at + "Z").toLocaleString()}</td>
                  <td>{d.external_id}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </>
    );
  }
}

export { UserRewards };
