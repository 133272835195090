import React from "react";
import { TabPane, ListGroup, ListGroupItem, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import Moment from "react-moment";
import { Api } from "../../../Api/index";
import SharedComponent from "../Shared";
import ModalMap from "./ModalMap";
class GeoFence extends SharedComponent {
  state = {
    items: [],
    mapModal: false,
    latitude: null,
    longitude: null,
    zoom: 11,
    center: {
      lat: 0,
      lng: 0,
    },
  };
  componentDidMount() {
    this.GpsNotifications();
  }
  GpsNotifications = () => {
    let applicationId = this.props.id;

    Api.ApplicationManagement.ApplicationGpsNotifications(applicationId)
      .then((items) => {
        this.setState({ items: items.items });
      })
      .catch(console.log);
  };

  handleMap = (latitude, longitude) => {
    const center = this.state.center;
    center.lat = latitude;
    center.lng = longitude;
    this.setState({
      mapModal: !this.state.mapModal,
      latitude,
      longitude,
      center,
    });
  };
  render() {
    const { items } = this.state;
    return (
      <TabPane tabId="GeoFence">
        <Row>
          <Col xs={12} className="mt-3">
            <h3 className="mb-0">Geo Fence Notifications</h3>
            <hr className="w-25 float-left" />
          </Col>
        </Row>
        {items.length === 0 ? (
          this.renderSadFace(false)
        ) : (
          <Row>
            <Col md={12}>
              <ListGroup className="mb-3 w-50">
                {items.map((item, index) => (
                  <ListGroupItem
                    key={index}
                    className="d-flex justify-content-between"
                  >
                    <div>
                      <h6 className="mb-3">#{item.direction}</h6>
                      <h6 className="m-0">{item.address}</h6>
                    </div>
                    <div>
                      <h6 className="mb-2 d-flex align-items-center justify-content-center">
                        <Moment format="MM/DD/YYYY">{item.created}</Moment>
                      </h6>
                      <button
                        className="mb-2 text-info float-right btn"
                        onClick={() => this.handleMap(item.lat, item.long)}
                      >
                        View On Map
                      </button>
                    </div>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </Col>
          </Row>
        )}

        <ModalMap
          title="Geo Fence Location"
          isOpenMap={this.state.mapModal}
          closeModalMap={() => this.handleMap(null, null)}
          center={this.state.center}
          zoom={this.state.zoom}
          latitude={this.state.latitude}
          longitude={this.state.longitude}
        />
      </TabPane>
    );
  }
}

export default withRouter(GeoFence);
