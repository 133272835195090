import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import SharedComponent from "../../Shared";
import { Api } from "../../../../Api/index";

export class PhoneUpdateModal extends SharedComponent {
  state = {
    phone: "",
    value: "",
    error: false,
    submitting: false,
  };

  formatPhone = (s) => {
    const areaCode = s.substring(0, 3);
    const middle = s.substring(3, 6);
    const last = s.substring(6, 10);
    let phone = `(${areaCode}) ${middle}-${last}`;
    if (s.length > 6) {
      phone = `(${areaCode}) ${middle} - ${last}`;
    } else if (s.length > 3) {
      phone = `(${areaCode}) ${middle}`;
    } else if (s.length > 0) {
      phone = `(${areaCode}`;
    } else phone = "";
    return phone;
  };

  handlePhoneChange = (e) => {
    const value = e.currentTarget.value
      .replace(/\D/g, "") // remove non-digits
      .substring(0, 10); // limit to ten digits

    console.log("Helko", value);
    this.setState({
      value,
      error: false,
      phone: this.formatPhone(value),
    });
  };

  submit = () => {
    if (!this.state.value || !/\d{10}/.test(this.state.value)) {
      this.setState({
        error: "Please enter a valid phone #",
      });
    } else if (`1${this.state.value}` === this.props.user.phone) {
      this.setState({
        error: "User is already setup for this phone number",
      });
    } else {
      this.renderConfirmAlert(
        `Are you sure you want to ${
          this.props.user.name
        }'s phone from ${this.formatPhone(this.props.user.phone.slice(1))} to ${
          this.state.phone
        }? Once this is completed the user will be logged out everywhere and have to login with ${
          this.state.phone
        }`,
        async () => {
          this.setState({ submitting: true });
          Api["@driveavva"].new.Users.UpdatePhone(
            this.props.user.id,
            this.state.value
          )
            .then(() => {
              this.renderSuccessAlert(
                "Success!",
                `${this.props.user.name}'s phone has been updated. Their login is now ${this.state.phone}`,
                () => window.location.reload()
              );
            })
            .catch((e) => {
              this.setState({
                submitting: false,
                error: e.message.startsWith(
                  'duplicate key value violates unique constraint "users_phone_key"'
                )
                  ? "User already exists for this phone number. Please try another number"
                  : e.message,
              });
            });
        },
        this.props.onClose
      );
    }
  };

  render() {
    return (
      <>
        <Modal isOpen={this.props.isOpen} toggle={this.props.onClose}>
          <ModalHeader toggle={this.props.onClose}>
            Update {this.props.user.name}'s Phone
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="phone" className={this.state.error ? "error" : ""}>
                  New Phone #
                </Label>
                <Input
                  type="text"
                  name="phone"
                  value={this.state.phone}
                  className={this.state.error ? "error" : ""}
                  onChange={this.handlePhoneChange}
                />
                {this.state.error && (
                  <span
                    className="error"
                    dangerouslySetInnerHTML={{ __html: this.state.error }}
                  />
                )}
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              onClick={this.submit}
              disabled={this.state.submitting}
            >
              Updat{this.state.submitting ? "ing" : "e"}
            </Button>
            <Button color="secondary" onClick={this.props.onClose}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
