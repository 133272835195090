import React from "react";
import ContentWrapper from "../../Layout/ContentWrapper";
import {
  Card,
  CardBody,
  Button,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  CardFooter,
} from "reactstrap";
import SharedComponent from "../Shared";
import { Api } from "../../../Api/index";
class ClientDetails extends SharedComponent {
  state = {
    data: {},
  };
  componentDidMount() {
    this.GetClientDetails();
  }

  doSubmit = () => {
    const { data } = this.state;
    let ClientId = this.props.match.params.id;
    let obj = {
      email: data.email,
      phoneNumber: data.phoneNumber,
      firstName: data.firstName,
      lastName: data.lastName,
      middleIntial: data.middleNameInitial,
    };
    Api.ClientManagment.UpdateClient(ClientId, obj)
      .then(() => {
        this.renderSuccessAlert("Updated successfully", "Click the button");
        this.props.history.push("/clients");
      })
      .catch(() => {
        this.renderErrorAlert(
          "Ops!",
          "Something went wrong, please try again!"
        );
      });
  };

  GetClientDetails = () => {
    let ClientId = this.props.match.params.id;
    Api.ClientManagement.ClientDetails(ClientId)
      .then((response) => {
        this.setState({ data: response });
      })
      .catch(console.log);
  };
  render() {
    const { data } = this.state;
    return (
      <ContentWrapper>
        <div className="content-heading d-flex justify-content-between">
          <div>Client Details</div>
        </div>
        <Form onSubmit={this.handleSubmit}>
          <Card className="card-default">
            <CardBody>
              <Row>
                <Col className="pt-2" md={4} sm={12}>
                  <FormGroup>
                    <Label for="firstName">First Name </Label>
                    <Input
                      onChange={this.handleChange}
                      value={data.firstName}
                      type="text"
                      name="firstName"
                      id="id-firstName"
                    />
                  </FormGroup>
                </Col>
                <Col className="pt-2" md={4} sm={12}>
                  <FormGroup>
                    <Label for="middleNameInitial">Middle Name Initial</Label>
                    <Input
                      onChange={this.handleChange}
                      value={data.middleNameInitial}
                      type="text"
                      name="middleNameInitial"
                      id="id-middleNameInitial"
                    />
                  </FormGroup>
                </Col>
                <Col className="pt-2" md={4} sm={12}>
                  <FormGroup>
                    <Label for="lastName">Last Name</Label>
                    <Input
                      onChange={this.handleChange}
                      value={data.lastName}
                      type="text"
                      name="lastName"
                      id="id-lastName"
                    />
                  </FormGroup>
                </Col>
                <Col className="pt-2" md={4} sm={12}>
                  <FormGroup>
                    <Label for="phoneNumber">Phone Number</Label>
                    <Input
                      onChange={this.handleChange}
                      value={data.phoneNumber}
                      type="number"
                      name="phoneNumber"
                      id="id-phoneNumber"
                    />
                  </FormGroup>
                </Col>
                <Col className="pt-2" md={4} sm={12}>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      onChange={this.handleChange}
                      value={data.email}
                      type="email"
                      name="email"
                      id="id-email"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter className=" d-flex justify-content-between">
              <Button color="info" type="submit">
                Update
              </Button>
            </CardFooter>
          </Card>
        </Form>
      </ContentWrapper>
    );
  }
}

export { ClientDetails };
