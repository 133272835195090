import React from "react";
import ContentWrapper from "../../Layout/ContentWrapper";
import {
  Row,
  Col,
  TabContent,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import {
  Debug,
  Details,
  Logins,
  Notifications,
  Payments,
  Vehicle,
} from "./tabs";
import { Api } from "../../../Api/index";
import SharedComponent from "../Shared";
import { SmsModal, NotificationModal, BannerModal } from "./modals";
// import { tempData } from "./utils";

const TABS = [
  "Details",
  "Logins",
  "Notifications",
  // "Payments",
  "Vehicle",
  "Debug",
];

class MembersDetail extends SharedComponent {
  state = {
    modal: "",
    activeTab:
      TABS.find((t) => window.location.hash === `#${encodeURI(t)}`) || TABS[0],
    statusMsg: "",
    member: {},
    notifications: [], // tempData,
  };

  componentDidMount() {
    this.getUser(this.props.match.params.id);
  }

  getUser = async (userId) => {
    try {
      const res = await Api["@driveavva"].new.Users.UserDetails(userId);
      this.setState(
        {
          member: res.length ? res[0] : res,
          activeTab:
            TABS.find((t) => window.location.hash === `#${encodeURI(t)}`) ||
            TABS[0],
        },
        () => {
          // does the user have notifications enabled?, if so, get notifications
          this.getNotifications();
        }
      );
    } catch (error) {
      this.setState({ member: error });
    }
  };

  getNotifications = () => {
    Api["@driveavva"].new.Users.Notifications()
      .then((res) => {
        this.setState({ notifications: res });
      })
      .catch(() => {
        this.renderErrorAlert(
          "Oops!",
          "Something went wrong, not able to get Notifications!"
        );
      });
  };

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  handleNotificationDisplay = () => {
    this.setState({
      modal: "notifications",
    });
  };

  handleBannerDisplay = () => {
    this.setState({ model: "banner" });
  };

  render() {
    console.log(this.state.member.user_notifications);
    return (
      <ContentWrapper>
        <div className="content-heading d-flex justify-content-between">
          <div>Member Details</div>
          {/* <div>
            <ButtonDropdown
              isOpen={this.state.dropdownOpen}
              toggle={() =>
                this.setState({ dropdownOpen: !this.state.dropdownOpen })
              }
            >
              <DropdownToggle caret color="info">
                Actions
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={this.handleNotificationDisplay}>
                  Send Notification
                </DropdownItem>
                <DropdownItem onClick={this.handleBannerDisplay}>
                  Send Banner
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </div> */}
        </div>
        <h3>ID: {this.props.match.params.id}</h3>
        <Row>
          <Col xs={12}>
            <div className="card card-transparent">
              <Nav tabs justified>
                {TABS.map((tab) => (
                  <NavItem key={tab}>
                    <NavLink
                      active={this.state.activeTab === tab}
                      href={`/members/${this.state.member.id}#${encodeURI(
                        tab
                      )}`}
                      onClick={() => this.setState({ activeTab: tab })}
                    >
                      {tab}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>

              <TabContent activeTab={this.state.activeTab} className="bg-white">
                <TabPane tabId="Details">
                  {this.state.activeTab === "Details" && (
                    <Details member={this.state.member} />
                  )}
                </TabPane>
                <TabPane tabId="Logins">
                  {this.state.activeTab === "Logins" && (
                    <Logins logins={this.state.member.user_logins} />
                  )}
                </TabPane>
                <TabPane tabId="Vehicle">
                  {this.state.activeTab === "Vehicle" && (
                    <Vehicle vehicle={this.state.member.vehicle} />
                  )}
                </TabPane>
                <TabPane tabId="Notifications">
                  {this.state.activeTab === "Notifications" && (
                    <Notifications
                      notifications={this.state.member.user_notifications}
                    />
                  )}
                </TabPane>
                {/* <TabPane tabId="Payments">
                  {this.state.activeTab === "Payments" && (
                    <Payments lease={this.state.member.lease} />
                  )}
                </TabPane> */}
                <TabPane tabId="Debug">
                  {this.state.activeTab === "Debug" && (
                    <Debug id={this.props.match.params.id} />
                  )}
                </TabPane>
              </TabContent>
            </div>
          </Col>
        </Row>
      </ContentWrapper>
    );
  }
}

export { MembersDetail };
