import { getDataFromResponse, getAuthenticatedAxios } from "./helpers";
import api from "./apiEndPoint";

export const NotificationsApiEndpoints = {
  Schedule: {
    ScheduleList: (
      ApplicationNumber,
      ApplicationDate,
      TypeId,
      page,
      currentPage,
      pageSize,
      Status
    ) => {
      let url = new URL(`${api.scheduledMaintenances}`);
      if (ApplicationNumber) {
        url.searchParams.append("ApplicationNumber", ApplicationNumber);
      }
      if (ApplicationDate) {
        url.searchParams.append("ApplicationDate", ApplicationDate);
      }
      if (TypeId) {
        url.searchParams.append("TypeId", Number(TypeId));
      }
      if (Status) {
        url.searchParams.append("Status", Status);
      }
      // Apply pagination
      url.searchParams.append("Page", page || currentPage);
      url.searchParams.append("PageSize", pageSize);
      return getAuthenticatedAxios()
        .get(url)
        .then(getDataFromResponse);
    },

    ScheduledDetails: (ScheduledId) =>
      getAuthenticatedAxios()
        .get(`${api.scheduledMaintenances}/${ScheduledId}`)
        .then(getDataFromResponse),

    UpdateScheduled: (ScheduledId, data) =>
      getAuthenticatedAxios()
        .put(`${api.scheduledMaintenances}/${ScheduledId}`, data)
        .then(getDataFromResponse),

    ApproveOrCloseScheduled: (ScheduledId, Case) =>
      getAuthenticatedAxios()
        .put(`${api.scheduledMaintenances}/${ScheduledId}/${Case}`)
        .then(getDataFromResponse),
  },

  Unschedule: {
    UnscheduleList: (
      ApplicationNumber,
      ApplicationDate,
      page,
      currentPage,
      pageSize,
      Description,
      Status
    ) => {
      let url = new URL(`${api.unscheduledMaintenances}`);
      if (ApplicationNumber) {
        url.searchParams.append("ApplicationNumber", ApplicationNumber);
      }
      if (ApplicationDate) {
        url.searchParams.append("ApplicationDate", ApplicationDate);
      }
      if (Description) {
        url.searchParams.append("TypeId", Description);
      }
      if (Status) {
        url.searchParams.append("Status", Status);
      }
      // Apply pagination
      url.searchParams.append("Page", page || currentPage);
      url.searchParams.append("PageSize", pageSize);
      return getAuthenticatedAxios()
        .get(url)
        .then(getDataFromResponse);
    },

    UnScheduledDetails: (UnScheduledId) =>
      getAuthenticatedAxios()
        .get(`${api.unscheduledMaintenances}/${UnScheduledId}`)
        .then(getDataFromResponse),

    UpdateUnScheduled: (UnScheduledId, data) =>
      getAuthenticatedAxios()
        .put(`${api.unscheduledMaintenances}/${UnScheduledId}`, data)
        .then(getDataFromResponse),

    ApproveOrCloseUnScheduled: (UnScheduledId, Case) =>
      getAuthenticatedAxios()
        .put(`${api.unscheduledMaintenances}/${UnScheduledId}/${Case}`)
        .then(getDataFromResponse),
  },

  Incidents: {
    IncidentsList: (
      ApplicationNumber,
      ApplicationDate,
      page,
      currentPage,
      pageSize
    ) => {
      let url = new URL(api.incidents);
      if (ApplicationNumber) {
        url.searchParams.append("ApplicationNumber", ApplicationNumber);
      }
      if (ApplicationDate) {
        url.searchParams.append("ApplicationDate", ApplicationDate);
      }
      // Apply pagination
      url.searchParams.append("Page", page || currentPage);
      url.searchParams.append("PageSize", pageSize);
      return getAuthenticatedAxios()
        .get(url)
        .then(getDataFromResponse);
    },

    IncidentsDetails: (IncidentsId) =>
      getAuthenticatedAxios()
        .get(`${api.incidents}/${IncidentsId}`)
        .then(getDataFromResponse),
  },

  LongTrips: {
    LongTripsList: (
      ApplicationId,
      CreatedAt,
      page,
      currentPage,
      pageSize,
      Status,
      StartedDate,
      EndDate
    ) => {
      let url = new URL(api.longTrip);
      if (ApplicationId) {
        url.searchParams.append("ApplicationId", ApplicationId);
      }
      if (CreatedAt) {
        url.searchParams.append("CreatedAt", CreatedAt);
      }
      if (Status) {
        url.searchParams.append("Status", Status);
      }
      if (StartedDate) {
        url.searchParams.append("StartedDate", StartedDate);
      }
      if (EndDate) {
        url.searchParams.append("EndDate", EndDate);
      }
      // Apply pagination
      url.searchParams.append("Page", page || currentPage);
      url.searchParams.append("PageSize", pageSize);
      return getAuthenticatedAxios()
        .get(url)
        .then(getDataFromResponse);
    },
    LongTripDetails: (LongTripId) =>
      getAuthenticatedAxios()
        .get(`${api.longTrip}/${LongTripId}`)
        .then(getDataFromResponse),

    UpdateLongTrip: (LongTripId, data) =>
      getAuthenticatedAxios()
        .put(`${api.longTrip}/${LongTripId}`, data)
        .then(getDataFromResponse),

    ApproveOrCloseLongTrip: (LongTripId, Case) =>
      getAuthenticatedAxios()
        .put(`${api.longTrip}/${LongTripId}/${Case}`)
        .then(getDataFromResponse),
  },

  Support: {
    SupportList: (
      ApplicationNumber,
      CreatedAt,
      page,
      currentPage,
      pageSize,
      TypeId,
      Status
    ) => {
      let url = new URL(api.support);
      if (ApplicationNumber) {
        url.searchParams.append("ApplicationNumber", ApplicationNumber);
      }
      if (CreatedAt) {
        url.searchParams.append("CreatedAt", CreatedAt);
      }
      if (TypeId) {
        url.searchParams.append("TypeId", Number(TypeId));
      }
      if (Status) {
        url.searchParams.append("Status", Status);
      }
      // Apply pagination
      url.searchParams.append("Page", page || currentPage);
      url.searchParams.append("PageSize", pageSize);
      return getAuthenticatedAxios()
        .get(url)
        .then(getDataFromResponse);
    },
    SupportDetails: (SupportId) =>
      getAuthenticatedAxios()
        .get(`${api.support}/${SupportId}`)
        .then(getDataFromResponse),

    CloseSupport: (SupportId, Case) =>
      getAuthenticatedAxios()
        .post(`${api.support}/${SupportId}/${Case}`)
        .then(getDataFromResponse),
  },

  PilotFeedback: {
    PilotFeedbackList: (
      ApplicationNumber,
      CreatedAt,
      page,
      currentPage,
      pageSize,
      status
    ) => {
      let url = new URL(api.feedbackTickets);
      if (ApplicationNumber) {
        url.searchParams.append("ApplicationNumber", ApplicationNumber);
      }
      if (CreatedAt) {
        url.searchParams.append("CreatedAt", CreatedAt);
      }
      if (status) {
        url.searchParams.append("Status", status);
      }
      // Apply pagination
      url.searchParams.append("Page", page || currentPage);
      url.searchParams.append("PageSize", pageSize);
      return getAuthenticatedAxios()
        .get(url)
        .then(getDataFromResponse);
    },
    FeedBackDetails: (FeedBackId) =>
      getAuthenticatedAxios()
        .get(`${api.feedbackTickets}/${FeedBackId}`)
        .then(getDataFromResponse),

    CloseFeedBack: (FeedBackId, Case) =>
      getAuthenticatedAxios()
        .post(`${api.feedbackTickets}/${FeedBackId}/${Case}`)
        .then(getDataFromResponse),
  },

  LookupType: () =>
    getAuthenticatedAxios()
      .get(`${api.scheduledMaintenances}/maintenance-types/lookups`)
      .then(getDataFromResponse),
};
