import React from "react";
import { Button, Collapse, Row, Col } from "reactstrap";
import SharedComponent from "../../Shared";
import { FinancialResultGraph } from "../graphs/FinancialResultGraph";
import { Transactions } from "./Transactions";

export class SummaryAccount extends SharedComponent {
  state = {
    showTransactions: false,
    open: false,
  };

  renderOwnerInfo(json) {
    const data = {};
    json.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (!data[key]) data[key] = [];
        switch (key) {
          case "emails":
            data[key].push(
              ...item[key].map(({ data, type, primary }) => `${data} (${type})`)
            );
            break;
          case "addresses":
            data[key].push(
              ...item[key].map(
                ({
                  data: { city, region, street, country, postal_code },
                  primary,
                }) =>
                  `${street} ${city} ${region} ${postal_code} (${
                    primary ? "Primary" : "Secondary"
                  })`
              )
            );
            break;
          case "phone_numbers":
            data[key].push(
              ...item[key].map(({ data, type, primary }) => `${data} (${type})`)
            );
            break;
          default:
            data[key].push(...item[key]);
            break;
        }
      });
    });

    const keys = Object.keys(data);
    const md = keys.length / 12;
    return (
      <div className="d-flex justify-content-between flex-row">
        {keys.map((key) => (
          <div>
            <h5>{key.replace(/_/g, " ")}</h5>
            <ul className="p-0 pl-3">
              {data[key].map((el, k) => (
                <li key={`${key}${k}`}>{el}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    );
  }

  renderRecommendation(finrec) {
    return (
      <b
        className={
          finrec === "PASS"
            ? "text-success"
            : finrec === "FAIL"
            ? "text-danger"
            : "text-info"
        }
      >
        {finrec === "PASS" ? "✅" : finrec === "FAIL" ? "🚫" : "🔍"} {finrec}
      </b>
    );
  }

  render() {
    console.log("Rendered SummaryAccount", this.props);
    return (
      <>
        <Button
          onClick={() =>
            this.setState((e) => ({
              open: !e.open,
            }))
          }
          className="mb-2 d-flex justify-content-between py-2"
          style={{ width: "100%", textAlign: "left" }}
          color={this.state.open ? "dark" : "secondary"}
        >
          {this.props.account.logo_url && (
            <img
              src={this.props.account.logo_url}
              width={50}
              className="mr-2"
            />
          )}
          <h4 className="mb-0">
            {this.props.account.name} {this.props.account.ach_last4}
            <i> (via {this.props.account.type})</i>
          </h4>
          {this.props.account.recommendation ? (
            <div>
              {this.renderRecommendation(this.props.account.recommendation)}
              <span class="ml-2">
                {this.props.account.fincalc_json.draftableDays}/
                {this.props.account.fincalc_json.graph.data.length} Draftable
                Days
              </span>
              <span className="ml-4 text-info">
                Linked on{" "}
                {new Date(this.props.account.created_at).toLocaleString()}
              </span>
            </div>
          ) : (
            <div>Legacy Account No Financial Recommendation</div>
          )}
        </Button>
        <Collapse isOpen={this.state.open} className="bg-light p-2">
          {/* We make the chart the size of the wrapper which it needs to be open to get */}
          {this.state.open && this.props.account.fincalc_json && (
            <FinancialResultGraph
              {...this.props.account.fincalc_json}
              vehiclePayment={this.props.app.vehicles.monthly_payment}
              currentVehiclePayment={
                !this.props.app.user_profiles.is_keeping_current_vehicle &&
                this.props.app.user_profiles.monthly_car_payment
              }
            />
          )}
          <div className="my-4 flex">
            <h4>Account Info</h4>
            <div class="d-flex flex-row justify-content-between flex-1">
              <div class="flex-1">
                <div>Name</div>
                <b>
                  {this.props.account.name} ({this.props.account.account_type})
                </b>
              </div>
              <div class="flex-1">
                <div>ACH</div> <b>{this.props.account._ach}</b>
              </div>
              <div class="flex-1">
                <div>Routing</div>
                <b>{this.props.account.routing}</b>
              </div>
              <div class="flex-1">
                <div>Months of History</div>
                <b>{this.props.account.months_of_history}</b>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <h4>Owner(s) Info</h4>
            {this.props.account.owners_json && (
              <div class="d-flex flex-row justify-content-between flex-1">
                <div class="flex-1">
                  <div>Name(s)</div>
                  <b>
                    {this.props.account.owners_json
                      .flatMap(({ names }) => names)
                      .map((name) => (
                        <div>{name}</div>
                      ))}
                  </b>
                </div>
                <div class="flex-1">
                  <div>Address(es)</div>{" "}
                  <b>
                    {this.props.account.owners_json
                      .flatMap(({ addresses }) => addresses)
                      .flatMap(
                        ({
                          data: { city, region, street, postal_code },
                          primary,
                        }) => (
                          <div>
                            {street} {city} {region} {postal_code} (
                            {primary ? "Primary" : "Secondary"}){" "}
                          </div>
                        )
                      )}
                  </b>
                </div>
                <div class="flex-1">
                  <div>Phone Number(s)</div>
                  <b>
                    {this.props.account.owners_json
                      .flatMap(({ phone_numbers }) => phone_numbers)
                      .flatMap(({ data, type }) => (
                        <div>
                          {data} ({type})
                        </div>
                      ))}
                  </b>
                </div>
                <div class="flex-1">
                  <div>Email(s)</div>
                  <b>
                    {this.props.account.owners_json
                      .flatMap(({ emails }) => emails)
                      .flatMap(({ data, type }) => (
                        <div>
                          {data} ({type})
                        </div>
                      ))}
                  </b>
                </div>
              </div>
            )}
          </div>
          {this.props.account.account_transactions &&
          this.props.account.account_transactions.length ? (
            <Transactions
              transactions={this.props.account.account_transactions}
              app={this.props.app}
            />
          ) : (
            <h4>No transactions</h4>
          )}
        </Collapse>
      </>
    );
  }
}
