import React from "react";
import ContentWrapper from "../../Layout/ContentWrapper";
import {
  Row,
  Col,
  Card,
  CardBody,
  Badge,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Table,
} from "reactstrap";
import Pagination from "rc-pagination";
import { withRouter } from "react-router-dom";
import "rc-pagination/assets/index.css";
import Moment from "react-moment";
import SharedComponent from "../Shared";
import { Api } from "../../../Api/index";
export class DeliveryRequest extends SharedComponent {
  state = {
    inputVal: this.props.inputValue,
    dropdownOpen: false,
    isLoading: true,
    data: {
      Status: "",
    },
    items: [],
    requestOptions: {
      currentPage: 1,
      totalPages: 1,
      totalItems: 0,
      pageSize: 20,
    },
    modal: false,
    status: [
      {
        id: 1,
        value: "New",
      },
      {
        id: 2,
        value: "Pending",
      },
      {
        id: 3,
        value: "Delivered",
      },
      {
        id: 4,
        value: "Expired",
      },
    ],
  };
  componentDidMount() {
    this.queryItems();
  }

  render() {
    const { isLoading, items, status, data } = this.state;
    const { currentPage, pageSize, totalItems } = this.state.requestOptions;
    return (
      <ContentWrapper>
        <div className="content-heading">
          <div>Delivery Request</div>
        </div>
        <Card className="card-default">
          <CardBody>
            <Form className="mb-3 mt-3" onSubmit={this.handleSubmit}>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="Status">Status</Label>
                    <Input
                      type="select"
                      name="Status"
                      id="Status"
                      value={data.Status}
                      onChange={this.handleChange}
                    >
                      <option selected>Select Please</option>
                      {status.map((option) => (
                        <option key={option.id} value={option.value}>
                          {option.value}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <div
                      style={{ height: 30 }}
                      className="d-none d-md-block d-lg-block"
                    />
                    <Button type="submit" color={"info"} className="w-100">
                      Search
                    </Button>
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <div
                      style={{ height: 30 }}
                      className="d-none d-md-block d-lg-block"
                    />
                    <Button
                      color={"success"}
                      onClick={this.resetData}
                      className="w-100"
                    >
                      Reset Data
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            {!isLoading ? (
              <React.Fragment>
                {this.state.items.length !== 0 ? (
                  <React.Fragment>
                    <Row>
                      <Col md={12}>
                        <Table className="table table-bordered table-striped table-hover mb-4">
                          <thead>
                            <tr>
                              <th className="sort-alpha">ID</th>
                              <th className="sort-alpha">Application ID</th>
                              <th className="sort-alpha">Car Make</th>
                              <th className="sort-alpha">Car Model</th>
                              <th className="sort-alpha">Delivery Date</th>
                              <th className="sort-alpha">Client Name</th>
                              <th className="sort-alpha">Status</th>
                              <th className="sort-alpha">Delivery Address</th>
                              <th className="sort-alpha">Delivery Interval</th>
                              <th className="sort-alpha">Control </th>
                            </tr>
                          </thead>
                          <tbody>
                            {items.map((item) => (
                              <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.applicationId}</td>
                                <td>{item.carMake}</td>
                                <td>{item.carModel}</td>
                                <td>
                                  {item.deliveredAt !== undefined ? (
                                    <Moment format="MM/DD/YYYY">
                                      {item.deliveredAt}
                                    </Moment>
                                  ) : null}
                                </td>
                                <td>{item.clientName}</td>
                                <td>
                                  <Badge color="success"> {item.status}</Badge>
                                </td>
                                <td>{item.deliveryAddress}</td>
                                <td>{item.deliveryInterval}</td>
                                <td className="text-center">
                                  {item.status === "Pending" ? (
                                    <div>
                                      <Button
                                        color="info"
                                        className="btn btn-info d-inline-flex align-items-center"
                                        onClick={() =>
                                          this.handleSendCarDelivery(item.id)
                                        }
                                      >
                                        Deliver Car
                                      </Button>
                                    </div>
                                  ) : null}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Pagination
                          showSizeChanger
                          defaultPageSize={pageSize}
                          defaultCurrent={currentPage}
                          onChange={this.handlePageChange}
                          total={totalItems}
                          className="float-right"
                        />
                      </Col>
                    </Row>
                  </React.Fragment>
                ) : (
                  this.renderSadFace(true)
                )}
              </React.Fragment>
            ) : (
              this.renderLoader()
            )}
          </CardBody>
        </Card>
      </ContentWrapper>
    );
  }

  queryItems = (page) => {
    this.setState({
      isLoading: true,
    });
    const { Status } = this.state.data;
    const { pageSize, currentPage } = this.state.requestOptions;
    Api.DeliveryRequest.DeliveryRequestList(Status, page, currentPage, pageSize)
      .then((response) => {
        let items = response.items;
        this.setState({
          isLoading: false,
          items,
          requestOptions: {
            currentPage: response.currentPage,
            totalPages: response.totalPages,
            totalItems: response.totalItems,
            pageSize: response.pageSize,
          },
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        this.renderErrorAlert(
          "Ops!",
          "Something went wrong, please try again!"
        );
      });
  };

  handlePageChange = (page) => {
    const requestOptions = this.state.requestOptions;
    requestOptions.currentPage = page;
    this.setState({ requestOptions });
    this.queryItems();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.queryItems(1);
  };

  resetData = () => {
    const requestOptions = this.state.requestOptions;
    const data = this.state.data;
    requestOptions.currentPage = 1;
    requestOptions.totalPages = 1;
    requestOptions.totalItems = 0;
    requestOptions.pageSize = 20;
    data.Status = "";
    this.setState({ requestOptions, data });
    this.queryItems(1);
  };

  handleSendCarDelivery = (requestId) => {
    let applicationId = this.props.match.params.id;
    Api.DeliveryRequest.SendCarDelivery(applicationId, requestId)
      .then(() => {
        this.renderSuccessAlert(
          "The post has been successful",
          "Please check the email"
        );
        this.queryItems();
      })
      .catch((error) => {
        this.renderErrorAlert("Ops!", `${error.response.data.Message}`);
      });
  };
}

DeliveryRequest = withRouter(DeliveryRequest);
