import React from "react";
import { Row, Col, TabPane, Button, Table } from "reactstrap";
import { withRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import SharedComponent from "../Shared";
import UploadAttachmentModal from "./UploadAttachmentModal";
import { Api } from "../../../Api/index";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import UploadFuelImageModal from "./UploadFuelImageModal";
export default class Images extends SharedComponent {
  state = {
    defaultImageUrl: false,
    defaultImageId: null,
    items: [],
    newCar: null,
    modal: "",
    deleting: false,
    defaulting: false,
    moving: false,
  };

  componentDidMount() {
    let CarId = this.props.match.params.id;
    Api.CarManagement.CarDetails(CarId).then((r) => {
      this.setState({
        defaultImageUrl: r.displayPhotoUrl,
        defaultImageId: r.displayPhotoId,
      });
      const realUnitId = r.vin.slice(-8);
      Api["@driveavva"].new.Vehicles.VehicleDetail(realUnitId)
        .then((r) => {
          console.log(`${realUnitId} exists in database`, r);
          this.setState({ newCar: r });
        })
        .catch((e) => {
          console.log(
            `${realUnitId} does not yet exist in new db. Creating...`,
            e.message
          );
          // Vehicle doesn't exist yet. So let's make it
          Api["@driveavva"].new.Vehicles.CreateVehicle({
            body_style: r.bodyStyle,
            interior_color: r.interiorColor.toUpperCase(),
            exterior_color: r.exteriorColor
              ? r.exteriorColor.toUpperCase()
              : null,
            make: r.make.toUpperCase(),
            mileage: r.mileage,
            model: r.model.toUpperCase(),
            monthly_payment: r.monthlyPayment * 100,
            vin: r.vin,
            year: r.year,
          }).then((r) => {
            console.log(`Created new car`, r);
            this.setState({ newCar: r });
          });
        });
    });
    Api.CarManagement.Attachments(CarId, "Photo")
      .then((response) => {
        this.setState({ items: response });
      })
      .catch(console.log);
  }

  onDragEnd = (result) => {
    console.log("here");
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = this.state.items;
    const [removed] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, removed);

    items.map(this.movePhoto);

    this.setState({
      items,
    });
  };

  deletePhoto = (attachmentId) => {
    let CarId = this.props.match.params.id;
    this.setState({ deleting: attachmentId });
    Api.CarManagement.DeleteAttachment(CarId, attachmentId)
      .then((r) => {
        const items = this.state.items.filter((m) => m.id !== attachmentId);
        this.setState({ items, deleting: false });
      })
      .catch((e) => {
        this.setState({ deleting: false });
        this.renderErrorAlert(
          "Ops!",
          e.response.data
            ? e.response.data
            : "Something went wrong, please try again!"
        );
      });
  };

  toggleModal = (to) => {
    this.setState((s) => ({ modal: s === to ? "" : to }));
  };

  movePhoto = (photo, orderNumber) => {
    let carId = this.props.match.params.id;
    let formData = new FormData();
    formData.append("Order", orderNumber + 1);
    formData.append("Type", "Photo");
    formData.append("Url", photo.url);
    console.log("moving " + photo.id + " to " + orderNumber);
    Api.CarManagement.UpdateAttachment(carId, photo.id, formData).catch((e) => {
      console.warn(e);
      this.setState({ moving: false });
      this.renderErrorAlert("Ops!", "Something went wrong, please try again!");
    });
  };

  defaultPhoto = (photoId) => {
    this.setState({ defaulting: photoId });
    let carId = this.props.match.params.id;
    Api.CarManagement.DefaultPhoto(photoId, carId)
      .then((r) => {
        this.setState({
          defaulting: false,
          defaultImageUrl: r.url,
          defaultImageId: r.id,
        });
      })
      .catch((e) => {
        console.warn(e);
        this.setState({ defaulting: false });
        this.renderErrorAlert(
          "Ops!",
          "Something went wrong, please try again!"
        );
      });
  };

  render() {
    return (
      <TabPane tabId="Images">
        <Row>
          <Col md={12} className="mt-3">
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="mb-0">Images</h3>
              <div>
                <Button
                  className="mr-2"
                  color={"info"}
                  onClick={() => this.toggleModal("app")}
                >
                  Upload App Image
                </Button>
                <Button
                  color={"info"}
                  onClick={() => this.toggleModal("images")}
                >
                  Upload Images
                </Button>
              </div>
            </div>
            <Row className="mb-3 d-flex align-items-center">
              {this.state.newCar && this.state.newCar.app_photo ? (
                <Col md={4} className="mr-1 d-flex align-items-center">
                  <img
                    style={{ width: "40%" }}
                    alt="Default car display"
                    data-id={this.state.newCar.app_photo.id}
                    src={this.state.newCar.app_photo.url}
                  ></img>
                  <div class="ml-2 d-flex flex-column">
                    <h4>App Image</h4>
                    <small>
                      This is what will be used in the AVVA Mobile App
                    </small>
                    <small>
                      You can change this by uploading a new image using the
                      Upload App Image button above
                    </small>
                  </div>
                </Col>
              ) : (
                <Col md={4}>
                  No App Image set. Use the Upload App Image button to add a new
                  App image that will show in the AVVA Mobile App.
                </Col>
              )}
            </Row>
            <hr />
          </Col>
        </Row>
        {this.state.items && this.state.items.length > 0 ? (
          <Row>
            <Table>
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Url</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <tbody
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{
                        background: snapshot.isDraggingOver
                          ? "#edf1f2"
                          : "white",
                      }}
                    >
                      {this.state.items.map((item, i) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={i}
                        >
                          {(provided, snapshot) => (
                            <tr
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                border: "1px solid #dde6e9",
                                padding: 10,
                                background: snapshot.isDragging
                                  ? "#dde6e9"
                                  : "white",
                                ...provided.draggableProps.style,
                              }}
                            >
                              <th>
                                <img
                                  src={item.url}
                                  alt={item.url}
                                  width={175}
                                />
                              </th>
                              <th>
                                <a
                                  href={item.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {item.url}
                                </a>
                              </th>
                              <th>
                                {!snapshot.isDragging && (
                                  <Button
                                    className="delete-btn"
                                    onClick={() => this.deletePhoto(item.id)}
                                    color="danger"
                                    disabled={this.state.deleting === item.id}
                                  >
                                    {this.state.deleting === item.id
                                      ? "Deleting..."
                                      : "Delete"}
                                  </Button>
                                )}
                              </th>
                            </tr>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
          </Row>
        ) : (
          this.renderSadFace(false)
        )}

        <UploadAttachmentModal
          car={this.props.match.params.id}
          type="Photo"
          isOpen={this.state.modal === "images"}
          onClose={() => this.toggleModal("images")}
          allowMultiple
          onSuccess={(r) => {
            this.state.items.push(...r);
            this.state.items.sort((a, b) => (a.order < b.order ? -1 : 1));
            this.setState({ items: this.state.items, modal: null });
          }}
          onError={() =>
            this.renderErrorAlert(
              "Oops!",
              "Something went wrong, please try again!"
            )
          }
        />

        <UploadFuelImageModal
          car={this.state.newCar}
          type="Photo"
          isOpen={this.state.modal === "app"}
          onClose={() => this.toggleModal("app")}
          allowMultiple
          onSuccess={(appImage) => {
            this.setState({ appImage, modal: null });
          }}
          onError={() =>
            this.renderErrorAlert(
              "Oops!",
              "Something went wrong, please try again!"
            )
          }
        />
      </TabPane>
    );
  }
}

Images = withRouter(Images);
