import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Row,
  Tooltip,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ModalBody,
  Modal,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  ModalFooter,
} from "reactstrap";
import { Api } from "../../../../Api/index";
import SharedComponent from "../../Shared";

class SummaryApplicant extends SharedComponent {
  state = {
    consents: [],
    tooltip: false,
    modal: false,
  };
  address() {
    return `${this.props.user.primary_address}
        ${
          this.props.user.primary_address_line2
            ? " " + this.props.user.primary_address_line2
            : ""
        } ${this.props.user.primary_address_city} ${
      this.props.user.primary_address_state
    }, ${this.props.user.primary_address_zip}`;
  }

  toggle = (type) => {
    this.setState({
      tooltip: this.state.tooltip === type ? false : type,
    });
  };

  componentDidMount() {
    Api["@driveavva"].core.UsersManagement.Consents(
      this.props.user.user_id
    ).then((e) => {
      this.setState({
        consents: e.data ? (Array.isArray(e.data) ? e.data : [e.data]) : [],
      });
      console.log("Got User's Consents", e);
    });
  }

  renderConsent(type) {
    const maybeConsent = this.state.consents.find(
      (c) => c.type && c.type === type
    );
    if (maybeConsent) {
      console.log(maybeConsent);
      return (
        <>
          <span
            className="mr-1"
            color="secondary"
            id={"Tooltip" + maybeConsent.id}
          >
            ✅
          </span>
          <Tooltip
            isOpen={this.state.tooltip === type}
            target={"Tooltip" + maybeConsent.id}
            toggle={() => this.toggle(type)}
          >
            <div>
              Accepted At: {new Date(maybeConsent.created_at).toLocaleString()}
            </div>
            <div>
              Location: {maybeConsent.requests.city},{" "}
              {maybeConsent.requests.postal_code}, {maybeConsent.requests.state}
            </div>
            <div>IP Address: {maybeConsent.requests.ip}</div>
            <div>
              Coordinates: {maybeConsent.requests.lat},
              {maybeConsent.requests.lng}
            </div>
          </Tooltip>
        </>
      );
    }
    return <span>❌</span>;
  }

  render() {
    const vehicle = this.props.vehicle;
    return (
      <>
        <Card style={{ width: "100%" }}>
          <CardBody>
            <CardTitle tag="h3">
              Details{" "}
              <a
                className="ml-2"
                href={`/users/update/${this.props.user.user_id}`}
              >
                View User
              </a>
            </CardTitle>
            <CardText tag="div" className="mt-2">
              <h4 className="mt-2">Personal Info</h4>
              <Row>
                <Col xs={6} md={3} className="mb-2">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Legal Name</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      disabled
                      value={`${this.props.user.first_name || "TBD"} ${
                        this.props.user.middle_name || ""
                      } ${this.props.user.last_name || "TBD"}`}
                    />
                  </InputGroup>
                </Col>
                <Col xs={6} md={3} className="mb-2">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Preferred Name</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      disabled
                      value={
                        this.props.user.preferred_name ||
                        this.props.user.first_name
                      }
                    />
                  </InputGroup>
                </Col>
                <Col xs={6} md={3} className="mb-2">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Phone</InputGroupText>
                    </InputGroupAddon>
                    <Input disabled value={this.props.user.phone} />
                  </InputGroup>
                </Col>
                <Col xs={6} md={3} className="mb-2">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Email</InputGroupText>
                    </InputGroupAddon>
                    <Input disabled value={this.props.user.email} />
                  </InputGroup>
                </Col>
                <Col xs={6} md={3} className="mb-2">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>DOB</InputGroupText>
                    </InputGroupAddon>
                    <Input disabled value={this.props.user.date_of_birth} />
                  </InputGroup>
                </Col>
                <Col xs={6} md={3} className="mb-2">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Spouse's Name</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      disabled
                      value={this.props.user.spouse_name || "Not Married"}
                    />
                  </InputGroup>
                </Col>
                <Col xs={6} md={3} className="mb-2">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>SSN</InputGroupText>
                    </InputGroupAddon>
                    <Input disabled value={this.props.user._ssn} />
                  </InputGroup>
                </Col>
                <Col xs={6} md={3} className="mb-2">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>DL</InputGroupText>
                    </InputGroupAddon>
                    <Input disabled value={this.props.user._dl_number} />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>
                        {this.props.user.dl_state}
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
                <Col xs={12} className="mb-2">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Address</InputGroupText>
                    </InputGroupAddon>
                    <Input disabled value={this.address()} />
                  </InputGroup>
                </Col>
              </Row>
              <h4 className="mt-2">Employment Info</h4>
              <Row>
                <Col xs={6} md={3} className="mb-2">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Employer</InputGroupText>
                    </InputGroupAddon>
                    <Input disabled value={this.props.user.employer} />
                  </InputGroup>
                </Col>
                <Col xs={6} md={3} className="mb-2">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Occupation</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      disabled
                      value={this.props.user.employment_occupation}
                    />
                  </InputGroup>
                </Col>
                <Col xs={6} md={3} className="mb-2">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Time with Employer?</InputGroupText>
                    </InputGroupAddon>
                    <Input disabled value={this.props.user.employment_length} />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>mo.</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </Col>

                <Col xs={6} md={3} className="mb-2">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Monthly Income</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      disabled
                      value={this.renderCents(this.props.user.monthly_income)}
                    />
                  </InputGroup>
                </Col>
              </Row>
              <h4 className="mt-2">Additional Info</h4>
              <Row>
                <Col xs={6} md={3} className="mb-2">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Homeowner?</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      disabled
                      value={this.props.user.is_homeowner ? "✅" : "❌"}
                    />
                  </InputGroup>
                </Col>
                <Col xs={6} md={3} className="mb-2">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>First Responder?</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      disabled
                      value={this.props.user.is_first_responder ? "✅" : "❌"}
                    />
                  </InputGroup>
                </Col>
                <Col xs={6} md={3} className="mb-2">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Com. Pref.</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      disabled
                      value={this.props.user.communication_preference}
                    />
                  </InputGroup>
                </Col>
                <Col xs={6} md={3} className="mb-2">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>Monthly Rent</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      disabled
                      value={this.renderCents(
                        this.props.user.monthly_rent_payment
                      )}
                    />
                  </InputGroup>
                </Col>
              </Row>
              <h4 className="mt-2">Vehicle Info</h4>
              <Row>
                <Col>
                  <h4>
                    <a href={`inventory/update/${vehicle.depricated_id}`}>
                      {vehicle.year} {vehicle.make} {vehicle.model} (
                      {vehicle.vin || "coming-soon"})
                    </a>
                    <Button
                      onClick={() => this.setState({ modal: "change" })}
                      className="ml-4"
                    >
                      Switch
                    </Button>
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col xs={4} className="mb-2">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        User Reported Monthly Vehicle Payment
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      disabled
                      value={this.renderCents(
                        this.props.user.monthly_car_payment
                      )}
                    />
                  </InputGroup>
                </Col>
                <Col xs={4} className="mb-2">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        Will membership replace current vehicle?
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      disabled
                      value={
                        !this.props.user.is_keeping_current_vehicle
                          ? "✅"
                          : "❌"
                      }
                    />
                  </InputGroup>
                </Col>
                <Col xs={4} className="mb-2">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>AVVA Vehicle Payment</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      disabled
                      value={this.renderCents(vehicle.monthly_payment)}
                    />
                  </InputGroup>
                </Col>
              </Row>

              <h4 className="mt-2">Consents</h4>
              <Row>
                <Col xs={6} className="">
                  Terms and Conditions:{" "}
                  {this.renderConsent("auth_terms_and_conditions")}
                </Col>
                <Col xs={6}>
                  BG Check and MVR Lookup:{" "}
                  {this.renderConsent("apply_bg_mvr_check")}
                </Col>
              </Row>
            </CardText>
          </CardBody>
        </Card>
        <ChangeVehicleModal
          isOpen={this.state.modal === "change"}
          appId={this.props.application}
          onClose={() => this.setState({ modal: "" })}
        />
      </>
    );
  }
}

export { SummaryApplicant };

class ChangeVehicleModal extends SharedComponent {
  state = {
    vin: "",
    submitting: false,
    vehicles: false,
  };

  submit = (e) => {
    e.preventDefault();

    if (!this.state.vin) {
      this.setState({ ...this.state, message_error: "Please enter a VIN" });
      return;
    } else if (!/\b[(A-H|J-N|P|R-Z|0-9)]{17}\b/.test(this.state.vin)) {
      this.setState({
        ...this.state,
        message_error: "Please enter a valid VIN",
      });
      return;
    }

    this.setState({ message_error: null, submitting: true });
    Api["@driveavva"].core.AppsManagement.ChangeVehicle(this.props.appId, {
      vin: this.state.vin,
    })
      .then(() => {
        this.renderSuccessAlert(
          `Vehicle has been updated to ${this.state.vin}`,
          "Reload"
        );
        window.location.reload();
      })
      .catch((e) => {
        console.log(e);
        this.setState({ submitting: false });
        this.renderErrorAlert(
          "Oops!",
          e.error.message || "Something went wrong, please try again!"
        );
      });
  };

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.onClose}>
        <ModalHeader toggle={this.props.onClose}>Switch Vehicle</ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label className={this.state.message_error ? "error" : ""}>
                    VIN *
                  </Label>
                  <Input
                    name="vin"
                    id="vin"
                    className={this.state.message_error ? "error" : ""}
                    value={this.state.vin}
                    onChange={(e) => this.setState({ vin: e.target.value })}
                  />
                  {this.state.message_error ? (
                    <span className="error">{this.state.message_error}</span>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="info"
            onClick={this.submit}
            disabled={this.state.submitting}
          >
            {this.state.submitting ? "Submitting..." : "Submit"}
          </Button>
          <Button color="secondary" onClick={this.props.onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
