import { getDataFromResponse, getAuthenticatedAxios } from "./helpers";
import api from "./apiEndPoint";

export const UserApiEndpoints = {
  UserList: () =>
    getAuthenticatedAxios()
      .get(`${api.Users}`)
      .then(getDataFromResponse),

  CreateNewUser: (data) =>
    getAuthenticatedAxios()
      .post(`${api.Users}`, data)
      .then(getDataFromResponse),

  AdminDetails: (UserId) =>
    getAuthenticatedAxios()
      .get(`${api.Users}/${UserId}`)
      .then(getDataFromResponse),

  UpdateUser: (UserId, data) =>
    getAuthenticatedAxios()
      .put(`${api.Users}/${UserId}`, data)
      .then(getDataFromResponse),

  ResetPassword: (UserId) =>
    getAuthenticatedAxios()
      .post(`${api.Users}/${UserId}/Password/Reset`)
      .then(getDataFromResponse),
};
