import React from "react";
import Joi from "joi-browser";
import swal from "sweetalert";

class FormComponent extends React.Component {
  state = {};
  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };
  validate = () => {
    const options = { abortEarly: false };
    const { data } = this.state;
    const { error } = Joi.validate(data, this.schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };
  handleSubmit = e => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      console.clear();
    }
    if (!errors) {
      this.doSubmit();
    }
  };
  doSubmit = () => {
    console.log("handle Submit");
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, errors });
  };

  renderError(ex) {
    if (ex.response && ex.response.status < 500) {
      const errors = { ...this.state.errors };
      errors.name = ex.response.data.message;
      this.setState({ errors });
    }
  }

  renderSwall(icon, title, text, buttonMessage) {
    return swal({
      icon,
      title,
      text,
      buttons: {
        confirm: buttonMessage
      },
      dangerMode: icon === "error" ? true : false
    });
  }
}

export default FormComponent;
