import React from "react";
import { FormGroup, Label } from "reactstrap";

export default class DateTimeSelector extends React.Component {
  state = {
    dateTime: "",
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.dateTime !== this.state.dateTime) {
      this.props.dt_channel(this.state.dateTime);
    }

    if (prevProps.dateTime !== this.props.dateTime) {
      this.setState({ dateTime: this.props.dateTime });
    }
  }

  onChange = (e) => {
    this.setState({ dateTime: e.target.value });
  };

  componentDidMount() {
    if (this.props.defaultValue) {
      console.log(this.props.defaultValue);
      this.setState({
        dateTime: this.props.date
          ? this.props.defaultValue.split("T")[0]
          : this.props.defaultValue,
      });
    }
  }

  render() {
    return (
      <FormGroup>
        <Label className={this.props.error ? "error" : ""}>
          {this.props.label}
        </Label>
        <br />
        <input
          className={
            this.props.error ? "error date-time-select" : "date-time-select"
          }
          type={this.props.date ? "date" : "datetime-local"}
          value={this.state.dateTime}
          onChange={this.onChange}
        />
        {this.props.error && (
          <span
            className="error"
            dangerouslySetInnerHTML={{ __html: this.props.error }}
          />
        )}
      </FormGroup>
    );
  }
}
