import React from "react";
import { SummaryMember } from "../SummaryMember";
import { Banners } from "../Banners";
import { UserRewards } from "../UserRewards";

const Details = ({ member }) => (
  <div>
    {member ? (
      <>
        <SummaryMember user={member} />
        {/* {member.banners ? (
          <Banners banners={member.banners} />
        ) : (
          <p>No banners</p>
        )} */}

        <UserRewards data={member} />
      </>
    ) : null}

    {/* <pre>{JSON.stringify(member, null, 2)}</pre> */}
  </div>
);

export default Details;
